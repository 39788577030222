import { GET_CLEARING_REQUEST_FULFILLED, GET_CLEARING_REQUEST_PENDING, POST_CLEARING_REQUEST_CONFIRM_FULFILLED, } from 'constants/ActionTypes';
const clearingRequestReducer = (state = null, action) => {
    switch (action.type) {
        case GET_CLEARING_REQUEST_PENDING: {
            return null;
        }
        case GET_CLEARING_REQUEST_FULFILLED: {
            return action.payload.data;
        }
        case POST_CLEARING_REQUEST_CONFIRM_FULFILLED: {
            return { ...state, confirmed: true };
        }
        default:
            return state;
    }
};
export default clearingRequestReducer;
