import classnames from 'classnames';
import extend from 'lodash/extend';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React from 'react';
import Social from 'constants/Social';
import openPopup from 'utils/openPopup';
export default class SocialButton extends React.Component {
    static contextTypes = {
        history: PropTypes.object,
    };
    static propTypes = {
        component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        service: PropTypes.oneOf(['facebook', 'twitter', 'vkontakte']).isRequired,
        text: PropTypes.string,
    };
    static defaultProps = {
        component: 'span',
    };
    render() {
        return React.createElement(this.props.component, extend({}, omit(this.props, ['service', 'text', 'component', 'link']), {
            className: classnames(this.props.className, 'social-button'),
            onClick: this._handleClick.bind(this),
        }), this.props.children);
    }
    _handleClick(event) {
        event.preventDefault();
        const resolveUrl = require('resolve-url');
        const link = this.props.link ? resolveUrl(this.props.link) : window.location.href;
        const params = {
            href: window.encodeURIComponent(link),
            text: this.props.text,
            via: 'type.today',
        };
        const href = Social[this.props.service].replace(/(\{\{(.*?)\}\})/g, (match, p1, p2) => {
            return params[p2] ? params[p2] : '';
        });
        openPopup(href, this.props.service, 600, 320);
    }
}
