import React from 'react';
import { useParallax } from 'react-scroll-parallax';
import styled from 'styled-components';
import bubblesSrc from 'assets/Home/bubbles.png';
import elementsSrc from 'assets/Home/elements.png';
import shoesSrc from 'assets/Home/shoes.png';
import squigglesSrc from 'assets/Home/squiggles.png';
import { desktop } from '../utils/mediaQueries';
const KeyVisual = () => {
    const bubbles = useParallax({ speed: 3 });
    const squiggles = useParallax({ speed: 7 });
    const elements = useParallax({ speed: 9 });
    return (React.createElement(Wrapper, null,
        React.createElement(Bubbles, { ref: bubbles.ref }),
        React.createElement(Squiggles, { ref: squiggles.ref }),
        React.createElement(Shoes, null),
        React.createElement(Elemenst, { ref: elements.ref })));
};
const ImageDiv = styled.div `
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @media ${desktop()} {
    background-size: contain;
  }
`;
const Wrapper = styled.div `
  width: 100%;
  height: 190px;
  position: relative;
  margin: 0 auto 20px;

  @media ${desktop()} {
    height: 430px;
    margin: 0 auto 10px;
  }
`;
const Bubbles = styled(ImageDiv) `
  background-image: url(${bubblesSrc});
`;
const Shoes = styled(ImageDiv) `
  background-image: url(${shoesSrc});
`;
const Squiggles = styled(ImageDiv) `
  background-image: url(${squigglesSrc});
`;
const Elemenst = styled(ImageDiv) `
  background-image: url(${elementsSrc});
`;
export default KeyVisual;
