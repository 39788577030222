import React from 'react';
import styled from 'styled-components';
import chevroneSrc from 'assets/AndroidInstructions/icon_chevrone.svg';
class SelectDevice extends React.Component {
    onChange = (e) => {
        const $current = e.currentTarget;
        this.props.selectDevice($current.value);
    };
    getOptionLabel = ({ key, instruction }) => {
        if (key === 'other')
            return key;
        return instruction.label || key;
    };
    render() {
        const { instructions, currentDevice } = this.props;
        return (React.createElement(Select, { value: currentDevice, onChange: this.onChange }, Object.keys(instructions).map((key) => (React.createElement("option", { value: key, key: key }, this.getOptionLabel({ key, instruction: instructions[key] }))))));
    }
}
const Select = styled.select `
  -webkit-appearance: none;
  text-transform: capitalize;
  -moz-appearance: none;
  margin-bottom: 24px;
  width: 100%;
  padding: 16px 44px 16px 15px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: white;
  background-color: transparent;
  font-weight: 600;
  background: url(${chevroneSrc}) no-repeat;
  background-position: right 14px center;
  outline: none;
  font-family: 'Roboto';

  &:focus {
    outline: none;
  }

  &::-ms-expand {
    display: none;
  }
`;
export default SelectDevice;
