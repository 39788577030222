import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AdviceNotSuitable, Common, Footer, List, ListItem, SamsungDivider, SettingsButton, Title } from './common';
const messages = defineMessages({
    title: 'Remove restrictions',
    settings: 'Settings',
    power: 'Power',
    battery: 'Battery optimization',
    notOptimized: 'Not optimized',
    apps: 'All apps',
    sweatcoin: 'Sweatcoin',
    dontOptimize: 'Enable “Don’t optimize”',
});
const HTC = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Common, { featureAccelerometerTracker: props.featureAccelerometerTracker, trackEvent: props.trackEvent, openSettings: props.openSettings, isFooterDividerDefault: true }),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.title })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.power })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.battery })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.notOptimized })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.dontOptimize }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(SamsungDivider, null),
        React.createElement(Footer, { trackEvent: props.trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: props.trackEvent })));
};
export default HTC;
