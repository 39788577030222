import queryString from 'query-string';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import fonts from '../../../assets/fonts/index.css';
import rooms from '../rooms';
const Showroom = ({ match, location }) => {
    const [name, type] = match.params.name.split('-');
    const params = queryString.parse(location.search);
    const Room = rooms[name];
    if (Room) {
        return (React.createElement(React.Fragment, null,
            React.createElement(GlobalStyle, null),
            React.createElement(Room, { ...params, type: type })));
    }
    return null;
};
export default Showroom;
const GlobalStyle = createGlobalStyle `
  ${fonts};
  html,
  body {
    background: transparent !important;
    color: white !important;
  }
`;
