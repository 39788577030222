const createRoutes = () => [
    {
        path: '/quiz/walker/:result',
        component: require('./pages/Walker').default,
    },
    {
        path: '/quiz/walker',
        params: { disableDefaultImageSrc: true },
        exact: true,
        component: require('./pages/Walker').default,
    },
    {
        path: '/quiz/shoes/:result',
        component: require('./pages/Shoes').default,
    },
    {
        path: '/quiz/shoes',
        params: { disableDefaultImageSrc: true },
        exact: true,
        component: require('./pages/Shoes').default,
    },
];
export default createRoutes;
