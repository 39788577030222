import { media } from 'components/responsive';
const container_height = {
    mobile: 80,
    tablet: 90,
};
export const offsetByHeaderHeight = (property) => `
  ${property}: ${container_height.mobile}px;
  ${media.tablet `
    ${property}: ${container_height.tablet}px;
  `}
`;
export const headerHeight = {
    phone: 70,
    tablet: 75,
    desktop: 90,
};
