import { useState } from 'react';
export const getRandomNum = (max) => {
    return Math.floor(Math.random() * (max - 0)) + 0;
};
export const fittingString = (c, str, maxWidth) => {
    var width = c.measureText(str).width;
    var ellipsis = '…';
    var ellipsisWidth = c.measureText(ellipsis).width;
    if (width <= maxWidth || width <= ellipsisWidth) {
        return str;
    }
    else {
        var len = str.length;
        while (width >= maxWidth - ellipsisWidth && len-- > 0) {
            str = str.substring(0, len);
            width = c.measureText(str).width;
        }
        return str + ellipsis;
    }
};
export const getRandomId = () => {
    return Math.random().toString().replace('0.', '');
};
// Copy to clipboard hook
export const useCopyToClipboard = () => {
    const [copiedText, setCopiedText] = useState(null);
    const copy = async (text) => {
        if (!navigator?.clipboard) {
            console.warn('Clipboard not supported');
            return false;
        }
        try {
            await navigator.clipboard.writeText(text);
            setCopiedText(text);
            return true;
        }
        catch (error) {
            console.warn('Copy failed', error);
            setCopiedText(null);
            return false;
        }
    };
    return [copiedText, copy];
};
