import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import styled, { keyframes } from 'styled-components';
import logoSrc from 'assets/Home/home_swc_logo.svg';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    title: 'Sweatcoin has been the No.1 app overall in over 60 countries',
    TOP_APPSTORE_USA: '🇺🇸 USA',
    TOP_APPSTORE_UK: '🇬🇧 UK',
    TOP_APPSTORE_ALGERIA: '🇩🇿 Algeria',
    TOP_APPSTORE_ANGUILLA: '🇬🇫 Anguilla',
    TOP_APPSTORE_ARGENTINA: '🇦🇷 Argentina',
    TOP_APPSTORE_AUSTRIA: '🇦🇹 Austria',
    TOP_APPSTORE_AZERBAIJAN: '🇦🇿 Azerbaijan',
    TOP_APPSTORE_BAHRAIN: '🇧🇭 Bahrain',
    TOP_APPSTORE_BELGIUM: '🇧🇪 Belgium',
    TOP_APPSTORE_BOLIVIA: '🇧🇴 Bolivia',
    TOP_APPSTORE_BULGARIA: '🇧🇬 Bulgaria',
    TOP_APPSTORE_CANADA: '🇨🇦 Canada',
    TOP_APPSTORE_CAPE_VERDE: '🇨🇻 Cape Verde',
    TOP_APPSTORE_CHILE: '🇨🇱 Chile',
    TOP_APPSTORE_COLOMBIA: '🇨🇴 Colombia',
    TOP_APPSTORE_COSTA_RICA: '🇨🇷 Costa Rica',
    TOP_APPSTORE_CZECH_REPUBLIC: '🇨🇿 Czech Republic',
    TOP_APPSTORE_DOMINICA: '🇩🇲 Dominica',
    TOP_APPSTORE_DOMINICAN_REPUBLIC: '🇩🇴 Dominican Republic',
    TOP_APPSTORE_ECUADOR: '🇪🇨 Ecuador',
    TOP_APPSTORE_EGYPT: '🇪🇬 Egypt',
    TOP_APPSTORE_EL_SALVADOR: '🇸🇻 El Salvador',
    TOP_APPSTORE_ESTONIA: '🇪🇪 Estonia',
    TOP_APPSTORE_FRANCE: '🇫🇷 France',
    TOP_APPSTORE_GEORGIA: '🇬🇪 Georgia',
    TOP_APPSTORE_GERMANY: '🇩🇪 Germany',
    TOP_APPSTORE_GUATEMALA: '🇬🇹 Guatemala',
    TOP_APPSTORE_HONDURAS: '🇭🇳 Honduras',
    TOP_APPSTORE_HUNGARY: '🇭🇺 Hungary',
    TOP_APPSTORE_ISRAEL: '🇮🇱 Israel',
    TOP_APPSTORE_ITALY: '🇮🇹 Italy',
    TOP_APPSTORE_JORDAN: '🇯🇴 Jordan',
    TOP_APPSTORE_LATVIA: '🇱🇻 Latvia',
    TOP_APPSTORE_LEBANON: '🇱🇧 Lebanon',
    TOP_APPSTORE_LITHUANIA: '🇱🇹 Lithuania',
    TOP_APPSTORE_LUXEMBOURG: '🇱🇺 Luxembourg',
    TOP_APPSTORE_MALTA: '🇲🇹 Malta',
    TOP_APPSTORE_MEXICO: '🇲🇽 Mexico',
    TOP_APPSTORE_MOLDOVA: '🇲🇩 Moldova',
    TOP_APPSTORE_MOROCCO: '🇲🇦 Morocco',
    TOP_APPSTORE_MOZAMBIQUE: '🇲🇿 Mozambique',
    TOP_APPSTORE_NETHERLANDS: '🇳🇱 Netherlands',
    TOP_APPSTORE_NORWAY: '🇳🇴 Norway',
    TOP_APPSTORE_PARAGUAY: '🇵🇾 Paraguay',
    TOP_APPSTORE_PERU: '🇵🇪 Peru',
    TOP_APPSTORE_PHILIPPINES: '🇵🇭 Philippines',
    TOP_APPSTORE_POLAND: '🇵🇱 Poland',
    TOP_APPSTORE_PORTUGAL: '🇵🇹 Portugal',
    TOP_APPSTORE_QATAR: '🇶🇦 Qatar',
    TOP_APPSTORE_ROMANIA: '🇷🇴 Romania',
    TOP_APPSTORE_SAO_TOME_AND_PRINCIPE: '🇸🇹 São Tomé and Príncipe',
    TOP_APPSTORE_SAUDI_ARABIA: '🇸🇦 Saudi Arabia',
    TOP_APPSTORE_SPAIN: '🇪🇸 Spain',
    TOP_APPSTORE_SWITZERLAND: '🇨🇭 Switzerland',
    TOP_APPSTORE_TUNISIA: '🇹🇳 Tunisia',
    TOP_APPSTORE_UAE: '🇦🇪 UAE',
    TOP_APPSTORE_UKRAINE: '🇺🇦 Ukraine',
    TOP_APPSTORE_UNITED_ARAB_EMIRATES: '🇦🇪 United Arab Emirates',
    TOP_APPSTORE_URUGUAY: '🇺🇾 Uruguay',
    TOP_APPSTORE_UZBEKISTAN: '🇺🇿 Uzbekistan',
    TOP_APPSTORE_VENEZUELA: '🇻🇪 Venezuela',
});
const TOP_APPSTORE = [
    'USA',
    'UK',
    'Algeria',
    'Anguilla',
    'Argentina',
    'Austria',
    'Azerbaijan',
    'Bahrain',
    'Belgium',
    'Bolivia',
    'Bulgaria',
    'Canada',
    'Cape_Verde',
    'Chile',
    'Colombia',
    'Costa_Rica',
    'Czech_Republic',
    'Dominica',
    'Dominican_Republic',
    'Ecuador',
    'Egypt',
    'El_Salvador',
    'Estonia',
    'France',
    'Georgia',
    'Germany',
    'Guatemala',
    'Honduras',
    'Hungary',
    'Israel',
    'Italy',
    'Jordan',
    'Latvia',
    'Lebanon',
    'Lithuania',
    'Luxembourg',
    'Malta',
    'Mexico',
    'Moldova',
    'Morocco',
    'Mozambique',
    'Netherlands',
    'Norway',
    'Paraguay',
    'Peru',
    'Philippines',
    'Poland',
    'Portugal',
    'Qatar',
    'Romania',
    'Sao_Tome_and_Principe',
    'Saudi_Arabia',
    'Spain',
    'Switzerland',
    'Tunisia',
    'UAE',
    'Ukraine',
    'United_Arab_Emirates',
    'Uruguay',
    'Uzbekistan',
    'Venezuela',
];
const TopCountries = ({}) => {
    const intl = useIntl();
    const countries = getTopAppstore();
    return (React.createElement(Wrapper, null,
        React.createElement(Title, null,
            React.createElement("img", { src: logoSrc, width: "36", height: "36", alt: "Sweatcoin logo" }),
            React.createElement(FormattedMessage, { ...messages.title })),
        React.createElement(CountriesSlider, null,
            React.createElement(Marquee, null,
                React.createElement(MarqueeWrap, null, countries.map((country, index) => (React.createElement("span", { key: index }, country))))),
            React.createElement(Marquee, null,
                React.createElement(MarqueeWrap2, null, countries.map((country, index) => (React.createElement("span", { key: index }, country))))))));
    function getTopAppstore() {
        return TOP_APPSTORE.reduce((top, country) => {
            const formattedCountry = intl.formatMessage(messages[`TOP_APPSTORE_${country.toUpperCase()}`]);
            return top.concat(formattedCountry);
        }, []);
    }
};
const marqueeAnimation = keyframes `
  0% {
    transform: translate(100%, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
`;
const Wrapper = styled.div `
  margin-top: 50px;
  padding-top: 50px;
  overflow: hidden;
  border-top: 1px solid rgba(170, 170, 170, 0.2);

  @media ${desktop()} {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
`;
const Title = styled.h3 `
  margin: 0 16px;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase;
  display: flex;

  @media ${desktop()} {
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 20px;
  }

  img {
    display: block;
    margin-right: 8px;
    opacity: 0.8;
    width: 36px;
    height: 36px;
  }
`;
const CountriesSlider = styled.div `
  position: relative;
  margin: 20px 0;
  height: 20px;

  @media ${desktop()} {
    margin: 20px 0;
    height: 25px;
  }
`;
const Marquee = styled.div `
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;

  span {
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: uppercase;
    margin: 0 16px;
    display: inline-block;

    @media ${desktop()} {
      font-size: 20px;
    }
  }
`;
const MarqueeWrap = styled.div `
  display: inline-block;
  animation: ${marqueeAnimation} 240s linear infinite;
  animation-delay: -120s;
`;
const MarqueeWrap2 = styled.div `
  display: inline-block;
  animation: ${marqueeAnimation} 240s linear infinite;
`;
export default TopCountries;
