import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import scr1 from 'assets/AndroidInstructions/samsung/battery_eng_01.png';
import scr2 from 'assets/AndroidInstructions/samsung/battery_eng_02.png';
import scr3 from 'assets/AndroidInstructions/samsung/battery_eng_03.png';
import scr4 from 'assets/AndroidInstructions/samsung/battery_eng_04.png';
import scr5 from 'assets/AndroidInstructions/samsung/battery_eng_05.png';
import scr6 from 'assets/AndroidInstructions/samsung/battery_eng_06.png';
import scr1Location from 'assets/AndroidInstructions/samsung/improve_acc_eng_01.png';
import scr2Location from 'assets/AndroidInstructions/samsung/improve_acc_eng_02.png';
import scr3Location from 'assets/AndroidInstructions/samsung/improve_acc_eng_03.png';
import scr4Location from 'assets/AndroidInstructions/samsung/improve_acc_eng_04.png';
import OnVisible from 'routes/Home/components/OnVisible';
import SliderBlock from '../SliderBlock';
import { AdviceNotSuitable, Footer, SamsungDivider, SettingsButton, Title } from './common';
const messages = defineMessages({
    card1: 'Go to Settings > Apps',
    card2: 'Go to Sweatcoin',
    card3: 'Tap Battery',
    card4: 'Enable Allow background activity, then tap Optimize battery usage',
    card5: 'Filter by All and find Sweatcoin',
    card6: 'Disable for Sweatcoin',
    card7: 'Go to Settings > Biometrics and security',
    card8: 'Enable Location, then tap on it',
    card9: 'Tap Improve accuracy',
    card10: 'Enable both options',
});
const CardsBackgroundActivity = [
    {
        screenshot: scr1,
        description: React.createElement(FormattedMessage, { ...messages.card1 }),
    },
    {
        screenshot: scr2,
        description: React.createElement(FormattedMessage, { ...messages.card2 }),
    },
    {
        screenshot: scr3,
        description: React.createElement(FormattedMessage, { ...messages.card3 }),
    },
    {
        screenshot: scr4,
        description: React.createElement(FormattedMessage, { ...messages.card4 }),
    },
    {
        screenshot: scr5,
        description: React.createElement(FormattedMessage, { ...messages.card5 }),
    },
    {
        screenshot: scr6,
        description: React.createElement(FormattedMessage, { ...messages.card6 }),
    },
];
const CardsLocation = [
    {
        screenshot: scr1Location,
        description: React.createElement(FormattedMessage, { ...messages.card7 }),
    },
    {
        screenshot: scr2Location,
        description: React.createElement(FormattedMessage, { ...messages.card8 }),
    },
    {
        screenshot: scr3Location,
        description: React.createElement(FormattedMessage, { ...messages.card9 }),
    },
    {
        screenshot: scr4Location,
        description: React.createElement(FormattedMessage, { ...messages.card10 }),
    },
];
function renderCard(index, screenshot) {
    return (React.createElement(Card, { key: index },
        React.createElement(CardImage, null,
            React.createElement(Placeholder, null),
            React.createElement(Screenshot, { src: screenshot }))));
}
let trackedEvents = [];
const Samsung9 = (props) => {
    const { openSettings, trackEvent } = props;
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 280,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    useEffect(() => {
        // cleanup
        return () => {
            trackedEvents = [];
        };
    }, []);
    const cardsCount = CardsBackgroundActivity.length;
    const CardArray = Array(cardsCount)
        .fill(null)
        .map((v, index) => renderCard(index, CardsBackgroundActivity[index].screenshot));
    const CardLocationArray = Array(CardsLocation.length)
        .fill(null)
        .map((v, index) => renderCard(index, CardsLocation[index].screenshot));
    const secondSliderRef = React.createRef();
    const onVisibleSecondSlider = () => {
        const event = 'slide_viewed_1_0';
        if (trackedEvents.indexOf(event) === -1) {
            trackEvent(event, { direction: null });
            trackedEvents.push(event);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, { trackEvent: props.trackEvent }, "Allow background activity"),
        React.createElement(SliderBlock, { trackedEvents: trackedEvents, trackEvent: trackEvent, index: 0, settings: sliderSettings, cardArray: CardArray, cards: CardsBackgroundActivity }),
        React.createElement(SettingsButton, { openSettings: openSettings }, "Go to settings"),
        React.createElement(SamsungDivider, null),
        !props.featureAccelerometerTracker
            ? [
                React.createElement(Title, { trackEvent: props.trackEvent }, "Improve GPS accuracy"),
                React.createElement(OnVisible, { onVisible: onVisibleSecondSlider, treshold: 0.4 },
                    React.createElement("div", { ref: secondSliderRef },
                        React.createElement(SliderBlock, { trackedEvents: trackedEvents, trackEvent: trackEvent, index: 1, settings: sliderSettings, cardArray: CardLocationArray, cards: CardsLocation }))),
                React.createElement(SettingsButton, { openSettings: openSettings }),
                React.createElement(SamsungDivider, null),
            ]
            : null,
        React.createElement(Footer, { trackEvent: trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: trackEvent })));
};
const Card = styled.div `
  padding: 16px 0 40px;
  width: calc(100% - 32px) !important;
  margin: 0 16px 0 0;
  border-radius: 9px;
  background: rgba(255, 255, 255, 0.1);
  outline: none;
  position: relative;
`;
const CardImage = styled.div `
  width: calc(100% - 64px);
  max-width: 264px;
  margin: 0 auto;
  position: relative;
`;
const Screenshot = styled.img `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
const Placeholder = styled.div `
  height: 0;
  padding-top: 97.72%;
`;
export default Samsung9;
