import ClipboardJS from 'clipboard';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import { useLocation, useRouteMatch } from 'react-router-dom';
import useFirebaseQuery from 'utils/useFirebaseQuery';
const channelSelector = (state) => get(state, 'config.location.query.channel');
const featureSelector = (state) => get(state, 'config.location.query.feature');
const campaignSelector = (state) => get(state, 'config.location.query.campaign');
const platformSelector = (state) => get(state, 'config.location.query.platform');
const sourceSelector = (state) => get(state, 'config.location.query.source');
// required for Firebase links to survive AppStore install
const useFirebaseClipboardRef = (text) => {
    const ref = useRef(null);
    // we cannot add ref.current as dep for useEffect by design of React
    // so we use isReady workaround instead
    const [isReady, setIsReady] = useState(false);
    const setRef = useCallback((node) => {
        ref.current = node;
        setIsReady(true);
    }, []);
    useEffect(() => {
        if (isReady && ref.current) {
            const clipboard = new ClipboardJS(ref.current, { text });
            return () => {
                clipboard.destroy();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReady]);
    return setRef;
};
const useFirebaseClipboardRefs = (texts) => {
    const buttonRef1 = useFirebaseClipboardRef(texts[0]);
    const buttonRef2 = useFirebaseClipboardRef(texts[1]);
    const buttonRef3 = useFirebaseClipboardRef(texts[2]);
    const buttonRef4 = useFirebaseClipboardRef(texts[3]);
    // just add another one if you need one more, but better avoid too many
    return [buttonRef1, buttonRef2, buttonRef3, buttonRef4];
};
const getNonEmptyInviterParams = (inviter, location) => {
    const urlInviterId = new URLSearchParams(location.search).get('inviter_id');
    const profileId = inviter?.id;
    const name = inviter?.fullname;
    const inviterParams = {};
    const inviterId = urlInviterId || profileId;
    if (inviterId) {
        inviterParams.inviter_id = inviterId;
    }
    if (name) {
        inviterParams.inviter_name = name;
    }
    return inviterParams;
};
const useTracking = () => {
    const route = useRouteMatch();
    const channel = useSelector(channelSelector) || get(route, 'params.channel', 'other');
    const platform = useSelector(platformSelector) || get(route, 'params.platform', 'unknown');
    const feature = useSelector(featureSelector) || get(route, 'params.feature');
    const campaign = useSelector(campaignSelector) || get(route, 'params.campaign');
    const source = useSelector(sourceSelector) || 'website';
    return { channel, platform, feature, campaign, source };
};
const configSelector = (state) => state.config;
const useConfig = () => {
    const config = useSelector(configSelector);
    return config;
};
const getNonEmptyTrackingParams = (tracking) => {
    const trackingParams = {};
    for (const [key, value] of Object.entries(tracking)) {
        if (value) {
            trackingParams[key] = value;
        }
    }
    return trackingParams;
};
const useFirebaseEssentials = ({ owner, deeplink_path, feature: custom_feature, transformTextLink, path = '/', refsTextFns, }) => {
    const location = useLocation();
    const tracking = useTracking();
    const data = {
        ...getNonEmptyInviterParams(owner, location),
        ...getNonEmptyTrackingParams(tracking),
    };
    if (deeplink_path) {
        data.deeplink_path = deeplink_path;
    }
    if (custom_feature) {
        data.feature = custom_feature;
    }
    const [firebaseQuery, getCopyLink, getRedirectLink] = useFirebaseQuery(path, data);
    const getTextFn = () => {
        if (transformTextLink) {
            return transformTextLink(getCopyLink());
        }
        return getCopyLink;
    };
    // in very rare cases we want to tell from outside what clipboard should use as text fn
    const buttonRefsTextFns = refsTextFns?.map((textFn) => (textFn === undefined ? getTextFn : textFn)) ||
        // 4 placeholders for clipboardRefs
        [null, null, null, null].map(getTextFn);
    const buttonsRefs = useFirebaseClipboardRefs(buttonRefsTextFns);
    const config = useConfig();
    const firebaseData = { firebaseQuery, getCopyLink, getRedirectLink };
    return { buttonsRefs, firebaseData, tracking, config };
};
export { useFirebaseEssentials, useTracking };
