import QRCode from 'qrcode.react';
import React from 'react';
import styled from 'styled-components';
const qrCode = ({ className, link, size = 260, padding = 15 }) => {
    const paddingSize = padding * 2;
    return (React.createElement(Layout, { className: className, size: size, padding: padding },
        React.createElement(QRCode, { value: link, size: size - paddingSize })));
};
const Layout = styled.div `
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: 0 auto;
  padding: ${({ padding }) => padding}px;
  background-color: #fff;
`;
export default qrCode;
