import React from 'react';
import styled from 'styled-components';
import noteIconSrc from 'assets/SweatPaws/note-icon.svg';
import { media } from 'components/responsive';
const ErrorMessage = (props) => {
    return (React.createElement(Container, null,
        React.createElement("span", null, props.text)));
};
const Container = styled.p `
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  padding: 12px 0;
  background: linear-gradient(90.87deg, #ff004d 0%, #dd0042 100%);
  box-shadow: 2px 2px 30px rgba(113, 0, 34, 0.6);
  border-radius: 4px;

  ${media.desktop `
    padding: 16px 0;
  `};

  span {
    position: relative;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #ffffff;

    ${media.desktop `
      font-size: 16px;
    `};

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -24px;
      width: 15px;
      height: 13px;
      background: url('${noteIconSrc}'), linear-gradient(90.87deg, #ff004d 0%, #dd0042 100%);
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }
`;
export default ErrorMessage;
