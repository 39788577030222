import classnames from 'classnames';
import React from 'react';
import { CookieBannerUniversal } from 'react-cookie-banner';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { matchRoutes, renderRoutes } from 'react-router-config';
import { NavLink } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import modernNormalize from 'styled-modern-normalize';
import appIcon from 'assets/app-icon.jpg';
import crossIconUrl from 'assets/cross_icon.svg';
import FONTS, { setFontFace } from 'assets/fonts';
import sharingUrl from 'assets/sharing.jpg';
import { media } from 'components/responsive';
import COLORS from 'constants/colors';
import faviconUrl from 'public/favicon.ico';
import { originSelector } from 'selectors/root';
import ErrorComponent from './ErrorComponent';
import 'assets/fonts';
import 'styles/globals';
const messages = defineMessages({
    title: 'Sweatcoin: Healthier planet. Healthier, wealthier you',
    description: 'Join our 170M+ users to become part of the movement economy. Sweatcoin app converts your steps into sweatcoins — virtual currency that you can spend on products and services. It Pays to Walk.',
    cookies: 'We use cookies 🍪 to provide the best experience. By continuing to use our website, you agree to our cookies policy',
    privacy: 'Privacy policy',
});
const errorSelector = (state) => state.error.nextCode;
const MAIN_DOMAIN = 'https://sweatco.in';
const Root = (props) => {
    const { location, route, serverRouteParams } = props;
    const branch = matchRoutes([route], location.pathname);
    const { cookies = true } = branch[branch.length - 1].route;
    const intl = useIntl();
    const origin = useSelector(originSelector);
    const code = useSelector(errorSelector);
    // dont render cookiebar on Android Instructions page & Showroom page
    const shouldShowCookieBanner = cookies && location.pathname !== '/android-instructions' && !location.pathname.includes('showroom');
    const absoluteSharingUrl = require('url').resolve(origin, sharingUrl);
    const noindex = origin !== MAIN_DOMAIN ? [{ name: 'robots', content: 'noindex' }] : [];
    const canonical = origin === MAIN_DOMAIN
        ? [
            {
                rel: 'canonical',
                href: `${origin}${location.pathname}${location.search}`,
            },
        ]
        : [];
    const imageSrc = serverRouteParams && serverRouteParams.disableDefaultImageSrc
        ? []
        : [{ rel: 'image_src', href: absoluteSharingUrl }];
    const title = intl.formatMessage(messages.title);
    const description = intl.formatMessage(messages.description);
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyle, null),
        shouldShowCookieBanner ? (React.createElement(CookieBannerUniversal, { dismissOnScroll: false, cookiePath: "/" }, (onAccept) => (React.createElement(CookieBanner, null,
            React.createElement(StyledNavLink, { to: "/privacy" },
                React.createElement(FormattedMessage, { ...messages.cookies })),
            React.createElement(CookieBannerClose, { onClick: onAccept }))))) : null,
        React.createElement("section", { className: classnames('root', 'max-height') },
            React.createElement(Helmet, { title: title, link: [{ rel: 'shortcut icon', href: faviconUrl }, ...imageSrc, ...canonical], meta: [
                    { name: 'apple-mobile-web-app-capable', content: 'yes' },
                    { name: 'description', content: description },
                    { property: 'al:ios:url', content: 'swc://' },
                    { property: 'al:ios:app_store_id', content: '971023427' },
                    { property: 'al:ios:app_name', content: title },
                    { property: 'al:web:url', content: 'https://sweatco.in/' },
                    {
                        property: 'og:url',
                        content: `${origin}${location.pathname}${location.search}`,
                    },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:site_name', content: 'Sweatcoin' },
                    { property: 'og:title', content: title },
                    { property: 'og:description', content: description },
                    { property: 'og:image', content: absoluteSharingUrl },
                    { property: 'fb:app_id', content: '185968838439659' },
                    { name: 'twitter:title', content: title },
                    { name: 'twitter:description', content: description },
                    { name: 'twitter:image', content: absoluteSharingUrl },
                    ...noindex,
                ] },
                React.createElement("script", { type: "application/ld+json" }, `{
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "https://sweatco.in",
              "logo": "${appIcon}"
            }`),
                React.createElement("body", { className: "max-height" })),
            React.createElement("div", { className: "root__content max-height" }, code ? renderError(code) : renderRoutes(route.routes)))));
    function renderError(code) {
        return React.createElement(ErrorComponent, { code: code });
    }
};
const GlobalStyle = createGlobalStyle `
${modernNormalize}
${setFontFace(FONTS.SimplonNorm)}
${setFontFace(FONTS.SimplonNormBold, 'bold')}
${setFontFace(FONTS.SimplonMono)}
${setFontFace(FONTS.LucidaGrande)}
/* latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v25/yMJRMIlzdpvBhQQL_Qq7dy1biN15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v25/yMJRMIlzdpvBhQQL_Qq7dy1biN15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v25/yMJRMIlzdpvBhQQL_Qq7dy1biN15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v25/yMJRMIlzdpvBhQQL_Qq7dy1biN15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v25/yMJRMIlzdpvBhQQL_Qq7dy1biN15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin */
@font-face {
  font-family: 'Orbitron';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/orbitron/v25/yMJRMIlzdpvBhQQL_Qq7dy1biN15.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html {
  width: 100%;
  height: 100%;
}

html, body {
  margin: 0px;
  padding: 0px;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  color: ${COLORS.blue};
  
  ${media.phone_medium `font-size: 22px !important;`};
  ${media.tablet `font-size: 30px !important;`};
  ${media.desktop `font-size: 26px !important;`};
  ${media.desktop_large `font-size: 34px !important;`};
}
`;
const StyledNavLink = styled(NavLink) ``;
const CookieBanner = styled.div `
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback} !important;
  position: fixed;
  z-index: 999;
  font-size: 1rem;
  text-align: center;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  font-size: 0.5rem;
  padding: 0.5rem;

  ${StyledNavLink}:hover {
    text-decoration: underline;
  }
`;
const CookieBannerClose = styled.span `
  display: inline-block;
  vertical-align: middle;
  width: 0.5rem;
  height: 0.5rem;
  margin-left: 0.5rem;
  margin-top: -1px;
  background: url(${crossIconUrl}) no-repeat;
  background-size: contain;
`;
export default Root;
