import { ROUTER_BEFORE_NAVIGATE, ROUTER_ERROR_RAISE, ROUTER_ERROR_RESET, ROUTER_NAVIGATE, ROUTER_REJECTED, } from 'constants/ActionTypes';
const errorReducer = (state = {}, action) => {
    switch (action.type) {
        case ROUTER_BEFORE_NAVIGATE: {
            if (action.initial) {
                return state;
            }
            return Object.assign({}, state, { nextCode: null });
        }
        case ROUTER_NAVIGATE: {
            return Object.assign({}, state, { code: state.nextCode });
        }
        case ROUTER_ERROR_RAISE: {
            if (state.nextCode) {
                return state;
            }
            return Object.assign({}, state, { nextCode: action.errorCode });
        }
        case ROUTER_ERROR_RESET: {
            // remove nextCode from state object
            // eslint-disable-next-line
            const { nextCode, ...newState } = state;
            return newState;
        }
        case ROUTER_REJECTED: {
            if (state.nextCode) {
                return state;
            }
            return Object.assign({}, state, { nextCode: action.payload.code || action.payload.status || 404 });
        }
        default:
            return state;
    }
};
export default errorReducer;
