import { GET_GLOBAL_STATS_FULFILLED, GET_GLOBAL_STATS_PENDING, GET_GLOBAL_STATS_REJECTED, ROUTER_BEFORE_NAVIGATE, } from 'constants/ActionTypes';
const statsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_GLOBAL_STATS_PENDING: {
            if (action.error) {
                return {
                    ...state,
                    fetchedAt: Date.now(),
                };
            }
            return state;
        }
        case ROUTER_BEFORE_NAVIGATE: {
            if (!action.initial) {
                return {
                    ...state,
                    fetched: false,
                };
            }
            else {
                return state;
            }
        }
        case GET_GLOBAL_STATS_FULFILLED: {
            return {
                ...state,
                ...action.payload.data,
                fetched: true,
                fetchedAt: Date.now(),
            };
        }
        case GET_GLOBAL_STATS_REJECTED: {
            return {
                ...state,
                fetchedAt: Date.now(),
            };
        }
        default:
            return state;
    }
};
export default statsReducer;
