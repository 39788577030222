import { GET_REFERRAL_CODE_REQUEST_FULFILLED } from 'constants/ActionTypes';
const referralCodeReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_REFERRAL_CODE_REQUEST_FULFILLED: {
            return action.payload.data;
        }
        default:
            return state;
    }
};
export default referralCodeReducer;
