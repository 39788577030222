import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
const messages = defineMessages({
    title: ' Download for free',
});
const DownloadButton = React.forwardRef(({ className, link, onClick }, ref) => {
    return (React.createElement(Button, { className: className, href: link, ref: ref, onClick: onClick },
        React.createElement(FormattedMessage, { ...messages.title })));
});
const Button = styled.a `
  display: block;
  width: 240px;
  text-align: center;
  background-color: #fff;
  border-radius: 120px;
  padding: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 19.2px;
  line-height: 26px;
  letter-spacing: -0.002em;
  color: #191841;
  border: none;
  position: relative;
  z-index: 99;
`;
export default DownloadButton;
