import { MISC_SET_CONFIG, MISC_SET_LOCALE, MISC_SET_META, MISC_SET_ON_ENTER, SESSION_UPDATE, SET_PARAMS, STORE_BUTTON_SHOW, SYNC_TIME, } from 'constants/ActionTypes';
import { getLocaleByCountry } from 'utils/countryManager';
export function setConfig(config) {
    return {
        type: MISC_SET_CONFIG,
        data: config,
    };
}
export function setOnEnterData(data) {
    return {
        type: MISC_SET_ON_ENTER,
        data,
    };
}
export function syncTime() {
    return {
        type: SYNC_TIME,
        data: Date.now(),
    };
}
export function setLocale(countryCode, translations) {
    return (dispatch, getState) => {
        const { locale } = getState();
        const countryLocale = getLocaleByCountry(countryCode);
        if (locale !== countryLocale) {
            dispatch({
                type: MISC_SET_LOCALE,
                locale: countryLocale,
                translations: translations,
            });
        }
    };
}
export function setMeta(meta) {
    return {
        type: MISC_SET_META,
        data: meta,
    };
}
export function setParams(params) {
    return {
        type: SET_PARAMS,
        data: params,
    };
}
export function updateSession(headers) {
    return {
        type: SESSION_UPDATE,
        headers: headers,
    };
}
export function storeButtonShow() {
    return {
        type: STORE_BUTTON_SHOW,
    };
}
