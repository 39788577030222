import get from 'lodash/get';
import { createSelector, createStructuredSelector } from 'reselect';
export const modelsSelector = (state) => state.offers.models;
export const featuredSelector = (state) => state.offers.featured;
export const upcomingSelector = (state) => state.offers.upcoming;
export function offerByParam(param) {
    const paramSelector = (state, props) => props.match.params[param];
    return createSelector(modelsSelector, paramSelector, (models, param) => models[param]);
}
export const featuredOffersSelector = createSelector(modelsSelector, featuredSelector, (models, featured) => featured.map((id) => models[id]));
export const upcomingOffersSelector = createSelector(modelsSelector, upcomingSelector, (models, upcoming) => upcoming.map((id) => models[id]));
const offerSelector = offerByParam('offer_id');
export const metaSelector = createSelector(offerSelector, (offer) => ({
    title: get(offer, 'title'),
    description: get(offer, 'description'),
    image: offer.featured_image ? offer.featured_image : get(offer, 'images[0].cover'),
}));
export const offerStructuredSelector = createStructuredSelector({
    model: offerSelector,
    meta: metaSelector,
});
