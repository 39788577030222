import { POST_INVITE_REQUEST_FULFILLED } from 'constants/ActionTypes';
const emailConfirmedReducer = (state = false, action) => {
    switch (action.type) {
        case POST_INVITE_REQUEST_FULFILLED: {
            return true;
        }
        default:
            return state;
    }
};
export default emailConfirmedReducer;
