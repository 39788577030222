import { isEmpty } from 'lodash';
import get from 'lodash/get';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedNumber } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import { desktop, desktop_medium } from 'routes/Home/utils/mediaQueries';
import useFps from '../hooks/useFps';
const interPrideStepsSelector = (state) => state.interPrideSteps;
const Counter = () => {
    const dispatch = useDispatch();
    const stats = useSelector(interPrideStepsSelector);
    const frame = useRef(null);
    const [cities, setCities] = useState();
    const { avgFps } = useFps();
    const fps = Number(avgFps) || 60;
    useEffect(() => {
        dispatch(ApiActions.prefetchInterPrideSteps());
    }, []);
    useEffect(() => {
        const initialSteps = {};
        if (!isEmpty(stats)) {
            Object.keys(stats).forEach((key) => {
                const city = key.replace('_speed', '').replace('_steps', '');
                const steps = get(stats, `${city}_steps`);
                const speed = get(stats, `${city}_speed`);
                initialSteps[city] = steps;
                const animate = () => {
                    const newSteps = (initialSteps[city] += speed / fps);
                    setCities((prev) => ({ ...prev, [city]: { steps: newSteps, speed } }));
                    frame.current = requestAnimationFrame(animate);
                };
                frame.current = requestAnimationFrame(animate);
                return () => cancelAnimationFrame(frame.current);
            });
        }
    }, [stats, fps]);
    return (React.createElement(Wrapper, null,
        React.createElement(Title, null, "Total steps walked"),
        React.createElement(Count, null,
            React.createElement(CountNumber, null, cities?.total?.steps ? React.createElement(FormattedNumber, { value: cities.total.steps, maximumFractionDigits: 0 }) : '...')),
        React.createElement(Countries, null,
            React.createElement(Country, null,
                React.createElement(CountryName, null,
                    "\uD83C\uDDEC\uD83C\uDDE7 Oxford",
                    React.createElement("br", null),
                    cities?.oxford?.steps ? React.createElement("span", null, "June 4") : null),
                cities?.oxford?.steps ? (React.createElement(CountryNumber, null,
                    React.createElement(FormattedNumber, { value: cities.oxford.steps, maximumFractionDigits: 0 }))) : (React.createElement(Soon, null, "Starts June 4"))),
            React.createElement(Country, null,
                React.createElement(CountryName, null,
                    "\uD83C\uDDF2\uD83C\uDDFD Guadalajara",
                    React.createElement("br", null),
                    cities?.guadalajara?.steps ? React.createElement("span", null, "June 4") : null),
                cities?.guadalajara?.steps ? (React.createElement(CountryNumber, null,
                    React.createElement(FormattedNumber, { value: cities.guadalajara.steps, maximumFractionDigits: 0 }))) : (React.createElement(Soon, null, "Starts June 4"))),
            React.createElement(Country, null,
                React.createElement(CountryName, null,
                    "\uD83C\uDDFA\uD83C\uDDF8 WeHo Pride",
                    React.createElement("br", null),
                    cities?.weho?.steps ? React.createElement("span", null, "June 5") : null),
                cities?.weho?.steps ? (React.createElement(CountryNumber, null,
                    React.createElement(FormattedNumber, { value: cities.weho.steps, maximumFractionDigits: 0 }))) : (React.createElement(Soon, null, "Starts June 5"))),
            React.createElement(Country, null,
                React.createElement(CountryName, null,
                    "\uD83C\uDDE8\uD83C\uDDE6 Toronto",
                    React.createElement("br", null),
                    cities?.toronto?.steps ? React.createElement("span", null, "June 26") : null),
                cities?.toronto?.steps ? (React.createElement(CountryNumber, null,
                    React.createElement(FormattedNumber, { value: cities?.toronto?.steps, maximumFractionDigits: 0 }))) : (React.createElement(Soon, null, "Starts June 26"))),
            React.createElement(Country, null,
                React.createElement(CountryName, null,
                    "\uD83C\uDDEE\uD83C\uDDF3 India",
                    React.createElement("br", null),
                    cities?.gandhinagar?.steps ? React.createElement("span", null, "September 25") : null),
                cities?.gandhinagar?.steps ? (React.createElement(CountryNumber, null,
                    React.createElement(FormattedNumber, { value: cities?.gandhinagar?.steps, maximumFractionDigits: 0 }))) : (React.createElement(Soon, null, "Starts September 25"))))));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 0px 0px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  margin: 55px 16px 80px;

  @media ${desktop} {
    width: 830px;
    flex-wrap: wrap;
    margin: 110px auto 80px;
  }

  @media ${desktop_medium} {
    width: 1330px;
  }
`;
const Title = styled.h3 `
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 14px;

  @media ${desktop} {
    font-size: 20px;
  }
`;
const Count = styled.div `
  margin-bottom: 32px;
`;
const CountNumber = styled.span `
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 800;
  font-size: 42px;
  line-height: 44px;
  letter-spacing: -0.01em;
  background: linear-gradient(264.81deg, #ffffff -0.09%, #ffd5a5 47.43%, #ffffff 99.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media ${desktop} {
    font-size: 72px;
    line-height: 76px;
  }
`;
const Countries = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 40px 40px 10px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 0px 0px 20px 20px;
  width: 100%;
  box-sizing: border-box;

  @media ${desktop} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 22px 40px 44px;
  }

  @media ${desktop_medium} {
    padding: 22px 40px;
    flex-wrap: nowrap;
  }
`;
const Country = styled.div `
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & + & {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 30px;
  }

  @media ${desktop} {
    & + & {
      border-top: none;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      padding-top: 0;
    }
  }

  &:nth-child(1),
  &:nth-child(2) {
    @media ${desktop} {
      flex: none;
      width: 50%;
      margin-bottom: 40px;
    }

    @media ${desktop_medium} {
      flex: 1;
      width: auto;
      margin-bottom: 0;
    }
  }

  &:nth-child(3) {
    @media ${desktop} {
      border-left: none;
    }
    @media ${desktop_medium} {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
`;
const CountryName = styled.p `
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 14px;
`;
const CountryNumber = styled.p `
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 34px;
  letter-spacing: -0.01em;
  text-align: center;
  background: linear-gradient(264.81deg, rgba(255, 255, 255, 0.8) -0.09%, rgba(255, 255, 255, 0.6) 99.88%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding-bottom: 30px;

  @media ${desktop} {
    padding-bottom: 0;
  }
`;
const Soon = styled(CountryNumber) `
  font-family: 'Inter';
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
`;
export default Counter;
