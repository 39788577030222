import { ROUTER_NAVIGATE } from 'constants/ActionTypes';
const locationReducer = (state = null, action) => {
    switch (action.type) {
        case ROUTER_NAVIGATE: {
            return action.state.location;
        }
        default:
            return state;
    }
};
export default locationReducer;
