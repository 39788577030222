import { ROUTER_BEFORE_NAVIGATE } from 'constants/ActionTypes';
const themeReducer = (state = null, action) => {
    switch (action.type) {
        case ROUTER_BEFORE_NAVIGATE: {
            const lastRoute = action.state.routes[action.state.routes.length - 1];
            return lastRoute.theme || null;
        }
        default:
            return state;
    }
};
export default themeReducer;
