import url from 'url';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import bg from 'assets/AppLinks/invite_bg.jpg';
import hero_tablet from 'assets/AppLinks/invite_phone.png';
import hero_phone from 'assets/AppLinks/invite_phone_m.png';
import sharingSrc from 'assets/AppLinks/invite_share.jpg';
import logo_src from 'assets/Home/header__logo.svg';
import appstoreSrc from 'assets/Home/home_appstore.png';
import googleplaySrc from 'assets/Home/home_googleplay.png';
import OutboundLink from 'components/OutboundLink';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { getFirstLetter, getFirstName } from 'utils/user';
import { makeProfileSelector } from 'selectors/invites';
import { useFirebaseEssentials } from '../utils/firebase';
import { link } from '../../Home/components/AppLinks';
const messages = defineMessages({
    title: ' invites you to join Sweatcoin app',
    subtitle: 'Join {name} and turn your steps into a currency called "sweatcoins" that you can spend to get cool stuff or donate to do good things.',
    buttonTitle: 'Accept Invite',
    appstoreName: 'Apple App Store',
    appstoreRating: '(200k ratings)',
    googleplayName: 'Google play',
    googleplayRating: '(1.8M ratings)',
});
const InvitePage = (props) => {
    const { match, route } = props;
    const { params } = match;
    // Profile & meta
    const profileSelector = useMemo(makeProfileSelector, []);
    const profile = useSelector((state) => profileSelector(state, params));
    const avatar = get(profile, 'avatar.preview');
    const name = getFirstName(profile);
    const firstLetter = getFirstLetter(profile);
    const username = params.username;
    const { firebaseData: { firebaseQuery, getRedirectLink }, tracking: { channel, platform, feature, campaign }, buttonsRefs: [buttonRef, bottomButtonRef], config: { origin }, } = useFirebaseEssentials({ owner: profile, route });
    const absoluteSharingUrl = url.resolve(origin, sharingSrc);
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'invite-page' },
                {
                    name: 'event-params',
                    content: pickBy({
                        page_type: 'invite_page',
                        username: username,
                        inviter_id: profile.id,
                        channel,
                        platform,
                        feature,
                        campaign,
                    }, identity),
                },
                { property: 'og:image', content: absoluteSharingUrl },
            ], link: [
                { rel: 'image_src', href: absoluteSharingUrl },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap',
                },
            ] }),
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(Header, null,
                React.createElement(Avatar, { src: avatar }, !avatar && React.createElement("span", null, firstLetter)),
                React.createElement(FormattedMessage, { ...messages.title }, (title) => (React.createElement(Title, null,
                    React.createElement("span", null, name),
                    title)))),
            React.createElement(Subtitle, null,
                React.createElement(FormattedMessage, { ...messages.subtitle, values: { name } })),
            firebaseQuery ? (React.createElement(Button, { onClick: handleClick, href: firebaseQuery.link, ref: buttonRef },
                React.createElement(FormattedMessage, { ...messages.buttonTitle }))) : null,
            React.createElement(HeroImageMobile, { src: hero_phone }),
            React.createElement(HeroImage, { src: hero_tablet }),
            React.createElement(Stores, null,
                React.createElement(StyledOutboundLink, { category: "Store", action: "redirect", label: "AppStore", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('AppStore') },
                    React.createElement(AppstoreIcon, null),
                    React.createElement(StoreDescription, null,
                        React.createElement(StoreName, null,
                            React.createElement(FormattedMessage, { ...messages.appstoreName })),
                        React.createElement(StoreRating, null,
                            React.createElement("strong", null, "4.6"),
                            "\u00A0",
                            React.createElement(FormattedMessage, { ...messages.appstoreRating })))),
                React.createElement(StyledOutboundLink, { category: "Store", action: "redirect", label: "Google Play", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('Google Play') },
                    React.createElement(GoogleplayIcon, null),
                    React.createElement(StoreDescription, null,
                        React.createElement(StoreName, null,
                            React.createElement(FormattedMessage, { ...messages.googleplayName })),
                        React.createElement(StoreRating, null,
                            React.createElement("strong", null, "4.5"),
                            "\u00A0",
                            React.createElement(FormattedMessage, { ...messages.googleplayRating }))))),
            React.createElement(OnlyMobile, null, firebaseQuery ? (React.createElement(Button, { onClick: handleClick, href: firebaseQuery.link, ref: bottomButtonRef },
                React.createElement(FormattedMessage, { ...messages.buttonTitle }))) : null))));
    function handleClick(event) {
        event.preventDefault();
        analytics.track(`${feature}_link_click`, 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink();
    }
};
InvitePage.fetchData = async ({ match: { params } }, { dispatch }) => {
    return await dispatch(ApiActions.searchUserByUsername(params.username));
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: url(${bg}) no-repeat top left;
  background-size: cover;
`;
const Container = styled.div `
  position: relative;
  padding: 20px 0;

  ${media.tablet `
    width: 700px;
    padding: 32px 0;
    margin: 0 0 0 108px;
  `};

  ${media.desktop_extra_large `
    margin: 0 0 0 187px;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
  text-align: center;

  ${media.tablet `
    text-align: left;
  `};
`;
const Logo = styled.img `
  position: relative;
  width: 170px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;

  ${media.tablet `
    width: 197px;
  `};
`;
const HeroImageMobile = styled.img `
  width: 100%;

  ${media.tablet `
    display: none;
  `}
`;
const HeroImage = styled.img `
  display: none;

  ${media.tablet `
    display: block;
    position: absolute;
    width: 1324px;
    top: -50px;
    left: 260px;
    z-index: -1;
  `};

  ${media.desktop_extra_large `
    top: 180px;
    left: 860px;
  `};
`;
const Header = styled.div `
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 18px;
  padding: 0 16px;

  ${media.tablet `
    padding: 0;
    align-items: center;
    margin-top: 160px;
    margin-bottom: 24px;
  `}

  ${media.desktop_extra_large `
    margin-top: 400px;
  `}
`;
const Avatar = styled.div `
  width: 80px;
  height: 80px;
  box-shadow: 12px 12px 40px 0px rgba(61, 0, 16, 0.3);
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  flex: none;
  margin-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet `
    width: 160px;
    height: 160px;
    margin-right: 24px;
  `}

  span {
    font-weight: 100;
    font-size: 70px;
    color: #fff;
  }
`;
const Title = styled.h1 `
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 1.6;

  ${media.tablet `
    font-weight: 700;
    font-size: 48px;
    line-height: 1.4;
  `};

  span {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-right: 8px;

    &::before {
      content: '';
      position: absolute;
      width: calc(100% + 12px);
      height: 100%;
      background-color: rgba(0, 20, 48, 0.3);
      border-radius: 4px;
      z-index: -1;
      top: 2px;
      left: -7px;
    }
  }
`;
const Subtitle = styled.p `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1.6;
  padding: 0 16px;

  ${media.tablet `
    font-size: 20px;
    margin-bottom: 32px;
    padding: 0;
  `};
`;
const Button = styled.a `
  display: block;
  text-align: center;
  width: calc(100% - 32px);
  box-sizing: border-box;
  padding: 18px 40px;
  box-shadow: 0px 8px 16px 0px rgba(152, 107, 82, 0.16);
  background: linear-gradient(102deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.7) 100%);
  border-radius: 12px;
  border: none;
  font-weight: 700;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  color: #001430;
  font-size: 19px;
  letter-spacing: -0.6px;
  line-height: 1.2;
  margin: 0 16px 40px;
  cursor: pointer;

  ${media.tablet `
    width: auto;
    display: inline-block;
    margin: 0 0 40px;
  `};
`;
const Stores = styled.div `
  margin: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  backdrop-filter: blur(10px);
  background: linear-gradient(253deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.05) 100%);
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.12);

  ${media.tablet `
    width: 700px;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px 40px;
  `};
`;
const StyledOutboundLink = styled(OutboundLink) `
  display: flex;

  &:first-child {
    margin-bottom: 32px;

    ${media.tablet `
      margin-bottom: 0;
    `}
  }
`;
const StoreIcon = styled.div `
  width: 58px;
  height: 58px;
  margin-right: 24px;
`;
const AppstoreIcon = styled(StoreIcon) `
  background: url(${appstoreSrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const GoogleplayIcon = styled(StoreIcon) `
  background: url(${googleplaySrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const StoreDescription = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
const StoreName = styled.div `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase;
`;
const StoreRating = styled.div `
  display: flex;
  align-items: flex-end;
  margin-top: 2px;

  strong {
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #ffffff;
    font-size: 28px;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: capitalize;
  }

  span {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    letter-spacing: 1.4px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    top: -8px;
  }
`;
const OnlyMobile = styled.div `
  ${media.tablet `
    display: none;
  `}
`;
export default InvitePage;
