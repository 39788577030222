import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import logo_src from '../../../assets/Home/header__logo.svg';
import bg from '../../../assets/NyResolution/bg.jpg';
import phone from '../assets/colombia_phone.png';
const countrySelector = (state) => state.config.country;
const isUsedReferralCodeSelector = (state) => state.referralCode.used;
const ColombiaReferrals = (props) => {
    const { match: { params }, } = props;
    const { referral_code } = params;
    const [userEmail, setUserEmail] = useState('');
    const [sent, setSent] = useState(false);
    const [sentSuccess, setSentSuccess] = useState(false);
    const inputRef = React.createRef();
    const country = useSelector(countrySelector);
    const isUsedReferralCode = useSelector(isUsedReferralCodeSelector);
    const dispatch = useDispatch();
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'colombia-launch-page' }] }),
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(Phone, { src: phone }),
            !isUsedReferralCode ? (React.createElement(Content, null,
                !sentSuccess ? React.createElement(Title, null, "\u00A1Hola! Has sido invitado a Sweatcoin.") : React.createElement(Title, null, "\u00A1Hurra!"),
                !sentSuccess ? (React.createElement(React.Fragment, null,
                    React.createElement(Caption, null,
                        "Somos la aplicaci\u00F3n que te brinda Sweatcoins, un nuevo tipo de moneda que",
                        ' ',
                        React.createElement("strong", null, "ganar\u00E1s por caminar"),
                        " \uD83C\uDFC3 y que adem\u00E1s podr\u00E1s usarla para adquirir cosas divertidas. \uD83D\uDECD\uFE0F"),
                    React.createElement(Caption, null, "Ingresa tu correo electr\u00F3nico a continuaci\u00F3n, para que puedas ser parte de nuestro lanzamiento el 1ero de marzo y obtengas acceso a ventajas exclusivas."))) : (React.createElement(Caption, null, "Gracias por registrarte. Mantente atento a las actualizaciones sobre nuestro pr\u00F3ximo lanzamiento")),
                !sentSuccess ? (React.createElement(InputWrapper, null,
                    React.createElement(Input, { type: "email", placeholder: "Tu correo electr\u00F3nico", value: userEmail, onChange: handleChange, ref: inputRef }),
                    React.createElement(Button, { onClick: handleSubmit, disabled: !validate() }, "Reclama tu lugar"))) : null)) : (React.createElement(Content, null,
                React.createElement(Title, null, "\u00A1Ups!"),
                !sentSuccess ? (React.createElement(React.Fragment, null,
                    React.createElement(Caption, null, "Has sido invitado a unirte a Sweatcoin. Pero parece que alguien ya us\u00F3 este enlace para unirse a la lista de espera."),
                    React.createElement(Caption, null, "Es posible que nos queden algunos lugares, as\u00ED que deja tu correo electr\u00F3nico a continuaci\u00F3n, para recibir actualizaciones sobre cu\u00E1ndo se inicia la aplicaci\u00F3n."))) : (React.createElement(Caption, null, "Genial, ahora no te pierdas los anuncios y actualizaciones sobre nuestro pr\u00F3ximo lanzamiento.")),
                !sentSuccess ? (React.createElement(InputWrapper, null,
                    React.createElement(Input, { type: "email", placeholder: "Tu correo electr\u00F3nico", value: userEmail, onChange: handleChange, ref: inputRef }),
                    React.createElement(Button, { onClick: handleSubmit, disabled: !validate() }, "Prueba mi suerte"))) : null,
                React.createElement(Subtitle, null, "\u00BFQuienes somos?"),
                React.createElement(Caption, null,
                    "Somos la aplicaci\u00F3n que te brinda Sweatcoins, un nuevo tipo de moneda que",
                    ' ',
                    React.createElement("strong", null, "ganar\u00E1s por caminar"),
                    " y que adem\u00E1s podr\u00E1s usarla para adquirir cosas divertidas."))))));
    function handleChange(event) {
        setUserEmail(event.target.value);
    }
    function validate() {
        return !sent && userEmail && /.+@.+\..{2,}/i.test(userEmail);
    }
    function handleSubmit(event) {
        event.preventDefault();
        if (!validate()) {
            inputRef.current.focus();
            return;
        }
        const params = {
            email: userEmail,
        };
        if (!isUsedReferralCode) {
            params.referral_code = referral_code;
        }
        if (country) {
            params.type = country;
        }
        async function fetch() {
            setSent(true);
            const result = await dispatch(ApiActions.createInviteRequest(params));
            if (result) {
                analytics.track('colombia_launch_send_button', 'click', {
                    origin: location.origin,
                    page: location.pathname,
                    email: userEmail,
                    referral_code: !isUsedReferralCode ? referral_code : null,
                });
                setSentSuccess(true);
            }
        }
        fetch();
    }
};
ColombiaReferrals.fetchData = async ({ match: { params } }, { dispatch }) => {
    return await dispatch(ApiActions.prefetchReferralCodeRequest(params.referral_code));
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: url(${bg}) 50% 50% no-repeat;
  background-size: cover;
`;
const Container = styled.div `
  position: relative;
  padding: 30px;

  ${media.tablet `
    width: 700px;
    padding: 30px 0;
    margin: 0 auto;
  `};

  ${media.desktop `
    width: 1000px;
    padding: 30px 0;
    margin: 0 auto;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
`;
const Logo = styled.img `
  position: relative;
  width: 197px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
`;
const Content = styled.div ``;
const Phone = styled.img `
  display: none;

  ${media.desktop `
    display: block;
    width: 450px;
    position: absolute;
    top: 35px;
    right: 0;
  `};
`;
const Title = styled.h1 `
  margin-top: 40px;
  margin-bottom: 40px;
  font-family: ${FONTS.SimplonNormBold.name}, ${FONTS.fallback};
  font-size: 42px;
  line-height: 1.3;
  font-weight: 800;
  color: #ffffff;
  letter-spacing: 0.2px;

  ${media.tablet `
    width: 500px;
  `};

  ${media.desktop `
    margin-top: 40px;
  `};
`;
const Subtitle = styled.h2 `
  margin-top: 40px;
  font-family: ${FONTS.SimplonNormBold.name}, ${FONTS.fallback};
  font-size: 28px;
  line-height: 1.3;
  font-weight: 800;
  color: #ffffff;
  letter-spacing: 0.2px;
  margin-bottom: 0;

  ${media.tablet `
    width: 500px;
  `};

  ${media.desktop `
    margin-top: 50px;
  `};
`;
const Caption = styled.p `
  margin-top: 14px;
  margin-bottom: 18px;
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #ffffff;

  ${media.tablet `
    width: 500px;
  `};
`;
const InputWrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  ${media.tablet `
    width: 500px;
    flex-direction: row;
  `};
`;
const Input = styled.input `
  flex: 1 1;
  padding: 0 17px;
  border-radius: 16px;
  border: 1px solid #ffffff;
  background-color: transparent;
  outline: none;
  line-height: 44px;
  color: #ffffff;
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-size: 18px;
  margin-bottom: 10px;

  ${media.tablet `
    margin-bottom: 0;
  `};
`;
const Button = styled.button `
  color: rgb(0, 20, 48);
  font-size: 17px;
  line-height: 44px;
  text-align: center;
  text-transform: uppercase;
  background: #fff;
  transition: opacity 0.3s;
  border: none;
  border-radius: 16px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  cursor: pointer;
  font-family: ${FONTS.SimplonNormBold.name}, ${FONTS.fallback};
  outline: none;

  ${media.tablet `
    margin-left: 10px;
    flex: 0 0 200px;
  `};
`;
export default ColombiaReferrals;
