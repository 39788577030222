import { SESSION_UPDATE } from 'constants/ActionTypes';
const sessionReducer = (state = {}, action) => {
    switch (action.type) {
        case SESSION_UPDATE: {
            return Object.assign({}, state, action.headers);
        }
        default:
            return state;
    }
};
export default sessionReducer;
