import React from 'react';
import Fade from 'react-reveal/Fade';
import styled from 'styled-components';
import boost from '../assets/boost.png';
import invite from '../assets/invite.png';
import blob from '../assets/phoneBlob.png';
import phone from '../assets/phoneBottom.png';
import rewards from '../assets/rewards.png';
import widget from '../assets/widget.png';
const Phone = () => {
    return (React.createElement(Layout, null,
        React.createElement(Blob, { src: blob }),
        React.createElement(Layer, null,
            React.createElement(Fade, { bottom: true },
                React.createElement("img", { src: phone }))),
        React.createElement(Layer, null,
            React.createElement(Fade, { bottom: true, delay: 200 },
                React.createElement("img", { src: widget }))),
        React.createElement(Layer, null,
            React.createElement(Fade, { bottom: true, delay: 500 },
                React.createElement("img", { src: boost }))),
        React.createElement(Layer, null,
            React.createElement(Fade, { bottom: true, delay: 800 },
                React.createElement("img", { src: invite }))),
        React.createElement(Layer, null,
            React.createElement(Fade, { bottom: true, delay: 1100 },
                React.createElement("img", { src: rewards })))));
};
const Layout = styled.div `
  position: relative;
  z-index: 1;
  margin: 0 auto -120px;

  img {
    width: 100%;
  }
`;
const Blob = styled.img `
  position: relative;
  z-index: 99;
`;
const Layer = styled.div `
  position: absolute;
  top: 0;
  left: 0;
`;
export default Phone;
