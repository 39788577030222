import { GET_LEGAL_FULFILLED } from 'constants/ActionTypes';
const legalReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_LEGAL_FULFILLED: {
            return {
                ...state,
                [action.meta.slug]: action.payload.data,
            };
        }
        default:
            return state;
    }
};
export default legalReducer;
