import debug from 'debug';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import get from 'lodash/get';
import omit from 'lodash/omit';
const LOG = debug('analytics:firebase');
const getFirebaseConfig = (apiKey) => ({
    apiKey: apiKey,
    authDomain: 'sweatcoin-1479809311382.firebaseapp.com',
    databaseURL: 'https://sweatcoin-1479809311382.firebaseio.com',
    projectId: 'sweatcoin-1479809311382',
    storageBucket: 'sweatcoin-1479809311382.appspot.com',
    messagingSenderId: '816831570131',
    appId: '1:816831570131:web:b9957d3b0009f866adf426',
    measurementId: 'G-NFXMHQEXER',
});
const trackingEnabled = process.browser ? typeof FIREBASE_KEY !== 'undefined' && FIREBASE_KEY : process.env.FIREBASE_KEY;
let analytics;
const firebaseProvider = (ensureDeviceId) => {
    const init = () => {
        if (!trackingEnabled) {
            return;
        }
        if (process.browser) {
            const app = initializeApp(getFirebaseConfig(FIREBASE_KEY));
            analytics = getAnalytics(app);
            setUserId(analytics, ensureDeviceId(), {});
            LOG('Initialized:', FIREBASE_KEY);
        }
        else {
        }
    };
    const track = async (category, action, properties = {}) => {
        if (!trackingEnabled) {
            return;
        }
        const event_type = `${category}.${action}`.toLowerCase();
        let event_properties = omit(properties, ['title']);
        if (process.browser) {
            logEvent(analytics, event_type, event_properties);
            LOG('Send event:', event_type, event_properties);
        }
    };
    const pageview = (event_type, event_properties = {}) => {
        if (!trackingEnabled) {
            return;
        }
        if (process.browser) {
            const page_type = get(event_properties, 'page_type', null);
            const eventData = {
                title: get(event_properties, 'title'),
                page: get(event_properties, 'page'),
                ...(page_type && { page_type }),
            };
            logEvent(analytics, 'screen_view', eventData);
            LOG('Send pageview:', eventData);
        }
    };
    return { init, track, pageview };
};
export default firebaseProvider;
