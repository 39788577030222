import _ from 'lodash';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import appstoreSrc from 'assets/SweatPaws/app-store_icon.svg';
import googleplaySrc from 'assets/SweatPaws/google-play_icon.svg';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { useFirebaseEssentials } from 'routes/InvitePages/utils/firebase';
const messages = defineMessages({
    appstoreName: 'Apple App Store',
    appstoreRating: '(200k ratings)',
    googleplayName: 'Google play',
    googleplayRating: '(1.8M ratings)',
});
const AppLinks = () => {
    const { firebaseData: { firebaseQuery }, buttonsRefs: [appstoreButton, googleplayButton], config: { origin }, } = useFirebaseEssentials({
        path: '/sweatpaws',
        feature: 'sweatpaws_download_button',
    });
    return (React.createElement(React.Fragment, null, firebaseQuery ? (React.createElement(Stores, null,
        React.createElement(Link, { target: "_blank", href: firebaseQuery.link, ref: appstoreButton, onClick: handleAppstoreClick },
            React.createElement(AppstoreIcon, null),
            React.createElement(StoreDescription, null,
                React.createElement(StoreName, null,
                    React.createElement(FormattedMessage, { ...messages.appstoreName })),
                React.createElement(StoreRating, null,
                    React.createElement("strong", null, "4.6"),
                    "\u00A0",
                    React.createElement(FormattedMessage, { ...messages.appstoreRating })))),
        React.createElement(Link, { target: "_blank", href: firebaseQuery.link, ref: googleplayButton, onClick: handleGooglePlayClick },
            React.createElement(GoogleplayIcon, null),
            React.createElement(StoreDescription, null,
                React.createElement(StoreName, null,
                    React.createElement(FormattedMessage, { ...messages.googleplayName })),
                React.createElement(StoreRating, null,
                    React.createElement("strong", null, "4.5"),
                    "\u00A0",
                    React.createElement(FormattedMessage, { ...messages.googleplayRating })))))) : null));
    function trackDownloadButton() {
        if (window.obApi) {
            obApi('track', 'Downloadbutton');
        }
    }
    function handleAppstoreClick() {
        event.preventDefault();
        trackDownloadButton();
        analytics.track('SweatpawsAppstoreDownloadButton', 'click', _.pickBy({
            origin,
            feature: 'sweatpaws_download_button',
        }, _.identity));
        window.open('https://itunes.apple.com/app/sweatcoin-app-that-pays-you/id971023427', '_blank');
    }
    function handleGooglePlayClick() {
        event.preventDefault();
        trackDownloadButton();
        analytics.track('SweatpawsGooglePlayDownloadButton', 'click', _.pickBy({
            origin,
            feature: 'sweatpaws_download_button',
        }, _.identity));
        window.open('https://play.google.com/store/apps/details?id=in.sweatco.app', '_blank');
    }
};
const Stores = styled.div `
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0 0 12px;
`;
const Link = styled.a `
  position: relative;
  display: flex;
  align-items: center;
  width: 50%;
  height: 68px;
  padding: 16px 20px;
  background: linear-gradient(
    112.4deg,
    rgba(229, 255, 156, 0.1) 29.63%,
    rgba(225, 255, 140, 0.1) 29.63%,
    rgba(26, 255, 217, 0.1) 76.7%
  );
  backdrop-filter: blur(20px);
  transition: border-color 0.2s ease;
  border-radius: 50px;

  @media (max-width: 389px) {
    height: 58px;
    padding: 16px;
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    background: linear-gradient(112.4deg, #e5ff9c 29.63%, #e1ff8c 29.63%, #1affd9 76.7%) border-box;
    -webkit-mask: /*4*/
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 50px;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }

  &:first-child {
    @media (max-width: 389px) {
      margin-right: 8px;
    }

    ${media.phone_medium `
      margin-right: 10px;
      margin-bottom: 0;
    `}
  }
`;
const StoreIcon = styled.div `
  width: 21px;
  height: 21px;
  margin-right: 12px;

  @media (max-width: 389px) {
    margin-right: 6px;
  }
`;
const AppstoreIcon = styled(StoreIcon) `
  background: url(${appstoreSrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const GoogleplayIcon = styled(StoreIcon) `
  background: url(${googleplaySrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const StoreDescription = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
const StoreName = styled.div `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.9);
  text-transform: uppercase;

  @media (max-width: 389px) {
    font-size: 8px;
  }
`;
const StoreRating = styled.div `
  line-height: 1;

  ${media.tablet `
    font-size: 22px !important;
  `};

  strong {
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.002em;
    line-height: 1;
    text-transform: capitalize;

    @media (max-width: 389px) {
      font-size: 14px;
    }
  }

  span {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    font-size: 10px;
    letter-spacing: 0.05em;
    line-height: 1.2;
    font-weight: 400;
    position: relative;
    top: -1px;

    @media (max-width: 389px) {
      font-size: 8px;
    }
  }
`;
export default AppLinks;
