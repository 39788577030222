import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import copyCompleteIcon from '../assets/copy-complete.png';
import copyIcon from '../assets/copy.png';
import facebookIcon from '../assets/facebook.png';
import twitterIcon from '../assets/twitter.png';
const Shares = ({ text, hashtags, url, quizId, className }) => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURI(text)}${hashtags ? `&hashtags=${hashtags}` : ''}&url=${url}`;
    const shareTitle = 'SHARE WITH FRIENDS';
    const [isCopied, setCopied] = useState(false);
    const [title, setTitle] = useState(shareTitle);
    useEffect(() => {
        let timer;
        if (isCopied) {
            timer = setTimeout(() => {
                setCopied(false);
                setTitle(shareTitle);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);
    return (React.createElement(Container, { className: className },
        React.createElement(Title, null, title),
        React.createElement(Buttons, null,
            React.createElement(ShareButton, { background: facebookIcon, onClick: () => openPopupWindow(facebookUrl, 'Share', 600, 500) }),
            React.createElement(ShareButton, { background: twitterIcon, onClick: () => openPopupWindow(twitterUrl, 'Share', 600, 500) }),
            React.createElement(CopyButton, { background: isCopied ? copyCompleteIcon : copyIcon, onClick: () => handleCopyClick(`${text} ${url}`) }))));
    function handleCopyClick(url) {
        const input = document.createElement('input');
        input.type = 'text';
        input.value = url;
        input.style.opacity = '0.000001';
        input.style.position = 'fixed';
        input.style.bottom = '-100%';
        input.readOnly = true;
        document.body.appendChild(input);
        input.focus();
        input.setSelectionRange(0, input.value.length);
        document.execCommand('copy', true, null);
        analytics.track('quiz_copy_button', 'click', {
            origin: location.origin,
            page: location.pathname,
            quizId: quizId,
        });
        setCopied(true);
        setTitle('LINK COPIED!');
    }
    function openPopupWindow(url, title, w, h) {
        const left = screen.width / 2 - w / 2;
        const top = screen.height / 2 - h / 2;
        analytics.track('quiz_share_button', 'click', {
            origin: location.origin,
            page: location.pathname,
            quizId: quizId,
        });
        return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
            w +
            ', height=' +
            h +
            ', top=' +
            top +
            ', left=' +
            left);
    }
};
const Container = styled.div `
  background-color: rgba(255, 255, 255, 1);
  padding: 16px 26px 30px;
  text-align: center;
  margin: 8px auto 20px;

  ${media.tablet `
    background-color: rgba(3, 37, 85, 0.3);
    margin: 0;
    padding: 16px 26px 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `};
`;
const Title = styled.p `
  font-family: ${FONTS.SimplonMono.name}, ${FONTS.fallback};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 3px;
  color: #032555;
  margin-bottom: 15px;
  ${media.tablet `
    color: rgb(255, 255, 255);
  `};
`;
const Buttons = styled.div `
  display: flex;
  justify-content: center;
`;
const ShareButton = styled.div `
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 0 4px;
  ${({ background }) => `background: url(${background}) 50% 50% no-repeat`};
  background-size: contain;
  cursor: pointer;
  transition: all 0.2s ease;

  ${media.tablet `
    &:first-child {
      margin-left: 0;
    }
  `};
`;
const CopyButton = styled(ShareButton) `
  background-color: rgba(3, 37, 85, 0.8);
  ${media.tablet `
    background-color: transparent;
  `};
`;
export default Shares;
