const createRoutes = () => [
    // CryptoOnboarding
    {
        path: '/app/crypto-onboarding',
        exact: true,
        component: require('./pages/Onboarding').default,
    },
    // CryptoWaitingList
    {
        path: '/app/cwl/:username/:listId',
        component: require('./pages/CryptoWaitingListPersonal').default,
    },
    {
        path: '/app/crypto-waiting-list',
        component: require('./pages/CryptoWaitingList').default,
    },
    // GroupOffers
    {
        path: '/app/go/:username/:offerId/:groupId',
        component: require('./pages/GroupOffers').default,
    },
    // PersonalChallenge
    {
        path: '/app/pc/:username/:challenge_id',
        component: require('./pages/PersonalChallenge').default,
    },
    // Rabotnik
    {
        path: '/app/company/:code',
        component: require('./pages/Rabotnik').default,
    },
    // ScratchPrize
    {
        path: '/app/sf/:username/',
        component: require('./pages/ScratchPrize').default,
    },
    // Challenge
    {
        path: '/app/race/:username/:challenge_id',
        component: require('./pages/Challenge').default,
    },
    {
        path: '/app/walkathon/:username/:challenge_id',
        component: require('./pages/Challenge').default,
    },
    // Get5Now
    {
        path: '/g5n/:username',
        exact: true,
        component: require('./pages/InvitePage').default,
        params: { feature: 'g5n_invite' },
        cookies: false,
        caching: true,
    },
    // Wallet
    {
        path: '/wal/:username',
        exact: true,
        component: require('./pages/InvitePage').default,
        params: { feature: 'wallet_invite' },
        cookies: false,
        caching: true,
    },
    // Daily Reward
    {
        path: '/dr/:username',
        exact: true,
        component: require('./pages/InvitePage').default,
        params: { feature: 'daily_reward_invite' },
        cookies: false,
        caching: true,
    },
    // Piggybank Invite
    {
        path: '/pb/:username',
        component: require('./pages/InvitePage').default,
        params: { feature: 'piggybank_invite' },
        cookies: false,
        caching: true,
    },
    // InviteS4GPage
    {
        path: '/s4g/:username/:offer_id',
        exact: true,
        component: require('./pages/InviteS4GPage').default,
        params: { feature: 's4g_invite' },
        cookies: false,
        caching: true,
    },
    // Leaderboard
    {
        path: '/app/lb/:username',
        exact: true,
        component: require('./pages/InviteLeaderboardPage').default,
        params: { feature: 'leaderboard_invite' },
        cookies: false,
        caching: true,
    },
    // Clubs
    {
        path: '/app/c/:handle',
        exact: true,
        component: require('./pages/InviteClubPage').default,
        params: { feature: 'club_invite' },
        cookies: false,
        caching: true,
    },
    // Sweatcoin For Work
    {
        path: '/app/sfw/:invite_id',
        exact: true,
        component: require('./pages/InviteSfwPage').default,
        params: { feature: 'sfw_invite' },
        cookies: false,
        caching: true,
    },
];
export default createRoutes;
