import get from 'lodash/get';
import omit from 'lodash/omit';
import queryString from 'query-string';
import { setCountry } from 'utils/countryManager';
const createRoutes = () => [
    {
        path: '/download-for-free/signup',
        component: require('./pages/Signup').default,
        onEnter: ({ ctx, location }) => getCountryFromQuery(ctx, location),
    },
    {
        path: '/download-for-free',
        onEnter: ({ ctx, location }) => getCountryFromQuery(ctx, location),
        component: require('./pages/WebToApp').default,
    },
];
// Country selection cookie & redirect
function getCountryFromQuery(ctx, location) {
    const country = get(location, 'query.country');
    if (country && country.length > 0) {
        setCountry(country, ctx);
        const query_string = queryString.stringify(omit(get(location, 'query'), 'country'));
        return {
            redirectUrl: query_string.length > 0 ? `${location.pathname}/?${query_string}` : `${location.pathname}`,
        };
    }
}
export default createRoutes;
