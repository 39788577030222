import _ from 'lodash';
import { ADD_SECTION_DATA, INIT_DEVICE, SET_ACTIVE_SECTION, SET_BACKGROUND_IMAGE, TOGGLE_MENU, } from 'constants/ActionTypes';
const homeReducer = (state = null, action) => {
    switch (action.type) {
        case ADD_SECTION_DATA: {
            let sections = (state && state.sections) || {};
            const name = action.payload.name;
            sections[name] = { ...sections[name], ...action.payload };
            return { ...state, sections };
        }
        case SET_ACTIVE_SECTION: {
            let activeSection = state.activeSection;
            if (action.payload === activeSection) {
                return state;
            }
            else {
                return { ...state, activeSection: action.payload };
            }
        }
        case SET_BACKGROUND_IMAGE: {
            // TODO save backgroundId for every section separatly
            return { ...state, backgroundImageId: action.payload, prevBackgroundImageId: state.backgroundImageId };
        }
        case INIT_DEVICE: {
            const device = action.payload;
            return { ...state, device };
        }
        case TOGGLE_MENU: {
            const isMenuOpen = !_.get(state, 'isMenuOpen', false);
            // TODO remove side effect
            if (isMenuOpen) {
                document.body.classList.add('menuIsOpen');
            }
            else {
                document.body.classList.remove('menuIsOpen');
            }
            return { ...state, isMenuOpen };
        }
        default:
            return state;
    }
};
export default homeReducer;
