import { MISC_SET_ON_ENTER } from 'constants/ActionTypes';
const onEnterReducer = (state = {}, action) => {
    switch (action.type) {
        case MISC_SET_ON_ENTER: {
            return {
                ...state,
                ...action.data,
            };
        }
        default:
            return state;
    }
};
export default onEnterReducer;
