import PropTypes from 'prop-types';
import React from 'react';
import analytics from 'utils/analytics';
import Layout from './Layout';
export default class ErrorHandler extends React.Component {
    static get propTypes() {
        return {
            code: PropTypes.number,
            description: PropTypes.string,
        };
    }
    static get defaultProps() {
        return {
            code: 404,
        };
    }
    componentDidMount() {
        analytics.track('error_handler', 'error', {
            code: this.props.code,
            url: location && location.href,
        });
    }
    render() {
        const { description, code } = this.props;
        return (React.createElement(Layout, null,
            React.createElement(Layout.Container, null,
                React.createElement("h1", null, description ? description : `${code} Not found`),
                React.createElement("p", null,
                    React.createElement("a", { href: "/" }, "Back home")))));
    }
}
