import find from 'lodash/find';
import uniq from 'lodash/uniq';
import countries from 'constants/SupportedCountries.json';
import { getIp } from './cloudflare';
export const DEFAULT_COUNTRY = find(countries, { default: true });
export const SUPPORTED_LOCALES = uniq(countries.map((country) => country.locale));
export const COOKIE_NAME = 'country';
let cookie;
if (process.browser) {
    cookie = require('cookies-js');
}
function ensureCountry(country) {
    let countryCode = country ? country.toLowerCase() : null;
    countryCode = countryCode === 'uk' ? 'gb' : countryCode;
    return (countryCode && find(countries, { country: countryCode })) || DEFAULT_COUNTRY;
}
export function clearCountry(request) {
    if (process.browser) {
        cookie.set(COOKIE_NAME);
    }
    else {
        request.cookies.set(COOKIE_NAME);
    }
}
export function setCountry(country, request) {
    const supportedCountry = ensureCountry(country);
    let expires = new Date();
    expires.setTime(expires.valueOf() + 10 * 365 * 24 * 60 * 60 * 1000);
    if (process.browser) {
        cookie.set(COOKIE_NAME, supportedCountry.country, { expires: expires, path: '/' });
    }
    else {
        request.cookies.set(COOKIE_NAME, supportedCountry.country, { expires, path: '/', httpOnly: false, secure: false });
    }
}
export function getCountry(request) {
    if (process.browser) {
        const supportedCountry = ensureCountry(cookie.get(COOKIE_NAME));
        return supportedCountry.country;
    }
    else {
        let country = request.cookies.get(COOKIE_NAME);
        if (!country) {
            const geoip = require('geoip-lite');
            const requestIp = getIp(request);
            const lookup = geoip.lookup(requestIp);
            if (lookup && lookup.country) {
                country = lookup.country;
            }
        }
        return ensureCountry(country).country;
    }
}
export function getLocaleByCountry(country) {
    return ensureCountry(country).locale;
}
