import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as HuntflowActions from 'actions/huntflow';
import FONTS from 'assets/fonts';
import COLORS from 'constants/colors';
import CheckmarkSrc from '../../../assets/Components/Checkbox__checkmark.svg';
import AttachSrc from '../../../assets/team/attach_icon.png';
const mapStateToProps = (state) => ({
    files: _.get(state, 'huntflow.files', []),
    applicantId: _.get(state, 'huntflow.applicantId', null),
    isFormSubmitting: _.get(state, 'huntflow.isFormSubmitting', false),
    isFormSuccess: _.get(state, 'huntflow.isFormSuccess', false),
    submitError: _.get(state, 'huntflow.submitError', null),
});
const mapDispatchToProps = (dispatch) => ({
    saveFile: (file) => file && dispatch(HuntflowActions.saveFile(file)),
    saveApplicant: (applicant) => applicant && dispatch(HuntflowActions.saveApplicant(applicant)),
    addApplicant: (data) => data && dispatch(HuntflowActions.addAplicantToVacancy(data)),
    formUpdated: () => dispatch(HuntflowActions.formUpdated()),
});
const Form = (props) => {
    const { files, applicantId, className, saveFile, saveApplicant, addApplicant, vacancyId, isFormSubmitting, submitError, isFormSuccess, formUpdated, } = props;
    // Handle Form values
    const [formValue, setFormValue] = useState({});
    const onInputChange = (id, value) => {
        // Reset isFormSuccess when form values updated
        if (isFormSuccess)
            formUpdated();
        id && setFormValue({ ...formValue, [id]: value });
    };
    // Refs for files
    const coverLetterRef = React.createRef();
    const resumeRef = React.createRef();
    // onSubmit Form
    const submitForm = (e) => {
        const coverLetter = coverLetterRef.current.files[0];
        const resume = resumeRef.current.files[0];
        saveApplicant(formValue);
        saveFile(coverLetter);
        saveFile(resume);
        e.preventDefault();
    };
    //Add applicant to vacancy
    const isFilesReady = () => {
        return coverLetterRef.current.files[0] ? files.length > 1 : files.length > 0;
    };
    useEffect(() => {
        if (applicantId && isFilesReady()) {
            addApplicant({
                applicantId,
                vacancyId,
                files,
            });
        }
    }, [files, applicantId]);
    // Error handling
    useEffect(() => {
        submitError && alert(submitError);
    }, [submitError]);
    // Reset form after submitting
    useEffect(() => {
        if (isFormSuccess) {
            setFormValue({});
            setAgreement(false);
        }
    }, [isFormSuccess]);
    const [agreement, setAgreement] = useState(false);
    const handleAgreementChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setAgreement(value);
    };
    return (React.createElement(StyledForm, { disabled: isFormSubmitting, className: className, onSubmit: submitForm },
        React.createElement(UserInput, { disabled: isFormSubmitting, onChange: onInputChange, value: formValue, id: "first_name", placeholder: "First name", required: true }),
        React.createElement(UserInput, { onChange: onInputChange, value: formValue, disabled: isFormSubmitting, id: "last_name", placeholder: "Last name", required: true }),
        React.createElement(UserInput, { onChange: onInputChange, value: formValue, disabled: isFormSubmitting, id: "email", placeholder: "email", type: "email", pattern: ".+@.+\\..+", required: true }),
        React.createElement(UserInput, { onChange: onInputChange, value: formValue, disabled: isFormSubmitting, id: "phone", placeholder: "phone", type: "tel", required: true }),
        React.createElement(UserInput, { onChange: onInputChange, value: formValue, disabled: isFormSubmitting, id: "company", placeholder: "Current company" }),
        React.createElement(UserInput, { onChange: onInputChange, value: formValue, disabled: isFormSubmitting, id: "position", placeholder: "Position" }),
        React.createElement(Files, null,
            React.createElement(CoverLetter, { onChange: onInputChange, value: formValue, ref: coverLetterRef, disabled: isFormSubmitting, id: "cover" }),
            React.createElement(Resume, { onChange: onInputChange, value: formValue, ref: resumeRef, disabled: isFormSubmitting, required: true, id: "resume" })),
        React.createElement(Agreement, null,
            React.createElement(Checkbox, { value: agreement, checked: agreement, onChange: handleAgreementChange }),
            React.createElement(CheckboxLabel, null,
                "I agree to",
                ' ',
                React.createElement(CheckboxNavLink, { target: "_blank", to: "/team/privacy" }, "privacy policy"),
                ' ',
                React.createElement(RedDot, null))),
        React.createElement(SubmitButton, { disabled: !agreement || isFormSubmitting, isFormSuccess: isFormSuccess, type: "submit" }, isFormSuccess ? 'Application submitted' : 'Submit application')));
};
const StyledForm = styled.form `
  margin-top: 1.55rem;
  transition: opacity 0.6s;
  opacity: ${(props) => (props.disabled ? 0.6 : 1)};
`;
const Input__padding_top = 0.42;
const Input__padding_bottom = 0.3;
const Input__padding = `${Input__padding_top}rem 0 ${Input__padding_bottom}rem`;
const Input__font_size = 1;
const Label = styled.label `
  font-size: ${Input__font_size}rem;
  opacity: 0.6;
  will-change: transform, font-size;
  transform-origin: 0 50%;
`;
const UserInputLabel = styled(Label) `
  position: absolute;
  bottom: 0;
  left: 0;
  padding: ${Input__padding};
  color: #032555;
  transition: all 0.2s;
  transform-origin: 0 0;
`;
// TODO FIX REQUIRED
const InputGroup = styled.div `
  position: relative;
  margin-top: 1.52rem;
  text-transform: uppercase;

  &[required] ${UserInputLabel}::after {
    content: '•';
    color: #f07363;
    margin-left: 0.2rem;
    font-size: 0.5rem;
    display: inline-block;
    line-height: 1rem;
    vertical-align: middle;
  }
`;
const Input__color = '#032555';
const Input__color_success = '#85963D';
const Label__fontSize_fullfiled = 0.5;
const Label__position_fullfiled = Input__padding_top + Input__padding_bottom + Label__fontSize_fullfiled;
const Input = styled.input `
  border: none;
  border-bottom: 1px solid ${Input__color};
  font-size: 1rem;
  line-height: 1rem;
  padding: ${Input__padding};
  width: 100%;
  outline: none;
  color: inherit;

  &[required]:valid {
    border-color: ${Input__color_success};
    caret-color: ${Input__color_success};
  }

  &:focus + ${Label}, &.isFulfilled + ${Label} {
    transform: translate3d(0, -${Label__position_fullfiled}rem, 0);
    font-size: ${Label__fontSize_fullfiled}rem;
  }
`;
const UserInput = (props) => {
    const value = props.value[props.id] || '';
    return (React.createElement(InputGroup, { required: props.required },
        React.createElement(Input, { disabled: props.disabled, id: props.id, onChange: (e) => props.onChange(props.id, e.target.value), className: value ? 'isFulfilled' : null, value: value, pattern: props.pattern, type: props.type || 'text', required: props.required || value }),
        React.createElement(UserInputLabel, { htmlFor: props.id }, props.placeholder)));
};
const Files = styled.div `
  display: flex;
  margin-top: 1.2rem;
`;
const FileLabel = styled(Label) `
  font-size: 0.5rem;
  text-transform: uppercase;
`;
const File = React.forwardRef((props, ref) => {
    const value = props.value[props.id] || '';
    return (React.createElement(FileInput, { className: props.className, required: props.required },
        React.createElement(FileLabel, { htmlFor: props.id }, props.label),
        React.createElement(UploadButton, null,
            React.createElement(UploadFile, { ref: ref, value: value, accept: props.accept, disabled: props.disabled, onChange: (e) => props.onChange(props.id, e.target.value), id: props.id, required: props.required }),
            React.createElement(AttachButton, { className: value ? 'selected' : null, isActive: !!value, htmlFor: props.id }))));
});
const AttachButton = styled.label.attrs((props) => ({
    children: props.isActive ? 'Attached' : 'Attach',
})) `
  display: block;
  position: relative;
  font-size: 0.5rem;
  line-height: 1.2;
  text-align: center;
  padding: 0.7rem 1.7rem;
  cursor: pointer;
  text-transform: uppercase;
  border: 1px solid #422f6c;
  transition: all 0.2s;

  &::before {
    content: '';
    width: 0.35rem;
    height: 100%;
    position: absolute;
    left: 0.85rem;
    top: 50%;
    background: url(${AttachSrc}) no-repeat;
    background-size: 200%;
    background-position: 0 50%;
    transform: translate(-50%, -50%) rotate(0.001deg);
    transition: transform 0.2s;
  }

  &.selected {
    border-color: #85963d;
    background: #85963d;
    color: #ffffff;

    &::before {
      background-position: 100% 50%;
      transform: translate(-50%, -50%) rotate(30deg);
    }
  }
`;
const FileInput = styled.div `
  &[required] ${FileLabel}::after {
    content: '•';
    color: #f07363;
    margin-left: 0.2rem;
    font-size: 0.5rem;
    vertical-align: middle;
  }
`;
const UploadButton = styled.div `
  position: relative;
  margin-top: 0.65rem;
`;
const UploadFile = styled.input.attrs(() => ({
    type: 'file',
})) `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;
const CoverLetter = styled(File).attrs(() => ({
    label: 'Cover letter',
    accept: 'application/pdf',
})) ``;
const Resume = styled(File).attrs(() => ({
    label: 'Resume',
    accept: 'application/pdf',
    required: true,
})) `
  margin-left: 1.6rem;
`;
const SubmitButton = styled.button `
  background: ${(props) => (props.isFormSuccess ? COLORS.green : COLORS.purple)};
  font-size: 0.5rem;
  line-height: 1.2;
  padding: 0.7rem 2.2rem;
  margin-top: 2.7rem;
  color: #ffffff;
  text-transform: uppercase;
  border: 0;

  &[disabled] {
    opacity: 0.3;
  }
`;
const Agreement__id = 'agreementCheckbox';
const Agreement__size = '1.6rem';
const Agreement = styled.div `
  margin-top: 1.7rem;
`;
const CheckboxLabel = styled.label.attrs(() => ({
    htmlFor: Agreement__id,
})) ``;
const CheckboxNavLink = styled(NavLink) `
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: rgba(66, 47, 108, 0.8);
  }
`;
const Checkbox = styled.input.attrs(() => ({
    type: 'checkbox',
    id: Agreement__id,
})) `
  position: absolute;
  opacity: 0;

  & + ${CheckboxLabel} {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: 17px;
    display: block;
    color: rgba(66, 47, 108, 0.6);
    text-transform: uppercase;
    font-family: ${FONTS.LucidaGrande.name};
  }

  // Box
  & + ${CheckboxLabel}:before {
    content: '';
    margin-right: 1rem;
    display: inline-block;
    vertical-align: middle;
    width: ${Agreement__size};
    height: ${Agreement__size};
    border: 1px solid ${COLORS.purple};
  }

  // Box hover
  &:hover + ${CheckboxLabel}:before {
    cursor: pointer;
  }

  // Box focus
  &:focus + ${CheckboxLabel}:before {
  }

  // Box checked
  &:checked + ${CheckboxLabel}:before {
  }

  // Disabled state label
  &:disabled + ${CheckboxLabel} {
  }

  // Disabled box
  &:disabled + ${CheckboxLabel}:before {
  }

  // Checkmark
  &:checked + ${CheckboxLabel}:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${Agreement__size};
    height: ${Agreement__size};
    background: url(${CheckmarkSrc}) no-repeat center center;
    background-size: 75%;
  }
`;
const RedDot = styled.span.attrs(() => ({
    children: '•',
})) `
  color: #f07363;
  margin-left: 0.2rem;
  font-size: 0.5rem;
  vertical-align: middle;
`;
export default connect(mapStateToProps, mapDispatchToProps)(Form);
