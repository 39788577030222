import isBotCheck from 'isbot';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import identity from 'lodash/identity';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import React from 'react';
import { Redirect } from 'react-router';
import { prefetchOffer, searchUserByUsername } from 'actions/api';
import ErrorComponent from 'components/ErrorComponent';
import analytics from 'utils/analytics';
import { generateFirebaseInviteRoute, generateFirebaseMarketplaceRoute, generateFirebaseProfileRoute, generateFirebaseQuery, generateFirebaseRoute, } from 'utils/firebase';
import buildLink from './utils/buildLink';
const sharingBlocksChannels = [
    {
        channel: 'facebook',
        letters: ['f', 'fb'],
    },
    {
        channel: 'fbmessenger',
        letters: ['fm'],
    },
    {
        channel: 'twitter',
        letters: ['t', 'tw'],
    },
    {
        channel: 'sms',
        letters: ['s', 'sms'],
    },
    {
        channel: 'whatsapp',
        letters: ['w', 'ws'],
    },
    {
        channel: 'message',
        letters: ['m', 'im'],
    },
    {
        channel: 'snapchat',
        letters: ['x', 'sc'],
    },
    {
        channel: 'other',
        letters: ['i', 'hi'],
    },
];
const createRoutes = (store) => [
    {
        path: '/track',
        component: () => {
            // TODO create Redirect component with code under
            const { onEnter } = store.getState();
            return React.createElement(Redirect, { to: onEnter.redirectUrl });
        },
        onEnter: ({ location }) => {
            const whitelist = ['swc.page.link', 'play.google.com', 'itunes.apple.com'];
            const hostname = extractHostname(location.query.url);
            if (whitelist.indexOf(hostname) === -1) {
                analytics.track('track', 'error', {
                    hostname,
                });
                return { redirectUrl: '/404' };
            }
            const { config } = store.getState();
            const location_query = location.query;
            let params = pick(location_query, 'ec', 'ea', 'el', 'ev', 'dp', 'params');
            let queryParams = omit(location_query, 'ec', 'ea', 'el', 'ev', 'dp', 'url', 'params');
            let additionalProperties = {};
            try {
                additionalProperties = JSON.parse(params.params);
            }
            catch (e) { }
            let query = queryString.parse(queryString.extract(location.query.url));
            query = { ...query, ...queryParams };
            const redirectUrl = `${location.query.url.split('?')[0]}?${queryString.stringify(query)}`;
            const eventProperties = pickBy({
                ...additionalProperties,
                label: params.el,
                value: params.ev,
                page: params.dp,
                origin: config.origin,
            }, identity);
            return analytics.track(params.ec, params.ea, eventProperties).then(() => {
                return { redirectUrl: redirectUrl };
            });
        },
    },
    {
        path: '/clearing_request',
        component: require('./pages/ClearingRequest').default,
    },
    {
        path: '/dl',
        component: () => {
            const { config } = store.getState();
            const route = generateFirebaseRoute(config, generateFirebaseQuery('/', {
                ct: 'web_dl',
            }));
            return React.createElement(Redirect, { to: buildLink(route) });
        },
    },
    {
        path: '/producthunt',
        component: () => {
            const { config } = store.getState();
            const producthuntUser = {
                id: 133416,
                username: 'producthunt',
                fullname: 'Product Hunt',
            };
            const { pathname, query } = generateFirebaseInviteRoute(producthuntUser, config, {
                channel: 'producthunt',
            });
            return React.createElement(Redirect, { to: buildLink({ pathname, query }) });
        },
    },
    // Marketplace deeplink
    {
        path: '/marketplace',
        component: marketplaceComponent(store),
        cookies: false,
        onEnter: marketplace(store),
    },
    // Profile deeplink
    {
        path: '/p/:username',
        component: profileComponent(store),
        cookies: false,
        onEnter: profile(store),
    },
    // Sharing block on the friends tab with applink
    ...flatten(sharingBlocksChannels.map((c) => {
        const createRoute = (path, channel, platform) => ({
            path,
            component: require('../InvitePages/pages/InvitePage').default,
            params: { channel, platform, feature: 'sharing_block_invite', disableDefaultImageSrc: true },
            cookies: false,
            caching: true,
        });
        return flatten(['ios', 'android'].map((platform, index) => [
            createRoute(`/sb/${c.letters[index]}/:username`, c.channel, platform),
        ]));
    })),
    // todo deprecated, remove in the future, ask Sasha Egorov
    // Invite links (regular & walk with friends)
    ...flatten(sharingBlocksChannels.map((c) => {
        const createRoute = (path, channel, platform) => ({
            path,
            component: inviteComponent(store),
            onEnter: invite(store),
            params: { channel, platform, disableDefaultImageSrc: true },
            cookies: false,
            caching: true,
        });
        return flatten(['ios', 'android'].map((platform, index) => [
            createRoute(`/${c.letters[index]}/:username/welcome`, c.channel, platform),
            createRoute(`/${c.letters[index]}/:username/walk_with_friends/:challenge_id`, c.channel, platform),
            createRoute(`/${c.letters[index]}/:username/group/:group_id`, c.channel, platform),
            createRoute(`/${c.letters[index]}/:username/:offer_id?`, c.channel, platform),
        ]));
    })),
    // Company Join Page
    {
        path: '/company/:code',
        component: companyJoinComponent(store),
        cookies: false,
    },
    // Personal invite page
    {
        path: '/loves/:username',
        component: require('./pages/InvitePage').default,
        caching: true,
    },
    // Firebase Dynamic Links
    {
        path: '/link/*',
        component: () => {
            const { onEnter } = store.getState();
            return React.createElement(Redirect, { to: onEnter.redirectUrl });
        },
        onEnter: dynamicLink(store),
    },
    // AppStore redirect
    {
        path: '/get/:path*',
        component: require('./pages/GetRedirect').default,
    },
];
export default createRoutes;
function checkUserAgent(headers) {
    const MobileDetect = require('mobile-detect');
    const ua = headers['user-agent'];
    const md = new MobileDetect(ua);
    return {
        isBot: isBotCheck(ua) || md.match('facebookexternalhit'),
        isIOS: md.is('iOS') || md.is('iPhone'),
        isAndroid: md.is('AndroidOS'),
    };
}
function inviteComponent(store) {
    const { onEnter } = store.getState();
    if (onEnter.redirectUrl) {
        return () => React.createElement(Redirect, { to: onEnter.redirectUrl });
    }
    return require('./pages/CustomFirebasePage').default;
}
function companyJoinComponent(store) {
    const { onEnter } = store.getState();
    if (onEnter.redirectUrl) {
        return () => React.createElement(Redirect, { to: onEnter.redirectUrl });
    }
    return require('./pages/CompanyJoinPage').default;
}
function invite(store) {
    return async ({ location, route, match, request }) => {
        const params = match.params;
        function redirectToInvitePage() {
            return Promise.resolve({ redirectUrl: match.url.replace(new RegExp(params.offer_id + '/?'), '') });
        }
        if (process.browser) {
            return Promise.resolve(null);
        }
        const channel = get(location, 'query.channel') || get(route, 'params.channel', 'other');
        const platform = get(location, 'query.platform') || get(route, 'params.platform', 'unknown');
        const feature = get(location, 'query.feature') || get(route, 'params.feature');
        let campaign = get(location, 'query.campaign') || get(route, 'params.campaign');
        const [userResponse, offerResponse] = await Promise.all([
            store.dispatch(searchUserByUsername(params.username)),
            params.offer_id ? store.dispatch(prefetchOffer(params.offer_id)) : null,
        ]);
        const offer = get(offerResponse, 'payload.data');
        if (offerResponse && !offerResponse.error) {
            if (offer.type !== 'crowdfunding') {
                return redirectToInvitePage();
            }
        }
        else if ((params.offer_id && !offerResponse) || (offerResponse && offerResponse.error)) {
            return redirectToInvitePage();
        }
        if (userResponse && !userResponse.error) {
            const { config } = store.getState();
            // const getVariant = require('./utils/getVariant')
            // const [variant, variantCampaign] = getVariant(config.origin, offer === undefined)
            const { isBot } = checkUserAgent(request.header);
            const user = get(userResponse, 'payload.data');
            // campaign = variant > 0 ? variantCampaign : campaign
            if (!isBot) {
                await analytics.track('InviteLink', 'click', pickBy({
                    platform,
                    campaign,
                    feature,
                    username: user.username,
                    label: channel,
                    page: `${location.pathname}${location.search ? `?${location.search}` : ''}`,
                    origin: config.origin,
                    ...pick(params, ['offer_id', 'challenge_id', 'group_id']),
                }, identity), request);
            }
        }
        return Promise.resolve(null);
    };
}
function marketplaceComponent(store) {
    const { onEnter } = store.getState();
    if (onEnter.redirectUrl) {
        return () => React.createElement(Redirect, { to: onEnter.redirectUrl });
    }
    return () => React.createElement(ErrorComponent, { description: 'To open marketplace, please visit this page from the mobile device' });
}
function marketplace(store) {
    return async ({ location, route, match, request }) => {
        const { isBot, isIOS, isAndroid } = checkUserAgent(request.header);
        // Profile deeplink works only on mobile devices
        if (!isIOS && !isAndroid) {
            return Promise.resolve(null);
        }
        const { config } = store.getState();
        const { channel, platform } = get(route, 'params', {});
        if (!isBot && !process.browser) {
            await analytics.track('MarketplaceLink', 'click', pickBy({
                platform,
                label: channel,
                page: `${match.path}${location.search}`,
                origin: config.origin,
            }, identity), request);
        }
        const options = {
            encodeLink: false,
        };
        const firebaseRoute = generateFirebaseMarketplaceRoute(config, options);
        return Promise.resolve({ redirectUrl: buildLink(firebaseRoute) });
    };
}
function profile(store) {
    return async ({ location, route, match, request }) => {
        const { isBot, isIOS, isAndroid } = checkUserAgent(request.header);
        // Profile deeplink works only on mobile devices
        if (!isIOS && !isAndroid) {
            return Promise.resolve(null);
        }
        const params = match.params;
        const { channel, platform } = get(route, 'params', {});
        const userResponse = await store.dispatch(searchUserByUsername(params.username));
        if (userResponse && !userResponse.error) {
            const { config } = store.getState();
            const user = get(userResponse, 'payload.data');
            if (!isBot && !process.browser) {
                await analytics.track('ProfileLink', 'click', pickBy({
                    platform,
                    label: channel,
                    username: user.username,
                    page: `${match.path}${location.search}`,
                    origin: config.origin,
                }, identity), request);
            }
            const options = {
                encodeLink: false,
            };
            const firebaseRoute = generateFirebaseProfileRoute(user, config, options);
            return Promise.resolve({ redirectUrl: buildLink(firebaseRoute) });
        }
        return Promise.resolve(null);
    };
}
function profileComponent(store) {
    const { onEnter, params } = store.getState();
    let username = '';
    if (onEnter.redirectUrl) {
        return () => React.createElement(Redirect, { to: onEnter.redirectUrl });
    }
    if (params && params.username) {
        username = params.username;
    }
    return () => (React.createElement(ErrorComponent, { description: `To open @${username} profile, please visit this page from the mobile device` }));
}
const dynamicLink = (store) => {
    return ({ request, location }) => {
        const params = location.query;
        if (!process.browser) {
            const { config } = store.getState();
            const { isBot, isIOS, isAndroid } = checkUserAgent(request.header);
            if ((isIOS || isAndroid) && !isBot) {
                return Promise.resolve({
                    redirectUrl: `${config.firebaseDynamicLinkDomain}${params.splat}`,
                });
            }
        }
        return Promise.resolve({ redirectUrl: '/' });
    };
};
function extractHostname(url) {
    let hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname
    if (typeof url !== 'string') {
        return '';
    }
    if (url.indexOf('//') > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }
    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];
    return hostname;
}
