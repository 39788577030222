import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AdviceNotSuitable, Common, Footer, List, ListItem, SamsungDivider, SettingsButton, Text, Title, } from './common';
const messages = defineMessages({
    text: 'Makers of mobile devices frequently pre-install “battery savers” which greatly slow down your smartphone. It’s important to disable or uninstall them from your app list. Check if your device has Doze Mode and make sure it doesn’t affect Sweatcoin.',
    removeTitle: 'Remove restrictions',
    settings: 'Settings',
    apps: 'Apps & Notifications',
    allApps: 'Apps/All apps',
    sweatcoin: 'Sweatcoin',
    restrictions: 'Disable Background restrictions / Background limits',
    alternative: 'Alternatively try this:',
    power: 'Battery & power saving',
    ignore: 'Enable “Ignore optimizations”',
    batteryOptimization: 'Battery Optimization',
    disable: 'Disable Battery Optimization',
    batteryAndBatteryOptimization: 'Battery and Battery Optimization',
    appsAndAppsOptimization: 'Apps and Apps Optimization',
    autostart: 'Autostart and preventing apps from being shut down either manually or automatically',
    android6: 'Android 6+:',
    batteryUsage: 'Battery usage',
    ignoreOptimizations: 'Ignore optimizations',
    turnOn: 'Turn on to ignore battery optimization for Sweatcoin',
    battery: 'Battery',
});
const Other = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Common, { featureAccelerometerTracker: props.featureAccelerometerTracker, trackEvent: props.trackEvent, openSettings: props.openSettings }),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.text })),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.removeTitle })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.restrictions }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.power })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.ignore }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batteryOptimization })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.allApps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.disable }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batteryAndBatteryOptimization })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.appsAndAppsOptimization })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.autostart }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.android6 })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.power })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batteryUsage })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.ignoreOptimizations })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.turnOn }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.battery })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batteryOptimization })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.allApps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.disable }))),
        React.createElement(SettingsButton, { marginTop: true, openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(SamsungDivider, null),
        React.createElement(Footer, { trackEvent: props.trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: props.trackEvent })));
};
export default Other;
