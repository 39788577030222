import * as RouterActions from 'actions/router';
import ErrorHandler from 'components/ErrorComponent';
import React from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
const createRoutes = () => [
    {
        path: '/tnc',
        component: require('./pages/Tnc').default,
    },
    {
        path: '/tnc-us',
        component: require('./pages/TncUs').default,
    },
    {
        path: '/affiliate-disclosure',
        component: require('./pages/AffiliateDisclosure').default,
    },
    {
        path: '/privacy',
        component: require('./pages/Privacy').default,
    },
    {
        path: '/verification-algorithm',
        component: require('./pages/VerificationAlgorithm').default,
    },
    // {
    //   path: '/forgood',
    //   component: require('./pages/ForGood').default,
    // },
    {
        path: '/nhs',
        component: require('./pages/NHS').default,
    },
    {
        path: '/health',
        component: require('./pages/Health').default,
    },
    {
        path: '/about',
        component: ({ location }) => React.createElement(Redirect, { to: `/team${location.search}` }),
    },
    {
        path: '/contacts',
        component: require('./pages/Contacts').default,
    },
    {
        path: '/select-your-country',
        component: require('./pages/SelectCountry').default,
    },
    {
        path: '/update-your-app',
        component: require('./pages/UpdateApp').default,
    },
    {
        path: '/download',
        component: require('./pages/DownloadApp').default,
    },
    {
        path: '/get-app',
        component: require('./pages/GetApp').default,
    },
    {
        path: '/boost-on-watch-guide',
        component: require('./pages/BoostOnWatchGuide').default,
    },
    {
        path: '/discounts',
        component: require('./pages/Discounts').default,
    },
    {
        path: '/ny-resolution-2021',
        component: require('./pages/NyResolution').default,
    },
    {
        path: '/android-instructions',
        onEnter: ({ request, query }) => {
            const { header } = request;
            const applicationAgent = header['application-agent'] || query['application-agent'];
            const isInApp = header.hasOwnProperty('application-agent');
            const featureAccelerometerTracker = header['feature-accelerometer-tracker'] === 'true' || query['feature-accelerometer-tracker'] === 'true';
            return { applicationAgent, featureAccelerometerTracker, isInApp };
        },
        component: require('./pages/AndroidInstructions').default,
    },
    {
        path: '*',
        onEnter: ({ dispatch }) => {
            const routerActions = bindActionCreators(RouterActions, dispatch);
            routerActions.raiseError(404);
        },
        component: () => React.createElement(ErrorHandler, { code: 404 }),
    },
];
export default createRoutes;
