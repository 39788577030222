import classnames from 'classnames';
import React, { Component } from 'react';
import 'styles/header';
export default class Header extends Component {
    state = {
        sticky: false,
    };
    _handleScroll = () => {
        const style = window.getComputedStyle(this.refs.mobile);
        const displayStyle = style.getPropertyValue('display');
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        const compare = displayStyle === 'block' ? 0 : 30;
        if (scrollTop > compare && !this.state.sticky) {
            this.setState({ sticky: true });
        }
        else if (scrollTop <= compare && this.state.sticky) {
            this.setState({ sticky: false });
        }
    };
    render() {
        return (React.createElement("header", { className: classnames('header', this.props.className, {
                'is-light': this.props.light,
                'is-light-mobile': this.props.lightMobile,
                'is-sticky': this.state.sticky,
            }) },
            React.createElement("div", { className: "only-mobile", ref: "mobile" }),
            React.createElement("div", { className: "header__content" },
                React.createElement("div", { className: "header__logo" },
                    React.createElement("a", { href: "/" },
                        React.createElement("i", { className: "icon-logo-full" }))))));
    }
}
