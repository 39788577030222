import React from 'react';
import styled from 'styled-components';
import { useUser } from '../../hooks';
import { logEvent } from '../../utils';
const ExceedingAverageAchievement = ({ id }) => {
    const user = useUser(id);
    return (React.createElement(Layout, null,
        React.createElement(Title, null, "You\u2019ve beaten your last week record!"),
        React.createElement(Label, null, "Only 17% of Sweatcoin users worldwide managed to do so. Keep pushing! These are rough times, congratulations on the hard work!"),
        React.createElement(ShareButton, { disabled: user == null, onClick: handleShare }, "Share with friends")));
    async function handleShare() {
        try {
            await navigator.share({
                title: "I've unlocked a new Sweatcoin achievement!",
                text: 'Check it out and find motivation to stay healthy!',
                url: `https://sweatco.in/i/${user.username}?channel=achievement&campaign=exceedingaverage`,
            });
            logEvent('event@lofi_exavgachievement.sharesuccess');
            alert('Thank you for sharing!');
        }
        catch (error) { }
    }
};
export default ExceedingAverageAchievement;
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  padding: 34px 16px 0px 16px;
`;
const Title = styled.h1 `
  font-family: PFDINMonoPro !important;
  font-size: 28px;
  letter-spacing: -0.5px;
  line-height: 33.6px;
`;
const Label = styled.span `
  font-family: sans-serif;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.4px;
  margin: 16px 0px;
`;
export const ShareButton = styled.button `
  height: 44px;
  border-radius: 13px;
  border: none;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #1b3556;
  background-color: #ffffff;
  margin: 194px 0px 36px 0px;
`;
