import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import QrCode from 'components/QrCode';
import { media } from 'components/responsive';
import logo_src from '../../../assets/Home/header__logo.svg';
import bg from '../../../assets/NyResolution/bg.jpg';
import pattern from '../../../assets/NyResolution/pattern.png';
import phone from '../../../assets/NyResolution/phone.png';
import AppLinks from '../../Home/components/AppLinks';
const configSelector = (state) => state.config;
const NyResolution = ({ location }) => {
    // Config & firebase
    const config = useSelector(configSelector);
    const { origin } = config;
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'ny-resolution-page' }] }),
        React.createElement(Pattern, null),
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(Phone, { src: phone }),
            React.createElement(Title, null, "Your are on the secret waitlist! We\u2019ll update you when feature is live."),
            React.createElement(Caption, null, "In the meantime, install and explore the latest version of Sweatcoin (unless you already have it). New Year Resolution feature will be available on the latest app."),
            React.createElement(StyledQrCode, { link: `${origin}${location.pathname}` }),
            React.createElement(StyledAppLinks, { isMobile: true, container: Container }))));
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: url(${bg}) 50% 50% no-repeat;
  background-size: cover;
`;
const Container = styled.div `
  position: relative;
  padding: 30px;

  ${media.tablet `
    width: 700px;
    padding: 30px 0;
    margin: 0 auto;
  `};

  ${media.desktop `
    width: 1000px;
    padding: 30px 0;
    margin: 0 auto;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
`;
const Logo = styled.img `
  position: relative;
  width: 197px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
`;
const Phone = styled.img `
  display: none;

  ${media.tablet `
    display: block;
    width: 330px;
    position: absolute;
    top: 35px;
    right: 0;
  `};

  ${media.desktop `
    display: block;
    width: 500px;
    position: absolute;
    top: 35px;
    right: 0;
  `};
`;
const Title = styled.h1 `
  margin-top: 40px;
  font-family: ${FONTS.SimplonNormBold.name}, ${FONTS.fallback};
  font-size: 28px;
  line-height: 1.3;
  font-weight: 800;
  color: #ffffff;
  letter-spacing: 0.2px;

  ${media.tablet `
    width: 350px;
  `};

  ${media.desktop `
    margin-top: 80px;
  `};
`;
const Caption = styled.p `
  margin-top: 14px;
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-size: 18px;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: #ffffff;

  ${media.tablet `
    width: 330px;
  `};
`;
const StyledQrCode = styled(QrCode) `
  display: none;

  ${media.tablet `
    margin: 40px 0 0;
    display: block;
  `};
`;
const StyledAppLinks = styled(AppLinks) `
  position: static !important;
  margin: 60px 0 0 0 !important;
  justify-content: flex-start;

  ${media.tablet `
    width: 330px;
  `};
`;
const Pattern = styled.div `
  background: url(${pattern}) repeat-x center bottom;
  background-size: 500px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  ${media.tablet `
    display: none;
  `};
`;
export default NyResolution;
