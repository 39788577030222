import { GET_SFW_CLUB_FULFILLED, GET_SFW_CLUB_REJECTED } from 'constants/ActionTypes';
const sfwClubReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_SFW_CLUB_FULFILLED: {
            return { ...state, ...action.payload.data };
        }
        case GET_SFW_CLUB_REJECTED: {
            return { ...state, ...action.payload.data };
        }
        default:
            return state;
    }
};
export default sfwClubReducer;
