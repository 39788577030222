import _ from 'lodash';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as HomeActions from 'actions/home';
import crossSvg from 'assets/Home/cross.svg';
import crossSvgEmbed from 'assets/Home/cross_embed.svg';
import menuSvg from 'assets/Home/menu.svg';
import menuSvgEmbed from 'assets/Home/menu_embed.svg';
import { media } from 'components/responsive';
import COLORS from 'constants/colors';
import SECTIONS from 'constants/sections';
import analytics from 'utils/analytics';
import { scrollToElement } from 'utils/scrollTo';
import { Helpers } from './Background';
const orangeColor = COLORS.orange;
export const messages = defineMessages({
    vision: 'Vision',
    facts: 'Facts',
    how_it_works: 'How it works',
    partners: 'Partners',
    more: 'More',
});
const mapStateToProps = (state) => ({
    isMenuOpen: _.get(state, 'home.isMenuOpen'),
    active_section: _.get(state, 'home.activeSection'),
    isMobile: _.get(state, 'home.device.isMobile'),
});
const mapDispatchToProps = (dispatch) => ({
    toggleMenu: (background) => dispatch(HomeActions.toggleMenu(background)),
});
class Menu extends React.Component {
    menuTogglerRef = React.createRef();
    toggleMenu = () => {
        const current = this.menuTogglerRef.current;
        let background;
        // do not apply background color to embed Menu
        if (!this.props.embed) {
            const menuContainer = current.parentNode;
            background = menuContainer.getAttribute('data-background') || orangeColor;
            menuContainer.style.background = background;
        }
        const { top } = current.getBoundingClientRect();
        current.style.top = top + 'px';
        this.props.toggleMenu(background);
    };
    scrollTo = (section_id, behavior = 'smooth') => {
        const scroll_to = Helpers.select(`.section_${section_id}`)[0];
        if (scroll_to) {
            scrollToElement(scroll_to, behavior);
        }
    };
    trackMenuItemClick = (value) => {
        analytics.track(`header_menu_item_${value}`, 'click', {
            origin: location.origin,
            page: location.pathname,
        });
    };
    menuItemOnClick = (section_id, item_id) => (e) => {
        // If it is HOME page
        if (!this.props.embed) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (this.props.isMenuOpen) {
            setTimeout(() => {
                this.toggleMenu();
            }, 200);
        }
        this.trackMenuItemClick(item_id);
        this.scrollTo(section_id, this.props.isMobile ? 'auto' : 'smooth');
    };
    isFirstRender() {
        return typeof this.props.active_section === 'undefined';
    }
    isActive(index) {
        const { active_section, embed } = this.props;
        let active = null;
        if (embed)
            return false;
        if (this.isFirstRender() && index === 0) {
            return true;
        }
        else if (active_section) {
            active = SECTIONS[active_section.name].menuItem;
        }
        return active === index;
    }
    render() {
        const { isMenuOpen, embed } = this.props;
        return (React.createElement(Wrapper, { embed: embed, className: isMenuOpen ? 'open' : '', id: "menu" },
            React.createElement(MenuItem, { ref: this.menuTogglerRef, className: "menu__item_mobile", embed: embed, onClick: this.toggleMenu }),
            React.createElement(MenuItem, { href: "/#vision", className: `menu__item ${this.isActive(0) ? 'active' : ''}`, onClick: this.menuItemOnClick(1, 1) },
                React.createElement(FormattedMessage, { ...messages.vision })),
            React.createElement(MenuItem, { href: "/#facts", className: `menu__item ${this.isActive(1) ? 'active' : ''}`, onClick: this.menuItemOnClick(4, 2) },
                React.createElement(FormattedMessage, { ...messages.facts })),
            React.createElement(MenuItem, { href: "/#how-it-works", className: `menu__item ${this.isActive(2) ? 'active' : ''}`, onClick: this.menuItemOnClick(7, 3) },
                React.createElement(FormattedMessage, { ...messages.how_it_works })),
            React.createElement(MenuItem, { href: "/#partners", className: `menu__item ${this.isActive(3) ? 'active' : ''}`, onClick: this.menuItemOnClick(8, 4) },
                React.createElement(FormattedMessage, { ...messages.partners })),
            React.createElement(MenuItem, { href: "/nhs", embed: embed, className: 'menu__item_more' }, "NHS")));
    }
}
const mainColor = '#032555';
const MenuItem = styled.a `
  font-size: 17px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 5px 8px 4px;
  line-height: 1;
  display: none;
  margin-left: -1em;

  &,
  &:active {
    text-decoration: none;
    color: inherit;
  }

  &.menu__item_mobile {
    display: block;

    &,
    &:hover,
    &.active {
      &::before {
        content: none;
      }
    }
  }

  ${media.desktop `
    display: block;
    
    &.menu__item_mobile {
      display: none;
    }
  `}

  &::before {
    content: '> ';
    display: inline;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.3);

    &::before {
      color: #fff;
      opacity: 1;
    }
  }

  &.active,
  &.active:hover {
    color: #ffffff;

    &::before {
      color: inherit;
      opacity: 1;
    }
  }

  &.menu__item_more {
    border: 1px solid ${(props) => (props.embed ? mainColor : '#fff')};
    border-radius: 4px;

    ${media.desktop `
      margin-left: 35px !important;
    `}

    &::before {
      content: none;
    }
  }

  &.menu__item_mobile {
    width: 24px;
    height: 24px;
    background: url('${(props) => (props.embed ? menuSvgEmbed : menuSvg)}') no-repeat;
    opacity: 1;

    &:hover {
      opacity: 1;
    }
  }

  & + & {
    margin-left: 12px;

    ${media.desktop_large `margin-left: 20px`}
  }
`;
const Wrapper = styled.div `
  position: relative;
  display: flex;

  &:not(.open) {
    background: transparent !important;
  }

  &.open {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${(props) => props.background};
    flex-direction: column;
    justify-content: center;

    ${MenuItem} {
      display: block;
      margin-left: 0;
      text-align: center;
      padding: 20px 0;

      &.menu__item_more {
        border-right: 0;
        border-left: 0;
      }

      & + ${MenuItem} {
        margin-top: 32px;
      }

      ${media.landscape `
        padding: 10px 0;
        
        & + ${MenuItem} {
          margin-top: 20px;
        }
      `};
    }

    .menu__item_mobile {
      position: absolute;
      right: 20px;
      background-image: url('${(props) => (props.embed ? crossSvgEmbed : crossSvg)}');
    }
  }

  ${(props) => props.embed
    ? `
      background: #ffffff;
      
      ${MenuItem}:hover::before {
        color: inherit;
      }
      
      ${MenuItem}.active {
        background: ${mainColor};
        color: #ffffff !important;
      }
    `
    : null};
`;
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
