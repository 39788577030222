import React from 'react';
import Helmet from 'react-helmet';
import { Motion, spring } from 'react-motion';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import responsiveContainer, { media } from 'components/responsive';
import hinSrc from '../../../assets/NHS/hin.jpg';
import leaderboardSrc from '../../../assets/NHS/leaderboard.jpg';
import marketplaceSrc from '../../../assets/NHS/marketplace.jpg';
import mertonSrc from '../../../assets/NHS/merton.jpg';
import nhs_swlSrc from '../../../assets/NHS/nhs_swl.jpg';
import Layout from '../../../components/Layout';
import { containerHeight } from '../../Home/components/Header';
import OnVisible from '../../Home/components/OnVisible';
import DefaultRound, { Big, RoundContent, Text as RoundText } from '../../Home/components/Round';
import '../styles/static';
export default class NHS extends React.Component {
    // Show all elements for clients with disabled js
    state = {
        programLengthShown: 1,
        participantsShown: 1,
        marketplaceShown: 1,
        leaderboardShown: 1,
        averageWeightShown: 1,
        completionRateShown: 1,
        walkingLevelsShown: 1,
    };
    componentDidMount() {
        // Prepare elements for animation
        this.setState({
            programLengthShown: 0,
            participantsShown: 0,
            marketplaceShown: 0,
            leaderboardShown: 0,
            averageWeightShown: 0,
            completionRateShown: 0,
            walkingLevelsShown: 0,
        });
    }
    programLengthRef = React.createRef();
    participantsRef = React.createRef();
    marketplaceRef = React.createRef();
    leaderboardRef = React.createRef();
    averageWeightRef = React.createRef();
    completionRateRef = React.createRef();
    walkingLevelsRef = React.createRef();
    // Set element with tag shown
    onElementShow = (tag) => () => setTimeout(() => this.setState((state) => ({ ...state, [tag]: 1 })), 200);
    render() {
        const { programLengthShown, participantsShown, marketplaceShown, leaderboardShown, averageWeightShown, completionRateShown, walkingLevelsShown, } = this.state;
        return (React.createElement(Layout, { newHeader: true },
            React.createElement(Helmet, { meta: [
                    { name: 'event-id', content: 'nhs-page' },
                    {
                        name: 'apple-itunes-app',
                        content: 'app-id=971023427',
                    },
                ] }),
            React.createElement(Container, null,
                React.createElement(PageTitle, null, "HEALTHY INCENTIVE DECATHLON: NHS\u00A0&\u00A0SWEATCOIN"),
                React.createElement(Row, null,
                    React.createElement(Cell, null,
                        React.createElement(Title, null, "Background"),
                        React.createElement(Text, null, "There are currently five million people in England at high risk of developing Type 2 diabetes, which is largely preventable through lifestyle changes. If current trends persist, one in three people will be obese by 2034 and one in 10 will develop Type 2 diabetes. About 10 percent of the entire NHS budget is spent treating complications from diabetes, or \u00A310 billion/ year. Reducing this would have a major impact both on people\u2019s well-being and on resources. The current National Diabetes Prevention Programme achieves only a 20% completion rate, and so an engaging alternative is much needed.")),
                    React.createElement(Cell, null,
                        React.createElement(Round, { background: "#CF5142", style: { margin: '0 auto' } },
                            React.createElement(FixedRoundContent, null,
                                React.createElement(Big, { mono: true }, "10%"),
                                React.createElement(NHSRoundText, { mono: true }, "of the entire NHS budget is spent treating complications from diabetes"))))),
                React.createElement(Row, null,
                    React.createElement(Cell, null,
                        React.createElement(Title, null, "Our work with the NHS South West London Health & Care Partnership"),
                        React.createElement(Text, null, "Since 2021 we have been piloting a new type of behaviour change programme, together with the NHS. Over 10 weeks we introduced people at risk of developing Type 2 diabetes to different types of physical activity. We offered information and learning about health, diet, lifestyle, connected users to locally offered services, exercise groups as well as peer support and introduced some good healthy competition. The aim of the programme was to prevent the onset of Type 2 diabetes by supporting and encouraging people to make positive behaviour changes in a way that is engaging"),
                        React.createElement(Text, null, "The results of the programme have been phenomenal - the average weight loss per participant was 3kg, with an average increase in walking of +45%. Over 95% of participants who started the programme, completed it (vs industry averages of <25%)."),
                        React.createElement(Image, { src: nhs_swlSrc, style: { height: '3.35rem' } })),
                    React.createElement(Cell, null,
                        React.createElement(RoundWrapper, null,
                            React.createElement(OnVisible, { onVisible: this.onElementShow('programLengthShown') },
                                React.createElement(SmallRound, { background: "#F8A746", style: { margin: '0 auto' }, ref: this.programLengthRef },
                                    React.createElement(FixedRoundContent, null,
                                        React.createElement(StyledBig, { mono: true },
                                            React.createElement(Motion, { style: { value: spring(programLengthShown ? 10 : 0) } }, ({ value }) => React.createElement("span", null, Math.ceil(value)))),
                                        React.createElement(StyledNHSRoundText, { mono: true }, "weeks is the length of the programme")))),
                            React.createElement(OnVisible, { onVisible: this.onElementShow('averageWeightShown') },
                                React.createElement(SmallRound, { background: "#F07363", ref: this.averageWeightRef },
                                    React.createElement(FixedRoundContent, null,
                                        React.createElement(StyledBig, { mono: true },
                                            React.createElement(Motion, { style: { value: spring(averageWeightShown ? 3 : 0) } }, ({ value }) => React.createElement("span", null, Math.round(value * 100) / 100))),
                                        React.createElement(StyledNHSRoundText, { mono: true }, "kg average weight loss per participant")))),
                            React.createElement(OnVisible, { onVisible: this.onElementShow('completionRateShown') },
                                React.createElement(SmallRound, { background: "#211145", ref: this.completionRateRef },
                                    React.createElement(FixedRoundContent, null,
                                        React.createElement(StyledBig, { mono: true },
                                            React.createElement(Motion, { style: { value: spring(completionRateShown ? 92 : 0) } }, ({ value }) => React.createElement("span", null,
                                                Math.ceil(value),
                                                "%"))),
                                        React.createElement(StyledNHSRoundText, { mono: true }, "completion rate")))),
                            React.createElement(OnVisible, { onVisible: this.onElementShow('walkingLevelsShown') },
                                React.createElement(SmallRound, { background: "#87cab5", ref: this.walkingLevelsRef },
                                    React.createElement(FixedRoundContent, null,
                                        React.createElement(StyledBig, { mono: true },
                                            React.createElement(Motion, { style: { value: spring(walkingLevelsShown ? 45 : 0) } }, ({ value }) => React.createElement("span", null,
                                                "+",
                                                Math.ceil(value),
                                                "%"))),
                                        React.createElement(StyledNHSRoundText, { mono: true }, "increase in walking levels"))))))),
                React.createElement(Row, null,
                    React.createElement(Cell, null,
                        React.createElement(Title, null, "Innovation Grant from the Health Innovation Network"),
                        React.createElement(Text, null, "Working with the South West London Integrated Care System, who were successful in winning an Innovation Grant from the Health Innovation Network, we got to work developing and planning the \u2018Decathlon\u2019 programme."),
                        React.createElement(Text, null, "We knew we wanted to work with a small number of people in similar situations and in the same area. We thought it was important to create a peer group that could work together in a way that is common among diet and weight loss groups but that went beyond sitting in a community hall and was more active. We want people to encourage each other and share their successes and support each other a getting over barriers of behavioural change together."),
                        React.createElement(Text, null, "Each week the prograrmme featured one hour of learning and an hour of physical activity (delivered by the Harlequins Foundation), all sessions being unique. Finding an exercise or activity that a user likes and that suits them can be difficult, especially if they have become overweight and don\u2019t know where to start or don\u2019t like the idea of going into a gym or a class on their own. We know that when people know more about their health and understand their diet and lifestyle choices they self-manage better."),
                        React.createElement(Image, { src: hinSrc, style: { height: '2.5rem' } })),
                    React.createElement(Cell, null,
                        React.createElement(OnVisible, { onVisible: this.onElementShow('participantsShown') },
                            React.createElement(Round, { background: "#602979", style: { margin: '0 auto' }, ref: this.participantsRef },
                                React.createElement(FixedRoundContent, null,
                                    React.createElement(Big, { mono: true },
                                        React.createElement(Motion, { style: { value: spring(participantsShown ? 33 : 0) } }, ({ value }) => React.createElement("span", null, Math.ceil(value)))),
                                    React.createElement(NHSRoundText, { mono: true }, "participants signed up in the first week")))))),
                React.createElement(Row, null,
                    React.createElement(Cell_Marketplace, null,
                        React.createElement(Title, null, "Easy to use technology with no barriers to entry"),
                        React.createElement(Text, null, "Our product is an app on a smartphone that incentivises physical activity through rewarding users with products and services for achieving agreed targets. It\u2019s like a supermarket loyalty card meets a step count. You get points, essentially prizes, for achieving physical activity targets that are monitored via your phone. Our objective is simple; trying to influence behaviour change in an engaging way.")),
                    React.createElement(Cell, null,
                        React.createElement(OnVisible, { onVisible: this.onElementShow('marketplaceShown'), threshold: 0.4 },
                            React.createElement(Screenshot, { ref: this.marketplaceRef, visible: marketplaceShown, src: marketplaceSrc })))),
                React.createElement(Row, null,
                    React.createElement(Cell, null,
                        React.createElement(Title, null, "Co-designing a new prevention programme, together with the NHS"),
                        React.createElement(Text, null, "Traditional behaviour change solutions struggle \u2014 gym memberships/fitness trackers can be unaffordable for many, whilst gym memberships can often sit unused."),
                        React.createElement(Text, null, "Our product is a bespoke app tailored for specific demographics \u2013 and in this project, we created a custom leaderboard for the participants, who were randomly placed in groups. Each group then has challenges and checklists to complete, related to physical activity, education, and participation. Points for learning about low carb eating, attending the physical in-person session, or encouraging your fellow participant when they are feeling unmotivated")),
                    React.createElement(Cell, null,
                        React.createElement(OnVisible, { onVisible: this.onElementShow('leaderboardShown'), threshold: 0.4 },
                            React.createElement(Screenshot, { ref: this.leaderboardRef, visible: leaderboardShown, src: leaderboardSrc })))),
                React.createElement(Row, null,
                    React.createElement(Cell, null,
                        React.createElement(Title, null, "Incentives curated with Merton Public Health"),
                        React.createElement(Text, null, "Together with Merton Public Health, we also created a suite of rewards and prizes that participants could claim with their sweatcoins, which were earned through steps. These were also linked to the educational session - for example: the week 1 theme was at-home yoga exercises with our app offering yoga mats and equipment; the week 2 theme was walking football, so the app offered footballs and goalposts; week six focussed on diet and healthy eating and offered a \u2018carbs and cals\u2019 recipe book that included international cuisine options to meet the preferences of users from diverse backgrounds. These subtle nudges of rewards reinforced the learnings and enhanced behaviour change."),
                        React.createElement(Text, null, "There was also a community-giving aspect to the app enabling participants to donate their reward points to local charities."),
                        React.createElement(Text, null, "The education and learning aspect of the programme were digitised too - videos posted to the app summarising what the group learned that week, with the ability to participate in a quiz to earn that week\u2019s reward."),
                        React.createElement(Text, null, "Developing the digital innovation aspect to the Decathlon has opened it up to be more accessible, more engaging and more rewarding for people on the programme."),
                        React.createElement(Text, null,
                            React.createElement("strong", null, "Spring 2023:"),
                            " The success of this trial is now seeing it rolled out to many more participants across South London and expanded to patients at risk of cardiovascular disease. Watch this space for more data and results as they come in!"),
                        React.createElement(Image, { src: mertonSrc, style: { height: '4rem' } })),
                    React.createElement(Cell_ContactUS, null,
                        React.createElement(ContactUs, null,
                            "If this project sounds interesting and you\u2019d like to know more, please contact Clinical Director, at\u00A0",
                            React.createElement("a", { href: "mailto:shaun@sweatco.in" }, "shaun@sweatco.in")))))));
    }
}
const Container = styled.div `
  ${responsiveContainer};
  padding-top: ${containerHeight.phone};

  ${media.tablet `padding-top: ${containerHeight.tablet};`};
  ${media.desktop `padding-top: ${containerHeight.desktop};`};

  padding-bottom: 8.6rem;
`;
const FixedRoundContent = styled(RoundContent) `
  width: 85% !important;
  /* shift a vertical center a little bit upper */
  top: 45% !important;
`;
const NHSRoundText = styled(RoundText) `
  font-size: 0.85rem !important;
`;
const Image = styled.img `
  margin-top: 1.5rem;

  ${media.desktop `margin-top: 1.2rem;`};
`;
const Screenshot = styled(Image) `
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  transition: all 0.45s;
  opacity: ${(props) => (props.visible ? 1 : 0)};

  ${media.desktop `
    height: 24rem;
    width: auto;
    transform: ${(props) => (props.visible ? 'none' : 'translateX(20%)')};
  `};
`;
const Row = styled.div `
  & + & {
    margin-top: 6.3rem;
  }

  ${media.desktop `
    display: flex;

    & + & {
      margin-top: 7rem;
    }
  `};
`;
const Cell = styled.div `
  & + & {
    margin-top: 3.3rem;

    ${media.desktop `margin-top: 0;`};
  }

  ${media.desktop `
    flex: 1 1 50%;

    &:first-child {
      padding-right: 10%;
    }
  `};
`;
const Cell_ContactUS = styled(Cell) `
  ${media.desktop `
    display: flex;
    align-items: center;
  `};
`;
const Cell_Marketplace = styled(Cell) `
  & + ${Cell} {
    margin-top: 3.3rem;

    ${media.desktop `margin-top: 0;`};
  }

  ${media.desktop `
    display: flex;
    justify-content: center;
    flex-direction: column;
  `};
`;
const Round = styled(DefaultRound) `
  ${media.desktop `
    margin-left: auto !important;
    margin-right: 0 !important;
  `};
`;
const RoundWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const SmallRound = styled(Round) `
  width: 10em !important;
  height: 10em !important;
  margin: 0 0.5em 1em !important;
`;
const StyledBig = styled(Big) `
  font-size: 2rem !important;
`;
const StyledNHSRoundText = styled(RoundText) `
  font-size: 0.6rem !important;
`;
const PageTitle = styled.h1 `
  font-size: 2rem;
  font-weight: bold;
  padding: 2.5rem 0;
  font-family: ${FONTS.SimplonNorm.getFontFamily()};

  ${media.tablet `
    font-size: 1.6rem;
  `};
  ${media.desktop `
    font-size: 3rem;
    padding: 4rem 0;
  `};
`;
const Title = styled.h2 `
  font-size: 1.8rem;
  font-family: ${FONTS.SimplonMono.getFontFamily()};
  padding-bottom: 0.5rem;

  ${media.tablet `
    font-size: 1rem;
  `};
  ${media.desktop `
    font-size: 1.8rem;
  `};
`;
const Text = styled.p `
  font-size: 1rem;
  margin-top: 0.5rem;
  font-family: ${FONTS.SimplonNorm.getFontFamily()};

  ${media.tablet `
    font-size: 0.7rem;
  `};
  ${media.desktop `
    font-size: 0.8rem;
  `};
  ${media.desktop_large `
    font-size: 1rem;
  `};
`;
const ContactUs = styled.h3 `
  font-family: ${FONTS.SimplonMono.getFontFamily()};
  font-size: 1.6rem;
  color: #3cd6ee;
  letter-spacing: -0.025em;

  a {
    text-decoration: underline;
    transition: all 0.3s;

    &:hover {
      opacity: 0.6;
    }
  }

  ${media.desktop `
    font-size: 1.8rem;
  `};
`;
