import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as ApiActions from 'actions/api';
import HTMLText from 'components/HTMLText';
import Layout from 'components/Layout';
import '../styles/static';
const mapStateToProps = createStructuredSelector({
    text: (state) => state.legal.tncUs,
});
class Privacy extends React.Component {
    static fetchData(_, store) {
        return store.dispatch(ApiActions.prefetchLegal('tncUs'));
    }
    render() {
        return (React.createElement(Layout, null,
            React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'tncUs' }] }),
            React.createElement(Layout.Container, null,
                React.createElement("div", { className: "static" },
                    React.createElement("h2", null, "Terms&Conditions"),
                    React.createElement(HTMLText, { component: "div", html: this.props.text && this.props.text.content })))));
    }
}
export default connect(mapStateToProps)(Privacy);
