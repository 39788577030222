import ClipboardJS from 'clipboard';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import apple from 'assets/Home/apple.png';
import phone1 from 'assets/Home/banner_phone_1.png';
import phone2 from 'assets/Home/banner_phone_2.png';
import google from 'assets/Home/google.png';
import analytics from 'utils/analytics';
import useFirebaseQuery from 'utils/useFirebaseQuery';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    title: 'Download the Sweatcoin App to get started',
    appstoreName: 'Apple App Store',
    appstoreRating: '(273k ratings)',
    googleplayName: 'Google play',
    googleplayRating: '(1.8M ratings)',
});
const configSelector = (state) => state.config;
const SweatcoinBanner = ({ title, subtitle, className, feature = 'main_page_download_button' }) => {
    const appstoreButton = useRef();
    const googleplayButton = useRef();
    // Config & firebase
    const config = useSelector(configSelector);
    const { origin } = config;
    const [firebaseQuery, getCopyLink] = useFirebaseQuery('/', {
        feature,
    });
    useEffect(() => {
        new ClipboardJS(appstoreButton.current, { text: getCopyLink });
        new ClipboardJS(googleplayButton.current, { text: getCopyLink });
    }, [getCopyLink]);
    return (React.createElement(Wrapper, { id: "download", className: className },
        React.createElement(Phone1, null),
        React.createElement(Phone2, null),
        React.createElement(Title, null, title || React.createElement(FormattedMessage, { ...messages.title })),
        subtitle && React.createElement(Subtitle, null, subtitle),
        firebaseQuery ? (React.createElement(Buttons, null,
            React.createElement(Appstore, { onClick: handleAppstoreClick, ref: appstoreButton, href: firebaseQuery.link },
                React.createElement(StoreName, null,
                    React.createElement(FormattedMessage, { ...messages.appstoreName })),
                React.createElement(StoreRating, null,
                    React.createElement("strong", null, "4.6"),
                    "\u00A0",
                    React.createElement(FormattedMessage, { ...messages.appstoreRating }))),
            React.createElement(GooglePlay, { onClick: handleGooglePlayClick, ref: googleplayButton, href: firebaseQuery.link },
                React.createElement(StoreName, null,
                    React.createElement(FormattedMessage, { ...messages.googleplayName })),
                React.createElement(StoreRating, null,
                    React.createElement("strong", null, "4.4"),
                    "\u00A0",
                    React.createElement(FormattedMessage, { ...messages.googleplayRating }))))) : null));
    function handleAppstoreClick(event) {
        event.preventDefault();
        analytics.track('MainPageAppstoreDownloadButton', 'click', pickBy({
            origin,
            feature: 'main_page_download_button',
        }, identity));
        window.open('https://itunes.apple.com/app/sweatcoin-app-that-pays-you/id971023427', '_blank');
    }
    function handleGooglePlayClick(event) {
        event.preventDefault();
        analytics.track('MainPageGooglePlayDownloadButton', 'click', pickBy({
            origin,
            feature: 'main_page_download_button',
        }, identity));
        window.open('https://play.google.com/store/apps/details?id=in.sweatco.app', '_blank');
    }
};
const Wrapper = styled.div `
  margin: 35px 16px -10px;
  background: linear-gradient(299deg, rgba(60, 214, 238, 0.44) 0%, rgba(255, 255, 255, 0.1) 100%);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 24px 21px 345px;
  position: relative;
  z-index: 1;
  overflow: hidden;

  @media ${desktop()} {
    width: 980px;
    margin: 120px auto 67px;
    padding: 70px 22px 92px 420px;
    border-radius: 40px;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 2px solid rgba(255, 179, 219, 0.12);
    box-sizing: border-box;
    border-radius: 20px;
    z-index: -1;

    @media ${desktop()} {
      border-radius: 40px;
    }
  }
`;
const Phone1 = styled.div `
  position: absolute;
  width: 285px;
  height: 604px;
  top: 130px;
  left: 0;
  background: url(${phone1}) 50% 50% no-repeat;
  background-size: contain;
  z-index: -2;

  @media ${desktop()} {
    width: 360px;
    height: 604px;
    top: 0;
    left: 0;
  }
`;
const Phone2 = styled.div `
  position: absolute;
  width: 187px;
  height: 377px;
  top: 270px;
  left: 130px;
  background: url(${phone2}) 50% 50% no-repeat;
  background-size: contain;
  z-index: -3;

  @media ${desktop()} {
    width: 238px;
    height: 480px;
    top: 133px;
    left: 150px;
  }
`;
const Title = styled.h2 `
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 24px;
  text-align: center;
  padding: 0 30px;

  @media ${desktop()} {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 42px;
    padding: 0;
    text-align: left;
  }
`;
const Subtitle = styled.p `
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 16px;
  line-height: 1.5;
  margin-top: -16px;
  margin-bottom: 24px;
  text-align: center;
  padding: 0 30px;

  @media ${desktop()} {
    font-weight: 400;
    font-size: 20px;
    line-height: 1.5;
    margin-top: -32px;
    margin-bottom: 42px;
    padding: 0;
    text-align: left;
  }
`;
const Buttons = styled.div `
  display: flex;
`;
const Button = styled.a `
  display: block;
  padding: 10px 10px 10px 46px;
  border-radius: 16px;
  flex: 1;
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  transition: all 0.3s ease;

  @media ${desktop()} {
    width: 262px;
    padding: 25px 14px 25px 70px;
    border-radius: 20px;
    flex: none;
  }

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.8);
  }

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    display: block;
    position: absolute;
    top: 15px;
    left: 12px;

    @media ${desktop()} {
      width: 44px;
      height: 44px;
      top: 27px;
      left: 15px;
    }
  }
`;
const Appstore = styled(Button) `
  margin-right: 8px;

  @media ${desktop()} {
    margin-right: 14px;
  }
  &::before {
    background: url(${apple}) 50% 50% no-repeat;
    background-size: contain;
  }
`;
const GooglePlay = styled(Button) `
  &::before {
    background: url(${google}) 50% 50% no-repeat;
    background-size: contain;
  }
`;
const StoreName = styled.div `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 8px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase;

  @media ${desktop()} {
    font-size: 14px;
  }
`;
const StoreRating = styled.div `
  display: flex;
  align-items: flex-end;
  margin-top: 2px;

  strong {
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: capitalize;

    @media ${desktop()} {
      font-size: 24px;
    }
  }

  span {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    font-size: 6px;
    letter-spacing: 1.2px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    top: -3px;

    @media ${desktop()} {
      font-size: 13px;
      top: -5px;
    }
  }
`;
export default SweatcoinBanner;
