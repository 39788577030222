import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import Stack from './Stack';
const Header = ({ title, description, className }) => (React.createElement(StyledStack, { className: className },
    React.createElement(Logo, null),
    React.createElement(Title, null, title),
    React.createElement(Description, null, description)));
const StyledStack = styled(Stack) `
  margin: 0 16px 40px;
`;
const Title = styled.h1 `
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 70px;
  line-height: 80px;
  letter-spacing: -0.006em;
  color: #ffffff;
  text-align: center;
  margin: 25px 0 15px;
`;
const Description = styled.p `
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 46px;
  text-align: center;
  letter-spacing: -0.004em;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
`;
export default Header;
