import classnames from 'classnames';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import SocialButton from './SocialButton';
import 'styles/share-kit';
import 'styles/icons';
import 'styles/buttons';
const messages = defineMessages({
    share: 'Share on',
});
export default class ShareKit extends React.Component {
    render() {
        return (React.createElement("ul", { className: classnames(this.props.className, 'share-kit') },
            React.createElement("li", null,
                React.createElement(SocialButton, { className: "button share-kit__entry share-kit__entry--facebook", service: "facebook", link: this.props.link },
                    React.createElement(FormattedMessage, { ...messages.share }),
                    " ",
                    React.createElement("i", { className: "icon-fb-inverted" }))),
            React.createElement("li", null,
                React.createElement(SocialButton, { className: "button share-kit__entry share-kit__entry--twitter", service: "twitter", link: this.props.link },
                    React.createElement("i", { className: "icon-tw-inverted" })))));
    }
}
