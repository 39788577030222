import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { media } from 'components/responsive';
const messages = defineMessages({
    item1: '<strong>30%</strong> of the world (> 2.1 billion) are overweight or obese, projected to increase to <strong>50%</strong> by 2030',
    item2: 'The NHS spends <strong>>£10Bn</strong> on preventable diabetes. Cardiovascular disease costs the US health system <strong>>$320bn</strong>',
    item3: '<strong>40%</strong> of premature deaths are due to preventable diseases',
    item4: 'For every <strong>2,000</strong> steps, risk of heart disease, cancer and premature death decreases by <strong>10%</strong>',
});
const Statistics = () => {
    return (React.createElement(Layout, null,
        React.createElement(Item, null,
            React.createElement(FormattedMessage, { ...messages.item1 })),
        React.createElement(Item, null,
            React.createElement(FormattedMessage, { ...messages.item2 })),
        React.createElement(Item, null,
            React.createElement(FormattedMessage, { ...messages.item3 })),
        React.createElement(Item, null,
            React.createElement(FormattedMessage, { ...messages.item4 }))));
};
const Layout = styled.div `
  display: flex;
  gap: 30px;
  padding: 0 25px;
  margin: 70px auto 120px;
  flex-direction: column;

  ${media.desktop `
    gap: 50px;
    padding: 0 10px;
    margin: 70px auto 160px;
    flex-direction: row;
    padding: 0;
  `};
`;
const Item = styled.div `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #191841;
  flex: 1;

  strong {
    font-weight: 800;
    font-size: 30px;
    line-height: 1;
    background: #6202c0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    white-space: nowrap;
  }
`;
export default Statistics;
