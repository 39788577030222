import { GET_UPCOMING_OFFERS_FULFILLED } from 'constants/ActionTypes';
const offersUpcomingReducer = (state = null, action) => {
    switch (action.type) {
        case GET_UPCOMING_OFFERS_FULFILLED: {
            return action.payload.data.map((model) => model.id.toString());
        }
        default:
            return state;
    }
};
export default offersUpcomingReducer;
