import url from 'url';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import downloadIcon from 'assets/Challenges/download_icon.svg';
import preview from 'assets/Challenges/personal-challenge-preview.png';
import sharingSrc from 'assets/Challenges/personal-challenge.jpg';
import phone from 'assets/Challenges/phone_v2.png';
import logo_src from 'assets/Home/header__logo.svg';
import Button from 'components/Button';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { makeProfileSelector } from 'selectors/invites';
import About from '../components/About';
import DesktopView from '../components/DesktopView';
import HowItWorks from '../components/HowItWorks';
import Join from '../components/Join';
import Popup from '../components/Popup';
import { useFirebaseEssentials } from '../utils/firebase';
const messages = defineMessages({
    // Mobile
    mobileTitle: ' is challenging you to become the Champion.',
    mobileButtonTitle: 'Install Sweatcoin App',
    joinButtonTitle: 'Accept Challenge',
    // Desktop
    desktopTitle: 'Open the link on your phone to accept the challenge.',
    desktopDescription: 'You need to open the invitation link on your mobile device. Please copy the link or scan the QR code with your phone.',
    desktopButtonTitle: 'Copy the Link',
    copyButtonTitle: 'Link copied',
    // How it works
    howItWorksTitle: 'How it works',
    step_1: 'Install the Sweatcoin app to accept the challenge.',
    step_2: 'As soon as you join sweatcoin the race will start.',
    step_3: 'Whoever reaches the 10,000 steps in 48 hours first wins the Champions Cup.',
    // Popup
    popupTitle: 'Want to learn more?',
    popupDescription: 'Install the Sweatcoin app to see more details and join this challenge. ',
});
const PersonalChallenge = (props) => {
    const { match, location } = props;
    const { params } = match;
    const [isPopupVisible, setPopupVisible] = useState(false);
    // Profile & meta
    const profileSelector = useMemo(makeProfileSelector, []);
    const profile = useSelector((state) => profileSelector(state, params));
    const challengeType = 'personal_challenge';
    const { firebaseData: { firebaseQuery, getRedirectLink }, tracking: { channel, campaign, feature, platform }, buttonsRefs: [topButton, bottomButton], config: { origin }, } = useFirebaseEssentials({
        owner: profile,
        deeplink_path: `/pc/${profile.username}/${params.challenge_id}`,
        feature: `${challengeType}_invite`,
    });
    const pageLink = `${origin}${location.pathname}${location.search}`;
    const absoluteSharingUrl = url.resolve(origin, sharingSrc);
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'personal-challenge-page' },
                { name: 'event-params', content: { page_type: 'invite_page', inviter_id: profile.id } },
                { property: 'og:image', content: absoluteSharingUrl },
            ], link: [
                { rel: 'image_src', href: absoluteSharingUrl },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap',
                },
            ] }),
        isPopupVisible ? React.createElement(Popup, { onClickHandler: setPopupVisible, messages: messages }) : null,
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(DesktopView, { qrCodeLink: getCopyLink(), pageLink: pageLink, phone: phone, messages: messages }),
            React.createElement(MobileView, null,
                React.createElement(FormattedMessage, { ...messages.mobileTitle }, (title) => (React.createElement(Title, null,
                    React.createElement("span", null, profile.fullname || profile.username),
                    "\u00A0",
                    title))),
                firebaseQuery ? (React.createElement(FormattedMessage, { ...messages.mobileButtonTitle }, (title) => (React.createElement(StyledButton, { title: title, ref: topButton, link: firebaseQuery.link, onClick: handleClick },
                    React.createElement(DownloadIcon, { src: downloadIcon }))))) : null,
                React.createElement(Preview, { src: preview, onClick: () => setPopupVisible(true) }),
                React.createElement(HowItWorks, { messages: messages }),
                React.createElement(About, null),
                React.createElement(Join, null, firebaseQuery ? (React.createElement(FormattedMessage, { ...messages.joinButtonTitle }, (title) => (React.createElement(JoinButton, { title: title, ref: bottomButton, link: firebaseQuery.link, onClick: handleClick })))) : null)))));
    function handleClick(event) {
        event.preventDefault();
        analytics.track('PersonalChallengePage', 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink();
    }
};
PersonalChallenge.fetchData = async ({ match: { params } }, { dispatch }) => {
    return await dispatch(ApiActions.searchUserByUsername(params.username));
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(122deg, #fd9081 0%, #7729ff 100%);
`;
const Container = styled.div `
  position: relative;
  padding: 30px 0;
  margin: 0 auto;

  ${media.tablet `
    padding: 30px 0;
    width: 700px;
    display: block;
  `};

  ${media.desktop `
    width: 1000px;
  `};

  ${media.desktop_medium `
    width: 1200px;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
  padding: 0 16px;
`;
const Logo = styled.img `
  position: relative;
  width: 150px;

  ${media.tablet `
    width: 239px;
  `};
`;
const MobileView = styled.div `
  display: flex;
  flex-direction: column;

  ${media.tablet `
    display: none;
  `};
`;
const Title = styled.h1 `
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 28px;
  letter-spacing: -0.2px;
  line-height: 1.3;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 24px;
  padding: 0 16px;
  word-wrap: break-word;

  span {
    background-color: #735cd1;
    padding: 0 3px;
  }
`;
const StyledButton = styled(Button) `
  width: calc(100% - 32px) !important;
  margin: 0 16px;
`;
const DownloadIcon = styled.img `
  width: 18px;
  display: inline-block;
  margin-right: 8px;
`;
const JoinButton = styled(StyledButton) `
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffffff !important;
`;
const Preview = styled.img `
  margin: 24px 16px 0;
  width: calc(100% - 32px);
  height: auto;
  display: block;
`;
export default PersonalChallenge;
