import React from 'react';
import styled from 'styled-components';
import chatAvatarSrc from 'assets/SweatPaws/chat-avatar.png';
import { media } from 'components/responsive';
const Chat = (props) => {
    const { isMultipleImagesType, message } = props;
    return (React.createElement(Container, { isMultipleImagesType: isMultipleImagesType },
        React.createElement(AvatarContainer, { isMultipleImagesType: isMultipleImagesType },
            React.createElement(Avatar, { src: chatAvatarSrc })),
        React.createElement(Message, { isMultipleImagesType: isMultipleImagesType, dangerouslySetInnerHTML: { __html: message } })));
};
const Container = styled.div `
  position: ${(props) => (props.isMultipleImagesType ? 'sticky' : 'static')};
  display: flex;
  z-index: 1;
  order: ${(props) => (props.isMultipleImagesType ? null : 1)};
  margin-top: ${(props) => (props.isMultipleImagesType ? '-58px' : '30px')};
  top: ${(props) => (props.isMultipleImagesType ? 'calc(100% - 178px)' : null)};
  margin-bottom: ${(props) => (props.isMultipleImagesType ? 0 : '40px')};
  left: ${(props) => (props.isMultipleImagesType ? '16px' : null)};

  ${media.desktop `
    position: ${(props) => (props.isMultipleImagesType ? 'sticky' : 'static')};
    display: flex;
    justify-content: ${(props) => (props.isMultipleImagesType ? null : 'center')};
    width: 400px;
    margin-top: ${(props) => (props.isMultipleImagesType ? 0 : '40px')};
    margin-left: ${(props) => (props.isMultipleImagesType ? '26px' : 'auto')};
    margin-right: ${(props) => (props.isMultipleImagesType ? null : 'auto')};
    top: ${(props) => (props.isMultipleImagesType ? 'calc(100% - 182px)' : 'auto')};
    margin-bottom: ${(props) => (props.isMultipleImagesType ? '-62px' : 0)};
    left: auto;
  `};
`;
const AvatarContainer = styled.div `
  min-width: 32px;
  width: 32px;
  height: 32px;
  margin-top: auto;
  margin-right: 12px;
  overflow: hidden;
  background: ${(props) => props.isMultipleImagesType
    ? '#E6E4EB'
    : 'linear-gradient(112.4deg, #e5ff9c 29.63%, #e1ff8c 29.63%, #1affd9 76.7%)'};
  box-shadow: ${(props) => props.isMultipleImagesType
    ? '-2px -4px 16px rgba(159, 255, 167, 0.4), 2px 4px 16px rgba(26, 255, 217, 0.4)'
    : '-2px -4px 16px rgba(159, 255, 167, 0.4), 2px 4px 16px rgba(26, 255, 217, 0.4), inset 2px 4px 16px rgba(159, 255, 167, 0.25), inset -2px -2px 16px rgba(26, 255, 217, 0.3)'};
  backdrop-filter: blur(20px);
  border-radius: 100px;
`;
const Avatar = styled.img `
  display: block;
  width: 28px;
  height: 28px;
  margin: 0 auto;
  margin-top: 4px;
`;
const Message = styled.p `
  position: relative;
  margin-bottom: 16px;
  width: ${(props) => (props.isMultipleImagesType ? '185px' : 'auto')};
  padding: 12px 16px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: ${(props) => (props.isMultipleImagesType ? '#1C1132' : '#ffffff')};
  opacity: 0.9;
  background: ${(props) => props.isMultipleImagesType
    ? 'rgba(255, 255, 255, 0.95)'
    : 'linear-gradient(112.4deg, rgba(254, 83, 187, 0.1) 29.63%, rgba(83, 78, 255, 0.1) 76.7%)'};
  backdrop-filter: blur(20px);
  border-radius: 20px 20px 20px 0px;

  ${media.desktop `
    width: auto;
    font-weight: 400;
    font-size: 18px;
  `};

  &::before {
    content: ${(props) => (props.isMultipleImagesType ? 'none' : '""')};
    position: absolute;
    inset: 0;
    border: 1px solid transparent;
    background: linear-gradient(112.4deg, #ff70c7 29.63%, #6964ff 76.7%) border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 20px 20px 20px 0px;
  }

  span {
    text-transform: capitalize;
  }
`;
export default Chat;
