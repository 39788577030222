import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import cryptobg from 'assets/CryptoOnboarding/blob_2.png';
import logo from 'assets/CryptoOnboarding/logo.png';
import near from 'assets/CryptoOnboarding/near.png';
import sweat from 'assets/CryptoOnboarding/sweat.png';
import Button from 'components/Button';
import QrCode from 'components/QrCode';
import { media } from 'components/responsive';
import AppLinks from 'routes/Home/components/AppLinks';
import analytics from 'utils/analytics';
import { useFirebaseEssentials, useTracking } from '../utils/firebase';
const messages = defineMessages({
    buttonTitle: 'Open your app',
    step_1: 'Install Sweatcoin app',
    step_2: 'Opt-in to crypto',
    step_3: 'Grow your $SWEAT, enter to win rewards, and much more',
});
const Onboarding = (props) => {
    const { match: { params }, location, } = props;
    const [delimeter, delimeterReplacer] = ['%3F', '?'];
    // we need to get source before passing to useFirebaseEssentials as param inside deeplink_path
    const { source: source_ } = useTracking();
    const { firebaseData: { firebaseQuery, getRedirectLink }, tracking: { channel, campaign, feature, platform, source = source_ }, buttonsRefs: [button], config: { origin }, } = useFirebaseEssentials({
        transformTextLink: (text) => text.replace(delimeter, delimeterReplacer),
        deeplink_path: `/crypto-onboarding?source%3D${source_}`,
        feature: 'crypto_onboarding_link',
    });
    const qrCodeLink = `${origin}${location.pathname}?source=${source}`;
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'app-link-page' },
                { name: 'event-params', content: { page_type: 'invite_page' } },
            ] }),
        React.createElement(Layout, null,
            React.createElement(Container, null,
                React.createElement(LogoContainer, null,
                    React.createElement(Logo, { src: logo })),
                React.createElement(List, null,
                    React.createElement(Item, null,
                        React.createElement(Number, null, "1"),
                        React.createElement(FormattedMessage, { ...messages.step_1 }, (description) => React.createElement(Description, null, description))),
                    React.createElement(Item, null,
                        React.createElement(Number, null, "2"),
                        React.createElement(FormattedMessage, { ...messages.step_2 }, (description) => React.createElement(Description, null, description))),
                    React.createElement(Item, null,
                        React.createElement(Number, null, "3"),
                        React.createElement(FormattedMessage, { ...messages.step_3 }, (description) => React.createElement(Description, null, description))))),
            React.createElement(StyledQrCode, { link: qrCodeLink }),
            React.createElement(StyledAppLinks, { isMobile: true, container: Container }),
            firebaseQuery ? (React.createElement(FormattedMessage, { ...messages.buttonTitle }, (title) => React.createElement(StyledButton, { ref: button, title: title, link: firebaseQuery.link, onClick: handleClick }))) : null,
            React.createElement(SweatWrapper, null,
                React.createElement(Sweat, { src: sweat }),
                React.createElement(PoweredBy, null,
                    "powered by ",
                    React.createElement(Near, { src: near }))))));
    function handleClick(event) {
        event.preventDefault();
        analytics.track('CryptoOnboardingPage', 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink().replace(delimeter, delimeterReplacer);
    }
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: #09031c url(${cryptobg}) -380px -300px no-repeat;
  background-size: cover;

  ${media.tablet `
     background: #09031c url(${cryptobg}) -800px -300px no-repeat;
  `};
`;
const Layout = styled.div `
  position: relative;
  padding: 30px;

  ${media.tablet `
    width: 700px;
    padding: 30px 0;
    margin: 0 auto;
  `};

  ${media.desktop `
    width: 1000px;
    padding: 20px 0;
    margin: 0 auto;
  `};
`;
const LogoContainer = styled.div `
  margin-bottom: 60px;
`;
const Logo = styled.img `
  position: relative;
  width: 240px;
`;
const SweatWrapper = styled.div `
  display: flex;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  margin-top: 20px;

  ${media.tablet `
    margin-top: 0;
    display: block;
    width: 450px;
    position: absolute;
    top: 50px;
    right: -64px;
  `};

  ${media.desktop `
    margin-top: 50px;
    display: block;
    width: 850px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -300px;
  `};
`;
const Sweat = styled.img `
  width: 90%;
  height: auto;
  padding-left: 10%;
  margin: 0 auto;
`;
const PoweredBy = styled.p `
  color: #ff0d75;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  ${media.tablet `
    font-size: 10px;
    margin-top: 50px;
  `};

  ${media.desktop `
    margin-top: -20px;
    font-size: 18px;
    position: absolute;
    bottom: 45px;
    right: 300px;
  `};
`;
const Near = styled.img `
  width: 100px;
  height: auto;
  margin-top: -10px;

  ${media.tablet `
    margin-top: -5px;
  `};

  ${media.desktop `
    width: 217px;
  `};
`;
const Container = styled.div `
  margin: 24px 0;
  padding: 0 0 20px 0;
`;
const List = styled.div `
  z-index: 3;
  position: relative;

  ${media.tablet `
    max-width: 50vw;
  `};
`;
const Number = styled.div `
  flex: none;
  width: 50px;
  height: 50px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  margin-right: 20px;
  line-height: 1;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 26px;
    background-color: rgba(255, 255, 255, 1);
    left: 22px;
    bottom: -28px;
    z-index: -1;
  }
`;
const Item = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  /* max-width: 400px; */

  &:last-child ${Number}::after {
    display: none;
  }
`;
const Description = styled.p `
  color: #ffffff;
  font-size: 24px;
  line-height: 140%;
  letter-spacing: -0.2px;
  padding-top: 2px;
`;
const StyledQrCode = styled(QrCode) `
  display: none;

  ${media.desktop `
    margin: 40px 0 0;
    display: block;
  `};
`;
const StyledAppLinks = styled(AppLinks) `
  display: none;

  ${media.desktop `
    display: flex;
    position: static !important;
    margin: 60px 0 0 0 !important;
    width: 330px;
  `};
`;
const StyledButton = styled(Button) `
  margin: 40px 0 0;
  width: 100%;

  ${media.phone_medium `
    width: auto;
  `}

  ${media.tablet `
    width: 260px;
  `}

  ${media.desktop `
    display: none;
  `};
`;
export default Onboarding;
