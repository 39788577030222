const orange = '#F8A746';
const white = '#FFFFFF';
const blue = '#042655';
const purple = '#422F6C';
const green = '#85963d';
const colors = {
    orange,
    white,
    blue,
    purple,
    green,
};
export default colors;
