import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import bgSrc from 'assets/Home/home_bg.jpg';
import { media } from 'components/responsive';
import SweatcoinBanner from '../../Home/components/SweatcoinBanner';
const GetApp = ({}) => {
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'update-app-page' }] }),
        React.createElement(Container, null,
            React.createElement(StyledSweatcoinBanner, { title: "It Pays to Walk", subtitle: "Download the #1 Health & Fitness app in the world", feature: "get_app_page_download_button" }))));
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: url(${bgSrc}) bottom center no-repeat;
  background-size: cover;
`;
const Container = styled.div `
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet `
    width: 700px;
    margin: 0 auto;
  `};

  ${media.desktop `
    width: 1000px;
    margin: 0 auto;
  `};
`;
const StyledSweatcoinBanner = styled(SweatcoinBanner) `
  margin-top: 0;
`;
export default GetApp;
