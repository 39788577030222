import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Slider from 'react-slick';
import styled from 'styled-components';
import marks from 'assets/Health/marks.png';
import { media } from 'components/responsive';
import { breakpoints } from 'routes/Home/utils/mediaQueries';
const messages = defineMessages({
    title: 'User Testimonials',
    name1: 'GEETHA (58)',
    text1: 'Through informative sessions on diabetes prevention, a variety of exercise classes, and daily walking challenges with Sweatcoin, the Decathlon programme has helped me significantly improve my physical and mental health. I was shown how small changes to daily habits make a huge difference to my long-term wellbeing. This programme has shaped my health habits for life. I now have the knowledge and tools I need to make lasting, positive changes.',
    name2: 'KADI (35)',
    text2: "I'm a male teacher in my mid-30s, and since I started using Sweatcoin, I've noticed a significant improvement in my mental and physical wellbeing. When I downloaded the app, it was a wake-up call to see how few steps I was actually taking. Now, Sweatcoin motivates me to stay active and reach my daily target of 10,000 steps.",
    name3: 'DEIVIDAS (33)',
    text3: 'What I appreciate most about Sweatcoin is that it has helped me to improve my overall health and wellbeing. I feel better both physically and mentally, and I have more energy to tackle the challenges of daily life. I would definitely recommend Sweatcoin to anyone who wants to lead a more active and healthy lifestyle.',
});
const Testimonials = () => {
    const settings = {
        arrows: false,
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 3,
        dots: true,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: breakpoints.tablet,
                settings: {
                    slidesToShow: 1,
                    adaptiveHeight: false,
                },
            },
            {
                breakpoint: breakpoints.desktop,
                settings: {
                    slidesToShow: 3,
                    adaptiveHeight: true,
                },
            },
        ],
    };
    return (React.createElement("div", null,
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { ...messages.title })),
        React.createElement(TesimonialsSlider, { ...settings },
            React.createElement(Testimonial, null,
                React.createElement(Name, null,
                    React.createElement(FormattedMessage, { ...messages.name1 })),
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, { ...messages.text1 }))),
            React.createElement(Testimonial, null,
                React.createElement(Name, null,
                    React.createElement(FormattedMessage, { ...messages.name2 })),
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, { ...messages.text2 }))),
            React.createElement(Testimonial, null,
                React.createElement(Name, null,
                    React.createElement(FormattedMessage, { ...messages.name3 })),
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, { ...messages.text3 }))))));
};
const Title = styled.h2 `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #191841;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 40px;

  ${media.tablet `
    margin-bottom: 100px;
    padding: 0;
  `}
`;
const TesimonialsSlider = styled(Slider) `
  //display: flex;
  //justify-content: space-between;
  //gap: 10px;
  margin: 0 -16px 150px;

  ${media.desktop `
    margin: 0 0 150px
  `}
  .slick-dots {
    display: flex !important;
    justify-content: center;
    padding: 0 !important;
    margin: 30px 0 0 !important;

    li {
      list-style: none !important;
      margin: 0 4px;
      line-height: 0;

      button {
        width: 8px;
        height: 8px;
        background: #191841;
        opacity: 0.3;
        border-radius: 6px;
        border: none;
        font-size: 0;
        padding: 0;
        cursor: pointer;
        transition: all 0.3s linear;
      }

      &.slick-active {
        button {
          width: 40px;
          opacity: 1;
        }
      }
    }
  }
`;
const Testimonial = styled.div `
  background: linear-gradient(158.78deg, rgba(255, 255, 255, 0.3) 2.48%, rgba(163, 126, 247, 0.5) 101.41%);
  border-radius: 50px;
  padding: 50px 20px 50px 40px;
  width: calc(100% - 32px) !important;
  margin: 0 16px 20px;
  position: relative;

  ${media.desktop `
    width: calc(100% - 20px) !important;
    min-height: 620px!important;
    margin: 0;
    padding: 50px 60px;
  `}
  &::before {
    content: '';
    display: block;
    width: 63px;
    height: 40px;
    background: url(${marks}) no-repeat;
    background-size: contain;
    position: absolute;
    top: 8px;
    left: -6px;
  }

  &::after {
    content: '';
    display: block;
    width: 63px;
    height: 40px;
    background: url(${marks}) no-repeat;
    background-size: contain;
    position: absolute;
    bottom: -20px;
    right: 15px;
    transform: rotate(180deg);

    ${media.desktop `
      bottom: 46px;
      right: -11px;
    `}
  }
`;
const Name = styled.div `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  color: #191841;
  margin-bottom: 30px;
`;
const Text = styled.div `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
`;
export default Testimonials;
