import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import Layout from 'components/Layout';
import { media } from 'components/responsive';
import '../styles/static';
export default class VerificationAlgorithm extends React.Component {
    render() {
        return (React.createElement(Layout, { newHeader: true },
            React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'verification-algorithm' }] }),
            React.createElement(Container, null,
                React.createElement(Title, null,
                    "Sweatcoin Step Verification",
                    ' ',
                    React.createElement(NoWrap, null,
                        "Algorithm",
                        React.createElement(TradeMark, null, "TM"))),
                React.createElement(Text, null,
                    "Sweatcoin Step Verification Algorithm",
                    React.createElement(TradeMark, null, "TM"),
                    " validates steps to filter out the shakes and bumps registered by your phone \u2014 which are not real steps \u2014 and recognises cheating (yes, some people tried to put their phone on top of a washing machine, their dog or even a pet rabbit!). The Algorithm analyses your movement data and takes out the invalid steps. Once it\u2019s done, all remaining verified steps are converted into sweatcoins."),
                React.createElement(SubTitle, null, "User Data Privacy"),
                React.createElement(Text, null,
                    "The Step Verification Algorithm",
                    React.createElement(TradeMark, null, "TM"),
                    " takes the steps provided by your device and checks them against other data points like speed and consistency of movement. Among other processes, it aims to match the full range of input data and remove all invalid steps by looking at the patterns of movement. So it is paramount that the Algorithm has access to the location data."),
                React.createElement(Text, null, "Your location data is completely anonymised and cryptographically protected. Sweatcoin fully complies with GDPR (data protection regulation). Importantly, Sweatcoin does not make any user data available to any third party and only uses the data for the purposes of the Sweatcoin Step Verification Algorithm and targeting of offers available on Sweatcoin marketplace."),
                React.createElement(SubTitle, null, "Conversion Accuracy"),
                React.createElement(Text, null, "Currently, the average user converts about 65% of the total \u2018steps\u2019 registered by their phone into sweatcoins. Whilst we would love this percentage to be higher, the number can be limited by various factors such as the device hardware and your physical environment."),
                React.createElement(Text, null,
                    "Sweatcoin app is constantly being improved to optimise its performance on various devices. But there are actions you can take to increase the step conversion on your smartphone. Over time we have identified a number of steps which can ",
                    React.createElement(Link, { href: "https://help.sweatco.in/" }, "help"),
                    " you - these can be found on help alongside an active and helpful community."))));
    }
}
const textColor = '#032555';
const Link = styled.a `
  color: #85963d;
`;
const NoWrap = styled.span `
  white-space: nowrap;
`;
const TradeMark = styled.span `
  font-family: ${FONTS.SimplonNormBold.name};
  font-size: 0.3em;
  vertical-align: top;
  line-height: 1;
  transform: translateY(1em);
  display: inline-block;
`;
const Container = styled.div `
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-bottom: 4.4em;
  font-size: 22px;

  ${media.desktop `
  width: 900px;
  padding: 0;
  font-size: 34px;
  `};
`;
const Title = styled.h1 `
  font-family: ${FONTS.SimplonMono.name};
  font-size: 1.8em;
  color: ${textColor};
  letter-spacing: -2px;
  line-height: 1.5;
  margin-top: 3.5em;
`;
const SubTitle = styled.h3 `
  font-family: ${FONTS.SimplonNormBold.name};
  font-size: 1em;
  color: ${textColor};
  line-height: 44px;
  margin-top: 1.9em;
`;
const Text = styled.p `
  margin-top: 0.6em;
  font-family: ${FONTS.SimplonNorm.name};
  font-size: 1em;
  color: ${textColor};
  line-height: 44px;
`;
