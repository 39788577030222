import { ROUTER_BEFORE_NAVIGATE, ROUTER_ERROR_RAISE, ROUTER_ERROR_RESET, ROUTER_NAVIGATE } from 'constants/ActionTypes';
export function beforeNavigate(state, initial) {
    return {
        type: ROUTER_BEFORE_NAVIGATE,
        state: state,
        initial: initial,
    };
}
export function navigate(state) {
    return {
        type: ROUTER_NAVIGATE,
        state: state,
    };
}
export function raiseError(errorCode) {
    return {
        type: ROUTER_ERROR_RAISE,
        errorCode: errorCode,
    };
}
export function resetError() {
    return {
        type: ROUTER_ERROR_RESET,
    };
}
