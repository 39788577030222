import classNames from 'classnames';
import _ from 'lodash';
import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { storeButtonShow } from 'actions/misc';
import buttons from 'assets/Home/buttons.png';
import OutboundLink from 'components/OutboundLink';
import { media } from 'components/responsive';
import { Helpers } from './Background';
const mapStateToProps = (state) => ({
    isIOs: _.get(state, 'home.device.isIOs', false),
    isAndroid: _.get(state, 'home.device.isAndroid', false),
});
export const link = (platform) => {
    const data = {
        linkAppStore: 'https://itunes.apple.com/app/sweatcoin-app-that-pays-you/id971023427',
        linkGooglePlay: 'https://play.google.com/store/apps/details',
        campaign: 'store_button',
    };
    return platform === 'AppStore'
        ? `${data.linkAppStore}?${queryString.stringify({
            pt: '117705952',
            ct: data.campaign,
            mt: 8,
        })}`
        : `${data.linkGooglePlay}?${queryString.stringify({
            id: 'in.sweatco.app',
            referrer: queryString.stringify({
                utm_source: 'website',
                utm_campaign: data.campaign,
            }),
        })}`;
};
class AppLinks extends React.Component {
    state = {
        stickToBottom: false,
        type: 'AppStore',
    };
    // TODO Refactor, merge with same code from Header component
    componentDidMount() {
        this.props.storeButtonShow();
        window.addEventListener('scroll', this.onScroll);
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }
    onScroll = _.throttle(() => {
        const $container = Helpers.select(this.props.container.toString())[0];
        const { top, height } = $container.getBoundingClientRect();
        if (height + top - window.innerHeight < 0) {
            this.setState({ stickToBottom: true });
        }
        else if (this.state.stickToBottom) {
            this.setState({ stickToBottom: false });
        }
    }, 80, { trailing: true, leading: true });
    render() {
        let { className, isIOs, isAndroid, background } = this.props;
        return (React.createElement(Container, { className: classNames(className, { stickToBottom: this.state.stickToBottom }) },
            !isAndroid ? (React.createElement(OutboundLink, { category: "Store", action: "redirect", label: "AppStore", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('AppStore') },
                React.createElement(Link, { platform: "iOS", bg: background ? background : buttons, "data-id": "appstore" }))) : null,
            !isIOs ? (React.createElement(OutboundLink, { category: "Store", action: "redirect", label: "Google Play", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('Google Play') },
                React.createElement(Link, { platform: "Android", bg: background ? background : buttons, "data-id": "googleplay" }))) : null));
    }
}
const Link = styled.span `
  display: block;
  width: 156px;
  height: 48px;
  background: url(${(props) => props.bg}) no-repeat;
  background-size: cover;
  background-position-x: ${(props) => (props.platform === 'Android' ? '-178px' : '')};
`;
const Container = styled.div `
  position: fixed;
  width: 100%;
  padding-bottom: 30px;
  bottom: 0;
  display: flex;
  z-index: 98;
  margin-left: 0;
  justify-content: center;

  &.stickToBottom {
    position: absolute;
  }

  a + a {
    margin-left: 20px;
  }

  ${media.tablet `padding-bottom: 40px;`};

  ${media.desktop `
    width: auto;
    margin-left: 60px;
    justify-content: flex-start;
    display: none;
  `};
`;
export default connect(mapStateToProps, { storeButtonShow })(AppLinks);
