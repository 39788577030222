import React from 'react';
import styled from 'styled-components';
import infoIconSrc from 'assets/SweatPaws/info-icon.svg';
import { media } from 'components/responsive';
const Disclaimer = (props) => {
    const { points, isSharePanelOpened } = props;
    return (React.createElement(Container, { isSharePanelOpened: isSharePanelOpened },
        React.createElement(DisclaimerTitle, null, "Where\u2019s the info from"),
        React.createElement(DisclaimerText, null, "This is for entertainment purposes. Always consult a professional for advice on how to best raise your pets."),
        React.createElement(DisclaimerText, null,
            "According to the\u00A0",
            React.createElement("a", { href: "https://www.thekennelclub.org.uk/search/breeds-a-to-z/#B", target: "_blank", rel: "noopener noreferrer" }, "Kennel Club"),
            ", this breed of dog requires a recommended ",
            points.breed.text,
            " of walking daily.")));
};
const Container = styled.div `
  margin-bottom: ${(props) => (props.isSharePanelOpened ? '220' : '164')}px;
  padding: 20px;
  background: linear-gradient(97.63deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.03) 100%);
  backdrop-filter: blur(20px);
  border-radius: 20px;

  ${media.desktop `
    width: 500px;
    margin-right: auto;
    margin-left: auto;
  `}
`;
const DisclaimerTitle = styled.p `
  position: relative;
  margin-bottom: 8px;
  padding-left: 22px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    background-image: url('${infoIconSrc}');
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
`;
const DisclaimerText = styled.p `
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  &:first-of-type {
    margin-bottom: 10px;
  }

  a {
    border-bottom: 1px solid #ffffff;

    &:hover {
      border-bottom: none;
    }
  }
`;
export default Disclaimer;
