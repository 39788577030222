import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { media } from 'components/responsive';
import { getRandomNum } from '../utils/functions';
import AnswersList from './AnswersList';
import Chat from './Chat';
const Question = (props) => {
    const { questions, answers, title, handleAnswer, answerNumber, type, activeId, questionId, setActiveId, points, showError, isSmallBannerOpened, } = props;
    const [showMessage, setShowMessage] = useState(false);
    let isFunction, randomNum;
    const messages = questions[answerNumber - 1].messages;
    isFunction = typeof messages === 'function';
    randomNum = points['name'].randomNum
        ? points['name'].randomNum
        : isFunction
            ? getRandomNum(messages().length - 1)
            : getRandomNum(messages.length - 1);
    const message = isFunction ? messages(points['name'].label)[randomNum] : messages[randomNum];
    const titleText = typeof title === 'function' ? title(points['name'].label) : title;
    useEffect(() => {
        if (activeId !== null ||
            (questionId === 'name' && points['name'] !== '' && points['name'].label.length) ||
            (questionId === 'pasttime' && points['pasttime'] !== '' && points['pasttime'].label.length) ||
            (questionId === 'breed' && points['breed'] !== '' && points['breed'].label.length)) {
            setShowMessage(true);
        }
        else {
            setShowMessage(false);
        }
    });
    return (React.createElement(Container, { isMultipleImagesType: type === 'multiple-images', isSmallBannerOpened: isSmallBannerOpened, className: questionId === 'duration' ? 'duration' : '' },
        React.createElement(CSSTransition, { in: showMessage, timeout: 800, classNames: "chat", unmountOnExit: true },
            React.createElement(Chat, { isMultipleImagesType: type === 'multiple-images', message: message })),
        React.createElement(Header, null,
            React.createElement(Title, null, titleText)),
        React.createElement(AnswersList, { answers: answers, handleAnswer: handleAnswer, type: type, questionId: questionId, setActiveId: setActiveId, activeId: activeId, showError: showError, questions: questions, answerNumber: answerNumber, points: points, randomNum: randomNum })));
};
const Container = styled.div `
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.isMultipleImagesType ? 'auto' : 'calc(100vh - 20px - 68px)')};
  padding-top: 68px;

  @media (min-width: 600px) {
    width: 100%;
    max-width: ${(props) => (props.isMultipleImagesType ? '568px' : '')};
    margin: ${(props) => (props.isMultipleImagesType ? '0 auto' : '')};
    margin-bottom: ${(props) => (props.isMultipleImagesType ? '280px' : 0)};
  }

  ${media.desktop `
    width: 700px;
    max-width: none;
    height: ${(props) => (props.isMultipleImagesType ? 'auto' : '100vh')};
    margin-top: ${(props) => (props.isSmallBannerOpened ? '-101px' : null)};
    margin-right: auto;
    margin-bottom: ${(props) => (props.isMultipleImagesType ? '280px' : 0)};
    margin-left: auto;
    padding-top: 110px;
  `}

  // chat animation
  .chat-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .chat-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 800ms, transform 800ms;
  }
  .chat-exit {
    opacity: 1;
  }
  .chat-exit-active {
    opacity: 0;
    transform: scale(0.9);
  }

  &.duration {
    @media (min-width: 1074px) and (max-width: 1439px) {
      height: auto;
      padding-bottom: 100px;
    }
  }
`;
const Header = styled.div `
  ${media.desktop `
    padding-top: 60px;
  `};

  ${media.desktop_medium `
    padding-top: 70px;
  `};
`;
const Title = styled.h1 `
  font-weight: 800;
  font-size: 25px;
  line-height: 120%;

  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;

  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 30px;

  ${media.desktop `
    font-size: 32px;
    margin-bottom: 40px;
  `};
`;
export default Question;
