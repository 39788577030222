const disableOverscroll = (el) => {
    const disableOverscroll = () => {
        document.body.style.overscrollBehaviorX = 'none';
    };
    const enableOverscroll = () => {
        document.body.style.overscrollBehaviorX = null;
    };
    el.addEventListener('mouseover', disableOverscroll);
    el.addEventListener('mouseout', enableOverscroll);
    return () => {
        el.removeEventListener('mouseover', disableOverscroll);
        el.removeEventListener('mouseout', enableOverscroll);
    };
};
export default disableOverscroll;
