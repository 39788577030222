import omit from 'lodash/omit';
import zipObject from 'lodash/zipObject';
import { GET_FEATURED_OFFERS_FULFILLED, GET_OFFER_FULFILLED, GET_UPCOMING_OFFERS_FULFILLED, } from 'constants/ActionTypes';
const offersModelsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_OFFER_FULFILLED: {
            const offer = { ...action.payload.data };
            return { ...state, [offer.id.toString()]: offer };
        }
        case GET_FEATURED_OFFERS_FULFILLED:
        case GET_UPCOMING_OFFERS_FULFILLED: {
            const modelValues = action.payload.data.map((model) => model.disallow_search_engines ? omit(model, 'description', 'instructions') : model);
            const modelKeys = modelValues.map((model) => model.id.toString());
            return { ...state, ...zipObject(modelKeys, modelValues) };
        }
        default:
            return state;
    }
};
export default offersModelsReducer;
