import React from 'react';
import Helmet from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';
import FONTS from 'assets/fonts';
import Footer from '../../../routes/Home/components/Footer';
import Header from '../../../routes/Home/components/Header';
import Facts from '../components/Facts';
import Join from '../components/Join';
import Principles from '../components/Principles';
import Team from '../components/Team';
import Video from '../components/Video';
import { FONT_SIZES } from '../components/common';
class TeamPage extends React.Component {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Helmet, { meta: [
                    { name: 'event-id', content: 'team' },
                    {
                        name: 'apple-itunes-app',
                        content: 'app-id=971023427',
                    },
                ] },
                React.createElement("link", { rel: "stylesheet", type: "text/css", charset: "UTF-8", href: "https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" })),
            React.createElement(GlobalStyle, null),
            React.createElement(Header, { embed: true }),
            React.createElement(StyledContainer, null,
                React.createElement(Join, null),
                React.createElement(Video, null),
                React.createElement(Team, null),
                React.createElement(Principles, null),
                React.createElement(Facts, null)),
            React.createElement(Footer, null)));
    }
}
const GlobalStyle = createGlobalStyle `
  html,
  body {
    font-family: ${FONTS.SimplonNorm.name}, system-ui !important;
    ${FONT_SIZES};
  }
`;
const StyledContainer = styled.div ``;
export default TeamPage;
