import React, { useEffect } from 'react';
const Canvas = ({ draw, width, height }) => {
    const canvas = React.useRef();
    useEffect(() => {
        const context = canvas.current.getContext('2d');
        draw(context);
    });
    return (React.createElement("canvas", { ref: canvas, width: width, height: height, id: "canvas" }));
};
export default Canvas;
