/*

  All iOS, us/uk — appstore button
  iOS, from other countries — Available in the US and UK only. Enter your email to try beta on iPhone 5s and above.
  Android, all countries — Leave your e-mail to get notified when Android app launches.
  Other mobile devices uk/us — appstore button
  Other mobile devices, from other countries - Available in the US and UK only. Enter your email to try beta on iPhone 5s and above.
  Desktop, us/uk — appstore button + sms
  Desktop, from other countries - Available in the US and UK only. Enter your email to try beta on iPhone 5s and above.

*/
import classnames from 'classnames';
import filter from 'lodash/filter';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { Component } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import countries from 'constants/SupportedCountries.json';
import InviteForm from './InviteForm';
import StoreButton from './StoreButton';
import '../styles/availability';
const messages = defineMessages({
    reward: 'Join now and{br}receive your first',
    gte_iphone_model_warning: 'iPhone 7 or newer',
    invite_form_title: 'Available in selected markets only. Enter your email to be notified when I come to your country',
    invite_form_success: 'Thanks! We’ll be in touch when we land near you!',
});
const countrySelector = (state) => state.config.country;
const deviceSelector = (state) => state.home.device;
const mapStateToProps = createStructuredSelector({
    country: countrySelector,
    device: deviceSelector,
});
class Availability extends Component {
    static defaultProps = {
        rewardInvitee: false,
        storeButton: true,
        storeButtonLink: null,
        storeButtonClassName: null,
    };
    render() {
        const availableCountries = filter(countries, (c) => !c.unsupported).map((c) => c.country);
        return (React.createElement("div", { className: classnames('availability', this.props.className) }, ~availableCountries.indexOf(this.props.country)
            ? this._renderSupportedCounties()
            : this._renderUnsupportedCounties()));
    }
    _renderSupportedCounties() {
        const isAndroid = this.props.device.isAndroid;
        let storeButtonMobileProps = pickBy({
            campaign: this.props.campaign,
            className: this.props.storeButtonClassName,
            link: this.props.storeButtonLink,
            from: this.props.from,
        }, identity);
        let storeButtonDesktopProps = pickBy({
            campaign: this.props.campaign,
            className: this.props.storeButtonClassName,
            from: this.props.from,
        }, identity);
        return (React.createElement("div", { className: "availability__widget" }, this.props.storeButton ? (React.createElement("div", { className: "availability__widget__link" },
            this.props.rewardInvitee ? (React.createElement("div", { className: "availability__widget__link__reward only-mobile" },
                React.createElement(FormattedMessage, { ...messages.reward }),
                React.createElement("strong", null,
                    React.createElement("i", { className: "icon-swc" }),
                    "5"))) : null,
            React.createElement("div", { className: classnames('availability__widget__link__download', { 'only-desktop': isAndroid }) },
                React.createElement(StoreButton, { ...storeButtonDesktopProps, type: "AppStore", className: "only-desktop" }),
                React.createElement(StoreButton, { ...storeButtonMobileProps, type: "AppStore", className: "only-mobile" }),
                React.createElement("br", null),
                React.createElement("em", null,
                    React.createElement(FormattedMessage, { ...messages.gte_iphone_model_warning }))),
            React.createElement("div", { className: classnames('availability__widget__link__download', { 'only-desktop': !isAndroid }) },
                React.createElement(StoreButton, { ...storeButtonDesktopProps, type: "GooglePlay", className: "only-desktop" }),
                React.createElement(StoreButton, { ...storeButtonMobileProps, type: "GooglePlay", className: "only-mobile" }),
                React.createElement("br", null)))) : null));
    }
    _renderUnsupportedCounties() {
        return (React.createElement("div", { className: "availability__widget" },
            React.createElement("div", { className: "availability__widget__form" },
                React.createElement(FormattedMessage, { ...messages.invite_form_title }, (title) => (React.createElement(FormattedMessage, { ...messages.invite_form_success }, (success) => React.createElement(InviteForm, { type: this.props.country, title: title, success: success })))))));
    }
}
export default connect(mapStateToProps)(Availability);
