import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, FormattedNumber, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import * as ApiActions from 'actions/api';
import Layout from 'components/Layout';
import ShareKit from 'components/ShareKit';
import SimpleText from 'components/SimpleText';
import * as OfferSelectors from 'selectors/offer';
import Gallery from '../components/Gallery';
import Sponsor from '../components/Sponsor';
import '../styles/offer.scss';
const mapStateToProps = OfferSelectors.offerStructuredSelector;
const messages = defineMessages({
    price: '{price, number, amount_nomin} SWC',
    left: '{quantity} left',
    donation: 'CHARITY',
    redemption: 'Redemption process',
    details: 'Offer details',
});
class Offer extends Component {
    static fetchData({ match }, store) {
        return store.dispatch(ApiActions.prefetchOffer(match.params.offer_id));
    }
    render() {
        let meta = [
            { name: 'description', content: this.props.meta.description },
            { property: 'og:title', content: this.props.meta.title },
            { property: 'og:description', content: this.props.meta.description },
            { property: 'og:image', content: this.props.meta.image },
            { name: 'twitter:title', content: this.props.meta.title },
            { name: 'twitter:description', content: this.props.meta.description },
            { name: 'twitter:image', content: this.props.meta.image },
            { name: 'event-id', content: 'offer' },
            { name: 'event-params', content: { offer_id: this.props.model.id } },
            {
                name: 'apple-itunes-app',
                content: 'app-id=971023427',
            },
        ];
        if (this.props.model.disallow_search_engines) {
            meta.push({ name: 'robots', content: 'noindex' });
        }
        return (React.createElement(Layout, { headerOptions: { lightMobile: true } },
            React.createElement(Helmet, { title: this.props.meta.title, link: [{ rel: 'image_src', href: this.props.meta.image }], meta: meta }),
            React.createElement("section", { className: "offer" },
                React.createElement(Gallery, { models: this.props.model.images }),
                React.createElement(Layout.Container, null,
                    React.createElement("div", { className: "offer__header" },
                        React.createElement("div", { className: "offer__share only-desktop" },
                            React.createElement(ShareKit, null)),
                        React.createElement("div", { className: "offer__info" },
                            this._renderTitle(),
                            this._renderPrice()),
                        React.createElement("div", { className: "offer__share only-mobile" },
                            React.createElement(ShareKit, null))),
                    React.createElement("div", { className: "offer__description" },
                        React.createElement(SimpleText, { content: this.props.model.description })),
                    this._renderBullets(),
                    this._renderBrand(),
                    this._renderCharity(),
                    this._renderSponsors()))));
    }
    _renderTitle() {
        return React.createElement("h2", null, this.props.model.title);
    }
    _renderPrice() {
        if (this.props.model.type === 'donation') {
            return (React.createElement("div", { className: "offer__info__price" },
                React.createElement(FormattedMessage, { ...messages.donation })));
        }
        else {
            return (React.createElement("div", { className: "offer__info__price" },
                React.createElement("i", { className: "icon-swc-thin" }),
                React.createElement(FormattedNumber, { value: this.props.model.price, maximumFractionDigits: 1 })));
        }
    }
    _renderBullets() {
        if (this.props.model.bullets && this.props.model.bullets.length > 0) {
            return (React.createElement("div", { className: "offer__redemption" },
                React.createElement("h3", null, "Bullets"),
                React.createElement("ul", null, this.props.model.bullets.map(this._renderBullet.bind(this)))));
        }
        else {
            return null;
        }
    }
    _renderInstruction(model, index) {
        return (React.createElement("li", { key: `instruction-${index}` },
            React.createElement(SimpleText, { content: model })));
    }
    _renderBullet(model, index) {
        return (React.createElement("li", { key: `instruction-${index}` },
            React.createElement(SimpleText, { content: model.title + ': ' + model.body })));
    }
    _renderBrand() {
        if (this.props.model.brand) {
            return (React.createElement("div", { className: "offer__brand" },
                React.createElement("h3", null, "Brand"),
                React.createElement(Sponsor, { content: this.props.model.brand })));
        }
        else {
            return null;
        }
    }
    _renderCharity() {
        if (this.props.model.charity) {
            return (React.createElement("div", { className: "offer__brand" },
                React.createElement("h3", null, "Charity"),
                React.createElement(Sponsor, { content: this.props.model.charity })));
        }
        else {
            return null;
        }
    }
    _renderSponsors() {
        if (this.props.model.sponsors && this.props.model.sponsors.length > 0) {
            return (React.createElement("div", { className: "offer__sponsors" },
                React.createElement("h3", null, "Sponsors"),
                React.createElement("div", null, this.props.model.sponsors.map(this._renderSponsor.bind(this)))));
        }
        else {
            return null;
        }
    }
    _renderSponsor(model, index) {
        return React.createElement(Sponsor, { key: `sponsor-${index}`, content: model });
    }
}
export default connect(mapStateToProps)(Offer);
