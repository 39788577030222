import React, { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    influencer: 'Become an Influencer',
    forgood: 'Sweatcoin for good',
    health: 'Health',
    sweat: 'About SWEAT',
});
const BurgerNavigation = ({}) => {
    const [isMenuActive, setMenuActive] = useState(false);
    const handleClick = () => {
        setMenuActive(!isMenuActive);
    };
    return (React.createElement(MenuToggle, { "aria-label": "Burger menu" },
        React.createElement(StyledLabel, { htmlFor: "burgerNavigation" }, "Menu"),
        React.createElement("input", { type: "checkbox", checked: isMenuActive, onChange: handleClick, id: "burgerNavigation" }),
        React.createElement("span", null),
        React.createElement("span", null),
        React.createElement("span", null),
        React.createElement(Menu, null,
            React.createElement(FormattedMessage, { ...messages.influencer }, (msg) => (React.createElement(Link, { href: "https://promote.sweatco.in/", rel: "noreferrer noopener", target: "_blank" }, msg))),
            React.createElement(FormattedMessage, { ...messages.health }, (msg) => (React.createElement(StyledNavLink, { to: "/health", exact: true, target: "_blank" }, msg))))));
};
const Menu = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(188deg, #ff7b47 0%, #643eef 100%);
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  @media ${desktop()} {
    display: none;
  }
`;
const StyledLabel = styled.label `
  font-size: 0;
  opacity: 0;
  position: absolute;
  width: 40px;
  height: 40px;
  top: -9px;
  left: -6px;
`;
const MenuToggle = styled.div `
  position: relative;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;

  input {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
    margin: 0;
    top: -9px;
    left: -6px;
  }

  span {
    display: block;
    width: 28px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    background: #fff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      opacity 0.55s ease;
  }

  span:first-of-type {
    margin-left: 8px;
    width: 20px;
    transform-origin: 100% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 100% 0%;
    margin-left: 11px;
    width: 17px;
  }

  input:checked ~ span {
    width: 28px;
    opacity: 1;
    transform: rotate(-45deg) translate(-3px, -4px);
    background: #fff;
    margin-left: 0;
  }

  input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  input:checked ~ span:nth-last-child(2) {
    transform: rotate(45deg) translate(1px, 4px);
  }

  input:checked ~ ${Menu} {
    transform: none;
  }

  @media ${desktop()} {
    display: none;
  }
`;
const LinkStyle = css `
  text-decoration: none;
  color: #fff !important;
  transition: color 0.3s ease;
  padding: 20px 0;
  font-size: 26px;
`;
const StyledNavLink = styled(NavLink) `
  ${LinkStyle}
`;
const Link = styled.a `
  ${LinkStyle}
`;
export default BurgerNavigation;
