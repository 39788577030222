import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as ApiActions from 'actions/api';
import Layout from 'components/Layout';
import Press, { quotes } from 'constants/Press';
import progress from 'utils/progress';
import 'styles/buttons.scss';
import '../styles/clearing-request.scss';
const messages = defineMessages({
    title: 'Hi {firstname}, Sweatcoin account linked to mobile number {masked_number} and email {masked_email} will be permamnently deleted within the next 48 hours.',
    description: '{hasBalance, select, true {Your remaining balance of {balance, number, amount_grouping} sweatcoins will then be transferred to Sweatcoin Treasury. } other {}}Please press the button below to cancel the deletion and use your remaining balance as you see fit.',
    cancel: 'Cancel my deletion request',
    cancel_success: 'Phew!{br}Your account and your sweatcoins are now safe.{br}Happy Sweatcoining for many years to come!!!',
});
const clearingRequestSelector = (state) => state.clearingRequest;
const mapStateToProps = createStructuredSelector({
    clearingRequest: clearingRequestSelector,
});
class ClearingRequest extends Component {
    static async fetchData(state, store, { location }) {
        const confirmation_token = get(location, 'query.confirmation_token');
        await store.dispatch(ApiActions.showClearingRequest(confirmation_token));
        const clearingRequest = clearingRequestSelector(store.getState());
        if (clearingRequest && clearingRequest.clearing_at === 0) {
            await store.dispatch(ApiActions.confirmClearingRequest(confirmation_token));
        }
    }
    static contextTypes = {
        router: PropTypes.object,
    };
    state = {};
    render() {
        return (React.createElement(Layout, { header: false, className: "clearing-request" },
            React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'clearing-request' }] }),
            React.createElement(Layout.Container, null,
                React.createElement("section", { className: "clearing-request__content" }, this.state.success ? this._renderSuccess() : this._renderRequest()),
                React.createElement("div", { className: "clearing-request__press" }, Press.map(this._renderPressEntry)))));
    }
    _renderSuccess = () => (React.createElement("p", null,
        React.createElement(FormattedMessage, { ...messages.cancel_success })));
    _renderRequest = () => {
        const { balance, firstname, masked_email, masked_number } = this.props.clearingRequest;
        return (React.createElement("div", null,
            React.createElement("p", null,
                React.createElement(FormattedMessage, { ...messages.title, values: { firstname, masked_number, masked_email } })),
            React.createElement("p", null,
                React.createElement(FormattedMessage, { ...messages.description, values: { balance, hasBalance: balance > 0 } })),
            React.createElement("button", { className: "button button--opaque", onClick: this._handleCancelClearingRequest },
                React.createElement(FormattedMessage, { ...messages.cancel }))));
    };
    _renderPressEntry = (model, index) => (React.createElement(FormattedMessage, { ...quotes[model.quoteKey] }, (quote) => (React.createElement("a", { href: model.link, target: "_blank", rel: "noreferrer noopener", className: "clearing-request__press__entry", key: `press-entry-${index}`, title: quote }, model.sourceBlack))));
    _handleCancelClearingRequest = async () => {
        progress.increment();
        const confirmation_token = get(this.props, 'location.query.confirmation_token');
        const result = await this.props.cancelClearingRequest(confirmation_token);
        progress.decrement();
        if (result && !result.error) {
            this.setState({ success: true });
        }
    };
}
export default connect(mapStateToProps, ApiActions)(ClearingRequest);
