import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
const messages = defineMessages({
    title: 'Already using Sweatcoin app?',
});
const Join = ({ children }) => {
    return (React.createElement(Container, null,
        React.createElement(FormattedMessage, { ...messages.title }, (title) => React.createElement(Title, null, title)),
        children));
};
const Container = styled.div `
  margin: 0 16px;
  padding: 20px 0 20px;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
  background: linear-gradient(180deg, rgba(69, 89, 148, 0.3) 0%, rgba(50, 63, 126, 0.8) 100%);
  overflow: hidden;
  border-radius: 16px;
  position: relative;
`;
const Title = styled.h3 `
  color: #ffffff;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.2px;
  text-align: center;
  margin-bottom: 20px;
`;
export default Join;
