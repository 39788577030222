import LucidaGrandeWoff from './LucidaGrande.woff'
import LucidaGrandeWoff2 from './LucidaGrande.woff2'
import SimplonMonoWoff from './SimplonMono.woff'
import SimplonMonoWoff2 from './SimplonMono.woff2'
import SimplonNormBoldWoff from './SimplonNorm-Bold.woff'
import SimplonNormBoldWoff2 from './SimplonNorm-Bold.woff2'
import SimplonNormWoff from './SimplonNorm.woff'
import SimplonNormWoff2 from './SimplonNorm.woff2'

const FONTS = {
  LucidaGrande: {
    name: 'LucidaGrande',
    woff: LucidaGrandeWoff,
    woff2: LucidaGrandeWoff2,
  },
  SimplonMono: {
    name: 'SimplonMono',
    woff: SimplonMonoWoff,
    woff2: SimplonMonoWoff2,
    getFontFamily: () => `${FONTS.SimplonMono.name}, ${FONTS.fallback}`,
  },
  SimplonNorm: {
    name: 'SimplonNorm',
    woff: SimplonNormWoff,
    woff2: SimplonNormWoff2,
    getFontFamily: () => `${FONTS.SimplonNorm.name}, ${FONTS.fallback}`,
  },
  SimplonNormBold: {
    name: 'SimplonNorm',
    woff: SimplonNormBoldWoff,
    woff2: SimplonNormBoldWoff2,
  },
  fallback:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
}

export default FONTS

export const setFontFace = (font, weight = 'normal') => `
@font-face {  
  font-family: ${font.name};  
  font-weight: ${weight};
  font-display: swap; 
  /* Download only latin glyphs */ 
  unicode-range: U+000-5FF; 
  src: url(${font.woff2}) format("woff2"), url(${font.woff}) format("woff");
}
`
