import { css } from 'styled-components';
export const sizes = {
    desktop_extra_large: 2400,
    desktop_large: 1919,
    desktop_medium: 1440,
    desktop: 1074,
    tablet: 768,
    phone_medium: 390,
};
// Iterate through the sizes and create a media template
const mediaMinWidth = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css `
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `;
    return acc;
}, {});
const mediaMobileLandscape = {
    landscape: (...args) => css `
    @media (max-width: 815px) and (orientation: landscape) {
      ${css(...args)};
    }
  `,
};
export const media = { ...mediaMinWidth, ...mediaMobileLandscape };
export const responsivePaddingLeftMobile = css `
  padding-left: 20px;
`;
export const responsivePaddingRightMobile = css `
  padding-right: 20px;
`;
export const responsiveContainerMobile = css `
  ${responsivePaddingLeftMobile};
  ${responsivePaddingRightMobile};
`;
export const responsivePaddingRight = css `
  ${media.desktop `
    padding-right: 60px;
  `}

  ${media.desktop_medium `
    padding-right: 75px;
  `}
  
  ${media.desktop_large `
    padding-right: 150px;
 `}
`;
export const responsivePaddingLeft = css `
  ${media.desktop `
    padding-left: 60px;
  `}

  ${media.desktop_medium `
    padding-left: 75px;
  `}
  
  ${media.desktop_large `
    padding-left: 150px;
 `}
`;
// only for desktop
export const responsiveNegativeMarginLeft = css `
  ${media.desktop `
    margin-left: -60px;
  `}

  ${media.desktop_medium `
    margin-left: -75px;
  `}
  
  ${media.desktop_large `
    margin-left: -150px;
 `}
`;
const responsiveContainer = css `
  width: 100%;
  ${responsiveContainerMobile};
  ${responsivePaddingLeft};
  ${responsivePaddingRight};
`;
export default responsiveContainer;
