import React from 'react';
import styled from 'styled-components';
import sweatcoinLogoSrc from 'assets/SweatPaws/sweatcoin-logo.svg';
import sweatpawsLogoSrc from 'assets/SweatPaws/sweatpaws-logo.svg';
import { media } from 'components/responsive';
import AppLink from '../components/AppLink';
const Banner = () => {
    return (React.createElement(Container, null,
        React.createElement(ContainerInner, null,
            React.createElement(SweatpawsLogo, { src: sweatpawsLogoSrc, width: "173", height: "24" }),
            React.createElement(SweatcoinLogo, { src: sweatcoinLogoSrc, width: "127", height: "24" }),
            React.createElement(AppLink, null))));
};
const Container = styled.div `
  position: fixed;
  top: 10px;
  left: 50%;
  z-index: 2;
  display: block;
  width: calc(100% - 20px);
  height: 48px;
  margin: 0 auto;
  padding: 12px 20px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(20px);
  border-radius: 4px;
  transform: translate(-50%);

  ${media.desktop `
    max-width: 1700px;
  `};

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    background: linear-gradient(112.4deg, #b3fe53 29.63%, #4efff4 76.7%) border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 4px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: calc(100% - 40px);
    height: 100%;
    background: linear-gradient(104.74deg, rgba(169, 255, 61, 0.3) 25.73%, rgba(0, 255, 255, 0.3) 66.52%);
    filter: blur(35px);
    border-radius: 50px;
  }
`;
const ContainerInner = styled.div `
  display: flex;
  justify-content: center;

  a::before,
  a::after,
  span {
    display: none;
  }

  a {
    backdrop-filter: none;
    border-radius: 0;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const SweatpawsLogo = styled.img `
  position: relative;
  width: 173px;
  margin-right: 12px;

  @media (max-width: 389px) {
    width: 125px;
  }
`;
const SweatcoinLogo = styled.img `
  position: relative;
  width: 127px;

  @media (max-width: 389px) {
    width: 100px;
  }
`;
export default Banner;
