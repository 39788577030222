import React from 'react';
import styled from 'styled-components';
const Button = React.forwardRef(({ children, className, title, link, onClick }, ref) => {
    return (React.createElement(Link, { className: className, href: link, onClick: onClick, ref: ref },
        children,
        title));
});
const Link = styled.a `
  box-sizing: border-box;
  width: 260px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 20px 0px 20px;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 16px;
  font-family: 'Inter', sans-serif;
  color: rgb(3, 37, 85);
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 1.5;
  font-weight: 700;
  font-style: normal;
  margin: 0 auto;
`;
export default Button;
