import React from 'react';
import styled from 'styled-components';
const Stack = ({ children, className }) => React.createElement(Container, { className: className }, children);
const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export default Stack;
