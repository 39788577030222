import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import Slider from 'react-slick';
import styled from 'styled-components';
import { media } from 'components/responsive';
import disableOverscroll from 'utils/dIsableOverscroll';
import fact1Src from '../../../assets/team/fact_1.png';
import fact2Src from '../../../assets/team/fact_2.png';
import fact3Src from '../../../assets/team/fact_3.png';
import fact4Src from '../../../assets/team/fact_4.png';
import fact5Src from '../../../assets/team/fact_5.png';
import Round from './Round';
import { SectionTitle, responsiveContainer, sectionTopOffset } from './common';
const messages = defineMessages({
    fact1: 'Collectively, we own 17 cats & 20 dogs',
    fact2: '5 of us have run a marathon',
    fact3: 'Average age is 29 yo',
    fact4: 'Each team member walks 7976 steps per day on average',
    fact5: 'Our team speaks in 19 different languages',
});
const transitionDuration = 750;
const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: transitionDuration,
    autoplaySpeed: 5800,
    pauseOnHover: false,
    slidesToShow: 1,
    variableWidth: true,
    swipeToSlide: true,
};
const Facts = (props) => {
    const [facts, setFacts] = useState();
    // Change slides on scroll
    const sliderEl = useRef(null);
    const factWrapperEl = useRef(null);
    const onScroll = _.throttle((e) => {
        const { deltaY, deltaX } = e;
        e.preventDefault();
        if (deltaX !== 0 && Math.abs(deltaX) > Math.abs(deltaY)) {
            deltaX > 0 ? sliderEl.current.slickNext() : sliderEl.current.slickPrev();
        }
    }, 1500, // fix momentum scrolling events
    {
        trailing: false,
    });
    // set event listener
    useEffect(() => {
        setFacts(FACTS);
        if (factWrapperEl) {
            const elCurrent = factWrapperEl.current;
            elCurrent.addEventListener('wheel', onScroll);
            const overscroll = disableOverscroll(factWrapperEl.current);
            // remove event listener
            return () => {
                elCurrent.removeEventListener('wheel', onScroll);
                overscroll();
            };
        }
    }, []);
    const renderRound = (props) => (React.createElement(RoundWrapper, { key: props.key },
        React.createElement(Round, { color: "#FFFFFF", ...props })));
    return (React.createElement(React.Fragment, null,
        React.createElement(FactsTitle, null, "Sweatcoin facts"),
        React.createElement(FactWrapper, { ref: factWrapperEl },
            React.createElement(StyledSlider, { ref: sliderEl, ...sliderSettings }, facts ? (facts.map(({ title, image, key }) => renderRound({
                title: props.intl.formatMessage(title),
                style: { backgroundImage: `url(${image})` },
                key: key,
            }))) : (React.createElement(Round, { style: { opacity: 0, pointerEvent: 'none' } }))))));
};
const FACTS = [
    {
        title: messages.fact5,
        key: 'fact5',
        image: fact5Src,
    },
    {
        title: messages.fact4,
        key: 'fact4',
        image: fact4Src,
    },
    {
        title: messages.fact2,
        key: 'fact2',
        image: fact2Src,
    },
    {
        title: messages.fact1,
        key: 'fact1',
        image: fact1Src,
    },
    {
        title: messages.fact3,
        key: 'fact3',
        image: fact3Src,
    },
];
const RoundWrapper = styled.div `
  margin-left: 0.9rem;
  outline: none;
`;
const FactsTitle = styled(SectionTitle) `
  ${responsiveContainer};
  ${sectionTopOffset};
`;
const StyledSlider = styled(Slider) ``;
const FactWrapper = styled.div `
  width: 100%;
  margin: 2rem 0 3rem;

  ${StyledSlider} {
    div {
      outline: none;
      user-select: none;
    }
  }

  ${media.desktop `margin-bottom: 5.5rem;`};
`;
export default injectIntl(Facts);
