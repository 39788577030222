import url from 'url';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import logo_src from 'assets/Home/header__logo.svg';
import googleplaySrc from 'assets/Home/home_googleplay.png';
import appstoreSrc from 'assets/Leaderboard/appstore_icon.svg';
import bg from 'assets/Leaderboard/bg.jpg';
import bgMobile from 'assets/Leaderboard/bg_mobile.jpg';
import sharingSrc from 'assets/Leaderboard/invite_share.jpg';
import phone from 'assets/Leaderboard/phone.png';
import phone_mobile from 'assets/Leaderboard/phone_mobile.png';
import OutboundLink from 'components/OutboundLink';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { getFirstLetter, getFirstName } from 'utils/user';
import { makeProfileSelector } from 'selectors/invites';
import { useFirebaseEssentials } from '../utils/firebase';
import { link } from '../../Home/components/AppLinks';
const messages = defineMessages({
    title: 'Find out your position on ',
    subtitle: 'Join {name}’s leaderboard, turn your steps into sweatcoins and spend them on awesome products or donate them to charity.',
    acceptButtonTitle: 'Accept Invite',
    downloadButtonTitle: 'Download Sweatcoin App',
    appstoreName: 'Apple App Store',
    appstoreRating: '(200k ratings)',
    googleplayName: 'Google play',
    googleplayRating: '(1.8M ratings)',
});
const InviteLeaderboardPage = (props) => {
    const { match } = props;
    const { params } = match;
    // Profile & meta
    const profileSelector = useMemo(makeProfileSelector, []);
    const profile = useSelector((state) => profileSelector(state, params));
    const avatar = get(profile, 'avatar.preview');
    const name = getFirstName(profile);
    const firstLetter = getFirstLetter(profile);
    const username = params.username;
    const { firebaseData: { firebaseQuery, getRedirectLink }, tracking: { channel, campaign, feature, platform }, buttonsRefs: [buttonRef, bottomButtonRef], config: { origin }, } = useFirebaseEssentials({ owner: profile, deeplink_path: `/lb/${username}` });
    const absoluteSharingUrl = url.resolve(origin, sharingSrc);
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'leaderboard-invite-page' },
                {
                    name: 'event-params',
                    content: pickBy({
                        page_type: 'invite_page',
                        username: username,
                        inviter_id: profile.id,
                        channel,
                        platform,
                        feature,
                        campaign,
                    }, identity),
                },
                { property: 'og:image', content: absoluteSharingUrl },
            ], link: [
                { rel: 'image_src', href: absoluteSharingUrl },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap',
                },
            ] }),
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(Header, null,
                React.createElement(Avatar, { src: avatar }, !avatar && React.createElement("span", null, firstLetter)),
                React.createElement(FormattedMessage, { ...messages.title }, (title) => (React.createElement(Title, null,
                    React.createElement("span", null, title),
                    React.createElement(Name, null,
                        name,
                        "'s"),
                    "leaderboard")))),
            React.createElement(Subtitle, null,
                React.createElement(FormattedMessage, { ...messages.subtitle, values: { name } })),
            firebaseQuery ? (React.createElement(React.Fragment, null,
                React.createElement(Button, { onClick: handleClick, href: firebaseQuery.link, ref: buttonRef },
                    React.createElement(FormattedMessage, { ...messages.acceptButtonTitle })),
                React.createElement(Text, null,
                    "*By\u00A0accepting an\u00A0invite to\u00A0a\u00A0leaderboard your step count and username will be\u00A0publicly visible to\u00A0other users on\u00A0sweatcoin. View our terms and conditions",
                    ' ',
                    React.createElement("a", { href: "https://sweatco.in/tnc", target: "_blank", rel: "noreferrer" }, "here"),
                    ". You can also download sweatcoin without using a\u00A0leaderboard by\u00A0",
                    React.createElement("a", { href: "https://sweatco.in/download", target: "_blank", rel: "noreferrer" }, "clicking here"),
                    "."))) : null,
            React.createElement(PhoneImageMobile, { src: phone_mobile }),
            React.createElement(Stores, null,
                React.createElement(StyledOutboundLink, { category: "Store", action: "redirect", label: "AppStore", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('AppStore') },
                    React.createElement(AppstoreIcon, null),
                    React.createElement(StoreDescription, null,
                        React.createElement(StoreName, null,
                            React.createElement(FormattedMessage, { ...messages.appstoreName })),
                        React.createElement(StoreRating, null,
                            React.createElement("strong", null, "4.6"),
                            "\u00A0",
                            React.createElement(FormattedMessage, { ...messages.appstoreRating })))),
                React.createElement(StyledOutboundLink, { category: "Store", action: "redirect", label: "Google Play", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('Google Play') },
                    React.createElement(GoogleplayIcon, null),
                    React.createElement(StoreDescription, null,
                        React.createElement(StoreName, null,
                            React.createElement(FormattedMessage, { ...messages.googleplayName })),
                        React.createElement(StoreRating, null,
                            React.createElement("strong", null, "4.4"),
                            "\u00A0",
                            React.createElement(FormattedMessage, { ...messages.googleplayRating }))))),
            React.createElement(OnlyMobile, null, firebaseQuery ? (React.createElement(Button, { onClick: handleClick, href: firebaseQuery.link, ref: bottomButtonRef },
                React.createElement(FormattedMessage, { ...messages.downloadButtonTitle }))) : null)),
        React.createElement(PhoneImage, { src: phone })));
    function handleClick(event) {
        event.preventDefault();
        analytics.track(`${feature}_link_click`, 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink();
    }
};
InviteLeaderboardPage.fetchData = async ({ match: { params } }, { dispatch }) => {
    return await dispatch(ApiActions.searchUserByUsername(params.username));
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background-image: url(${bgMobile});
  background-size: cover;

  ${media.tablet `
    background-image: url(${bg});
  `};
`;
const Container = styled.div `
  position: relative;
  padding: 20px 0;

  ${media.tablet `
    width: 700px;
    margin: 0 auto;
  `};

  ${media.desktop `
    padding: 32px 0;
    margin-left: 40px;
  `};

  ${media.desktop_medium `
    width: 800px;
  `};

  @media (min-width: 1280px) {
    margin-left: 108px;
  }

  ${media.desktop_extra_large `
    width: 1000px;
    margin: 0 0 0 187px;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
  text-align: center;

  ${media.tablet `
    text-align: left;
  `};
`;
const Logo = styled.img `
  position: relative;
  width: 170px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;

  ${media.tablet `
    width: 197px;
  `};
`;
const PhoneImageMobile = styled.img `
  display: block;
  width: 100%;
  max-width: 400px;
  margin-left: auto;

  ${media.tablet `
    display: none;
  `}
`;
const PhoneImage = styled.img `
  display: none;

  ${media.desktop `
    display: block;
    position: absolute;
    width: 47.5vw;
    bottom: 0;
    right: 0;
    z-index: -1;
  `};

  @media (min-width: 1280px) {
    top: 0;
    bottom: auto;
  }
`;
const Header = styled.div `
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 18px;
  padding: 0 16px;

  ${media.tablet `
    padding: 0;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 24px;
  `}

  ${media.desktop_medium `
    margin-top: 160px;
  `}

  ${media.desktop_extra_large `
    margin-top: 400px;
  `}
`;
const Avatar = styled.div `
  width: 70px;
  height: 70px;
  box-shadow: 12px 12px 40px 0px rgba(61, 0, 16, 0.3);
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  flex: none;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet `
    width: 120px;
    height: 120px;
    margin-right: 24px;
  `}

  span {
    font-weight: 100;
    font-size: 70px;
    color: #fff;
  }
`;
const Title = styled.h1 `
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 19px;
  line-height: 1.4;
  letter-spacing: -0.002em;
  color: #ffffff;

  ${media.phone_medium `
    font-size: 23px;
    line-height: 1.6;
  `};

  ${media.tablet `
    font-weight: 700;
    font-size: 36px;
    line-height: 1.4;
  `};

  span:first-child {
    margin-right: 12px;
  }
`;
const Name = styled.span `
  position: relative;
  z-index: 1;
  display: inline-block;
  margin-right: 10px;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 12px);
    height: 100%;
    background-color: rgba(0, 20, 48, 0.3);
    border-radius: 4px;
    z-index: -1;
    top: 2px;
    left: -7px;
  }
`;
const Subtitle = styled.p `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: -0.002em;
  padding: 0 16px;

  ${media.tablet `
    font-size: 20px;
    padding: 0;
  `};
`;
const Button = styled.a `
  display: block;
  text-align: center;
  width: calc(100% - 32px);
  box-sizing: border-box;
  padding: 18px 40px;
  box-shadow: 0px 8px 16px 0px rgba(152, 107, 82, 0.16);
  background: linear-gradient(102deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.7) 100%);
  border-radius: 16px;
  border: none;
  font-weight: 700;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  color: #001430;
  font-size: 20px;
  letter-spacing: -0.006em;
  line-height: 1.2;
  margin: 0 16px 12px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  @media (hover: hover) {
    &:hover {
      opacity: 0.4;
      transition: opacity 0.2s ease;
    }
  }

  ${media.tablet `
    width: auto;
    display: inline-block;
    margin: 0 0 12px;
  `};
`;
const Text = styled.p `
  width: calc(100% - 32px);
  margin: 0 auto;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.8);

  ${media.tablet `
    width: auto;
    margin-bottom: 40px;
  `};

  a {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.8);
      transition: background-color 0.2s ease;
    }

    @media (hover: hover) {
      &:hover::before {
        background-color: transparent;
        transition: background-color 0.2s ease;
      }
    }
  }
`;
const Stores = styled.div `
  margin: 32px 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.phone_medium `
    flex-direction: row;
    justify-content: center;
  `}

  ${media.tablet `
    width: 700px;
    justify-content: flex-start;
    margin: 0;
  `};
`;
const StyledOutboundLink = styled(OutboundLink) `
  display: flex;
  align-items: center;
  padding: 16px 22px;
  background: linear-gradient(83.85deg, rgba(255, 255, 255, 0.01) -0.02%, rgba(255, 255, 255, 0.1) 99.96%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  transition: border-color 0.2s ease;

  ${media.phone_medium `
    padding: 16px;
  `}

  @media (min-width: 414px) {
    padding: 16px 22px;
  }

  ${media.tablet `
    align-items: initial;
    padding: 24px 32px;
    padding-left: 47px;
    background: linear-gradient(94.63deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.1) 100%);
  `}

  @media (hover: hover) {
    &:hover {
      border-color: rgba(255, 255, 255, 0.8);
      transition: border-color 0.2s ease;
    }
  }

  &:first-child {
    margin-bottom: 10px;

    ${media.phone_medium `
      margin-right: 10px;
      margin-bottom: 0;
    `}
  }
`;
const StoreIcon = styled.div `
  width: 23px;
  height: 23px;
  margin-right: 12px;

  ${media.tablet `
    width: 58px;
    height: 58px;
    margin-right: 24px;
  `}
`;
const AppstoreIcon = styled(StoreIcon) `
  background: url(${appstoreSrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const GoogleplayIcon = styled(StoreIcon) `
  background: url(${googleplaySrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const StoreDescription = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
const StoreName = styled.div `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  ${media.tablet `
    font-size: 14px;
    line-height: 1.2;
  `}
`;
const StoreRating = styled.div `
  line-height: 1;

  ${media.tablet `
    display: flex;
    align-items: flex-end;
    margin-top: 2px;
  `}

  strong {
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.002em;
    line-height: 1;
    text-transform: capitalize;

    ${media.phone_medium `
    `}

    ${media.tablet `
      font-size: 28px;
      line-height: 1.2;
    `}
  }

  span {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    font-size: 10px;
    letter-spacing: 0.05em;
    line-height: 1.2;
    font-weight: 400;
    position: relative;
    top: -1px;

    ${media.tablet `
      top: -8px;
      font-size: 14px;
      text-transform: uppercase;
    `}
  }
`;
const OnlyMobile = styled.div `
  ${media.tablet `
    display: none;
  `}
`;
export default InviteLeaderboardPage;
