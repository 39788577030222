import React from 'react';
import styled from 'styled-components';
import { media, responsiveContainerMobile } from 'components/responsive';
import { PrincipleRound } from './Round';
import { HideScrollbar, PRINCIPLES, SectionTitle, Text, responsiveContainer, sectionTopOffset } from './common';
// const messages = defineMessages({
//   title: '',
//   text: ''
// })
const Principles = (props) => (React.createElement("div", { ...props },
    React.createElement(NoMobile, null,
        React.createElement(PrinciplesCard, null,
            React.createElement(PrinciplesCardInner, null,
                React.createElement(Title, null, "Sweatcoin Principles"),
                React.createElement(Text, { style: { marginTop: '0.6rem' } }, "We are a diverse bunch and each and every one of us is unique. But there is something we all share \u2014 it\u2019s how we approach things at work. We call them Sweatcoin Principles"))),
        PRINCIPLES.map((round, index) => (React.createElement(PrincipleRound, { key: index, background: round.background, color: "#FFFFFF", index: `#${index + 1}`, title: round.title, text: round.text })))),
    React.createElement(OnlyMobile, null,
        React.createElement(PrinciplesCard, null,
            React.createElement(PrinciplesCardInner, null,
                React.createElement(Title, null, "Sweatcoin Principles"),
                React.createElement(Text, { style: { marginTop: '0.6rem' } }, "We are a diverse bunch and each and every one of us is unique. But there is something we all share \u2014 it\u2019s how we approach things at work. We call them Sweatcoin Principles"))),
        PRINCIPLES.map((round, index) => (React.createElement(PrincipleLine, { key: index },
            React.createElement(PrincipleRound, { key: `title-${index}`, background: round.background, color: "#FFFFFF", index: `#${index + 1}`, title: round.title }),
            React.createElement(PrincipleRound, { key: `text-${index}`, background: round.background, color: "#FFFFFF", text: round.text })))))));
const StyledPrinciples = styled(Principles) `
  width: 100%;
  ${sectionTopOffset};
  ${responsiveContainer};

  ${PrincipleRound} {
    display: inline-block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }

  ${Text} {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    line-height: 1.3rem;

    ${media.tablet `
      font-size: 0.7rem;
      line-height: 1.3;
    `};
    ${media.desktop `
      line-height: 1.5rem;
      font-size: 0.8rem;
      width: 90%;
    `};
    ${media.desktop_medium `
      width: 80%;
    `};
  }
`;
const NoMobile = styled.div `
  display: none;

  ${media.tablet `
    display: grid;
    align-items: end;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
  `};
  ${media.desktop `
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
  `};
`;
const PrincipleLine = styled.div `
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  ${HideScrollbar};
`;
const Title = styled(SectionTitle) `
  margin-bottom: 0.5rem;

  ${media.tablet `
    margin-bottom: 0;
  `};
`;
const PrinciplesCard = styled.div `
  grid-column: span 2 / auto;
  display: inline-block;
  white-space: normal;
  display: inline-block;
  box-sizing: content-box;
  vertical-align: top;
  align-self: flex-start;

  ${media.tablet `
    grid-column: span 2 / auto;
  `};
`;
const PrinciplesCardInner = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;
const OnlyMobile = styled.div `
  margin-right: -20px;
  margin-left: -20px;

  ${PrinciplesCardInner} {
    ${responsiveContainerMobile};
  }

  ${PrincipleLine} {
    ${responsiveContainerMobile};
    margin-bottom: 15px;
  }
  ${PrincipleRound} {
    margin-right: 5px;
    width: 90%;
    padding-bottom: 90%;
  }
  ${media.tablet `display: none`};
`;
export default StyledPrinciples;
