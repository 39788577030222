import _ from 'lodash';
const platform = (userAgent) => {
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent) || /IEMobile/i.test(userAgent)) {
        return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
        return 'Android';
    }
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && (!process.browser || !window.MSStream)) {
        return 'iOS';
    }
    return 'unknown';
};
export default platform;
export const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';
// Detect if server(or browser) runs in development mode
export const isDev = _.get(process, 'env.NODE_ENV') === 'development' || (typeof window !== 'undefined' && window.IS_DEV);
