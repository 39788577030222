import ClipboardJS from 'clipboard';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import styled from 'styled-components';
import dailyMailSrc from 'assets/Home/daily_mail_logo.svg';
import appstoreSrc from 'assets/Home/home_appstore.png';
import googleplaySrc from 'assets/Home/home_googleplay.png';
import mensJournalSrc from 'assets/Home/mens_journal_logo.svg';
import telegraphSrc from 'assets/Home/telegraph_logo.svg';
import analytics from 'utils/analytics';
import useFirebaseQuery from 'utils/useFirebaseQuery';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    title: 'What others say about us',
    telegraph: '“If ever you’ve needed an incentive to exercise, a new app could give you a much-needed boost to get active”',
    dailyMail: '“Sweatcoin.. beats Whatsapp as the most popular in App Store.”',
    mensJournal: '“Basically pays you to move and exercise, and it costs nothing to use”',
    appstoreName: 'Apple App Store',
    appstoreRating: '(273k ratings)',
    googleplayName: 'Google play',
    googleplayRating: '(1.8M ratings)',
});
const slides = [
    {
        logo: telegraphSrc,
        text: React.createElement(FormattedMessage, { ...messages.telegraph }),
        alt: 'Telegraph logo',
    },
    {
        logo: dailyMailSrc,
        text: React.createElement(FormattedMessage, { ...messages.dailyMail }),
        alt: 'DailyMail logo',
    },
    {
        logo: mensJournalSrc,
        text: React.createElement(FormattedMessage, { ...messages.mensJournal }),
        alt: 'Mens Journal logo',
    },
];
const configSelector = (state) => state.config;
const Quotes = () => {
    const appstoreButton = useRef();
    const googleplayButton = useRef();
    // Config & firebase
    const config = useSelector(configSelector);
    const { origin } = config;
    const [firebaseQuery, getCopyLink] = useFirebaseQuery('/', {
        feature: 'main_page_download_button',
    });
    useEffect(() => {
        new ClipboardJS(appstoreButton.current, { text: getCopyLink });
        new ClipboardJS(googleplayButton.current, { text: getCopyLink });
    }, [getCopyLink]);
    const settings = {
        dots: true,
        arrows: false,
        infinite: true,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        cssEase: 'linear',
    };
    return (React.createElement(Wrapper, null,
        React.createElement(FormattedMessage, { ...messages.title }, (title) => React.createElement(Title, null, title)),
        React.createElement(StyledSlider, { ...settings }, slides.map((slide, id) => (React.createElement(Slide, { key: id },
            React.createElement(LogoWrap, null,
                React.createElement(Logo, { src: slide.logo, width: "207", height: "38", alt: slide.alt })),
            React.createElement(Text, null, slide.text))))),
        React.createElement(Stores, null,
            React.createElement(Appstore, { onClick: handleAppstoreClick, ref: appstoreButton, href: firebaseQuery.link },
                React.createElement(AppstoreIcon, null),
                React.createElement(StoreDescription, null,
                    React.createElement(StoreName, null,
                        React.createElement(FormattedMessage, { ...messages.appstoreName })),
                    React.createElement(StoreRating, null,
                        React.createElement("strong", null, "4.6"),
                        "\u00A0",
                        React.createElement(FormattedMessage, { ...messages.appstoreRating })))),
            React.createElement(GooglePlay, { onClick: handleGooglePlayClick, ref: googleplayButton, href: firebaseQuery.link },
                React.createElement(GoogleplayIcon, null),
                React.createElement(StoreDescription, null,
                    React.createElement(StoreName, null,
                        React.createElement(FormattedMessage, { ...messages.googleplayName })),
                    React.createElement(StoreRating, null,
                        React.createElement("strong", null, "4.4"),
                        "\u00A0",
                        React.createElement(FormattedMessage, { ...messages.googleplayRating })))))));
    function handleAppstoreClick(event) {
        event.preventDefault();
        analytics.track('QuotesAppstoreDownloadButton', 'click', pickBy({
            origin,
            feature: 'main_page_download_button',
        }, identity));
        window.open('https://itunes.apple.com/app/sweatcoin-app-that-pays-you/id971023427', '_blank');
    }
    function handleGooglePlayClick(event) {
        event.preventDefault();
        analytics.track('QuotesGooglePlayDownloadButton', 'click', pickBy({
            origin,
            feature: 'main_page_download_button',
        }, identity));
        window.open('https://play.google.com/store/apps/details?id=in.sweatco.app', '_blank');
    }
};
const Wrapper = styled.div `
  box-sizing: border-box;
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 0px 24px;
  background: linear-gradient(253deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.05) 100%);
  border-radius: 20px;

  @media ${desktop()} {
    width: 980px;
    margin: 120px auto 40px;
    padding: 32px 0px;
  }
`;
const Title = styled.h3 `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 32px;

  @media ${desktop()} {
    font-size: 18px;
  }
`;
const StyledSlider = styled(Slider) `
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 16px;

  @media ${desktop()} {
    width: 820px;
    height: 130px;
    margin: 0 auto;
    padding: 0;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    padding: 0 !important;
    margin: 0 !important;

    li {
      list-style: none !important;
      margin: 0 4px;
      line-height: 0;

      button {
        width: 4px;
        height: 4px;
        background-color: #ffffff;
        opacity: 0.3;
        border-radius: 6px;
        border: none;
        font-size: 0;
        padding: 0;
        cursor: pointer;
        transition: all 0.3s linear;
      }

      &.slick-active {
        button {
          width: 32px;
          opacity: 1;
        }
      }
    }
  }
`;
const Slide = styled.div `
  padding-bottom: 16px;

  @media ${desktop()} {
    padding-bottom: 25px;
  }
`;
const LogoWrap = styled.div ``;
const Logo = styled.img `
  height: 38px;
  display: block;
  width: auto;
  margin: 0 auto 12px;
`;
const Text = styled.p `
  font-style: italic;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: lowercase;
  text-align: center;
`;
const Stores = styled.div `
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  padding-top: 28px;
  margin-top: 28px;
  flex-direction: column;
  justify-content: start;

  @media ${desktop()} {
    padding-top: 32px;
    margin-top: 32px;
    flex-direction: row;
    justify-content: center;
  }
`;
const Button = styled.a `
  display: flex;
  margin: 0 32px;

  @media ${desktop()} {
    width: 266px;
    margin: 0 65px;
  }

  & + & {
    margin-top: 40px;

    @media ${desktop()} {
      margin-top: 0px;
    }
  }
`;
const Appstore = styled(Button) `
  margin-right: 8px;

  @media ${desktop()} {
    margin-right: 14px;
  }
`;
const GooglePlay = styled(Button) ``;
const StoreIcon = styled.div `
  width: 48px;
  height: 48px;
  margin-right: 24px;

  @media ${desktop()} {
    width: 58px;
    height: 58px;
  }
`;
const AppstoreIcon = styled(StoreIcon) `
  background: url(${appstoreSrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const GoogleplayIcon = styled(StoreIcon) `
  background: url(${googleplaySrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const StoreDescription = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
const StoreName = styled.div `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 4px;

  @media ${desktop()} {
    line-height: 1.2;
    margin-bottom: 0;
  }
`;
const StoreRating = styled.div `
  display: flex;
  align-items: flex-end;

  @media ${desktop()} {
    margin-top: 2px;
  }

  strong {
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #ffffff;
    font-size: 24px;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: capitalize;

    @media ${desktop()} {
      font-size: 28px;
    }
  }

  span {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    letter-spacing: 1.4px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    top: -3px;

    @media ${desktop()} {
      top: -8px;
    }
  }
`;
export default Quotes;
