import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import alertIconSrc from 'assets/SweatPaws/alert-icon.svg';
import blurredFragmentSrc from 'assets/SweatPaws/blurred-fragment.png';
import { media } from 'components/responsive';
import { fittingString, getRandomNum } from '../utils/functions';
import Canvas from './Canvas';
import Disclaimer from './Disclaimer';
import Loading from './Loading';
import ResultCard from './ResultCard';
import Shares from './Shares';
const Result = (props) => {
    const { points, results, meta, id, handleAnswer, handleShareBtnClick, handleClosePanelBtnClick, isSharePanelOpened, isSmallBannerOpened, } = props;
    const [isLoaded, setLoaded] = useState(false);
    const [sharingImage, setSharingImage] = useState(null);
    const [sharingText, setSharingText] = useState(null);
    const [isCanvasDrawn, setCanvasDrawn] = useState(false);
    const [value, setValue] = useState('');
    const [isBarsFilled, setBarsFilled] = useState({ happiness: null, health: null });
    const [role, setRole] = useState(null);
    const walkingAmout = points.walking.value;
    const walkingDuration = points.duration.value;
    const reccomendedTime = points.breed.hours;
    const name = points['name'].label;
    const shareImage = points['avatar'].shareImage;
    const breed = points['breed'].label;
    const treatValue = points['treat'].value;
    const age = points['age'].label;
    const healthData = results.healthData;
    const disclaimer = results.disclaimer;
    let outcomeValue, resultText;
    const outcomePercent = (walkingAmout * walkingDuration - reccomendedTime) * 100 + 100;
    const stepsPerDay = walkingAmout * walkingDuration * 14000;
    const stepsRange = `${stepsPerDay - 1000}`.slice(0, 2) + '—' + `${stepsPerDay + 1000}`.slice(0, 2) + 'k steps';
    if (outcomePercent < 75) {
        outcomeValue = 'notEnough';
        resultText = `Uh-oh. <span>${name}</span> needs to walk much more than that. Let's go together! Download Sweatcoin to count your steps, and get that tail wagging!`;
    }
    if (outcomePercent >= 75 && outcomePercent < 100) {
        outcomeValue = 'nearlyThere';
        resultText = `Looks like <span>${name}</span> is almost getting there. Download Sweatcoin, and take the app along with you on walks to count those extra neccesary steps!`;
    }
    if (outcomePercent >= 100 && outcomePercent < 200) {
        outcomeValue = 'great';
        resultText =
            "Woah! You're getting the ultimate steps. Shoutout to your furry friend! Download Sweatcoin to check how many sweatcoins you could get on your walks.";
    }
    if (outcomePercent >= 200) {
        outcomeValue = 'tooMuch';
        resultText =
            "So much step! You've got to be careful walking that much - make sure your fur baby is getting regular breaks and has access to water. Download Sweatcoin to keep track of your steps, and make walking your dog simpler. ";
    }
    const roleRandomNum = getRandomNum(healthData[outcomeValue].labels.length - 1);
    const roleValue = healthData[outcomeValue].labels[roleRandomNum];
    const happinessRandomNum = getRandomNum(treatValue.length - 1);
    const healthRandomNum = getRandomNum(healthData[outcomeValue].percents.length - 1);
    const draw = (context) => {
        if (id && name && breed && role && stepsRange) {
            const image = new Image(952, 500);
            image.onload = drawImageActualSize;
            image.setAttribute('crossorigin', 'anonymous');
            image.src = `${shareImage}`;
            function drawImageActualSize() {
                context.drawImage(this, 0, 0);
                context.drawImage(this, 0, 0, this.width, this.height);
                context.fillStyle = 'rgba(255, 255, 255, 0.6)';
                context.textAlign = 'center';
                context.font = 'normal 900 12px Orbitron';
                context.fillText(`ID ${id}`, 476, 250);
                context.fillStyle = '#FFEFF9';
                context.textAlign = 'center';
                context.font = 'normal 900 27px Orbitron';
                context.fillText(name.toUpperCase() + ',', 476, 280);
                context.fillStyle = '#D5F7F2';
                context.textAlign = 'center';
                context.font = 'normal 900 18px Orbitron';
                context.fillText(role.toUpperCase(), 476, 305);
                context.fillStyle = '#FFEFF9';
                context.textAlign = 'left';
                context.font = 'normal 900 27px Orbitron';
                context.fillText(fittingString(context, breed.toUpperCase(), 400), 276, 378);
                context.fillStyle = '#D5F7F2';
                context.font = 'normal 900 32px Orbitron';
                context.textAlign = 'left';
                context.fillText(stepsRange.toUpperCase(), 276, 445);
                setCanvasDrawn(true);
            }
        }
    };
    useEffect(() => {
        let timer1, timer2;
        if (!isLoaded) {
            if (value) {
                timer1 = setTimeout(() => {
                    setValue('One more second...');
                }, 1500);
                timer2 = setTimeout(() => {
                    setLoaded(true);
                }, 2500);
            }
        }
        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    });
    useEffect(() => {
        if (isLoaded) {
            setBarsFilled({
                happiness: treatValue[happinessRandomNum],
                health: healthData[outcomeValue].percents[healthRandomNum],
            });
        }
    }, [isLoaded]);
    useEffect(() => {
        const nameCapitalized = name[0].toUpperCase() + name.slice(1);
        setRole(roleValue);
        setSharingText(`Awesome! ${nameCapitalized} is a ${roleValue}, taking ${stepsRange} daily. What about your pal? Find out!`);
        handleAnswer('results', {
            data: `name: ${nameCapitalized}, happinessPercent: ${treatValue[happinessRandomNum]}, healthPercent: ${healthData[outcomeValue].percents[healthRandomNum]}, stepsRange: ${stepsRange}`,
            shareText: sharingText,
            id: id,
        });
    }, []);
    useEffect(() => {
        if (isCanvasDrawn) {
            const canvas = document.getElementById('canvas');
            getCanvasBlob(canvas).then((blob) => {
                uploadImageToS3(blob, id);
            }, (err) => {
                console.log(err);
            });
        }
    }, [isCanvasDrawn]);
    useEffect(() => {
        if (window.obApi) {
            obApi('track', 'Result');
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        !isLoaded ? React.createElement(Loading, { text: value }) : null,
        React.createElement(Container, { isSmallBannerOpened: isSmallBannerOpened },
            React.createElement(ResultCard, { meta: meta, points: points, role: role, isBarsFilled: isBarsFilled, stepsRange: stepsRange, id: id }),
            React.createElement(Canvas, { draw: draw, width: 952, height: 500 }),
            React.createElement(ResultText, { dangerouslySetInnerHTML: { __html: resultText } }),
            React.createElement(Panel, null,
                React.createElement(ShareButton, { onClick: handleShareBtnClick },
                    React.createElement("span", null, "Share with friends"))),
            age === 'Puppy' || age === 'Senior' ? (React.createElement(Note, null,
                React.createElement(NoteTitle, null, "Important note"),
                React.createElement(NoteText, null, age === 'Puppy' ? disclaimer.puppy : disclaimer.senior))) : null,
            React.createElement(Disclaimer, { isSharePanelOpened: isSharePanelOpened, points: points }),
            React.createElement(Credits, null,
                React.createElement(CreditsTitle, null, "SweatPaws is created by Sweatcoin"),
                React.createElement(CreditsText, null, "Download Sweatcoin App, start walking, turn your steps into sweatcoins and spend them on awesome products or donate them to charity.")),
            React.createElement(CSSTransition, { in: isSharePanelOpened, timeout: 800, classNames: "panel", unmountOnExit: true },
                React.createElement(Shares, { shareText: sharingText, sharingImage: sharingImage, url: `https://sweatco.in/sweatpaws/${id}?src=sharing`, quizId: meta.quizId, isSharePanelOpened: isSharePanelOpened, handleClosePanelBtnClick: handleClosePanelBtnClick })))));
    function getCanvasBlob(canvas) {
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/jpeg', '0.85');
        });
    }
    async function uploadImageToS3(blob, id) {
        let formData = new FormData();
        const fileToUpload = new File([blob], `${id}.jpg`, { type: 'image/jpeg' });
        formData.append('canvasImage', fileToUpload);
        const response = await fetch('/sweatpaws-upload', {
            method: 'POST',
            body: formData,
        }).catch((error) => console.log(error));
        const { url } = await response.json();
        if (url) {
            setSharingImage(`${meta.sharingPath}${id}.jpg`);
            setTimeout(() => {
                setValue('Loading your results...');
            }, 500);
        }
    }
};
const Note = styled.div `
  margin-bottom: 30px;
  padding: 20px;
  background: linear-gradient(97.63deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.03) 100%);
  backdrop-filter: blur(20px);
  border-radius: 20px;

  ${media.desktop `
    width: 500px;
    margin-right: auto;
    margin-left: auto;
  `}
`;
const NoteTitle = styled.p `
  position: relative;
  margin-bottom: 8px;
  padding-left: 22px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 16px;
    background-image: url('${alertIconSrc}');
    background-repeat: no-repeat;
    transform: translateY(-50%);
  }
`;
const NoteText = styled.p `
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const Credits = styled.div `
  margin: 0 auto;

  @media (min-width: 400px) {
    text-align: center;
  }

  ${media.desktop `
    width: 700px;
    max-width: none;
    margin: 0 auto;
  `}
`;
const CreditsTitle = styled.h3 `
  margin-bottom: 8px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  ${media.desktop `
    margin-bottom: 12px;
    font-size: 24px;
  `}
`;
const CreditsText = styled.p `
  margin-bottom: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  opacity: 0.8;

  ${media.desktop `
    font-size: 18px;
  `}
`;
const Panel = styled.div `
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  order: 1;
  width: 100%;
  padding: 20px 16px;
  padding-bottom: 40px;
  background: url('${blurredFragmentSrc}'),
    linear-gradient(235.91deg, rgba(47, 18, 118, 0.8) 23.55%, rgba(11, 10, 40, 0.8) 46.91%, rgba(0, 8, 20, 0.8) 70.18%);
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px -5px 10px rgba(7, 0, 32, 0.1), 0px -10px 40px rgba(7, 0, 32, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 30px 30px 0px 0px;

  ${media.desktop `
    padding-bottom: 20px;
    border-radius: 0;
  `};
`;
const ShareButton = styled.button `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 500px;
  height: 48px;
  margin: 0 auto;
  background: none;
  border-radius: 50px;
  border: none;

  ${media.desktop `
    width: 500px;
    margin: 0 auto;
  `};

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    background: linear-gradient(112.4deg, #fe53bb 29.63%, #09fbd3 76.7%) border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 50px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(104.74deg, rgba(255, 105, 227, 0.2) 25.73%, rgba(99, 95, 255, 0.2) 66.52%);
    filter: blur(40px);
  }

  span {
    font-weight: 800;
    font-size: 15px;
    line-height: 19px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #ffffff;
  }

  &:active::after {
    background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.5) 25.73%, rgba(48, 255, 226, 0.5) 66.52%);
  }

  @media (hover: hover) {
    &:hover {
      &::after {
        background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.5) 25.73%, rgba(48, 255, 226, 0.5) 66.52%);
      }
    }
  }
`;
const ResultText = styled.p `
  margin-bottom: 30px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  ${media.desktop `
    width: 500px;
    margin-right: auto;
    margin-left: auto;
  `}

  span {
    text-transform: capitalize;
  }
`;
const Container = styled.div `
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding-top: 128px;

  ${media.desktop `
    width: 700px;
    max-width: 700px;
    margin: 0 auto;
    margin-top: ${(props) => (props.isSmallBannerOpened ? '-101px' : null)};
    padding-top: 200px;
  `}

  ${media.desktop_large `
    padding-top: 300px;
  `}

  // panel animation
  .panel-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .panel-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .panel-exit {
    opacity: 1;
  }
  .panel-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }

  canvas {
    display: none;
  }
`;
export default Result;
