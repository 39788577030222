import mintCrowdfunding1 from 'assets/Home/cr_1.png';
import mintCrowdfunding2 from 'assets/Home/cr_2.png';
import mintCrowdfunding3 from 'assets/Home/cr_3.png';
import mountains from 'assets/Home/mountains.png';
import nature from 'assets/Home/nature.jpg';
import COLORS from './colors';
const SECTIONS = {
    orange: {
        name: 'orange',
        left: {
            color: COLORS.orange,
        },
        right: {
            gradient: 'linear-gradient(135deg, #032555 0%, #42E9FF 100%)',
            image: mountains,
        },
        menuItem: 0,
    },
    pink: {
        name: 'pink',
        left: {
            color: '#F07363',
        },
        right: {
            gradient: 'linear-gradient(162deg, #032555 2%, #FA5C4B 100%)',
            image: mountains,
        },
        menuItem: 0,
    },
    green: {
        name: 'green',
        left: {
            color: '#85963D',
        },
        right: {
            gradient: 'linear-gradient(135deg, #032555 0%, #525200 100%)',
        },
        menuItem: 0,
    },
    blue: {
        name: 'blue',
        left: {
            color: '#032555',
        },
        right: {
            gradient: 'linear-gradient(135deg, #032555 0%, #47bfcc 100%)',
        },
        menuItem: 1,
    },
    peach: {
        name: 'peach',
        left: {
            color: '#F07762',
        },
        right: {
            gradient: 'linear-gradient(135deg, #EF7364 0%, #F8A746 100%)',
            image: mountains,
        },
        menuItem: 1,
    },
    purple: {
        name: 'purple',
        left: {
            color: '#422F6C',
        },
        right: {
            color: '#422F6C',
            image: nature,
            image_size: 'cover',
        },
        menuItem: 1,
    },
    mint: {
        name: 'mint',
        left: {
            color: '#87CAB5',
        },
        right: {
            gradient: 'linear-gradient(135deg, #032555 0%, #87CAB5 100%)',
            image: [
                null,
                { url: mintCrowdfunding1, opacity: 0.75, image_size: 'cover' },
                { url: mintCrowdfunding3, opacity: 0.75, image_size: 'cover' },
                { url: mintCrowdfunding2, opacity: 0.75, image_size: 'cover' },
            ],
        },
        menuItem: 2,
    },
    partners: {
        name: 'partners',
        left: {
            color: '#F07363',
        },
        right: {
            gradient: 'linear-gradient(162deg, #032555 2%, #FA5C4B 100%)',
            image: mountains,
        },
        menuItem: 3,
    },
    advisoryBoard: {
        name: 'advisoryBoard',
        left: {
            color: '#032555',
        },
        right: {
            gradient: 'linear-gradient(135deg, #032555 0%, #47bfcc 100%)',
        },
        menuItem: 3,
    },
};
export default SECTIONS;
