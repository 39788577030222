import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import copyIcon from 'assets/SweatPaws/copy-icon.svg';
import crossIcon from 'assets/SweatPaws/cross-icon.svg';
import facebookIcon from 'assets/SweatPaws/fb-icon.svg';
import twitterIcon from 'assets/SweatPaws/twitter-icon.svg';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { useCopyToClipboard } from '../utils/functions';
const Shares = (props) => {
    const { shareText, sharingImage, url, quizId, handleClosePanelBtnClick } = props;
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURI(shareText)}&url=${url}`;
    const [isCopied, setCopied] = useCopyToClipboard();
    const [copyLinktitle, setCopyLinkTitle] = useState('Copy link');
    const handleShareBtnClick = (url, socialName) => {
        openPopupWindow(url, 'Share', 600, 500);
        analytics.track(`share_${socialName}`, 'click', {
            origin: location.origin,
            page: location.pathname,
            quizId: quizId,
        });
    };
    useEffect(() => {
        let timer;
        if (isCopied) {
            timer = setTimeout(() => {
                setCopied(false);
                setCopyLinkTitle('Copy link');
            }, 2000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);
    return (React.createElement(Container, { className: "shares-panel" },
        React.createElement(ContainerInner, null,
            React.createElement(Title, null, "Share your results"),
            React.createElement(CloseButton, { onClick: handleClosePanelBtnClick }),
            React.createElement(Preview, null,
                React.createElement(ImageContainer, null,
                    React.createElement(PreviewImage, { src: sharingImage })),
                React.createElement(PreviewContent, null,
                    React.createElement("span", null, "sweatco.in"),
                    React.createElement(PreviewTitle, null, "Check your dog's activity with SweatPaws"),
                    React.createElement(PreviewText, null, shareText))),
            React.createElement(Buttons, null,
                React.createElement(ShareButton, { onClick: () => handleShareBtnClick(twitterUrl, 'twitter') },
                    React.createElement("span", null, "Share on Twitter")),
                React.createElement(FbButton, { onClick: () => handleShareBtnClick(facebookUrl, 'facebook') },
                    React.createElement("span", null, "Share on Facebook")),
                React.createElement(CopyButton, { isCopied: isCopied, onClick: handleCopyBtnClick },
                    React.createElement("span", null, copyLinktitle))))));
    function handleCopyBtnClick() {
        setCopied(`${shareText} ${url}`);
        setCopyLinkTitle('Link copied!');
        analytics.track('copy_button', 'click', {
            origin: location.origin,
            page: location.pathname,
            quizId: quizId,
        });
    }
    function openPopupWindow(url, title, w, h) {
        const left = screen.width / 2 - w / 2;
        const top = screen.height / 2 - h / 2;
        analytics.track('share_button', 'click', {
            origin: location.origin,
            page: location.pathname,
            quizId: quizId,
        });
        return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
            w +
            ', height=' +
            h +
            ', top=' +
            top +
            ', left=' +
            left);
    }
};
const CloseButton = styled.button `
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  background-image: url('${crossIcon}');
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;

  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }
`;
const Container = styled.div `
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  padding: 30px 16px;
  padding-bottom: 40px;
  background: linear-gradient(
    235.91deg,
    rgba(47, 18, 118, 0.8) 23.55%,
    rgba(11, 10, 40, 0.8) 37.18%,
    rgba(0, 8, 20, 0.8) 58.54%
  );
  box-shadow: 0px -5px 10px rgba(7, 0, 32, 0.1), 0px -10px 40px rgba(7, 0, 32, 0.4);
  backdrop-filter: blur(40px);
  border-radius: 30px 30px 0px 0px;

  ${media.desktop `
    padding-top: 60px;
    padding-bottom: 100px;
    border-radius: 0;
  `};
`;
const ContainerInner = styled.div `
  position: relative;
  display: flex;
  flex-direction: column;

  ${media.desktop `
    width: 500px;
    margin: 0 auto;
  `};
`;
const Title = styled.p `
  margin-bottom: 22px;
  font-weight: 800;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const Preview = styled.div `
  display: flex;
  margin-bottom: 32px;
`;
const PreviewContent = styled.div `
  display: flex;
  flex-direction: column;
  padding-top: 9px;

  span {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: #ffffff;
    opacity: 0.8;
  }
`;
const ImageContainer = styled.div `
  width: 120px;
  min-width: 120px;
  height: 120px;
  margin-right: 12px;
  border-radius: 8px;
  display: block;
  position: relative;
  overflow: hidden;
`;
const PreviewImage = styled.img `
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  height: 120px;
`;
const PreviewTitle = styled.p `
  margin-bottom: 4px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
`;
const PreviewText = styled.p `
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  opacity: 0.8;

  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #ffffff;
    opacity: 1;
  }

  span:first-child {
    text-transform: capitalize;
  }
`;
const Buttons = styled.div `
  display: flex;
  flex-direction: column;
`;
const ShareButton = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  margin-bottom: 16px;
  backdrop-filter: blur(10px);
  border-radius: 50px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    background: linear-gradient(112.4deg, #fe53bb 29.63%, #09fbd3 76.7%) border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 50px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.3) 25.73%, rgba(48, 255, 226, 0.3) 66.52%);
    filter: blur(30px);
    border-radius: 50px;
  }

  span {
    position: relative;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.002em;
    color: #ffffff;
    background: linear-gradient(112.4deg, #ffa6dc 29.63%, #6affe6 76.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -34px;
      width: 24px;
      height: 24px;
      background-image: url('${twitterIcon}');
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:active::after {
    background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.5) 25.73%, rgba(48, 255, 226, 0.5) 66.52%);
  }

  @media (hover: hover) {
    &:hover {
      &::after {
        background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.5) 25.73%, rgba(48, 255, 226, 0.5) 66.52%);
      }
    }
  }
`;
const FbButton = styled(ShareButton) `
  span {
    &::before {
      background-image: url('${facebookIcon}');
    }
  }
`;
const CopyButton = styled(ShareButton) `
  span {
    &::before {
      background-image: ${(props) => (props.isCopied ? 'none' : `url('${copyIcon}')`)};
    }
  }
`;
export default Shares;
