import React from 'react';
import { Redirect } from 'react-router';
const createRoutes = () => [
    {
        path: '/offers',
        exact: true,
        component: ({ location }) => React.createElement(Redirect, { to: `/${location.search}` }),
    },
    {
        path: '/offers/:offer_id(\\d+)',
        component: require('./pages/Offer').default,
    },
];
export default createRoutes;
