const createRoutes = () => [
    {
        path: '/al/confirm_email',
        component: require('./pages/ConfirmEmail').default,
    },
    {
        path: '/al/group/:group_id',
        component: require('./pages/AppLink').default,
    },
    {
        path: '/app/:path*',
        exact: true,
        component: require('./pages/AppLink').default,
    },
];
export default createRoutes;
