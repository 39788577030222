const createRoutes = () => [
    {
        path: '/team',
        exact: true,
        component: require('./pages/Team').default,
    },
    {
        path: '/team/privacy',
        exact: true,
        component: require('./pages/ConsentPrivacyNotice').default,
    },
    {
        path: '/team/:vacancy_id',
        exact: true,
        component: require('./pages/Vacancy').default,
    },
];
export default createRoutes;
