import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AdviceNotSuitable, Common, Divider, Footer, List, ListItem, SettingsButton, Title } from './common';
const messages = defineMessages({
    bgActivity: 'Allow background activity',
    settings: 'Settings',
    deviceMaintenance: 'Device maintenance',
    battery: 'Battery',
    apps: 'Unmonitored apps',
    add: 'Add apps',
    select: 'Select Sweatcoin app and tap “Done”',
    gps: 'Improve GPS accuracy',
    connections: 'Connections',
    location: 'Location',
    method: 'Locating method',
    set: 'Set “Locating” method to “High accuracy”',
});
const Samsung7 = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.bgActivity })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.deviceMaintenance })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.battery })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.add })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.select }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(Divider, null),
        !props.featureAccelerometerTracker
            ? [
                React.createElement(Title, { trackEvent: props.trackEvent },
                    React.createElement(FormattedMessage, { ...messages.gps })),
                React.createElement(List, null,
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.settings })),
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.connections })),
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.location })),
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.method })),
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.set }))),
                React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
                React.createElement(Divider, null),
            ]
            : null,
        !props.featureAccelerometerTracker ? (React.createElement(Common, { trackEvent: props.trackEvent, openSettings: props.openSettings, isFooterDividerDefault: true })) : null,
        React.createElement(Footer, { trackEvent: props.trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: props.trackEvent })));
};
export default Samsung7;
