import React from 'react';
import styled from 'styled-components';
import { logEvent } from '../../utils';
import leaderboardSrc from './assets/leaderboard.png';
import leaderboardSrc2x from './assets/leaderboard@2x.png';
import leaderboardSrc3x from './assets/leaderboard@3x.png';
import EmailList, { isValidEmail } from './components/EmailList';
const AVAILABLE_TYPES = {
    friends: {
        title: 'Challenge your friends to a step competition',
        label: 'Compete with your friends, stay healthy and earn rewards.',
    },
    cowork: {
        title: 'Challenge your colleagues to a step competition',
        label: 'Compete with your colleagues, stay healthy and earn rewards.',
    },
    collabwork: {
        title: 'Connect with your colleagues',
        label: 'Are you working from home? Invite your colleagues to a group and stay healthy together!',
    },
};
const Competition = ({ type = 'friends' }) => {
    const [email, setEmail] = React.useState();
    const [friends, setFriends] = React.useState([]);
    const { title, label } = AVAILABLE_TYPES[type];
    return (React.createElement(Layout, null,
        React.createElement(Title, null, title),
        React.createElement(Label, null, label),
        React.createElement(Screenshot, { src: leaderboardSrc, srcSet: `${leaderboardSrc2x} 2x, ${leaderboardSrc3x} 3x` }),
        React.createElement(EmailList, { email: email, setEmail: setEmail, friends: friends, setFriends: setFriends }),
        React.createElement(SubmitButton, { onClick: handleSubmit }, "Start competition!")));
    function handleSubmit() {
        if (!email) {
            alert('Email is empty');
        }
        else if (!isValidEmail(email)) {
            alert('Email is invalid');
        }
        else {
            let event = `event@lofi_competition.friendlist?email=${replaceEventSeparator(email)}`;
            if (friends.length > 0) {
                const list = friends.join(',');
                event += `&friends=${replaceEventSeparator(list)}`;
            }
            logEvent(event);
            alert('Thank you! We’ll get back to you shortly');
        }
    }
};
function replaceEventSeparator(str) {
    return str.split('@').join('#');
}
export default Competition;
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
`;
const Title = styled.h1 `
  font-family: 'PFDINMonoPro' !important;
  font-size: 28px;
  letter-spacing: -0.5px;
  line-height: 33.6px;
`;
const Label = styled.span `
  font-family: sans-serif;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.4px;
  margin: 16px 0px;
`;
const Screenshot = styled.img `
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
  transition: all 0.45s;
  margin-bottom: 36px;
`;
export const SubmitButton = styled.button `
  height: 44px;
  border-radius: 13px;
  border: none;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #1b3556;
  background-color: #ffffff;
  margin: 36px 0px;
`;
