import find from 'lodash/find';
import get from 'lodash/get';
import { RSAA } from 'redux-api-middleware';
import { DELETE_CLEARING_REQUEST_FULFILLED, DELETE_CLEARING_REQUEST_PENDING, DELETE_CLEARING_REQUEST_REJECTED, GET_CHALLENGES_FULFILLED, GET_CHALLENGES_PENDING, GET_CLEARING_REQUEST_FULFILLED, GET_CLEARING_REQUEST_PENDING, GET_CLUB_FULFILLED, GET_CLUB_PENDING, GET_CLUB_REJECTED, GET_CROWDFUNFING_CAMPAIGNS_FULFILLED, GET_CROWDFUNFING_CAMPAIGNS_PENDING, GET_FEATURED_OFFERS_FULFILLED, GET_INTERPRIDE_STEPS_FULFILLED, GET_INTERPRIDE_STEPS_PENDING, GET_LEGAL_FULFILLED, GET_LEGAL_PENDING, GET_OFFER_FULFILLED, GET_OFFER_PENDING, GET_REFERRAL_CODE_REQUEST_FULFILLED, GET_REFERRAL_CODE_REQUEST_PENDING, GET_SFW_CLUB_FULFILLED, GET_SFW_CLUB_PENDING, GET_SFW_CLUB_REJECTED, GET_SHLOMO_STATS_FULFILLED, GET_SHLOMO_STATS_PENDING, GET_UPCOMING_OFFERS_FULFILLED, GET_UPCOMING_OFFERS_PENDING, GET_USERS_ME_FULFILLED, GET_USERS_ME_PENDING, GET_USERS_ME_REJECTED, GET_USER_BY_USERNAME_FULFILLED, GET_USER_BY_USERNAME_PENDING, GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_FULFILLED, GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_PENDING, POST_CLEARING_REQUEST_CONFIRM_FULFILLED, POST_CLEARING_REQUEST_CONFIRM_PENDING, POST_INVITE_REQUEST_FULFILLED, POST_INVITE_REQUEST_PENDING, POST_INVITE_REQUEST_REJECTED, POST_OFFER_PAY_FULFILLED, POST_OFFER_PAY_PENDING, POST_OFFER_PAY_REJECTED, ROUTER_ERROR_RAISE, ROUTER_REJECTED, } from 'constants/ActionTypes';
import Featured from 'constants/Featured.json';
import * as api from 'utils/api';
import { isBrowser } from 'utils/platform';
export function fetchCurrentUser() {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path('/api/v2/users/me.json'),
            headers: api.headers(),
            types: [GET_USERS_ME_PENDING, GET_USERS_ME_FULFILLED, GET_USERS_ME_REJECTED],
            bailout: ({ session }) => !('authentication-token' in session),
        },
    };
}
export function searchUserByUsername(username) {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path('/api/v2/users/search.json', { username }),
            headers: api.headers(),
            types: [GET_USER_BY_USERNAME_PENDING, GET_USER_BY_USERNAME_FULFILLED, ROUTER_REJECTED],
            bailout: ({ profiles }) => profiles[username],
        },
    };
}
export function searchUserByUsernameWithPrizedrawDeadline(username) {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path('/api/v2/users/search.json', { username, get_prizedraw_deadline: 1 }),
            headers: api.headers(),
            types: [
                GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_PENDING,
                GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_FULFILLED,
                ROUTER_REJECTED,
            ],
            bailout: ({ profiles }) => profiles[username],
        },
    };
}
export function prefetchFeaturedOffers() {
    return {
        type: GET_FEATURED_OFFERS_FULFILLED,
        payload: Featured,
    };
}
export function prefetchUpcomingOffers() {
    let query = { order_by: 'votes_count' };
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: (state) => {
                const country = get(state, 'config.country');
                if (country !== 'int') {
                    query.country = country;
                }
                return api.path('/api/v2/offers/upcoming.json', query)(state);
            },
            headers: api.headers(),
            types: [GET_UPCOMING_OFFERS_PENDING, GET_UPCOMING_OFFERS_FULFILLED, ROUTER_REJECTED],
            bailout: ({ offers }) => offers.upcoming !== null,
        },
    };
}
export function prefetchOffer(offerId) {
    return (dispatch) => {
        const staticOffer = find(Featured.data, (model) => model.id == offerId);
        if (staticOffer) {
            dispatch({
                type: GET_OFFER_FULFILLED,
                payload: {
                    data: staticOffer,
                },
            });
        }
        return dispatch({
            [RSAA]: {
                method: 'GET',
                endpoint: api.path(`/api/v2/offers/${offerId}.json`),
                headers: api.headers(),
                types: [GET_OFFER_PENDING, GET_OFFER_FULFILLED, ROUTER_REJECTED],
                bailout: ({ offers }) => offerId in offers.models && 'description' in offers.models[offerId],
            },
        });
    };
}
export function prefetchDonation(offerId) {
    return (dispatch) => {
        return dispatch(prefetchOffer(offerId)).then((result) => {
            const type = get(result, 'payload.data.type');
            if (result && result.type === GET_OFFER_FULFILLED && type !== 'donation') {
                dispatch({
                    type: ROUTER_ERROR_RAISE,
                    errorCode: 404,
                });
            }
            return result;
        });
    };
}
export function prefetchLegal(slug) {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path(`/api/v2/legal/${slug}.json`),
            headers: api.headers(),
            types: [
                { type: GET_LEGAL_PENDING, meta: { slug } },
                { type: GET_LEGAL_FULFILLED, meta: { slug } },
                ROUTER_REJECTED,
            ],
            bailout: ({ legal }) => slug in legal,
        },
    };
}
export function prefetchApplicationLegal() {
    const slug = 'consent-to-privacy-notice';
    const legalPath = `/legal/${slug}.json`;
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: ({ config }) => (isBrowser ? legalPath : `${config.origin}${legalPath}`),
            headers: api.headers(),
            types: [
                { type: GET_LEGAL_PENDING, meta: { slug } },
                { type: GET_LEGAL_FULFILLED, meta: { slug } },
                ROUTER_REJECTED,
            ],
            bailout: ({ legal }) => slug in legal,
        },
    };
}
export function donate(offerId, amount) {
    return {
        [RSAA]: {
            method: 'POST',
            endpoint: api.path(`/api/v2/offers/${offerId}/pay.json`),
            headers: api.headers(),
            body: JSON.stringify({ amount }),
            types: [POST_OFFER_PAY_PENDING, POST_OFFER_PAY_FULFILLED, POST_OFFER_PAY_REJECTED],
        },
    };
}
export function createInviteRequest(invite_request) {
    return {
        [RSAA]: {
            method: 'POST',
            endpoint: api.path('/api/v2/invite_requests.json'),
            headers: api.headers(),
            body: JSON.stringify({ invite_request }),
            types: [POST_INVITE_REQUEST_PENDING, POST_INVITE_REQUEST_FULFILLED, POST_INVITE_REQUEST_REJECTED],
        },
    };
}
export function checkEmailCode(params) {
    return {
        [RSAA]: {
            method: 'POST',
            endpoint: api.path('/api/v2/users/email/check_code.json'),
            headers: api.headers(),
            body: JSON.stringify(params),
            types: [POST_INVITE_REQUEST_PENDING, POST_INVITE_REQUEST_FULFILLED, ROUTER_REJECTED],
            bailout: ({ emailConfirmed }) => emailConfirmed,
        },
    };
}
export function showClearingRequest(confirmation_token) {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path('/api/v2/users/me/clearing_request.json', { confirmation_token }),
            headers: api.headers(),
            types: [GET_CLEARING_REQUEST_PENDING, GET_CLEARING_REQUEST_FULFILLED, ROUTER_REJECTED],
            bailout: ({ clearingRequest }) => clearingRequest,
        },
    };
}
export function confirmClearingRequest(confirmation_token) {
    return {
        [RSAA]: {
            method: 'POST',
            endpoint: api.path('/api/v2/users/me/clearing_request/confirm.json'),
            headers: api.headers(),
            body: JSON.stringify({ confirmation_token }),
            types: [POST_CLEARING_REQUEST_CONFIRM_PENDING, POST_CLEARING_REQUEST_CONFIRM_FULFILLED, ROUTER_REJECTED],
            bailout: ({ clearingRequest }) => !clearingRequest || clearingRequest.confirmed,
        },
    };
}
export function cancelClearingRequest(confirmation_token) {
    return {
        [RSAA]: {
            method: 'DELETE',
            endpoint: api.path('/api/v2/users/me/clearing_request.json'),
            headers: api.headers(),
            body: JSON.stringify({ confirmation_token }),
            types: [DELETE_CLEARING_REQUEST_PENDING, DELETE_CLEARING_REQUEST_FULFILLED, DELETE_CLEARING_REQUEST_REJECTED],
        },
    };
}
export function prefetchShlomoStepCount() {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path('/api/v2/companies/226/stats.json'),
            headers: api.headers(),
            types: [{ type: GET_SHLOMO_STATS_PENDING }, { type: GET_SHLOMO_STATS_FULFILLED }, ROUTER_REJECTED],
        },
    };
}
export function prefetchChallenges() {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path('/api/v2/challenge_collections/sweatcoin-races/public_preview.json'),
            headers: api.headers(),
            types: [{ type: GET_CHALLENGES_PENDING }, { type: GET_CHALLENGES_FULFILLED }, ROUTER_REJECTED],
        },
    };
}
export function prefetchReferralCodeRequest(referralCode) {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path(`/api/v2/invite_request_referral_codes/${referralCode}.json`),
            headers: api.headers(),
            types: [
                { type: GET_REFERRAL_CODE_REQUEST_PENDING },
                { type: GET_REFERRAL_CODE_REQUEST_FULFILLED },
                ROUTER_REJECTED,
            ],
        },
    };
}
export function prefetchCrowdfundingCampaigns() {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path('/api/v2/offers/crowdfunding.json'),
            headers: api.headers(),
            types: [
                { type: GET_CROWDFUNFING_CAMPAIGNS_PENDING },
                { type: GET_CROWDFUNFING_CAMPAIGNS_FULFILLED },
                ROUTER_REJECTED,
            ],
        },
    };
}
export function prefetchInterPrideSteps() {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path('/api/v2/specials/pride22.json'),
            headers: api.headers(),
            types: [{ type: GET_INTERPRIDE_STEPS_PENDING }, { type: GET_INTERPRIDE_STEPS_FULFILLED }, ROUTER_REJECTED],
        },
    };
}
export function getClub(handle) {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path(`https://production.sweatco.in/landing/clubs/${handle}.json`),
            headers: api.headers(),
            types: [{ type: GET_CLUB_PENDING }, { type: GET_CLUB_FULFILLED }, GET_CLUB_REJECTED],
        },
    };
}
export function getSfwClub(invite_id) {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: api.path(`https://production.sweatco.in/landing/sfw_clubs/${invite_id}.json`),
            headers: api.headers(),
            types: [{ type: GET_SFW_CLUB_PENDING }, { type: GET_SFW_CLUB_FULFILLED }, GET_SFW_CLUB_REJECTED],
        },
    };
}
