import darthVader from '../assets/darth.jpg';
import forrestGump from '../assets/forrest.jpg';
import islandPath from '../assets/island_path.jpg';
import monumentValley from '../assets/monument_valley.jpg';
import michaelJackson from '../assets/moonwalker.jpg';
import nightStreet from '../assets/night_street.jpg';
import sonic from '../assets/sonic.jpg';
import tatooine from '../assets/tatooine.jpg';
export default {
    meta: {
        quizId: 'walker',
        label: 'QUIZ',
        title: 'What type of walker are you? 🤔',
        subtitle: 'Answer a few questions to find out!',
        startButtonTitle: 'Start',
        restartButtonTitle: 'Start again',
        startCoverType: 'embed',
        startCoverSrc: 'https://giphy.com/embed/l0K3Y5puOn54XvEic',
    },
    questions: [
        {
            title: 'Where would you walk?',
            embed: null,
            embedRatio: null,
            answers: [
                { title: 'Tatooine', img: tatooine, id: 'Skywalker' },
                { title: 'Picture with Monument Valley', img: monumentValley, id: 'Forrest' },
                { title: 'Night bar, night street ', img: nightStreet, id: 'Jackson' },
                { title: 'Island path ', img: islandPath, id: 'Sonic' },
            ],
        },
        {
            title: 'What would you wear?',
            embed: 'https://giphy.com/embed/KXdCe1y4mSFSo',
            embedRatio: '56.25%',
            answers: [
                { title: 'A black cloak', img: null, id: 'Skywalker' },
                { title: 'A red cap', img: null, id: 'Forrest' },
                { title: 'A white hat', img: null, id: 'Jackson' },
                { title: 'Red sneakers', img: null, id: 'Sonic' },
            ],
        },
        {
            title: 'What snack would you choose? ',
            embed: 'https://giphy.com/embed/2sjwYgxfmCtgfPPGPi',
            embedRatio: '56.25%',
            answers: [
                { title: 'I love it fried. So french fries', img: null, id: 'Skywalker' },
                { title: 'Two ice-cream cones', img: null, id: 'Forrest' },
                { title: 'Candies to share', img: null, id: 'Jackson' },
                { title: 'Apples are the best for to-go', img: null, id: 'Sonic' },
            ],
        },
        {
            title: 'What sport do you like?',
            embed: 'https://giphy.com/embed/WsjvRxj8RRxYZgIzzI',
            embedRatio: '56.25%',
            answers: [
                { title: 'Fencing', img: null, id: 'Skywalker' },
                { title: 'Running', img: null, id: 'Forrest' },
                { title: 'Dancing', img: null, id: 'Jackson' },
                { title: 'Cybersport', img: null, id: 'Sonic' },
            ],
        },
        {
            title: 'What track would you listen to while walking? ',
            embed: 'https://giphy.com/embed/xT1Ra6ZgrskZ6anxBu',
            embedRatio: '75%',
            answers: [
                { title: 'Mozart - Requiem in D minor', img: null, id: 'Skywalker' },
                { title: 'The Beatles - All You Need is Love', img: null, id: 'Forrest' },
                { title: 'David Bowie - China Girl', img: null, id: 'Jackson' },
                { title: 'Avicii - Wake Me Up', img: null, id: 'Sonic' },
            ],
        },
        {
            title: 'Who would you take for a walk?',
            embed: 'https://giphy.com/embed/RdZznPAiPhc6vHfsL3',
            embedRatio: '56.25%',
            answers: [
                { title: 'Someone with the same goal as mine', img: null, id: 'Skywalker' },
                { title: 'Someone who can motivate me', img: null, id: 'Forrest' },
                { title: 'I walk alone', img: null, id: 'Jackson' },
                { title: 'Someone who can keep a fast pace', img: null, id: 'Sonic' },
            ],
        },
        {
            title: 'What is your life motto?',
            embed: 'https://giphy.com/embed/13pdBSoCcDejrG',
            embedRatio: '41%',
            answers: [
                { title: 'Sometimes we have to be in the dark in order to see the light', img: null, id: 'Skywalker' },
                { title: 'What’s normal, anyways?', img: null, id: 'Forrest' },
                { title: 'Live life to express, not to impress', img: null, id: 'Jackson' },
                { title: 'Life is a marathon, not a sprint - pace yourself accordingly', img: null, id: 'Sonic' },
            ],
        },
    ],
    results: {
        Skywalker: {
            img: darthVader,
            title: 'Anakin Skywalker',
            description: 'You’re Skywalker. You’re easily switching between light and dark sides of the Force, but technically speaking it doesn’t matter. One can’t walk without applying force, right?',
            callToAction: 'Download Sweatcoin to turn to another side of the Force 💫',
            shareTitle: 'I am Skywalker! What type of walker are you? 🤔',
            shareUrl: 'https://sweatco.in/quiz/walker/skywalker',
        },
        Forrest: {
            img: forrestGump,
            title: 'Forrest Gump',
            description: 'You’re Forrest Gump. You’re a good soul and you feel that time and space are barriers between people and a brighter future. So you run. We love you.',
            callToAction: 'Run with Sweatcoin and get a box of chocolates 🏃🏻',
            shareTitle: 'I am Forrest Gump! What type of walker are you? 🤔',
            shareUrl: 'https://sweatco.in/quiz/walker/forrest-gump',
        },
        Jackson: {
            img: michaelJackson,
            title: 'Moonwalker',
            description: "You're a Moonwalker. You hate cliches and you find it boring to just walk, so you walk backwards whilst defying gravity. We don't know what brought you here, but we're inspired!",
            callToAction: 'Dance with Sweatcoin and get rewards for your moonsteps 🎶',
            shareTitle: 'I am Moonwalker! What type of walker are you? 🤔',
            shareUrl: 'https://sweatco.in/quiz/walker/moonwalker',
        },
        Sonic: {
            img: sonic,
            title: 'Sonic The Hedgehog',
            description: 'You’re Sonic. You’re a hurricane of energy that can be in several places at the same time. Your friends wonder how you manage to do everything in such little time. We’ll keep your secret. Proceed to the next level.',
            callToAction: 'Let’s blast through with Sweatcoin 👾',
            shareTitle: 'I am Sonic The Hedgehog! What type of walker are you? 🤔',
            shareUrl: 'https://sweatco.in/quiz/walker/sonic',
        },
    },
};
