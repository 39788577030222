import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
import AnswersList from './AnswersList';
import Credits from './Credits';
const Question = (props) => {
    const [isLoad, setIsLoad] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.answers]);
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            props.embed ? (React.createElement(EmbedWrapper, { embedRatio: props.embedRatio ? props.embedRatio : null },
                React.createElement(Preload, { isShow: isLoad },
                    React.createElement("div", null),
                    React.createElement("div", null),
                    React.createElement("div", null)),
                React.createElement(Embed, { isLoad: isLoad, src: props.embed, frameBorder: "0", onLoad: () => setIsLoad(true) }))) : null,
            props.cover ? (React.createElement(Cover, null,
                React.createElement(MobileImg, { src: props.cover.mobile }),
                React.createElement(DesktopImg, { src: props.cover.desktop }),
                props.credits ? React.createElement(StyledCredits, { credits: props.credits }) : null)) : null,
            React.createElement(Label, null,
                "QUESTION ",
                props.answerNumber,
                "/",
                props.quizLength),
            React.createElement(Title, null, props.title)),
        React.createElement(AnswersList, { answers: props.answers, onAnswerClick: props.onAnswerClick })));
};
const Container = styled.div ``;
const Header = styled.div ``;
const Label = styled.div `
  font-family: ${FONTS.SimplonMono.name}, ${FONTS.fallback};
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 3px;
  margin-top: 43px;
  margin-bottom: 13px;

  ${media.tablet `
    font-size: 20px;
    line-height: 24px;
    margin-top: 49px;
    margin-bottom: 18px;
  `};
`;
const EmbedWrapper = styled.div `
  position: relative;
  height: 0;
  overflow: hidden;
  margin: 20px 0 33px;
  background-color: #000;

  ${(props) => props.embedRatio
    ? `padding-bottom: ${props.embedRatio}`
    : 'padding-bottom: 56.25%'}; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  ${media.tablet `
    padding-bottom: 273px;
  `};
`;
const Embed = styled.iframe `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${(props) => (props.isLoad ? 'display: block' : 'display: none')};
`;
const Cover = styled.div `
  margin: 20px auto 33px;
  position: relative;

  ${media.tablet `
    width: 667px;
  `};
`;
const StyledCredits = styled(Credits) `
  display: none;

  ${media.tablet `
    display: block;
  `};
`;
const MobileImg = styled.img `
  display: block;
  width: 100%;

  ${media.tablet `
    display: none;
  `};
`;
const DesktopImg = styled.img `
  display: none;

  ${media.tablet `
    display: block;
    width: 100%;
  `};
`;
const Title = styled.h1 `
  font-family: ${FONTS.SimplonMono.name}, ${FONTS.fallback};
  font-size: 28px;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 20px;

  ${media.tablet `
    font-size: 44px;
    line-height: 64px;
    margin-bottom: 41px;
  `};
`;
const preloadAnimation = keyframes `
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Preload = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 80px;
  height: 80px;
  opacity: 0.2;
  ${(props) => (props.isLoad ? 'display: none' : 'display: block')};

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: ${preloadAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
export default Question;
