import mapKeys from 'lodash/mapKeys';
import ChallengeOffer from './ChallengeOffer/ChallengeOffer';
import Competition from './Competition/Competition';
import ExceedingAverageAchievement from './ExceedingAverageAchievement/ExceedingAverageAchievement';
import FirstAchievement from './FirstAchievement/FirstAchievement';
import StreakAchievement from './StreakAchievement/StreakAchievement';
const Rooms = {
    Competition,
    ChallengeOffer,
    FirstAchievement,
    StreakAchievement,
    ExceedingAverageAchievement,
};
export default mapKeys(Rooms, (_, key) => key.toLowerCase());
