import { AUTHENTICATION_LOGIN_FULFILLED, AUTHENTICATION_LOGOUT_FULFILLED, SESSION_UPDATE } from 'constants/ActionTypes';
const COOKIE_NAME = 'sweatcoin_session';
const EXPIRE_INTERVAL = 10 * 365 * 24 * 60 * 60 * 1000;
let cookie;
if (process.browser) {
    cookie = require('cookies-js');
}
export function updateSession(headers, request, shouldRemeber = false) {
    let expires = new Date();
    expires.setTime(expires.valueOf() + EXPIRE_INTERVAL);
    let sessionHeaders = extractSession(request);
    sessionHeaders = Object.assign({}, sessionHeaders, headers, shouldRemeber ? { remember: true } : null);
    setSession(sessionHeaders, sessionHeaders.remember ? { expires } : null, request);
}
export function setRemember(request) {
    let sessionHeaders = extractSession(request);
    let expires = new Date();
    expires.setTime(expires.valueOf() + EXPIRE_INTERVAL);
    sessionHeaders = Object.assign({}, sessionHeaders, {
        remember: true,
    });
    setSession(sessionHeaders, { expires }, request);
}
export function clearSession() {
    if (process.browser) {
        cookie.expire(COOKIE_NAME);
    }
}
export function setSession(value, options, request) {
    if (process.browser) {
        cookie.set(COOKIE_NAME, JSON.stringify(value), options);
    }
    else if (request) {
        request.cookies.set(COOKIE_NAME, encodeURIComponent(JSON.stringify(value)), Object.assign({}, options, { httpOnly: false }));
    }
}
export function extractSession(request) {
    if (process.browser) {
        let sessionHeaders = cookie.get(COOKIE_NAME);
        if (sessionHeaders) {
            sessionHeaders = JSON.parse(sessionHeaders);
        }
        return sessionHeaders;
    }
    else if (request) {
        let sessionHeaders = request.cookies.get(COOKIE_NAME);
        if (sessionHeaders) {
            sessionHeaders = JSON.parse(decodeURIComponent(sessionHeaders));
        }
        return sessionHeaders;
    }
    return null;
}
const sessionMiddleware = () => {
    return (next) => (action) => {
        if (action && action.type === SESSION_UPDATE) {
            updateSession(action.headers);
        }
        else if (action && action.type === AUTHENTICATION_LOGOUT_FULFILLED) {
            clearSession();
            window.location.replace('/');
        }
        else if (action && action.type === AUTHENTICATION_LOGIN_FULFILLED && action.meta.remember_me) {
            setRemember();
        }
        return next(action);
    };
};
export default sessionMiddleware;
