import queryString from 'query-string';
import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import platformDetector from 'utils/platform';
const GetRedirect = () => {
    useEffect(() => {
        window.location = getRedirectLink();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'getlink' }] })));
    function getPlatform() {
        return platformDetector(window.navigator.userAgent || window.navigator.vendor || window.opera);
    }
    function getRedirectLink() {
        const visitorPlatform = getPlatform();
        if (visitorPlatform === 'iOS') {
            return `https://itunes.apple.com/app/apple-store/id971023427?${queryString.stringify({
                pt: '117705952',
                ct: 'getlink',
                mt: 8,
            })}`;
        }
        else if (visitorPlatform === 'Android') {
            return `https://play.google.com/store/apps/details?${queryString.stringify({
                id: 'in.sweatco.app',
                referrer: queryString.stringify({
                    utm_source: 'website',
                    utm_campaign: 'getlink',
                }),
            })}`;
        }
        else {
            return '/';
        }
    }
};
export default GetRedirect;
