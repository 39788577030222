import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { media } from 'components/responsive';
import TEAM from 'constants/Team';
import { PRINCIPLES, SectionTitle, responsiveContainer, sectionTopOffset } from './common';
const messages = defineMessages({
    title: 'Our Team',
    description: 'We are an ever-growing team of outstanding individuals who are always eager to learn. We come from <strong>all over the world</strong> — UK, Russia, Azerbajan, China, South Africa, France, Kazakhstan, Belarus, Poland, Romania, Spain, Italy, Greece, Turkey, Netherlands, Philippines.{br} Moreover we speak <strong>22 languages</strong> English, Russian, Azerbajan, Belarusian, Cantonese, Dutch, French, German, Greek, Hebrew, Indonesian, Italian, Kazakh, Mandarin, Pakistani, Polish, Portuguese, Romanian, Spanish, Tagalog, Turkish, Urdu',
});
const Team = () => {
    const [team, setTeam] = useState([]);
    useEffect(() => {
        setTeam(_.shuffle(TEAM));
    }, []);
    return (React.createElement(Container, null,
        React.createElement(Card, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { ...messages.title })),
            React.createElement(Description, null,
                React.createElement(FormattedMessage, { ...messages.description }))),
        team.map((member) => (React.createElement(Member, { key: member.image, member: member })))));
};
const MEMBER_INFO_BACKGROUNDS = PRINCIPLES.map((principle) => principle.background);
const getRandomInfoBackground = () => MEMBER_INFO_BACKGROUNDS[Math.floor(Math.random() * MEMBER_INFO_BACKGROUNDS.length)];
const MEMBER_INFO_VISIBILITY = {
    HIDDEN: 0,
    VISIBLE: 1,
    FLIPPED: 2,
};
const Member = ({ member }) => {
    const { image, name, position, pets } = member;
    const [isInfoVisible, setInfoVisibility] = useState();
    const [infoBackground, setInfoBackground] = useState();
    const [activePetImage, setActivePetImage] = useState(null);
    return (React.createElement(TeamMember, { key: image, onMouseEnter: (e) => {
            const { currentTarget } = e;
            const { parentNode } = currentTarget;
            const padding = Number.parseInt(window.getComputedStyle(parentNode, null).getPropertyValue('padding-right'));
            const { right } = currentTarget.getBoundingClientRect();
            const { right: parentRight } = parentNode.getBoundingClientRect();
            setInfoVisibility(right >= parentRight - padding - 1 ? MEMBER_INFO_VISIBILITY.FLIPPED : MEMBER_INFO_VISIBILITY.VISIBLE);
            setInfoBackground(getRandomInfoBackground());
        }, onMouseLeave: () => setInfoVisibility(MEMBER_INFO_VISIBILITY.HIDDEN) },
        React.createElement(MemberImage, { key: image, style: { backgroundImage: activePetImage ? `url(${activePetImage})` : `url(${image})` } }),
        React.createElement(MemberInfo, { background: infoBackground, isVisible: isInfoVisible },
            React.createElement(MemberName, null, name),
            React.createElement(MemberPosition, null, position),
            pets ? getPets(pets) : null)));
    function getPets(pets) {
        return (React.createElement(Pets, null, pets.map((pet, index) => (React.createElement(Pet, { key: index, onMouseEnter: () => {
                setActivePetImage(pet.image);
            }, onMouseLeave: () => setActivePetImage(null) },
            pet.type,
            React.createElement(PetPreload, { bg: pet.image }))))));
    }
};
const Container = styled.div `
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  ${responsiveContainer};
  ${sectionTopOffset};

  ${media.tablet `grid-template-columns: repeat(5, 1fr);`};
  ${media.desktop `
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;
  `};
  ${media.desktop_medium `
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 20px;
  `};
  ${media.desktop_large `
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 10px;
  `};
`;
const Card = styled.div `
  grid-column: span 3 / auto;

  ${media.tablet `grid-column: span 5 / auto;`};
  ${media.desktop `
    grid-column: span 4 / auto;
    grid-row: span 2 / auto;
  `};
  ${media.desktop_medium `grid-column: span 5 / auto;`};
`;
const Title = styled(SectionTitle) `
  margin-bottom: 0.5rem;

  ${media.tablet `margin-bottom: 0.5rem;`};
`;
const Description = styled.div `
  font-size: 0.9rem;
  margin-bottom: 1rem;
  line-height: 1.3rem;
  ${media.tablet `
    font-size: 0.7rem;
    width: 75%;
  `};
  ${media.desktop `
    line-height: 1.5rem;
    font-size: 0.9rem;
    width: 90%;
  `};
`;
const TeamMember = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.tablet `margin-bottom: 0.5rem;`};
`;
const MemberImage = styled.div `
  border-radius: 50%;
  width: 100%;
  height: 0;
  background-size: cover;
  padding-bottom: 100%;
`;
const MemberInfo__background_default = '#D9D5E2';
const MemberInfo = styled.div `
  color: #ffffff;
  margin-top: 0.47rem;
  padding: 0 13px 0.6rem;
  background: ${(props) => props.background || MemberInfo__background_default};
  position: absolute;
  top: 80%;
  ${(props) => (props.isVisible === MEMBER_INFO_VISIBILITY.FLIPPED ? 'right' : 'left')}: 50%;
  z-index: 1;
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  font-size: 22px;
  min-width: 6.35em;

  &::before {
    position: absolute;
    top: 0;
    ${(props) => (props.isVisible === MEMBER_INFO_VISIBILITY.FLIPPED ? 'right' : 'left')}: 0;
    transform: translateY(-100%);
    content: '';
    width: 0;
    height: 0;
    margin-top: 1px;
    border-bottom: 16px solid ${(props) => props.background || MemberInfo__background_default};
    ${(props) => props.isVisible === MEMBER_INFO_VISIBILITY.FLIPPED
    ? 'border-left: 9px solid transparent;'
    : 'border-right: 9px solid transparent;'}
  }

  ${media.desktop_medium `
    font-size: 27px;
  `};
  ${media.desktop_large `
    font-size: 34px;
  `};
`;
const MemberName = styled.div `
  padding-top: 0.5rem;
  margin-bottom: 0.2rem;
  font-size: 1em;
  line-height: 1.2;
  font-weight: bold;

  ${media.desktop_medium `font-size: 1.06rem;`};
`;
const MemberPosition = styled.div `
  font-size: 0.55em;
  line-height: 1;
`;
const Pets = styled.div `
  display: flex;
  margin-top: 0.2rem;
`;
const Pet = styled.div `
  padding: 5px 5px 0 0;
  font-size: 0.75em;
  border-radius: 50%;
  cursor: default;
`;
const PetPreload = styled.div `
  background: url(${({ bg }) => bg});
  width: 1px;
  height: 1px;
  opacity: 0;
  position: absolute;
`;
export default Team;
