import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import nhs from 'assets/Health/case.jpg';
import bg from 'assets/Health/helpsBg.jpg';
import legs from 'assets/Health/legs.png';
import hand from 'assets/Health/link_hand.png';
import { media } from 'components/responsive';
const messages = defineMessages({
    title: 'Partner Case Study',
    blockTitle: '‘Decathlon’ NHS Study on Pre-Diabetes Patients, South-West London 2021- ongoing',
    item1: 'The average user increased their step-count by <strong>45%</strong>',
    item2: 'Average weight loss of <strong>3kg</strong> (some participants losing up to <strong>10kg</strong>)',
    item3: '<strong>93%</strong> trial completion (vs. <40% average)',
    item4: '<strong>75%</strong> ethnic minority participation',
    item5: 'Trial currently being expanded in size and to patients at risk of cardiovascular disease',
    readMore: 'Read more about the trial results',
    here: 'here',
});
const CaseStudy = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { ...messages.title })),
        React.createElement(Layout, null,
            React.createElement(TopPart, null,
                React.createElement(BlockTitle, null,
                    React.createElement(FormattedMessage, { ...messages.blockTitle })),
                React.createElement(Legs, null),
                React.createElement(NHS, null)),
            React.createElement(Items, null,
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item1 })),
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item2 })),
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item3 })),
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item4 })),
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item5 }))),
            React.createElement(Link, null,
                React.createElement(Hand, null),
                React.createElement("div", null,
                    React.createElement(FormattedMessage, { ...messages.readMore }),
                    "\u00A0",
                    React.createElement("a", { href: "https://www.healthtechdigital.com/nhs-sweatcoin-partner-to-roll-out-an-expanded-new-diabetes-prevention-programme-to-tackle-10-bn-year-problem/", target: "_blank", rel: "noreferrer" },
                        React.createElement(FormattedMessage, { ...messages.here })))))));
};
const Title = styled.h2 `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #191841;
  text-align: center;
  margin-bottom: 105px;

  ${media.desktop `
    margin-bottom: 120px;
  `};
`;
const Layout = styled.div `
  background: url(${bg}) no-repeat;
  background-size: cover;
  border-radius: 50px;
  position: relative;
  padding: 165px 16px 50px;
  margin-bottom: 50px;

  ${media.desktop `
    padding: 50px 90px 50px;
    margin-bottom: 164px;
  `};
`;
const TopPart = styled.div `
  display: flex;
  gap: 26px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 22px;
`;
const BlockTitle = styled.h3 `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  color: rgba(25, 24, 65, 0.8);
  max-width: 440px;
  padding: 0 16px;

  ${media.desktop `
    font-size: 30px;
    line-height: 1.34;
  `};
`;
const Legs = styled.div `
  width: 301px;
  height: 173px;
  background: url(${legs}) no-repeat;
  background-size: contain;
  display: none;

  ${media.desktop `
    display: block;
  `};
`;
const NHS = styled.div `
  position: absolute;
  width: 329px;
  height: 190px;
  background: url(${nhs}) no-repeat;
  background-size: contain;
  top: -74px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 21px;

  ${media.desktop `
    width: 495px;
    height: 281px;
    top: -89px;
    left: 930px;
    transform: none;
  `};
`;
const Items = styled.div `
  display: flex;
  justify-content: center;
  gap: 50px;
  position: relative;
  z-index: 1;
  margin-bottom: 36px;
  overflow: hidden;
  counter-set: item 0;
  flex-direction: column;

  ${media.desktop `
    flex-direction: row;
    gap: 20px;
  `};
`;
const Item = styled.div `
  flex: 1;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #191841;
  position: relative;
  padding-left: 70px;
  padding-right: 30px;

  ${media.desktop `
    padding: 0;
    font-size: 18px;
    line-height: 30px;
  `};

  &::before {
    content: counter(item);
    counter-increment: item;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(78.36deg, #ffffff -20.31%, #ffac65 16.95%, #854bff 77.8%);
    margin: 0 auto 20px;
    position: absolute;
    left: 0;
    top: 0;

    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: #ffffff;

    ${media.desktop `
      width: 60px;
      height: 60px;
      position: relative;
    `};
  }

  &::after {
    top: 0;
    left: 18px;
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: calc(100% + 50px);
    z-index: -1;
    border-left: 1px solid #553caa;
    border-right: 1px solid #553caa;

    ${media.desktop `
      top: 28px;
      left: 0;
      height: 4px;
      width: calc(100% + 20px);
      border: none;
      border-top: 1px solid #553caa;
      border-bottom: 1px solid #553caa;
    `};
  }

  &:first-child::before {
    margin: 0 0 20px;
  }

  &:last-child::after {
    display: none;

    ${media.desktop `
      display: block;
      width: 50%;
    `};
  }

  strong {
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
  }
`;
const Link = styled.div `
  display: flex;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  align-items: center;
  color: #032555;
  gap: 30px;

  a {
    text-decoration: underline;
  }
`;
const Hand = styled.div `
  width: 64px;
  height: 64px;
  background: url(${hand}) no-repeat;
  background-size: contain;
  flex: none;
`;
export default CaseStudy;
