import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import coins from 'assets/Health/helps.png';
import bg from 'assets/Health/helpsBg.jpg';
import { media } from 'components/responsive';
const messages = defineMessages({
    title1: 'How Sweatcoin helps',
    description1: 'The free Sweatcoin app has inspired more than <strong>150 million people</strong> worldwide to move more via behavioral nudges and real-time incentives.',
    link: 'The most downloaded health and fitness app',
    description1_1: 'globally in 2022, it is improving the health and mental wellbeing of people around the world, one step at a time.',
    title2: 'How it works',
    description2: 'Steps are rewarded with ‘sweatcoins’, a loyalty currency that can be redeemed for a host of products and services in the in-app marketplace or donated to global charities. Step-tracking technology, community gamification and compelling rewards all combine to provide the critical nudges required to increase daily movement rates',
    walk: 'Sweatcoin users walk <strong>20%</strong> more than they did prior to using the app.',
});
const Helps = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, null,
            React.createElement(Item, null,
                React.createElement(Title, null,
                    React.createElement(FormattedMessage, { ...messages.title1 })),
                React.createElement(Description, null,
                    React.createElement(FormattedMessage, { ...messages.description1 }),
                    React.createElement("a", { href: "https://www.businessofapps.com/data/most-popular-apps/", target: "_blank", rel: "noreferrer" },
                        React.createElement(FormattedMessage, { ...messages.link })),
                    "\u00A0",
                    React.createElement(FormattedMessage, { ...messages.description1_1 }))),
            React.createElement(Item, null,
                React.createElement(Title, null,
                    React.createElement(FormattedMessage, { ...messages.title2 })),
                React.createElement(Description, null,
                    React.createElement(FormattedMessage, { ...messages.description2 })))),
        React.createElement(Highlight, null,
            React.createElement("a", { href: "https://warwick.ac.uk/newsandevents/pressreleases/unfit_people_are/#:~:text=The%20University%20of%20Warwick%20found%20that%20an%20economy%20built%20on,%2C%20these%20are%20significant%20findings.%E2%80%9D", target: "_blank", rel: "noreferrer" },
                React.createElement(FormattedMessage, { ...messages.walk })))));
};
const Layout = styled.div `
  background: url(${bg}) no-repeat;
  background-size: cover;
  border-radius: 50px;
  padding: 120px 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 40px;

  ${media.desktop `
    padding: 50px 90px;
    gap: 145px;
    justify-content: space-between;
    flex-direction: row;
  `};

  &::before {
    content: '';
    position: absolute;
    width: 235px;
    height: 164px;
    display: block;
    background: url(${coins}) no-repeat;
    background-size: contain;
    top: -50px;
    right: 0;

    ${media.desktop `
      width: 307px;
      height: 211px;
      top: -94px;
      right: -30px;
    `};
  }
`;
const Item = styled.div `
  flex: 1;
`;
const Title = styled.h2 `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #191841;
  margin-bottom: 40px;

  ${media.desktop `
    line-height: 1.5;
    margin-bottom: 78px;
  `};
`;
const Description = styled.p `
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #191841;

  a {
    text-decoration: underline;
  }
`;
const Highlight = styled.div `
  margin: 60px auto;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 45px;
  color: #191841;

  ${media.desktop `
    margin: 90px auto;
    text-align: center;
    font-size: 26px;
    line-height: 2;
  `};

  strong {
    font-weight: 800;
    font-size: 140px;
    line-height: 100%;
    letter-spacing: -4px;
    text-transform: uppercase;
    background: #6202c0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    position: relative;
    top: 20px;
    display: block;
    margin-bottom: 25px;
    margin-top: -10px;

    ${media.desktop `
      font-size: 60px;
      line-height: 1;
      display: inline-block;
      margin: 0;
      transform: translateY(-8px);
    `};
  }
`;
export default Helps;
