import { SYNC_TIME } from 'constants/ActionTypes';
const timeReducer = function (state = null, action) {
    switch (action.type) {
        case SYNC_TIME: {
            return action.data;
        }
        default:
            return state;
    }
};
export default timeReducer;
