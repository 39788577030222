import { RSAA } from 'redux-api-middleware';
import { ADD_APPLICANT_TO_VACANCY_FULLFILLED, ADD_APPLICANT_TO_VACANCY_PENDING, ADD_APPLICANT_TO_VACANCY_REJECTED, APPLICATION_FORM_UPDATED, GET_JOB_OPENINGS_FULLFILLED, GET_JOB_OPENINGS_PENDING, GET_JOB_OPENINGS_REJECTED, GET_LOCATIONS_FULLFILLED, GET_LOCATIONS_PENDING, GET_LOCATIONS_REJECTED, GET_TEAMS_FULLFILLED, GET_TEAMS_PENDING, GET_TEAMS_REJECTED, GET_VACANCY_FULLFILLED, GET_VACANCY_PENDING, ROUTER_REJECTED, SAVE_APPLICANT_FULLFILLED, SAVE_APPLICANT_PENDING, SAVE_APPLICANT_REJECTED, SAVE_FILE_FULLFILLED, SAVE_FILE_PENDING, SAVE_FILE_REJECTED, } from 'constants/ActionTypes';
const GET_VACANCIES = '/huntflow/vacancies?opened=true&count=100';
const GET_TEAMS = '/huntflow/dictionaries/team_sweatcoin';
const GET_LOCATIONS = '/huntflow/dictionaries/city_sweatcoin';
const GET_VACANCY = (vacancyId) => `/huntflow/vacancies/${vacancyId}`;
const SAVE_APPLICANT = '/huntflow/applicants';
const SAVE_FILE = '/huntflow/upload';
const ADD_APPLICANT_TO_VACANCY = (applicantId) => `/huntflow/applicants/${applicantId}/vacancy`;
// Prepare URL (client or server)
const buildUrl = (url) => {
    if (typeof window !== 'undefined') {
        return url;
    }
    else {
        return ({ config }) => `http://localhost:${config.port}${url}`;
    }
};
export function fetchJobOpenings() {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: buildUrl(GET_VACANCIES),
            types: [GET_JOB_OPENINGS_PENDING, GET_JOB_OPENINGS_FULLFILLED, GET_JOB_OPENINGS_REJECTED],
            bailout: ({ huntflow }) => {
                return huntflow && huntflow.jobOpenings;
            },
        },
    };
}
export function fetchVacancy(vacancyId) {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: buildUrl(GET_VACANCY(vacancyId)),
            types: [GET_VACANCY_PENDING, GET_VACANCY_FULLFILLED, ROUTER_REJECTED],
        },
    };
}
export function fetchTeams() {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: buildUrl(GET_TEAMS),
            types: [GET_TEAMS_PENDING, GET_TEAMS_FULLFILLED, GET_TEAMS_REJECTED],
        },
    };
}
export function fetchLocations() {
    return {
        [RSAA]: {
            method: 'GET',
            endpoint: buildUrl(GET_LOCATIONS),
            types: [GET_LOCATIONS_PENDING, GET_LOCATIONS_FULLFILLED, GET_LOCATIONS_REJECTED],
        },
    };
}
export function saveFile(file) {
    const data = new FormData();
    data.append('file', file);
    return {
        [RSAA]: {
            method: 'POST',
            endpoint: buildUrl(SAVE_FILE),
            types: [SAVE_FILE_PENDING, SAVE_FILE_FULLFILLED, SAVE_FILE_REJECTED],
            body: data,
        },
    };
}
export function saveApplicant(applicant) {
    return {
        [RSAA]: {
            method: 'POST',
            endpoint: buildUrl(SAVE_APPLICANT),
            headers: {
                'Content-type': 'application/json',
            },
            types: [SAVE_APPLICANT_PENDING, SAVE_APPLICANT_FULLFILLED, SAVE_APPLICANT_REJECTED],
            body: JSON.stringify(applicant),
        },
    };
}
export function addAplicantToVacancy({ applicantId, vacancyId, files = [] }) {
    return {
        [RSAA]: {
            method: 'POST',
            endpoint: buildUrl(ADD_APPLICANT_TO_VACANCY(applicantId)),
            headers: {
                'Content-type': 'application/json',
            },
            types: [ADD_APPLICANT_TO_VACANCY_PENDING, ADD_APPLICANT_TO_VACANCY_FULLFILLED, ADD_APPLICANT_TO_VACANCY_REJECTED],
            body: JSON.stringify({
                vacancy: vacancyId,
                status: 137198, // screening status
                files,
            }),
        },
    };
}
export const formUpdated = () => ({
    type: APPLICATION_FORM_UPDATED,
});
