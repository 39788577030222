import React from 'react';
import '../styles/sponsor';
export default class Sponsor extends React.Component {
    render() {
        return (React.createElement("div", { className: "sponsor" },
            React.createElement("div", { className: "sponsor__container" },
                this.props.content.logo ? (React.createElement("img", { className: "sponsor__thumb", src: this.props.content.logo.thumb, alt: "Sponsor image" })) : null,
                React.createElement("div", { className: "sponsor__title" }, this.props.content.title),
                React.createElement("div", { className: "sponsor__subtitle" }, this.props.content.subtitle),
                React.createElement("div", { className: "sponsor__clear" }),
                this._renderDetails())));
    }
    _renderDetails() {
        if (this.props.content.details && this.props.content.details.length > 0) {
            return (React.createElement("div", { className: "offer__redemption" },
                React.createElement("div", null, this.props.content.details.map(this._renderDetail.bind(this)))));
        }
        else {
            return null;
        }
    }
    _renderDetail(model, index) {
        return (React.createElement("div", { key: index },
            React.createElement("div", null, model.title),
            React.createElement("div", null,
                model.image ? React.createElement("img", { className: "sponsor__thumb", src: model.image.thumb, alt: "Sponsor image" }) : React.createElement("div", null),
                React.createElement("div", { className: "sponsor__subtitle" }, model.body),
                React.createElement("div", { className: "sponsor__clear" }))));
    }
}
