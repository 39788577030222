import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { media } from 'components/responsive';
import altfi from 'assets/Health/altfi.jpg';
import healthtech50 from 'assets/Health/healthtech50.webp';
import tr100 from 'assets/Health/tr100.png';
import htn from 'assets/Health/htn.jpg';
import htw from 'assets/Health/htw.png';
const messages = defineMessages({
    title: 'Awards & Recognition',
});
const Awards = () => {
    return (React.createElement("div", null,
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { ...messages.title })),
        React.createElement(BadgesCollection, null,
            React.createElement(Badge, { src: altfi, width: "240px", alt: "" }),
            React.createElement(Badge, { src: healthtech50, width: "320px", alt: "" }),
            React.createElement(Badge, { src: tr100, width: "320px", alt: "" }),
            React.createElement(Badge, { src: htn, width: "200px", alt: "" }),
            React.createElement(Badge, { src: htw, width: "160px", alt: "" }))));
};
const Title = styled.h2 `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #191841;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 40px;

  ${media.tablet `
    padding: 0;
  `}
`;
const BadgesCollection = styled.div `
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 72px;
`;
const Badge = styled.img `
  object-fit: contain;
`;
export default Awards;
