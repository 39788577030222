import url from 'url';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import bg from 'assets/Clubs/bg.jpg';
import bgMobile from 'assets/Clubs/bg_mobile.jpg';
import sharingSrc from 'assets/Clubs/invite_share.jpg';
import phone from 'assets/Clubs/promo.png';
import phone_mobile from 'assets/Clubs/promo_mobile.png';
import logo_src from 'assets/Home/header__logo.svg';
import googleplaySrc from 'assets/Home/home_googleplay.png';
import appstoreSrc from 'assets/Leaderboard/appstore_icon.svg';
import OutboundLink from 'components/OutboundLink';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { getFirstLetter, getFirstName } from 'utils/user';
import { useFirebaseEssentials } from '../utils/firebase';
import { link } from '../../Home/components/AppLinks';
import DesktopView from '../components/DesktopView';
const messages = defineMessages({
    regularTitle: 'Join {clubname} and earn {rewards} for your steps',
    regularSubtitle: 'Download Sweatcoin and Join {name}’s club, turn your steps into sweatcoins and spend them on awesome products or donate them to charity.',
    generalTitle: 'Join clubs and earn {rewards} for your steps',
    generalSubtitle: 'Download Sweatcoin and Join clubs, turn your steps into sweatcoins and spend them on awesome products or donate them to charity.',
    influencerTitle: 'Get rewards for walking in my Club',
    influencerSubtitle: 'Download Sweatcoin and Join {name}’s club, turn your steps into sweatcoins and spend them on awesome products or donate them to charity.',
    note: '*By accepting an invite to a Club your step count and username will be publicly visible to other users on sweatcoin. View our terms and conditions {tcLink}. You can also download sweatcoin without joining a Club by {downloadLink}.',
    desktopRegularTitle: 'Open the link on your phone and earn {rewards} for your steps',
    generalDesktopTitle: 'Join clubs and earn {rewards} for your steps',
    influencerDesktopTitle: 'Open the link on your phone and get {rewards} for walking',
    desktopRegularSubtitle: 'You need to open the invitation link on your mobile device. Please copy the link or scan the QR code with your phone.',
    joinBtnTitle: 'Join the Club',
    influencerJoinBtnTitle: 'Join my Club',
    generalJoinBtnTitle: 'Join Clubs',
    downloadButtonTitle: 'Download Sweatcoin App',
    desktopButtonTitle: 'Copy the Link',
    copyButtonTitle: 'Link copied',
    appstoreName: 'Apple App Store',
    appstoreRating: '(273k ratings)',
    googleplayName: 'Google play',
    googleplayRating: '(1.8M ratings)',
});
const clubsSelector = (state) => state.clubs;
const InviteClubPage = (props) => {
    const { match, location } = props;
    const { params } = match;
    const dispatch = useDispatch();
    const [clubState, setClubState] = useState();
    useEffect(() => {
        async function fetch() {
            const club = await dispatch(ApiActions.getClub(params.handle));
            setClubState(club.error ? !club.error : club);
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const club = useSelector(clubsSelector);
    const clubname = get(club, 'title');
    const owner = get(club, 'owner');
    const avatar = owner ? get(owner, 'avatar') : null;
    const name = owner ? getFirstName(owner) : null;
    const firstLetter = owner ? getFirstLetter(owner) : null;
    const { firebaseData: { firebaseQuery, getCopyLink, getRedirectLink }, tracking: { channel, campaign, feature, platform }, buttonsRefs: [buttonRef, downloadButtonRef], config: { origin }, } = useFirebaseEssentials(Object.assign({ owner }, clubState
        ? {
            deeplink_path: `/c/${params.handle}`,
        }
        : { deeplink_path: '/c' }));
    const pageLink = clubState ? `${origin}${location.pathname}${location.search}` : `${origin}/app/cb`;
    const absoluteSharingUrl = url.resolve(origin, sharingSrc);
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'leaderboard-invite-page' },
                {
                    name: 'event-params',
                    content: pickBy({
                        page_type: 'invite_page',
                        inviter_id: owner ? owner.id : null,
                        channel,
                        platform,
                        feature,
                        campaign,
                    }, identity),
                },
                { property: 'og:image', content: absoluteSharingUrl },
            ], link: [
                { rel: 'image_src', href: absoluteSharingUrl },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap',
                },
            ] }),
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(Header, null,
                clubState && owner && owner.is_influencer ? (React.createElement(Avatar, { src: avatar }, !avatar && React.createElement("span", null, firstLetter))) : null,
                React.createElement(OnlyDesktop, null, clubState && owner ? (owner.is_influencer ? (React.createElement(FormattedMessage, { ...messages.influencerDesktopTitle, values: { rewards: React.createElement("b", null, "rewarded") } }, (title) => (React.createElement(Title, null,
                    React.createElement("span", null, title))))) : (React.createElement(FormattedMessage, { ...messages.desktopRegularTitle, values: { rewards: React.createElement("b", null, "rewards"), clubname } }, (title) => (React.createElement(Title, null,
                    React.createElement("span", null, title)))))) : (React.createElement(FormattedMessage, { ...messages.generalDesktopTitle, values: { rewards: React.createElement("b", null, "rewards") } }, (title) => (React.createElement(Title, null,
                    React.createElement("span", null, title)))))),
                React.createElement(OnlyMobile, null, clubState && owner ? (owner.is_influencer ? (React.createElement(FormattedMessage, { ...messages.influencerTitle, values: { rewards: React.createElement("b", null, "rewards") } }, (title) => (React.createElement(Title, null,
                    React.createElement("span", null, title))))) : (React.createElement(FormattedMessage, { ...messages.regularTitle, values: { rewards: React.createElement("b", null, "rewards"), clubname } }, (title) => (React.createElement(Title, null,
                    React.createElement("span", null, title)))))) : (React.createElement(FormattedMessage, { ...messages.generalTitle, values: { rewards: React.createElement("b", null, "rewards") } }, (title) => (React.createElement(Title, null,
                    React.createElement("span", null, title))))))),
            React.createElement(Subtitle, null,
                React.createElement(OnlyDesktop, null,
                    React.createElement(FormattedMessage, { ...messages.desktopRegularSubtitle })),
                React.createElement(OnlyMobile, null, clubState && owner ? (owner.is_influencer ? (React.createElement(FormattedMessage, { ...messages.influencerSubtitle, values: { name } })) : (React.createElement(FormattedMessage, { ...messages.regularSubtitle, values: { name, clubname } }))) : (React.createElement(FormattedMessage, { ...messages.generalSubtitle })))),
            React.createElement(Wrap, null,
                React.createElement(DesktopView, { qrCodeLink: getCopyLink(), pageLink: pageLink, messages: messages, inviteType: "clubs" }),
                React.createElement(OnlyMobile, null, clubState ? (React.createElement(Button, { onClick: handleClick, href: firebaseQuery.link, ref: buttonRef }, owner && owner.is_influencer ? (React.createElement(FormattedMessage, { ...messages.influencerJoinBtnTitle })) : (React.createElement(FormattedMessage, { ...messages.joinBtnTitle })))) : (React.createElement(Button, { href: firebaseQuery.link },
                    React.createElement(FormattedMessage, { ...messages.generalJoinBtnTitle }))))),
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { ...messages.note, values: {
                        tcLink: (React.createElement("a", { href: "https://sweatco.in/tnc", target: "_blank", rel: "noreferrer" }, "here")),
                        downloadLink: (React.createElement("a", { href: "https://sweatco.in/download", target: "_blank", rel: "noreferrer" }, "clicking here")),
                    } })),
            React.createElement(PromoImageMobile, { src: phone_mobile }),
            React.createElement(Stores, null,
                React.createElement(StyledOutboundLink, { category: "Store", action: "redirect", label: "AppStore", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('AppStore') },
                    React.createElement(AppstoreIcon, null),
                    React.createElement(StoreDescription, null,
                        React.createElement(StoreName, null,
                            React.createElement(FormattedMessage, { ...messages.appstoreName })),
                        React.createElement(StoreRating, null,
                            React.createElement("strong", null, "4.6"),
                            "\u00A0",
                            React.createElement(FormattedMessage, { ...messages.appstoreRating })))),
                React.createElement(StyledOutboundLink, { category: "Store", action: "redirect", label: "Google Play", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('Google Play') },
                    React.createElement(GoogleplayIcon, null),
                    React.createElement(StoreDescription, null,
                        React.createElement(StoreName, null,
                            React.createElement(FormattedMessage, { ...messages.googleplayName })),
                        React.createElement(StoreRating, null,
                            React.createElement("strong", null, "4.4"),
                            "\u00A0",
                            React.createElement(FormattedMessage, { ...messages.googleplayRating }))))),
            React.createElement(OnlyMobile, null, firebaseQuery ? (React.createElement(DownloadButton, { onClick: handleClick, href: firebaseQuery.link, ref: downloadButtonRef },
                React.createElement(FormattedMessage, { ...messages.downloadButtonTitle }))) : null)),
        React.createElement(PromoImage, { src: phone })));
    function handleClick(event) {
        event.preventDefault();
        analytics.track(`${feature}_link_click`, 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink();
    }
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background-image: url(${bgMobile});
  background-size: cover;

  ${media.tablet `
    background-image: url(${bg});
  `};
`;
const Wrap = styled.div `
  display: flex;
  margin-bottom: 12px;

  ${media.tablet `
    margin-top: -28px;
  `}

  a.clubs {
    display: inline-block;
    width: auto;
    text-align: center;
    height: 60px;
    box-sizing: border-box;
    padding: 18px 40px;
    background: #09afcf;
    box-shadow: -2px 4px 40px rgba(45, 0, 146, 0.2);
    border-radius: 100px;
    border: none;
    font-weight: 700;
    font-family: 'Inter-Bold', 'Inter', sans-serif;
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 0.01em;
    line-height: 1.2;
    margin: 0 16px;
    cursor: pointer;
    transition: opacity 0.2s ease;

    @media (hover: hover) {
      &:hover {
        opacity: 0.4;
        transition: opacity 0.2s ease;
      }
    }

    ${media.tablet `
      display: inline-block;
      margin: 0 0 12px 24px;
      font-size: 22px;

      span {
        color: #ffffff;
      }
    `};

    span {
      color: #ffffff;
    }
  }
`;
const Container = styled.div `
  position: relative;
  padding: 20px 0 60px;

  ${media.tablet `
    width: 600px;
    margin: 0 auto;
  `};

  ${media.desktop `
    padding: 32px 0;
    margin-left: 40px;
  `};

  ${media.desktop_medium `
    width: 680px;
  `};

  @media (min-width: 1280px) {
    margin-left: 108px;
    margin-bottom: 70px;
  }

  ${media.desktop_extra_large `
    width: 1000px;
    margin: 0 0 0 187px;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
  text-align: center;

  ${media.tablet `
    text-align: left;
  `};
`;
const Logo = styled.img `
  position: relative;
  width: 170px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;

  ${media.tablet `
    width: 197px;
  `};
`;
const PromoImageMobile = styled.img `
  display: block;
  width: 100%;
  margin-left: auto;

  ${media.tablet `
    display: none;
  `}
`;
const PromoImage = styled.img `
  display: none;

  ${media.desktop `
    display: block;
    position: absolute;
    width: 850px;
    top: 10%;
    left: 53%;
    z-index: -1;
  `};

  @media (min-width: 1280px) {
    top: 140px;
    right: -170px;
    bottom: auto;
    width: auto;
    height: 100%;
  }

  ${media.desktop_medium `
    left: auto;
  `};
`;
const Header = styled.div `
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 18px;
  padding: 0 16px;

  ${media.tablet `
    padding: 0;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 24px;
  `}
`;
const Avatar = styled.div `
  position: relative;
  width: 70px;
  height: 70px;
  filter: drop-shadow(4px 4px 20px #ff42ec);
  border-radius: 50%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex: none;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    left: 0;
    inset: 0;
    border: 3px solid transparent;
    background: linear-gradient(104.72deg, #ddc2ff 11.97%, #c781ff 81.81%) border-box;
    -webkit-mask: /*4*/
      linear-gradient(104.72deg, #ddc2ff 11.97%, #c781ff 81.81%) padding-box,
      linear-gradient(104.72deg, #ddc2ff 11.97%, #c781ff 81.81%);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 50%;

    ${media.tablet `
      border: 6px solid transparent;
      border-radius: 100px;
    `}
  }

  ${media.tablet `
    width: 140px;
    height: 216px;
    margin-right: 24px;
    filter: drop-shadow(4px 4px 40px rgba(255, 66, 236, 0.74));
    border-radius: 100px;
  `}

  ${media.desktop `
      margin-right: 36px;
  `}

  span {
    font-weight: 100;
    font-size: 70px;
    color: #fff;
  }
`;
const Title = styled.h1 `
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 1.4;
  letter-spacing: -0.002em;
  color: #ffffff;

  ${media.phone_medium `
    line-height: 1.6;
  `};

  ${media.tablet `
    font-weight: 700;
    font-size: 48px;
    line-height: 1.4;
  `};

  b {
    background: linear-gradient(299.71deg, #ffd2ab 26.1%, #ffa4e5 76.88%), linear-gradient(0deg, #ffffff, #ffffff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;
const Subtitle = styled.div `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 1.6;
  letter-spacing: -0.002em;
  padding: 0 16px;

  ${media.tablet `
    width: auto;
    max-width: 600px;
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 32px;
  `};
`;
const Button = styled.a `
  display: block;
  text-align: center;
  width: calc(100% - 32px);
  height: 60px;
  box-sizing: border-box;
  padding: 18px 40px;
  background: #09afcf;
  box-shadow: -2px 4px 40px rgba(45, 0, 146, 0.2);
  border-radius: 100px;
  border: none;
  font-weight: 700;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.01em;
  line-height: 1.2;
  margin: 0 16px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  @media (hover: hover) {
    &:hover {
      opacity: 0.4;
      transition: opacity 0.2s ease;
    }
  }

  ${media.tablet `
    width: 100%;
    max-width: 168px;
    display: inline-block;
    margin: 0 0 12px;
    font-size: 22px;

    span {
      color: #ffffff;
    }
  `};

  span {
    color: #ffffff;
  }
`;
const DownloadButton = styled.a `
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: calc(100% - 32px);
  max-width: 382px;
  height: 58px;
  margin: 0 auto;
  padding: 12px 30px;
  font-weight: 700;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.006em;
  color: #001430;
  background: linear-gradient(91.32deg, rgba(255, 255, 255, 0.95) 0.01%, rgba(255, 255, 255, 0.7) 100.01%);
  border-radius: 16px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  border: none;

  ${media.phone_medium `
    font-size: 20px;
  `};
`;
const Text = styled.p `
  width: calc(100% - 32px);
  margin: 0 auto;
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.8);

  ${media.tablet `
    width: auto;
    max-width: 600px;
    margin: 0;
    margin-bottom: 40px;
  `};

  @media (min-width: 1520px) {
    margin-bottom: 100px;
  }

  a {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.8);
      transition: background-color 0.2s ease;
    }

    @media (hover: hover) {
      &:hover::before {
        background-color: transparent;
        transition: background-color 0.2s ease;
      }
    }
  }
`;
const Stores = styled.div `
  margin: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.phone_medium `
    flex-direction: row;
    justify-content: center;
  `}

  ${media.tablet `
    width: 600px;
    justify-content: flex-start;
    margin: 0;
  `};
`;
const StyledOutboundLink = styled(OutboundLink) `
  display: flex;
  align-items: center;
  padding: 16px 22px;
  background: linear-gradient(83.85deg, rgba(255, 255, 255, 0.01) -0.02%, rgba(255, 255, 255, 0.1) 99.96%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  transition: border-color 0.2s ease;

  ${media.phone_medium `
    padding: 16px;
  `}

  @media (min-width: 414px) {
    padding: 16px 22px;
  }

  ${media.tablet `
    align-items: initial;
    padding: 24px 32px;
    padding-left: 47px;
    background: linear-gradient(94.63deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.1) 100%);
  `}

  @media (hover: hover) {
    &:hover {
      border-color: rgba(255, 255, 255, 0.8);
      transition: border-color 0.2s ease;
    }
  }

  &:first-child {
    margin-bottom: 10px;

    ${media.phone_medium `
      margin-right: 10px;
      margin-bottom: 0;
    `}
  }
`;
const StoreIcon = styled.div `
  width: 23px;
  height: 23px;
  margin-right: 12px;

  ${media.tablet `
    width: 58px;
    height: 58px;
    margin-right: 24px;
  `}
`;
const AppstoreIcon = styled(StoreIcon) `
  background: url(${appstoreSrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const GoogleplayIcon = styled(StoreIcon) `
  background: url(${googleplaySrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const StoreDescription = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
const StoreName = styled.div `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  ${media.tablet `
    font-size: 12px;
    line-height: 1.2;
  `}
`;
const StoreRating = styled.div `
  line-height: 1;

  ${media.tablet `
    display: flex;
    align-items: flex-end;
    margin-top: 2px;
  `}

  strong {
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.002em;
    line-height: 1;
    text-transform: capitalize;

    ${media.phone_medium `
    `}

    ${media.tablet `
      font-size: 24px;
      line-height: 1.2;
    `}
  }

  span {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    font-size: 10px;
    letter-spacing: 0.05em;
    line-height: 1.2;
    font-weight: 400;
    position: relative;
    top: -1px;

    ${media.tablet `
      top: -8px;
      font-size: 10px;
      text-transform: uppercase;
    `}
  }
`;
const OnlyMobile = styled.div `
  width: 100%;

  ${media.tablet `
    display: none;
  `}
`;
const OnlyDesktop = styled.div `
  display: none;

  ${media.tablet `
    display: block;
  `}
`;
export default InviteClubPage;
