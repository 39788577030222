import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
const duration = 180;
const Instruction = (props) => {
    const { instruction, openSettings, trackEvent, device, featureAccelerometerTracker } = props;
    return (React.createElement(Container, null,
        React.createElement(TransitionGroup, null, instruction ? (React.createElement(CSSTransition, { classNames: "item", timeout: duration, key: device }, (state) => (React.createElement(InstructionContainer, { state: state }, React.createElement(instruction, {
            openSettings,
            featureAccelerometerTracker,
            trackEvent: (event, params) => {
                trackEvent(event, { ...params, device });
            },
        }))))) : (''))));
};
const InstructionContainer = styled.div `
  position: relative;
  top: 0;
  left: 0;
  width: 100%;

  &.item-enter {
    opacity: 0;
    position: absolute;
  }
  &.item-enter-active {
    opacity: 0;
    position: absolute;
  }
  &.item-exit {
    opacity: 1;
  }

  &.item-exit-active {
    opacity: 0;
    transition: opacity ${duration}ms ease-in-out;
  }

  &.item-enter-done {
    opacity: 1;
    position: relative;
    transition-delay: ${duration * 0.7}ms;
    transition: opacity ${duration}ms ease-in-out;
  }
`;
const Container = styled.div `
  position: relative;
`;
export default Instruction;
