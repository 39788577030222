import Lottie from 'lottie-react';
import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import chatAvatarSrc from 'assets/SweatPaws/chat-avatar.png';
import loaderAnimation from 'assets/SweatPaws/loader.json';
import { media } from 'components/responsive';
const Loading = (props) => {
    const lottieStyles = {
        width: 140,
        height: 140,
    };
    return (React.createElement(Container, null,
        React.createElement(LoadingInner, null,
            React.createElement(LottieContainer, null,
                React.createElement(Lottie, { animationData: loaderAnimation, style: lottieStyles })),
            React.createElement(AvatarContainer, null,
                React.createElement(Avatar, { src: chatAvatarSrc })),
            React.createElement(CSSTransition, { in: props.text.length > 0, timeout: 100, classNames: "text", unmountOnExit: true },
                React.createElement(LoadingText, null, props.text)))));
};
const Container = styled.div `
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(24, 14, 43, 0.7);
  backdrop-filter: blur(20px);

  // loading text animation
  .text-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .text-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 100ms, transform 100ms;
  }
  .text-exit {
    opacity: 1;
  }
  .text-exit-active {
    opacity: 0;
    transform: scale(0.9);
  }
`;
const LoadingInner = styled.div `
  position: absolute;
  top: 140px;
  left: 50%;
  transform: translateX(-50%);

  ${media.desktop `
    top: 25%;
    transform: translate(-50%);
  `};
`;
const LoadingText = styled.p `
  width: 250px;
  margin: 0 auto;
  margin-top: 120px;
  font-weight: 800;
  font-size: 25px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background: linear-gradient(275.55deg, #ffb3ef 3.2%, #c2c5ff 53%, #b3fff2 97.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const LottieContainer = styled.div `
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
`;
const AvatarContainer = styled.div `
  min-width: 32px;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  overflow: hidden;
  background: linear-gradient(112.4deg, #e5ff9c 29.63%, #e1ff8c 29.63%, #1affd9 76.7%);
  box-shadow: -2px -4px 16px rgba(159, 255, 167, 0.4), 2px 4px 16px rgba(26, 255, 217, 0.4),
    inset 2px 4px 16px rgba(159, 255, 167, 0.25), inset -2px -2px 16px rgba(26, 255, 217, 0.3);
  backdrop-filter: blur(20px);
  border-radius: 100px;
`;
const Avatar = styled.img `
  display: block;
  width: 100%;
  height: 100;
  margin: 0 auto;
  margin-top: 4px;
`;
export default Loading;
