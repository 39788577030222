import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
import logo_src from '../../../assets/Home/header__logo.svg';
import AppLinks from '../../Quiz/components/AppLinks';
const ShlomoRaveParty = () => {
    const dispatch = useDispatch();
    const [resultState, setResultState] = useState();
    useEffect(() => {
        async function fetch() {
            const result = await dispatch(ApiActions.prefetchShlomoStepCount());
            setResultState(result);
        }
        setInterval(fetch, 5000);
        fetch();
    }, []);
    return (React.createElement(Background, null,
        React.createElement(Layout, null,
            React.createElement(Header, null,
                React.createElement(StyledNavLink, { to: "/", exact: true },
                    React.createElement(Logo, { id: "box-logo", src: logo_src })),
                React.createElement(HeaderAppLinks, null)),
            React.createElement("h1", null),
            React.createElement(Title, null,
                "Shlomo Rave Party Step Counter",
                React.createElement("br", null),
                resultState && resultState.payload.data.steps_since_join))));
};
const Background = styled.div `
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(164deg, #032555 0%, #637029 50%, #85963d 100%);
`;
const Layout = styled.div `
  margin: 20px 16px;
  flex: 1;

  ${media.tablet `
    width: 667px;
    flex: none;
  `};
`;
const Header = styled.div `
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;
const StyledNavLink = styled(NavLink) `
  display: block;
`;
const Logo = styled.img `
  position: relative;
  width: 156px;

  ${media.tablet `
    width: 177px;
  `};
`;
const HeaderAppLinks = styled(AppLinks) `
  display: none;

  ${media.tablet `
    display: flex;
    width: 220px;
  `};
`;
const Title = styled.h1 `
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-size: 56px;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 20px;
  margin-top: 200px;
  text-align: center;

  ${media.tablet `
    font-size: 44px;
    line-height: 64px;
    padding-right: 200px;
    margin-bottom: 22px;
  `};
`;
export default ShlomoRaveParty;
