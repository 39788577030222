import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
const messages = defineMessages({
    title: 'What is Sweatcoin?',
    description: 'We\'re an app that turns your steps into a currency called "Sweatcoins" that you can spend to get cool stuff or donate to do good things.',
});
const About = () => {
    return (React.createElement(Container, null,
        React.createElement(FormattedMessage, { ...messages.title }, (title) => React.createElement(Title, null, title)),
        React.createElement(FormattedMessage, { ...messages.description }, (description) => React.createElement(Description, null, description))));
};
const Container = styled.div `
  margin: 0 16px 24px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 0 0 30px 0;
`;
const Title = styled.h2 `
  color: #ffffff;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.2px;
  font-weight: bold;
  margin-bottom: 16px;
`;
const Description = styled.p `
  color: #ffffff;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.2px;
`;
export default About;
