import get from 'lodash/get';
import trim from 'lodash/trim';
const getNameBase = (user) => {
    let name = trim(get(user, 'fullname'));
    if (!name || name.length === 0) {
        name = get(user, 'username');
    }
    return name;
};
export const getFullName = (user) => {
    return getNameBase(user);
};
export const getFirstName = (user) => {
    const name = getNameBase(user);
    return name.split(' ')[0];
};
export const getFirstLetter = (user) => {
    const name = getNameBase(user);
    return String.fromCodePoint(name.codePointAt(0));
};
