import React from 'react';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
const AnswersItem = (props) => {
    return (React.createElement(Answer, { onClick: () => {
            props.onAnswerClick(props.answer.id);
        } }, props.answer.img ? (React.createElement(ImageContainer, null,
        React.createElement(Image, { src: props.answer.img }))) : (React.createElement(Text, null, props.answer.title))));
};
const Answer = styled.div `
  cursor: pointer;
  margin-bottom: 10px;

  ${media.tablet `
    width: calc(50% - 12px);
    margin-bottom: 24px;
    align-self:stretch;
  `};
`;
const ImageContainer = styled.div `
  position: relative;
  &:active::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    box-sizing: border-box;
    border: 2px solid #fff;
  }

  ${media.tablet `
    &:hover::before {
      box-shadow: inset 0px 0px 0px 1px #fff;
    }
  `};
`;
const Image = styled.img `
  width: 100%;
  display: block;
`;
const Text = styled.div `
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-size: 16px;
  color: #fff;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 24px 18px;
  display: flex;
  align-items: center;

  &:active {
    box-shadow: inset 0px 0px 0px 1px #fff;
  }

  ${media.tablet `
    font-size: 20px;
    line-height: 120%;
    padding: 28px 18px 30px;
    min-height: 100%;

    &:hover {
      box-shadow: inset 0px 0px 0px 1px #fff;
    }
  `};
`;
export default AnswersItem;
