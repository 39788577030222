import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { desktop } from 'routes/Home/utils/mediaQueries';
import logo from '../assets/sweatcoin_interpride.png';
import Counter from '../components/Counter';
import Footer from '../components/Footer';
const InterPride = () => {
    return (React.createElement(Wrapper, null,
        React.createElement(Helmet, { link: [
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Prompt&family=Inconsolata:wght@800&display=swap',
                },
            ] }),
        React.createElement(Heading, null, "Walk in solidarity with"),
        React.createElement(Logo, { src: logo }),
        React.createElement(Subtitle, null, "This year we are counting and rewarding every step marched at Pride!"),
        React.createElement(Counter, null),
        React.createElement(Footer, null)));
};
const Wrapper = styled.div `
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    127deg,
    rgb(249, 130, 55) 0%,
    rgb(201, 65, 92) 40%,
    rgb(126, 30, 132) 80%,
    rgb(102, 15, 145) 100%
  );
  min-height: 100vh;
`;
const Heading = styled.h1 `
  padding-top: 105px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  background: linear-gradient(90.55deg, #ffffff 0%, rgba(255, 255, 255, 0.6) 99.81%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 6px;

  @media ${desktop} {
    padding-top: 145px;
    font-size: 45px;
    line-height: 54px;
    margin-bottom: 13px;
  }
`;
const Logo = styled.img `
  width: 336px;
  display: block;
  margin: 0 auto 18px;

  @media ${desktop} {
    width: 564px;
    margin: 0 auto 25px;
  }
`;
const Subtitle = styled.h2 `
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  padding: 0 35px;

  @media ${desktop} {
    width: 430px;
    margin: 0 auto;
    font-size: 21px;
    line-height: 34px;
  }
`;
export default InterPride;
