import avatar1Src from 'assets/SweatPaws/avatars/1.png';
import avatar2Src from 'assets/SweatPaws/avatars/2.png';
import avatar3Src from 'assets/SweatPaws/avatars/3.png';
import avatar4Src from 'assets/SweatPaws/avatars/4.png';
import avatar5Src from 'assets/SweatPaws/avatars/5.png';
import avatar6Src from 'assets/SweatPaws/avatars/6.png';
import avatar7Src from 'assets/SweatPaws/avatars/7.png';
import avatar8Src from 'assets/SweatPaws/avatars/8.png';
import avatar9Src from 'assets/SweatPaws/avatars/9.png';
import sharingUrl1 from 'assets/SweatPaws/sharable/1.jpg';
import sharingUrl2 from 'assets/SweatPaws/sharable/2.jpg';
import sharingUrl3 from 'assets/SweatPaws/sharable/3.jpg';
import sharingUrl4 from 'assets/SweatPaws/sharable/4.jpg';
import sharingUrl5 from 'assets/SweatPaws/sharable/5.jpg';
import sharingUrl6 from 'assets/SweatPaws/sharable/6.jpg';
import sharingUrl7 from 'assets/SweatPaws/sharable/7.jpg';
import sharingUrl8 from 'assets/SweatPaws/sharable/8.jpg';
import sharingUrl9 from 'assets/SweatPaws/sharable/9.jpg';
export default {
    meta: {
        quizId: 'Sweatpaws',
        title: "Check your dog's activity with SweatPaws",
        description: 'Find out if your dog walks enough with SweatPaws. A playful, quick quiz to bark about!',
        sharingPath: 'https://assets.sweatco.in/sweatpaws-artifacts/',
    },
    questions: [
        {
            title: "What's your furry friend's name?",
            type: 'input',
            answers: null,
            messages: (name) => {
                return [
                    `Cute! <span>${name}</span> sounds the coolest!`,
                    `Mhmm - a funny name. Let's explore more about <span>${name}</span>.`,
                    `Woah! Does <span>${name}</span> live up to their name? I wanna know more.`,
                    `I had a furry friend with that name. Maybe.. it's the same <span>${name}</span>..`,
                ];
            },
            errorText: 'Aw, shucks! Answer needed!',
            id: 'name',
        },
        {
            title: 'How old is your little pal?',
            type: 'multiple',
            answers: [{ label: 'Puppy' }, { label: 'Adult' }, { label: 'Senior' }],
            messages: [
                "They grow up so fast, eh! It makes me feel warm. Maybe that's my fur.",
                'Ah, I remember when I was a little bean.',
                'Young or old, all furry babies need attention. Bark!',
                'Did you know, dogs have three eyelids. Weird - but awesome!',
            ],
            errorText: 'You need to pick one!',
            id: 'age',
        },
        {
            title: 'Pick an avatar that suits their vibe',
            type: 'multiple-images',
            answers: [
                { label: 'Furry Superstar', image: avatar1Src, shareImage: sharingUrl1 },
                { label: 'Cuddly Babe', image: avatar2Src, shareImage: sharingUrl2 },
                { label: 'Nerdy Doggo', image: avatar3Src, shareImage: sharingUrl3 },
                { label: 'Greedy Fluff', image: avatar4Src, shareImage: sharingUrl4 },
                { label: 'Tail-Wagger', image: avatar5Src, shareImage: sharingUrl5 },
                { label: 'Bug-Eyed Beauty', image: avatar6Src, shareImage: sharingUrl6 },
                { label: 'Silly Baby', image: avatar7Src, shareImage: sharingUrl7 },
                { label: 'Treat Taker', image: avatar8Src, shareImage: sharingUrl8 },
                { label: 'Disco Pup', image: avatar9Src, shareImage: sharingUrl9 },
            ],
            messages: ['Jheeze. What a cutie!', 'Um - okay. Interesting.', 'Oh, what a pup! I dig it!'],
            errorText: 'You need to pick one!',
            id: 'avatar',
        },
        {
            title: 'What breed is your dog?',
            type: 'select',
            answers: [
                { label: 'Affenpinscher', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Afghan Hound', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Airedale Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Akita', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Alaskan Malamute', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Anatolian Shepherd Dog', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Australian Cattle Dog', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Australian Shepherd', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Australian Silky Terrier', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Australian Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Azawakh (Imp)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Barbet (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Basenji', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Basset Bleu De Gascogne (Imp)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Basset Fauve De Bretagne', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Basset Griffon Vendeen (Grand)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Basset Griffon Vendeen (Petit)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Basset Hound', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Bavarian Mountain Hound', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Beagle', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Bearded Collie', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Beauceron', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Bedlington Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Belgian Shepherd Dog (Groenendael)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Belgian Shepherd Dog (Laekenois)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Belgian Shepherd Dog (Malinois)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Belgian Shepherd Dog (Tervueren)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Bergamasco (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Bernese Mountain Dog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Bichon Frise', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Black & Tan Coonhound (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Bloodhound', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Bolognese', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Border Collie', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Border Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Borzoi', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Boston Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Bouvier Des Flandres', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Boxer', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Bracco Italiano', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Braque D’Auvergne (Imp)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Briard', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Brittany', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Bull Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Bull Terrier (Miniature)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Bulldog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Bullmastiff', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Cairn Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Canaan Dog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Canadian Eskimo Dog', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Catalan Sheepdog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Cavalier King Charles Spaniel', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Cesky Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Chihuahua (Long Coat)', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Chihuahua (Smooth Coat)', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Chinese Crested', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Chow Chow', id: '', hours: 1, text: 'up to 1 hour' },
                { label: "Cirneco Dell'Etna", id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Collie (Rough)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Collie (Smooth)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Coton De Tulear', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Dachshund (Long Haired)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Dachshund (Miniature Long Haired)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Dachshund (Miniature Smooth Haired)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Dachshund (Miniature Wire Haired)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Dachshund (Smooth Haired)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Dachshund (Wire Haired)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Dalmatian', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Dandie Dinmont Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Deerhound', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Dobermann', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Dogue de Bordeaux', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'English Setter', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'English Toy Terrier (Black & Tan)', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Entlebucher Mountain Dog (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Estrela Mountain Dog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Eurasier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Finnish Lapphund', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Finnish Spitz', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Fox Terrier (Smooth)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Fox Terrier (Wire)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Foxhound', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'French Bulldog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'German Longhaired Pointer', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'German Pinscher', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'German Shepherd Dog', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'German Shorthaired Pointer', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'German Spitz (Klein)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'German Spitz (Mittel)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'German Wirehaired Pointer', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Giant Schnauzer', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Glen Of Imaal Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Gordon Setter', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Grand Bleu De Gascogne (Imp)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Great Dane', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Great Swiss Mountain Dog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Greenland Dog', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Greyhound', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Griffon Bruxellois', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Griffon Fauve De Bretagne (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Hamiltonstovare', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Harrier', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Havanese', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Hovawart', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Hungarian Kuvasz (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Hungarian Puli', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Hungarian Pumi', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Hungarian Vizsla', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Hungarian Wirehaired Vizsla', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Ibizan Hound', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Irish Red & White Setter', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Irish Setter', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Irish Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Irish Wolfhound', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Italian Greyhound', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Italian Spinone', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Jack Russell Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Japanese Akita Inu', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Japanese Chin', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Japanese Shiba Inu', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Japanese Spitz', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Keeshond', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Kerry Blue Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'King Charles Spaniel', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Komondor', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Kooikerhondje', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Korean Jindo (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Korthals Griffon (Imp)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Lagotto Romagnolo', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Lakeland Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Lancashire Heeler', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Large Munsterlander', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Leonberger', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Lhasa Apso', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Lowchen (Little Lion Dog)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Maltese', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Manchester Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Maremma Sheepdog', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Mastiff', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Miniature Pinscher', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Miniature Schnauzer', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Neapolitan Mastiff', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Newfoundland', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Norfolk Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Norwegian Buhund', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Norwegian Elkhound', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Norwich Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Old English Sheepdog', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Otterhound', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Papillon', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Parson Russell Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Pekingese', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Pharaoh Hound', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Picardy Sheepdog (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Pointer', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Polish Lowland Sheepdog', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Pomeranian', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Poodle (Miniature)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Poodle (Standard)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Poodle (Toy)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Portuguese Podengo', id: '', hours: 0.5, text: 'up to 30 minutes' },
                { label: 'Portuguese Pointer (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Portuguese Water Dog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Pug', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Pyrenean Mastiff (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Pyrenean Mountain Dog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Pyrenean Sheepdog (Long Haired)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Pyrenean Sheepdog (Smooth Faced) (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Retriever (Chesapeake Bay)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Retriever (Curly Coated)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Retriever (Flat Coated)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Retriever (Golden)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Retriever (Labrador)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Retriever (Nova Scotia Duck Tolling)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Rhodesian Ridgeback', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Rottweiler', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Russian Black Terrier', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Russian Toy (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Saluki', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Samoyed', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Schipperke', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Schnauzer', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Scottish Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Sealyham Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Shar Pei', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Shetland Sheepdog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Shih Tzu', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Siberian Husky', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Skye Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Sloughi', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Slovakian Rough Haired Pointer (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Small Munsterlander (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Soft Coated Wheaten Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Spaniel (American Cocker)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Spaniel (American Water) (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Spaniel (Clumber)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Spaniel (Cocker)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Spaniel (English Springer)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Spaniel (Field)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Spaniel (Irish Water)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Spaniel (Sussex)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Spaniel (Welsh Springer)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Spanish Water Dog', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'St. Bernard', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Staffordshire Bull Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Swedish Lapphund (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Swedish Vallhund', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Tibetan Mastiff', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Tibetan Spaniel', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Tibetan Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Turkish Kangal Dog', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Weimaraner', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Welsh Corgi (Cardigan)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Welsh Corgi (Pembroke)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Welsh Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'West Highland White Terrier', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Whippet', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'White Swiss Shepherd Dog (Imp)', id: '', hours: 2, text: 'more than 2 hours' },
                { label: 'Xoloitzcuintle (Mex Hairless) Int (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Xoloitzcuintle (Mex Hairless) Min (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Xoloitzcuintle (Mex Hairless) Std (Imp)', id: '', hours: 1, text: 'up to 1 hour' },
                { label: 'Yorkshire Terrier', id: '', hours: 0.5, text: 'up to 30 minutes' },
            ],
            messages: [
                "I've met some of those in my doggy lifetime. They're special, that's for sure.",
                'Ah, a remarkable breed. A truly unique doggy.',
                "Noteworthy - to say the least! And I'll be noting it.",
                'Did you know, your pal sweats from their paws?',
            ],
            errorText: 'You need to pick one!',
            id: 'breed',
        },
        {
            title: (name) => {
                return `How often do you take ${name} for a walk?`;
            },
            type: 'multiple',
            answers: [
                { label: 'Once per day', value: 1 },
                { label: '2 times per day', value: 2 },
                { label: '3 times per day', value: 3 },
            ],
            messages: [
                'Did someone say walk? I think I got distracted.',
                'Can you take me along next time? Haha. Please?',
                'I love a walk where I take the lead. Not the leash. I just want to walk in front.',
                'Did you know, dogs have four times more powerful hearing than humans. Shh! They know all your secrets.',
            ],
            errorText: 'You need to pick one!',
            id: 'walking',
        },
        {
            title: 'How long does each walk last?',
            type: 'multiple',
            answers: [
                { label: '15 min', value: 0.25 },
                { label: '30 min', value: 0.5 },
                { label: '1 hr', value: 1 },
                { label: '1+ hrs', value: 1.5 },
            ],
            messages: [
                "A run in the park or a walk to the shops, it's all important to me.",
                'Dogs really show you the world in a different light. I mean, we are sort of colourblind.',
                "I once met a wizard pup who told me my kibble was magic. I don't know how, but maybe it was.",
            ],
            errorText: 'You need to pick one!',
            id: 'duration',
        },
        {
            title: (name) => {
                return `Have you given ${name} a treat? They deserve it!`;
            },
            type: 'multiple',
            answers: [
                { label: 'Yes', value: [94, 95, 96, 97, 98, 99] },
                { label: 'Not yet', value: [88, 89, 90, 91, 92, 93] },
                { label: 'Giving now', value: [95, 96, 97, 98, 99, 100] },
            ],
            messages: (name) => {
                return [
                    'Happy pup, happy life.',
                    'Bring joy to your furry friend like they bring to you.',
                    "Sometimes I take naps after my treats. It's like being on cloud nine. Whatever that is.",
                    `Did you know, <span>${name}</span> loves you? It's true!`,
                ];
            },
            errorText: 'You need to pick one!',
            id: 'treat',
        },
        {
            title: (name) => {
                return `What's ${name}'s favourite pastime?`;
            },
            type: 'input',
            answers: null,
            messages: [
                'A major key to staying this cute is taking naps.',
                "Keep your doggy's spirits elated, and love them to keep them motivated.",
                "I get excited when the delivery man comes by. I don't know if he feels the same.",
            ],
            errorText: 'Aw, shucks! Answer needed!',
            id: 'pasttime',
        },
    ],
    results: {
        disclaimer: {
            puppy: "If your dog is still a pup, follow this general rule: puppies need to be vaccinated fully. And, when they're ready to go outside, provide five mins of walking per month of age.",
            senior: 'If your dog is senior, give plenty of time for rest and check with your vet for the best advice on walks!',
        },
        healthData: {
            tooMuch: {
                percents: [80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95],
                labels: ['Panting Zoomer', 'Tired Whizz', 'Rushing Doggo', 'Fidgety Hound'],
            },
            great: {
                percents: [90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100],
                labels: ['Speedy Baby', 'Lightning Rover', 'Best Pup', 'Bubbly Doggo'],
            },
            nearlyThere: {
                percents: [70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89],
                labels: ['Casual Pup', 'Shy One', 'Edgy Dude'],
            },
            notEnough: {
                percents: [60, 61, 62, 63, 64, 65, 66, 67, 68, 69],
                labels: ['Furry Potato', 'Idle Loaf', 'Little Snail'],
            },
        },
    },
};
