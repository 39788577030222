import { ADD_SECTION_DATA, INIT_DEVICE, SET_ACTIVE_SECTION, SET_BACKGROUND_IMAGE, TOGGLE_MENU, } from 'constants/ActionTypes';
export const addSectionData = (data) => ({
    type: ADD_SECTION_DATA,
    payload: data,
});
export const setActiveSection = (section_id) => ({
    type: SET_ACTIVE_SECTION,
    payload: section_id,
});
export const setBackgroundImage = (image_id) => ({
    type: SET_BACKGROUND_IMAGE,
    payload: image_id,
});
export const initDevice = (payload) => ({
    type: INIT_DEVICE,
    payload,
});
export const toggleMenu = () => ({
    type: TOGGLE_MENU,
});
