import * as url from 'url';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import bgHomeDesktop from 'assets/SweatPaws/bg-home.jpg';
import bgHomeMobile from 'assets/SweatPaws/bg-home_mobile.jpg';
import bgMobile from 'assets/SweatPaws/bg_mobile.jpg';
import sharingUrl from 'assets/SweatPaws/sharable/share.jpg';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import AppLink from '../components/AppLink';
import AppLinks from '../components/AppLinks';
import Banner from '../components/Banner';
import Quiz from '../components/Quiz';
import data from '../utils/data';
const GlobalStyles = createGlobalStyle `
  html,
  body {
    font-family: 'Orbitron', sans-serif !important;
  }
`;
function useQuery() {
    return queryString.parse(useLocation().search);
}
const App = () => {
    const { origin } = useSelector((state) => state.config);
    const { result } = useSelector((state) => state.params);
    const history = useHistory();
    const query = useQuery();
    const [points, setPoints] = useState({
        name: '',
        age: '',
        avatar: '',
        breed: '',
        walking: '',
        duration: '',
        treat: '',
        pasttime: '',
        results: '',
    });
    const [questions] = useState([...data.questions]);
    const [meta] = useState({ ...data.meta });
    const [results] = useState({ ...data.results });
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [isStarted, setIsStarted] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [activeId, setActiveId] = useState(null);
    const [showError, setShowError] = useState(false);
    const [isSharePanelOpened, setSharePanelOpened] = useState(false);
    const [isSmallBannerOpened, setSmallBannerOpened] = useState(true);
    const currentQuestion = questions[activeQuestion];
    const [isUserFromApp] = useState(query.src === 'app');
    const [userFrom] = useState(query.src);
    const isNoAnswer = points[currentQuestion.id] === '' || points[currentQuestion.id].label === '';
    let absoluteSharingUrl = url.resolve(origin, sharingUrl);
    useEffect(() => {
        if ((process.browser && result) || isUserFromApp) {
            history.replace('/sweatpaws');
        }
    }, []);
    useEffect(() => {
        /** DO NOT MODIFY THIS CODE**/
        !(function (_window, _document) {
            var OB_ADV_ID = '007c59ad4ffd50bbc7c749ae72edc9550e';
            if (_window.obApi) {
                var toArray = function (object) {
                    return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
                };
                _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
                return;
            }
            var api = (_window.obApi = function () {
                api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
            });
            api.version = '1.1';
            api.loaded = true;
            api.marketerId = OB_ADV_ID;
            api.queue = [];
            var tag = _document.createElement('script');
            tag.async = true;
            tag.src = 'https://amplify.outbrain.com/cp/obtp.js';
            tag.type = 'text/javascript';
            var script = _document.getElementsByTagName('script')[0];
            script.parentNode.insertBefore(tag, script);
            return () => {
                // clean up the script when the component in unmounted
                script.parentNode.removeChild(tag);
            };
        })(window, document);
        obApi('track', 'PAGE_VIEW');
    }, []);
    if (result) {
        absoluteSharingUrl = url.resolve(origin, `${meta.sharingPath}${result}.jpg`);
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(GlobalStyles, null),
        isStarted ? React.createElement(Banner, null) : null,
        React.createElement(Background, { isStarted: isStarted },
            getHelmet(),
            React.createElement(Layout, { isSharePanelOpened: isSharePanelOpened },
                React.createElement(ButtonsContainer, null,
                    React.createElement(HeaderAppLinks, null),
                    React.createElement(AppLink, null)),
                React.createElement(Quiz, { meta: meta, result: result, results: results, questions: questions, question: currentQuestion, answers: currentQuestion.answers, quizLength: questions.length, answerNumber: activeQuestion + 1, isStarted: isStarted, isFinished: isFinished, isSmallBannerOpened: isSmallBannerOpened, handleStartBtnClick: handleStartBtnClick, handleAnswer: handleAnswer, handleBackBtnClick: handleBackBtnClick, handleNextBtnClick: handleNextBtnClick, handleShareBtnClick: handleShareBtnClick, handleClosePanelBtnClick: handleClosePanelBtnClick, handleCloseBannerBtnClick: handleCloseBannerBtnClick, isSharePanelOpened: isSharePanelOpened, isUserFromApp: isUserFromApp, points: points, setActiveId: setActiveId, activeId: activeId, showError: showError })))));
    function getHelmet() {
        return (React.createElement(Helmet, { title: meta.title, link: [{ rel: 'image_src', href: absoluteSharingUrl }], meta: [
                { name: 'event-id', content: 'sweatpaws' },
                { property: 'og:title', content: meta.title },
                { name: 'twitter:title', content: meta.title },
                {
                    name: 'description',
                    content: meta.description,
                },
                {
                    property: 'og:description',
                    content: meta.description,
                },
                {
                    name: 'twitter:description',
                    content: meta.description,
                },
                { property: 'og:image', content: absoluteSharingUrl },
                { name: 'twitter:image', content: absoluteSharingUrl },
                { name: 'twitter:card', content: 'summary_large_image' },
                {
                    name: 'apple-itunes-app',
                    content: 'app-id=971023427',
                },
            ] }));
    }
    function handleStartBtnClick() {
        analytics.track('start', 'click', {
            origin: location.origin,
            page: location.pathname,
            quizId: meta.quizId,
            userFrom: userFrom,
        });
        setIsStarted(true);
    }
    function handleAnswer(answerId, value) {
        setPoints({ ...points, [answerId]: value });
        if (isNoAnswer) {
            setShowError(false);
        }
        if (isQuizFinished() && points['results'] !== '') {
            analytics.track('results', 'loadingResults', {
                origin: location.origin,
                page: location.pathname,
                quizId: meta.quizId,
                results: points['results'].data,
            });
        }
    }
    function handleBackBtnClick() {
        setActiveQuestion(activeQuestion - 1);
        setActiveId(null);
        setShowError(false);
    }
    function handleNextBtnClick() {
        window.scrollTo(0, 0);
        if (isNoAnswer) {
            setShowError(true);
        }
        else {
            const questionNumber = activeQuestion + 1;
            if (isQuizFinished()) {
                setIsFinished(true);
            }
            else {
                setShowError(false);
                setActiveQuestion(questionNumber);
                setActiveId(null);
            }
            analytics.track('answer', 'click', {
                origin: location.origin,
                page: location.pathname,
                quizId: meta.quizId,
                questionNumber: questionNumber,
                answer: points[currentQuestion.id].label,
            });
        }
    }
    function isQuizFinished() {
        return activeQuestion + 1 === questions.length;
    }
    function handleShareBtnClick() {
        setSharePanelOpened(true);
        if (window.obApi) {
            obApi('track', 'Share');
        }
        analytics.track('share', 'click', {
            origin: location.origin,
            page: location.pathname,
            quizId: meta.quizId,
        });
    }
    function handleClosePanelBtnClick() {
        setSharePanelOpened(false);
    }
    function handleCloseBannerBtnClick() {
        setSmallBannerOpened(false);
    }
};
const Background = styled.div `
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 0 16px;
  overflow: ${(props) => (props.isStarted ? null : 'hidden')};
  background-color: #19191b;
  background-image: ${(props) => (props.isStarted ? `url('${bgMobile}')` : `url('${bgHomeMobile}')`)};
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

  ${media.desktop `
    padding: 0;
    overflow: initial;
    background-image: url('${bgHomeDesktop}');
  `};
`;
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin: 20px 0 124px;
  margin-bottom: ${(props) => (props.isSharePanelOpened ? '470px' : '124px')};

  ${media.desktop `
    margin-top: 0;
    margin-bottom: ${(props) => (props.isSharePanelOpened ? '560px' : '124px')};
    padding: 0 60px;
  `};
`;
const ButtonsContainer = styled.div `
  display: flex;
  flex-wrap: wrap;
  order: 1;
  max-width: 400px;
  margin: 0 auto;

  ${media.desktop `
    width: 400px;
  `};

  & > div:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
  }

  a {
    display: flex;
  }
`;
const HeaderAppLinks = styled(AppLinks) `
  display: none;

  ${media.desktop `
    display: flex;
    width: 220px;
  `};
`;
export default App;
