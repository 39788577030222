import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
const BoostOnWatchGuide = () => {
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'boost-on-watch-guide' },
                { name: 'robots', content: 'noindex' },
            ] }),
        React.createElement(Container, null,
            React.createElement(StyledIframe, { src: "https://www.youtube.com/embed/tFAN3h1Wvjk?rel=0&autoplay=1", allowFullScreen: true }))));
};
const Section = styled.section `
  position: relative;
  background: #000;
`;
const Container = styled.div `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const StyledIframe = styled.iframe `
  border: 0;
  width: 100vh;
  height: 100vh;
`;
export default BoostOnWatchGuide;
