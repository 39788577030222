import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logo_src from 'assets/Home/header__logo.svg';
import logo_src_embed from 'assets/Home/header__logo_embed.png';
import FONTS from 'assets/fonts';
import responsiveContainer, { media } from 'components/responsive';
import { Helpers } from './Background';
import Menu from './Menu';
import { headerHeight } from './utils';
const mapStateToProps = (state) => ({
    isMobile: _.get(state, 'home.device.isMobile'),
});
class Header extends React.Component {
    state = {
        stickToBottom: false,
    };
    containerRef = React.createRef();
    onScroll = _.throttle(() => {
        const $container = Helpers.select(this.props.container.toString())[0];
        const $this = this.containerRef.current;
        if (!$this) {
            return;
        }
        const $this_height = $this.getBoundingClientRect().height;
        const { top, height } = $container.getBoundingClientRect();
        if (top + height - $this_height < 0) {
            this.setState({ stickToBottom: true });
        }
        else if (this.state.stickToBottom) {
            this.setState({ stickToBottom: false });
        }
    }, 100, { trailing: true, leading: true });
    componentDidMount() {
        if (!this.props.embed) {
            window.addEventListener('scroll', this.onScroll);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }
    render() {
        return (React.createElement(Container, { embed: this.props.embed, ref: this.containerRef, id: "header", className: this.state.stickToBottom && 'stickToBottom' },
            !this.props.embed && (React.createElement(BackgroundWrapper, null,
                React.createElement(BackgroundContainer, null,
                    React.createElement("div", { className: "background__left", id: "header__background-left" }),
                    React.createElement("div", { className: "background__right", id: "header__background-right" })))),
            React.createElement(StyledNavLink, { to: "/", exact: true }, this.props.logoAsset ? (React.createElement(Logo, { id: "box-logo", src: this.props.logoAsset })) : (React.createElement(Logo, { id: "box-logo", src: this.props.embed ? logo_src_embed : logo_src }))),
            React.createElement(Menu, { embed: this.props.embed })));
    }
}
const container_pad_mobile = '20px';
const container_pad_top = '20px';
const container_pad_bottom = '20px';
export const containerHeight = {
    phone: `${headerHeight.phone}px`,
    tablet: `${headerHeight.tablet}px`,
    desktop: `${headerHeight.desktop}px`,
};
const Container = styled.div `
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${container_pad_mobile} 0;
  font-family: ${FONTS.SimplonNorm.name};
  color: ${(props) => (props.embed ? '#032555' : '#FFFFFF')};
  background: ${(props) => (props.embed ? '#FFFFFF' : null)};
  ${responsiveContainer};
  padding-top: 15px;
  padding-bottom: 15px;

  &.stickToBottom {
    position: absolute;
    top: auto;
    bottom: 0;
  }

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  ${media.desktop `
    padding-top: ${container_pad_top};
    padding-bottom: ${container_pad_bottom};
  `};
`;
const BackgroundWrapper = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
`;
const Logo__height_desktop = '1.5rem';
const Logo = styled.img `
  position: relative;
  width: 182px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 8px solid transparent;
  ${media.desktop `
    height: ${Logo__height_desktop};
    width: auto;
    border: none !important;
  `};
`;
const StyledNavLink = styled(NavLink) `
  height: ${Logo__height_desktop};
`;
const BackgroundContainer = styled.div `
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;

  .background__left,
  .background__right {
    height: 100vh;
    width: 50%;
    position: relative;
  }

  .background__image {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-size: auto 50%;
    background-position-x: 50%;
    background-position-y: 100%;
    background-repeat: no-repeat;

    ${media.desktop `background-size: 100%;`};
  }
`;
export default connect(mapStateToProps)(Header);
