import _ from 'lodash';
import queryString from 'query-string';
import { setCountry } from 'utils/countryManager';
const createRoutes = () => [
    {
        path: '/',
        exact: true,
        caching: true,
        cacheMaxAge: 5, // 5 seconds cache
        // Country selection cookie & redirect
        onEnter: ({ ctx, location }) => {
            const country = _.get(location, 'query.country');
            if (country && country.length > 0) {
                setCountry(country, ctx);
                const query_string = queryString.stringify(_.omit(_.get(location, 'query'), 'country'));
                return { redirectUrl: query_string.length > 0 ? `/?${query_string}` : '/' };
            }
        },
        component: require('./pages/Home').default,
    },
];
export default createRoutes;
