import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { useFirebaseEssentials } from 'routes/InvitePages/utils/firebase';
const isIosSelector = (state) => _.get(state, 'home.device.isIOs', false);
const isAndroidSelector = (state) => _.get(state, 'home.device.isAndroid', false);
const AppLink = () => {
    const isIOs = useSelector(isIosSelector);
    const isAndroid = useSelector(isAndroidSelector);
    const { firebaseData: { firebaseQuery }, buttonsRefs: [appstoreButton, googleplayButton], config: { origin }, } = useFirebaseEssentials({ path: '/sweatpaws', feature: 'sweatpaws_download_button' });
    return (React.createElement(Container, null,
        firebaseQuery ? (React.createElement(MobileView, null,
            isIOs ? (React.createElement(Link, { href: firebaseQuery.link, target: "_blank", onClick: handleAppstoreClick, ref: appstoreButton },
                React.createElement("span", null, "Download Sweatcoin App"))) : null,
            isAndroid ? (React.createElement(Link, { href: firebaseQuery.link, target: "_blank", onClick: handleGooglePlayClick, ref: googleplayButton },
                React.createElement("span", null, "Download Sweatcoin App"))) : null)) : null,
        React.createElement(DesktopView, null,
            React.createElement(Link, { href: "/download", onClick: trackDownloadButton },
                React.createElement("span", null, "Download Sweatcoin App")))));
    function trackDownloadButton() {
        if (window.obApi) {
            obApi('track', 'Downloadbutton');
        }
    }
    function handleAppstoreClick() {
        event.preventDefault();
        trackDownloadButton();
        analytics.track('SweatpawsAppstoreDownloadButton', 'click', _.pickBy({
            origin,
            feature: 'sweatpaws_download_button',
        }, _.identity));
        window.open('https://itunes.apple.com/app/sweatcoin-app-that-pays-you/id971023427', '_blank');
    }
    function handleGooglePlayClick() {
        event.preventDefault();
        trackDownloadButton();
        analytics.track('SweatpawsGooglePlayDownloadButton', 'click', _.pickBy({
            origin,
            feature: 'sweatpaws_download_button',
        }, _.identity));
        window.open('https://play.google.com/store/apps/details?id=in.sweatco.app', '_blank');
    }
};
const MobileView = styled.div `
  width: 100%;
  height: 100%;

  ${media.desktop `
    display: none;
  `};
`;
const DesktopView = styled.div `
  display: none;
  width: 100%;
  height: 100%;

  ${media.desktop `
    display: flex;
  `};
`;
const Link = styled.a `
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  backdrop-filter: blur(10px);
  border-radius: 50px;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    inset: 0;
    border: 2px solid transparent;
    background: linear-gradient(112.4deg, #fe53bb 29.63%, #09fbd3 76.7%) border-box;
    -webkit-mask: /*4*/
      linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 50px;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.3) 25.73%, rgba(48, 255, 226, 0.3) 66.52%);
    filter: blur(30px);
    border-radius: 50px;
  }

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.002em;
    color: #ffffff;
    background: linear-gradient(112.4deg, #ffa6dc 29.63%, #6affe6 76.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  &:hover::after,
  &:active::after {
    background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.5) 25.73%, rgba(48, 255, 226, 0.5) 66.52%);
  }

  &:active::before {
    background: linear-gradient(112.4deg, rgba(254, 83, 187, 0.6) 29.63%, rgba(83, 78, 255, 0.6) 76.7%) border-box;
  }
`;
const Container = styled.div `
  width: 100%;
  height: 100%;
  z-index: 1;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }
`;
export default AppLink;
