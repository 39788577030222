export const MISC_SET_LOCALE = 'MISC_SET_LOCALE';
export const MISC_SET_META = 'MISC_SET_META';
export const MISC_SET_CONFIG = 'MISC_SET_CONFIG';
export const MISC_SET_ON_ENTER = 'MISC_SET_ON_ENTER';
export const SYNC_TIME = 'SYNC_TIME';
export const SET_PARAMS = 'SET_PARAMS';
export const ROUTER_BEFORE_NAVIGATE = 'ROUTER_BEFORE_NAVIGATE';
export const ROUTER_NAVIGATE = 'ROUTER_NAVIGATE';
export const ROUTER_ERROR_RAISE = 'ROUTER_ERROR_RAISE';
export const ROUTER_ERROR_RESET = 'ROUTER_ERROR_RESET';
export const ROUTER_REJECTED = 'ROUTER_REJECTED';
export const SESSION_UPDATE = 'SESSION_UPDATE';
export const STORE_BUTTON_SHOW = 'STORE_BUTTON_SHOW';
export const AUTHENTICATION_LOGIN_PENDING = 'AUTHENTICATION_LOGIN_PENDING';
export const AUTHENTICATION_LOGIN_FULFILLED = 'AUTHENTICATION_LOGIN_FULFILLED';
export const AUTHENTICATION_LOGIN_REJECTED = 'AUTHENTICATION_LOGIN_REJECTED';
export const AUTHENTICATION_LOGOUT_PENDING = 'AUTHENTICATION_LOGOUT_PENDING';
export const AUTHENTICATION_LOGOUT_FULFILLED = 'AUTHENTICATION_LOGOUT_FULFILLED';
export const AUTHENTICATION_LOGOUT_REJECTED = 'AUTHENTICATION_LOGOUT_REJECTED';
export const GET_USERS_ME_PENDING = 'GET_USERS_ME_PENDING';
export const GET_USERS_ME_FULFILLED = 'GET_USERS_ME_FULFILLED';
export const GET_USERS_ME_REJECTED = 'GET_USERS_ME_REJECTED';
export const GET_USER_BY_USERNAME_PENDING = 'GET_USER_BY_USERNAME_PENDING';
export const GET_USER_BY_USERNAME_FULFILLED = 'GET_USER_BY_USERNAME_FULFILLED';
export const GET_USER_BY_USERNAME_REJECTED = 'GET_USER_BY_USERNAME_REJECTED';
export const GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_PENDING = 'GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_PENDING';
export const GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_FULFILLED = 'GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_FULFILLED';
export const GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_REJECTED = 'GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_REJECTED';
export const GET_GLOBAL_STATS_PENDING = 'GET_GLOBAL_STATS_PENDING';
export const GET_GLOBAL_STATS_FULFILLED = 'GET_GLOBAL_STATS_FULFILLED';
export const GET_GLOBAL_STATS_REJECTED = 'GET_GLOBAL_STATS_REJECTED';
export const GET_FEATURED_OFFERS_PENDING = 'GET_FEATURED_OFFERS_PENDING';
export const GET_FEATURED_OFFERS_FULFILLED = 'GET_FEATURED_OFFERS_FULFILLED';
export const GET_FEATURED_OFFERS_REJECTED = 'GET_FEATURED_OFFERS_REJECTED';
export const GET_UPCOMING_OFFERS_PENDING = 'GET_UPCOMING_OFFERS_PENDING';
export const GET_UPCOMING_OFFERS_FULFILLED = 'GET_UPCOMING_OFFERS_FULFILLED';
export const GET_UPCOMING_OFFERS_REJECTED = 'GET_UPCOMING_OFFERS_REJECTED';
export const GET_OFFER_PENDING = 'GET_OFFER_PENDING';
export const GET_OFFER_FULFILLED = 'GET_OFFER_FULFILLED';
export const GET_OFFER_REJECTED = 'GET_OFFER_REJECTED';
export const GET_LEGAL_PENDING = 'GET_LEGAL_PENDING';
export const GET_LEGAL_FULFILLED = 'GET_LEGAL_FULFILLED';
export const GET_LEGAL_REJECTED = 'GET_LEGAL_REJECTED';
export const POST_OFFER_PAY_PENDING = 'POST_OFFER_PAY_PENDING';
export const POST_OFFER_PAY_FULFILLED = 'POST_OFFER_PAY_FULFILLED';
export const POST_OFFER_PAY_REJECTED = 'POST_OFFER_PAY_REJECTED';
export const POST_INVITE_REQUEST_PENDING = 'POST_INVITE_REQUEST_PENDING';
export const POST_INVITE_REQUEST_FULFILLED = 'POST_INVITE_REQUEST_FULFILLED';
export const POST_INVITE_REQUEST_REJECTED = 'POST_INVITE_REQUEST_REJECTED';
export const POST_EMAIL_CHECK_CODE_PENDING = 'POST_EMAIL_CHECK_CODE_PENDING';
export const POST_EMAIL_CHECK_CODE_FULFILLED = 'POST_EMAIL_CHECK_CODE_FULFILLED';
export const POST_EMAIL_CHECK_CODE_REJECTED = 'POST_EMAIL_CHECK_CODE_REJECTED';
export const GET_CLEARING_REQUEST_PENDING = 'GET_CLEARING_REQUEST_PENDING';
export const GET_CLEARING_REQUEST_FULFILLED = 'GET_CLEARING_REQUEST_FULFILLED';
export const POST_CLEARING_REQUEST_CONFIRM_PENDING = 'POST_CLEARING_REQUEST_CONFIRM_PENDING';
export const POST_CLEARING_REQUEST_CONFIRM_FULFILLED = 'POST_CLEARING_REQUEST_CONFIRM_FULFILLED';
export const DELETE_CLEARING_REQUEST_PENDING = 'DELETE_CLEARING_REQUEST_PENDING';
export const DELETE_CLEARING_REQUEST_FULFILLED = 'DELETE_CLEARING_REQUEST_FULFILLED';
export const DELETE_CLEARING_REQUEST_REJECTED = 'DELETE_CLEARING_REQUEST_REJECTED';
export const ADD_SECTION_DATA = 'ADD_SECTION_DATA';
export const INIT_DEVICE = 'INIT_DEVICE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
export const SET_BACKGROUND_IMAGE = 'SET_BACKGROUND_IMAGE';
export const GET_JOB_OPENINGS_PENDING = 'GET_JOB_OPENINGS_PENDING';
export const GET_JOB_OPENINGS_FULLFILLED = 'GET_JOB_OPENINGS_FULLFILLED';
export const GET_JOB_OPENINGS_REJECTED = 'GET_JOB_OPENINGS_REJECTED';
export const GET_VACANCY_PENDING = 'GET_VACANCY_PENDING';
export const GET_VACANCY_FULLFILLED = 'GET_VACANCY_FULLFILLED';
export const GET_VACANCY_REJECTED = 'GET_VACANCY_REJECTED';
export const GET_TEAMS_PENDING = 'GET_TEAMS_PENDING';
export const GET_TEAMS_FULLFILLED = 'GET_TEAMS_FULLFILLED';
export const GET_TEAMS_REJECTED = 'GET_TEAMS_REJECTED';
export const GET_LOCATIONS_PENDING = 'GET_LOCATIONS_PENDING';
export const GET_LOCATIONS_FULLFILLED = 'GET_LOCATIONS_FULLFILLED';
export const GET_LOCATIONS_REJECTED = 'GET_LOCATIONS_REJECTED';
export const SAVE_FILE_PENDING = 'SAVE_FILE_PENDING';
export const SAVE_FILE_FULLFILLED = 'SAVE_FILE_FULLFILLED';
export const SAVE_FILE_REJECTED = 'SAVE_FILE_REJECTED';
export const SAVE_APPLICANT_PENDING = 'SAVE_APPLICANT_PENDING';
export const SAVE_APPLICANT_FULLFILLED = 'SAVE_APPLICANT_FULLFILLED';
export const SAVE_APPLICANT_REJECTED = 'SAVE_APPLICANT_REJECTED';
export const ADD_APPLICANT_TO_VACANCY_PENDING = 'ADD_APPLICANT_TO_VACANCY_PENDING';
export const ADD_APPLICANT_TO_VACANCY_FULLFILLED = 'ADD_APPLICANT_TO_VACANCY_FULLFILLED';
export const ADD_APPLICANT_TO_VACANCY_REJECTED = 'ADD_APPLICANT_TO_VACANCY_REJECTED';
export const APPLICATION_FORM_UPDATED = 'APPLICATION_FORM_UPDATED';
export const GET_SHLOMO_STATS_PENDING = 'GET_SHLOMO_STATS_PENDING';
export const GET_SHLOMO_STATS_FULFILLED = 'GET_SHLOMO_STATS_FULFILLED';
export const GET_REFERRAL_CODE_REQUEST_PENDING = 'GET_REFERRAL_CODE_REQUEST_PENDING';
export const GET_REFERRAL_CODE_REQUEST_FULFILLED = 'GET_REFERRAL_CODE_REQUEST_FULFILLED';
export const GET_CROWDFUNFING_CAMPAIGNS_PENDING = 'GET_CROWDFUNFING_CAMPAIGNS_PENDING';
export const GET_CROWDFUNFING_CAMPAIGNS_FULFILLED = 'GET_CROWDFUNFING_CAMPAIGNS_FULFILLED';
export const GET_CHALLENGES_PENDING = 'GET_CROWDFUNFING_CAMPAIGNS_PENDING';
export const GET_CHALLENGES_FULFILLED = 'GET_CHALLENGES_FULFILLED';
export const GET_INTERPRIDE_STEPS_PENDING = 'GET_INTERPRIDE_STEPS_PENDING';
export const GET_INTERPRIDE_STEPS_FULFILLED = 'GET_INTERPRIDE_STEPS_FULFILLED';
export const GET_CLUB_PENDING = 'GET_CLUB_PENDING';
export const GET_CLUB_FULFILLED = 'GET_CLUB_FULFILLED';
export const GET_CLUB_REJECTED = 'GET_CLUB_REJECTED';
export const GET_SFW_CLUB_PENDING = 'GET_SFW_CLUB_PENDING';
export const GET_SFW_CLUB_FULFILLED = 'GET_SFW_CLUB_FULFILLED';
export const GET_SFW_CLUB_REJECTED = 'GET_SFW_CLUB_REJECTED';
