import React from 'react';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
import Credits from './Credits';
import Question from './Question';
import Result from './Result';
const Quiz = (props) => {
    return (React.createElement(React.Fragment, null, props.isFinished ? (React.createElement(Result, { winner: props.winner, onRetry: props.onRetryHandler, meta: props.meta, isUserFromApp: props.isUserFromApp })) : props.isStarted ? (React.createElement(Question, { answers: props.answers, title: props.question.title, embed: props.question.embed ? props.question.embed : null, cover: props.question.cover ? props.question.cover : null, embedRatio: props.question.embedRatio ? props.question.embedRatio : null, onAnswerClick: props.onAnswerClickHandler, quizLength: props.quizLength, answerNumber: props.answerNumber, credits: props.meta.credits })) : (React.createElement(React.Fragment, null,
        props.meta.startCoverType === 'embed' ? (React.createElement(EmbedWrapper, null,
            React.createElement(Embed, { src: props.meta.startCoverSrc, frameBorder: "0" }))) : null,
        props.meta.startCoverType === 'img' ? (React.createElement(CoverWrap, null,
            React.createElement(CoverDesktop, { src: props.meta.startCoverSrcDesktop }),
            React.createElement(CoverMobile, { src: props.meta.startCoverSrcMobile }),
            props.meta.credits ? React.createElement(Credits, { credits: props.meta.credits }) : null)) : null,
        React.createElement(Label, null, props.meta.label),
        React.createElement(Title, null, props.meta.title),
        React.createElement(Subtitle, null, props.meta.subtitle.split('\n').map((item, key) => {
            return (React.createElement("span", { key: key },
                item,
                React.createElement("br", null)));
        })),
        React.createElement(StartButton, { onClick: props.onStartClickHandler }, props.meta.startButtonTitle)))));
};
const EmbedWrapper = styled.div `
  position: relative;
  padding-bottom: 56.25%; /* proportion value to aspect ratio 16:9 (9 / 16 = 0.5625 or 56.25%) */
  height: 0;
  overflow: hidden;
  margin: 20px 0 33px;

  &::before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  ${media.tablet `
    margin: 30px 0 62px;
  `};
`;
const Embed = styled.iframe `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const CoverWrap = styled.div `
  margin: 20px 0 33px;
  position: relative;

  ${media.tablet `
    margin: 30px 0 44px;
  `};
`;
const CoverMobile = styled.img `
  width: 100%;
  display: block;

  ${media.tablet `
    display: none;
  `};
`;
const CoverDesktop = styled.img `
  display: none;

  ${media.tablet `
    display: block;
    width: 667px;
  `};
`;
const Label = styled.div `
  font-family: ${FONTS.SimplonMono.name}, ${FONTS.fallback};
  font-size: 18px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 3px;
  margin-bottom: 13px;

  ${media.tablet `
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  `};
`;
const Title = styled.h1 `
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-size: 28px;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 20px;

  ${media.tablet `
    font-size: 44px;
    line-height: 64px;
    padding-right: 150px;
    margin-bottom: 22px;
  `};
`;
const Subtitle = styled.p `
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-size: 17px;
  color: #fff;
  margin-bottom: 43px;
  word-spacing: pre-wrap;

  ${media.tablet `
    font-size: 21px;
    line-height: 27px;
    margin-bottom: 52px;
  `};
`;
const StartButton = styled.button `
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  font-family: ${FONTS.SimplonNormBold.name}, ${FONTS.fallback};
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  padding: 16px 0;
  letter-spacing: 2px;
  background-color: #fff;

  ${media.tablet `
    width: 326px;
  `};
`;
export default Quiz;
