import styled, { css } from 'styled-components';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
export const RoundSizes = css `
  width: 240px;
  height: 240px;

  ${media.phone_medium `
    width: 300px;
    height: 300px;
  `};

  ${media.tablet `
    width: 480px;
    height: 480px;
  `};

  ${media.landscape `
    width: 300px;
    height: 300px;
  `};

  ${media.desktop `
    width: 365px;
    height: 365px;
  `};

  ${media.desktop_medium `
    width: 456px;
    height: 456px;
  `};

  ${media.desktop_large `
    width: 569px;
    height: 569px;
  `};
`;
const Round = styled.div `
  position: relative;
  border-radius: 50%;
  color: #ffffff;
  background: ${(props) => props.background};
  text-align: center;
  ${RoundSizes};
`;
export const RoundContent = styled.div `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;

  ${media.phone_medium `width: 120%;`};
`;
export const Big = styled.div `
  font-size: 72px;
  line-height: 1.2;
  letter-spacing: -0.06em;
  font-family: ${(props) => props.mono && `'${FONTS.SimplonMono.name}'`};

  ${media.phone_medium `
    font-size: 90px;
  `};

  ${media.tablet `
    font-size: 144px;
  `};

  ${media.landscape `
    font-size: 90px;
  `};

  ${media.desktop `
    font-size: 100px;
  `};

  ${media.desktop_medium `
    font-size: 125px;
  `};

  ${media.desktop_large `
    font-size: 156px;
  `};
`;
export const Text = styled.div `
  font-size: 16px;
  line-height: 1.2;
  font-family: ${(props) => (props.mono ? FONTS.SimplonMono.name : null)};

  ${media.phone_medium `
    font-size: 20px;
  `};

  ${media.tablet `
    font-size: 32px;
  `};

  ${media.landscape `
    font-size: 20px;
  `};

  ${media.desktop `
    font-size: 25px;
  `};

  ${media.desktop_medium `
    font-size: 30px;
  `};

  ${media.desktop_large `
    font-size: 38px;
  `};
`;
export const SmallText = styled(Text) `
  ${media.desktop `
    font-size: 22px;
  `};

  ${media.desktop_medium `
    font-size: 28px;
  `};

  ${media.desktop_large `
    font-size: 34px;
  `};
`;
export const Title = styled.div `
  font-size: 32px;
  letter-spacing: -0.025em;

  ${media.phone_medium `
    font-size: 40px;
  `};

  ${media.tablet `
    font-size: 64px;
  `};

  ${media.landscape `
    font-size: 40px;
  `};

  ${media.desktop `
    font-size: 52px;
  `};

  ${media.desktop_medium `
    font-size: 64px;
  `};

  ${media.desktop_large `
    font-size: 80px;
  `};
`;
export default Round;
