import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import avatarBgSrc from 'assets/SweatPaws/ellipse-bg.png';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
const ResultCard = (props) => {
    const { meta, points, id, role, isBarsFilled, stepsRange } = props;
    const avatar = points['avatar'].image;
    const name = points['name'].label;
    const breed = points['breed'].label;
    const happinessPercent = isBarsFilled.happiness;
    const healthPercent = isBarsFilled.health;
    useEffect(() => {
        analytics.track('results', 'loadingResults', {
            origin: location.origin,
            page: location.pathname,
            quizId: meta.quizId,
            results: points['results'].data,
        });
    }, []);
    return (React.createElement(Container, null,
        React.createElement(AvatarContainer, null,
            React.createElement(AvatarImage, { src: avatar })),
        React.createElement(Header, null,
            React.createElement(Id, null,
                "ID ",
                id),
            React.createElement(Name, null, name),
            React.createElement(Role, null, role)),
        React.createElement(Info, null,
            React.createElement(Subtitle, null, "Breed"),
            React.createElement(Breed, null, breed),
            React.createElement(Bars, null,
                React.createElement(ProgressWrapper, null,
                    React.createElement(StyledSubtitle, null, "Health"),
                    React.createElement(Progress, null,
                        React.createElement(ProgressValue, { percent: healthPercent },
                            healthPercent,
                            "%"))),
                React.createElement(ProgressWrapper, null,
                    React.createElement(StyledSubtitle, null, "Happiness"),
                    React.createElement(Progress, null,
                        React.createElement(ProgressValue, { percent: happinessPercent },
                            happinessPercent,
                            "%")))),
            React.createElement(Subtitle, null, "Daily activity"),
            React.createElement(Activity, null, stepsRange))));
};
const Container = styled.div `
  position: relative;
  margin-bottom: 30px;
  padding: 20px;
  padding-top: 72px;
  background: rgba(26, 255, 217, 0.04);
  box-shadow: -2px -4px 16px 2px rgba(159, 255, 167, 0.3), 2px 4px 16px 4px rgba(26, 255, 217, 0.3),
    inset 2px 4px 16px 8px rgba(159, 255, 167, 0.2), inset -2px -2px 20px 6px rgba(26, 255, 217, 0.2);
  backdrop-filter: blur(20px);
  border-radius: 20px;

  ${media.desktop `
    width: 500px;
    margin-right: auto;
    margin-left: auto;
  `}

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    background: linear-gradient(112.4deg, #e5ff9c 29.63%, #e1ff8c 29.63%, #1affd9 76.7%) border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 20px;
  }
`;
const AvatarContainer = styled.div `
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  overflow: hidden;
  background: url('${avatarBgSrc}');
  background-size: 100%;
  box-shadow: -2px -4px 20px 2px rgba(159, 255, 167, 0.4), 2px 4px 20px 4px rgba(26, 255, 217, 0.4);
  border-radius: 50%;

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    inset: 0;
    border: 2px solid transparent;
    background: linear-gradient(112.4deg, #e5ff9c 29.63%, #e1ff8c 29.63%, #1affd9 76.7%) border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 50%;
  }
`;
const AvatarImage = styled.img `
  display: block;
  width: 100%;
  height: 100%;
`;
const Header = styled.div `
  margin-bottom: 20px;
`;
const Id = styled.div `
  margin-bottom: 4px;
  font-weight: 800;
  font-size: 12px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
`;
const Name = styled.div `
  margin-bottom: 4px;
  font-weight: 800;
  font-size: 25px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const Role = styled.div `
  font-weight: 800;
  font-size: 18px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background: linear-gradient(275.55deg, #ffb3ef 3.2%, #c2c5ff 53%, #b3fff2 97.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const Info = styled.div ``;
const Subtitle = styled.h2 `
  margin-bottom: 4px;
  font-weight: 800;
  font-size: 12px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.6);
`;
const StyledSubtitle = styled(Subtitle) `
  margin-bottom: 10px;
`;
const Breed = styled.div `
  margin-bottom: 12px;
  font-weight: 800;
  font-size: 25px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const Load = (percent) => keyframes `
  0% { width: 0; }
  100% { width: ${percent}%; }
`;
const Bars = styled.div `
  display: flex;
  margin-bottom: 12px;
`;
const ProgressWrapper = styled.div `
  width: 50%;

  &:first-child {
    margin-right: 16px;
  }

  &:first-child > span {
    background: linear-gradient(
      112.4deg,
      rgba(229, 255, 156, 0.1) 29.63%,
      rgba(225, 255, 140, 0.1) 29.63%,
      rgba(26, 255, 217, 0.1) 76.7%
    );

    &::before {
      background: linear-gradient(
          112.4deg,
          rgba(229, 255, 156, 0.3) 29.63%,
          rgba(225, 255, 140, 0.3) 29.63%,
          rgba(26, 255, 217, 0.3) 76.7%
        )
        border-box;
    }

    span {
      color: #44be8a;
      background: linear-gradient(94.86deg, #e5ff9c 4.57%, #e1ff8c 4.58%, #1affd9 98.63%);
    }
  }

  &:last-child > span {
    background: linear-gradient(
      94.6deg,
      rgba(229, 255, 156, 0.1) 6.36%,
      rgba(225, 255, 140, 0.1) 6.37%,
      rgba(255, 218, 26, 0.1) 95.28%
    );

    &::before {
      background: linear-gradient(
          94.6deg,
          rgba(229, 255, 156, 0.3) 6.36%,
          rgba(225, 255, 140, 0.3) 6.37%,
          rgba(255, 218, 26, 0.3) 95.28%
        )
        border-box;
    }

    span {
      color: #aba03e;
      background: linear-gradient(94.6deg, #e5ff9c 6.36%, #e1ff8c 6.37%, #ffda1a 95.28%);
    }
  }
`;
const Progress = styled.span `
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  justify-content: flex-start;
  border-radius: 16px;
  align-items: center;
  position: relative;
  display: flex;
  height: 20px;
  width: 100%;
  border-radius: 16px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid transparent;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 16px;
  }
`;
const ProgressValue = styled.span `
  animation: ${(props) => Load(props.percent)} 3s normal forwards;
  height: calc(100% - 2px);
  width: 0;
  margin: 0 1px;
  padding: 3px 8px;
  font-weight: 800;
  font-size: 10px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 16px;
`;
const Activity = styled.div `
  font-weight: 800;
  font-size: 32px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background: linear-gradient(275.55deg, #ffb3ef 3.2%, #c2c5ff 53%, #b3fff2 97.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
export default ResultCard;
