import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import appstore from '../assets/appstore.png';
import playstore from '../assets/playstore.png';
import starts from '../assets/stars.svg';
const messages = defineMessages({
    appstore: 'App store',
    playstore: 'Google play',
    appstoreRating: '(273k ratings)',
    playstoreRating: '(1.8M ratings)',
});
const Stores = () => {
    return (React.createElement(Layout, null,
        React.createElement(Store, null,
            React.createElement(Logo, { src: appstore }),
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { ...messages.appstore })),
            React.createElement(Stars, null),
            React.createElement(Ratings, null,
                React.createElement("strong", null, "4.6\u00A0"),
                React.createElement(FormattedMessage, { ...messages.appstoreRating }))),
        React.createElement(Store, null,
            React.createElement(Logo, { src: playstore }),
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { ...messages.playstore })),
            React.createElement(Stars, null),
            React.createElement(Ratings, null,
                React.createElement("strong", null, "4.4\u00A0"),
                React.createElement(FormattedMessage, { ...messages.playstoreRating })))));
};
const Layout = styled.div `
  max-width: 360px;
  background: linear-gradient(340.72deg, rgba(255, 255, 255, 0) 37.37%, rgba(255, 255, 255, 0.3) 108.22%);
  border-radius: 25px;
  padding: 20px;
  margin: 0 auto 74px;
  display: flex;
  align-items: flex-start;
  box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.2) inset;
`;
const Store = styled.div `
  flex: 1;
`;
const Logo = styled.img `
  width: 39px;
  display: block;
  margin-bottom: 10px;
`;
const Title = styled.div `
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.002em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 7px;
`;
const Stars = styled.div `
  background-image: url(${starts});
  background-size: contain;
  background-repeat: no-repeat;
  width: 90px;
  height: 16px;
  margin-bottom: 9px;
`;
const Ratings = styled.div `
  font-family: 'Inter';
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: -0.002em;

  strong {
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
  }
`;
export default Stores;
