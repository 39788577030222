import React, { useState } from 'react';
import styled from 'styled-components';
import { logEvent } from '../../utils';
const PAGES = ['intro', 'action', 'outro'];
const ChallengeOffer = () => {
    const [index, setIndex] = useState(0);
    const page = PAGES[index];
    return (React.createElement(Layout, null,
        React.createElement(Title, null, "Challenge Offer"),
        renderPage(page)));
    function renderPage(name) {
        switch (name) {
            case 'intro':
                return (React.createElement(React.Fragment, null,
                    React.createElement(Image, null),
                    React.createElement(Actions, null,
                        React.createElement(Button, { onClick: handleBuy, onPress: handleBuy }, "Buy for 20 SWC"))));
            case 'action':
                return (React.createElement(React.Fragment, null,
                    React.createElement(Image, null),
                    React.createElement(Actions, null,
                        React.createElement(Button, { onClick: handleDiscount }, "Buy with 50% off"),
                        React.createElement(Button, { onClick: handleChallenge }, "Challenge it"))));
            case 'outro':
                return (React.createElement(React.Fragment, null,
                    React.createElement(Image, null),
                    React.createElement(Subtitle, null, "This is a fake, I\u2019m so sorry..."),
                    React.createElement(Progress, null)));
            default:
                return null;
        }
    }
    function handleBuy() {
        logEvent('event@lofi_challengeoffer.buypress');
        setIndex((i) => i + 1);
    }
    function handleDiscount() {
        logEvent('event@lofi_challengeoffer.discountpress');
    }
    function handleChallenge() {
        setIndex((i) => i + 1);
        logEvent('event@lofi_challengeoffer.challengepress');
    }
};
const Layout = styled.div `
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 34px 16px 0px 16px;
  background: #032555;
  background: linear-gradient(-45deg, #032555 0%, #512d90 50%, #b11faa 100%);
  touch-action: pan-x pan-y;
`;
const Title = styled.h1 `
  font-family: 'PFDINMonoPro' !important;
  font-size: 28px;
  letter-spacing: -0.5px;
  line-height: 33.6px;
`;
const Subtitle = styled.h1 `
  font-family: 'PFDINMonoPro' !important;
  font-size: 22px;
  letter-spacing: -0.5px;
  line-height: 33.6px;
`;
const Image = styled.img.attrs(() => ({
    src: 'https://source.unsplash.com/random/800x600',
})) `
  width: 100%;
  height: 20rem;
  border-radius: 0.6rem;
  margin: 1rem 0 1rem 0;
`;
const Actions = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0 1rem 0;
`;
const Button = styled.button `
  width: 90%;
  height: 44px;
  margin-bottom: 1rem;
  border-radius: 13px;
  border: none;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #1b3556;
  background-color: #ffffff;
  user-select: none;
  :focus {
    outline: 0;
  }
`;
function Progress() {
    return (React.createElement(ProgressLayout, null,
        React.createElement(ProgressBackground, null,
            React.createElement(ProgressBar, null))));
}
const ProgressLayout = styled.div `
  display: flex;
  flex: 1;
  padding: 1rem;
`;
const ProgressBackground = styled.div `
  width: 100%;
  height: 2.5rem;
  border: solid;
  border-width: 1px;
  border-radius: 0.6rem;
  border-color: rgba(255, 255, 255, 0.6);
`;
const ProgressBar = styled.div `
  width: 50%;
  height: 100%;
  border-radius: 0.6rem;
  background: repeating-linear-gradient(45deg, #b21faa, #b21faa 10px, #512d90 10px, #512d90 20px);
`;
export default ChallengeOffer;
