import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import logoSrc from 'assets/Home/header__logo.svg';
import { desktop } from '../utils/mediaQueries';
import BurgerNavigation from './BurgerNavigation';
const messages = defineMessages({
    influencer: 'Become an Influencer',
    forgood: 'Sweatcoin for good',
    health: 'Health',
    sweat: 'About SWEAT',
});
const Navbar = ({ children }) => {
    return (React.createElement(Wrapper, { role: "navigation" },
        React.createElement(LogoLink, { to: "/", exact: true },
            React.createElement("img", { src: logoSrc, alt: "SWT Logo", width: "180", height: "35" })),
        React.createElement(BurgerNavigation, null, children),
        React.createElement(Links, null,
            React.createElement(FormattedMessage, { ...messages.influencer }, (msg) => (React.createElement(Link, { href: "https://promote.sweatco.in/", rel: "noreferrer noopener", target: "_blank" }, msg))),
            React.createElement(FormattedMessage, { ...messages.health }, (msg) => (React.createElement(StyledNavLink, { to: "/health", exact: true, target: "_blank" }, msg))),
            children)));
};
const Wrapper = styled.nav `
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  padding: 20px 16px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  backdrop-filter: blur(15px);
  background-color: rgba(29, 21, 40, 0.09);

  @media ${desktop()} {
    padding: 20px 70px;
  }
`;
const LogoLink = styled(NavLink) `
  width: 180px;
  height: 35px;

  img {
    width: 100%;
    height: auto;
  }
`;
const Links = styled.div `
  transform: translateY(-2px);
  display: none;

  a {
  }

  @media ${desktop()} {
    display: block;
  }
`;
const LinkStyle = css `
  font-family: 'Inter', sans-serif;
  color: #ffffff !important;
  font-size: 14px;
  line-height: 1.4;
  margin-left: 24px;
`;
const StyledNavLink = styled(NavLink) `
  ${LinkStyle}
`;
const Link = styled.a `
  ${LinkStyle}
`;
export default Navbar;
