import orderBy from 'lodash/orderBy';
import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import Layout from 'components/Layout';
import Countries from 'constants/Countries.json';
import countries from 'constants/SupportedCountries.json';
import '../styles/select-country.scss';
const messages = defineMessages({
    title: 'Select your country:',
});
export default class SelectCountry extends React.Component {
    render() {
        const countryModels = orderBy(countries.map((c) => ({
            code: c.country,
            name: Countries[c.country] ? Countries[c.country].name : c.name,
            supported: !c.unsupported,
        })), ['supported', 'name'], ['desc', 'asc']);
        return (React.createElement(Layout, { className: "select-country" },
            React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'select-your-country' }] }),
            React.createElement(Layout.Container, null,
                React.createElement("h2", null,
                    React.createElement(FormattedMessage, { ...messages.title })),
                React.createElement("ul", { className: "select-country__list" }, countryModels.map(this.renderCountry)))));
    }
    renderCountry = (country, index) => {
        return (React.createElement("li", { key: index },
            React.createElement("a", { href: `/?country=${country.code}` },
                React.createElement("span", { className: `flag-icon flag-icon-${country.code}` }),
                " ",
                country.name)));
    };
}
