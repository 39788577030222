import { GET_INTERPRIDE_STEPS_FULFILLED } from 'constants/ActionTypes';
const interPrideStepsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_INTERPRIDE_STEPS_FULFILLED: {
            return { ...state, ...action.payload.data };
        }
        default:
            return state;
    }
};
export default interPrideStepsReducer;
