import React from 'react';
import styled from 'styled-components';
import crossIconSrc from 'assets/SweatPaws/banner-cross-icon.svg';
import heartIconSrc from 'assets/SweatPaws/heart-icon.svg';
import { media } from 'components/responsive';
const SmallBanner = (props) => {
    return (React.createElement(Container, { isStarted: props.isStarted },
        React.createElement(CloseButton, { onClick: props.handleCloseBannerBtnClick }),
        React.createElement("p", null,
            React.createElement("strong", null, "SWEATPaws is a project by Sweatcoin:"),
            "a free app that rewards you for your steps,",
            ' ',
            React.createElement("a", { href: "/download", target: "_blank" }, "download now"))));
};
const Container = styled.div `
  position: sticky;
  top: ${(props) => (props.isStarted ? '78px' : '43px')};
  z-index: 3;
  margin-top: 23px;
  margin-left: ${(props) => (props.isStarted ? 'auto' : null)};
  margin-right: ${(props) => (props.isStarted ? '-50px' : 'auto')};
  display: none;
  width: 460px;
  padding: 20px;
  background: linear-gradient(97.63deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.03) 100%);
  backdrop-filter: blur(20px);
  border-radius: 20px;

  ${media.desktop `
    display: block;
  `}

  @media (min-width: 1700px) {
    margin-left: ${(props) => (props.isStarted ? null : 'calc(100% - 50% - 1700px / 2)')};
    margin-right: ${(props) => (props.isStarted ? 'calc(100% - 50% - 1700px / 2)' : 'auto')};
  }

  p {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 0.9;
  }

  strong {
    position: relative;
    display: block;
    margin-bottom: 2px;
    padding-left: 22px;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    opacity: 1;

    &::before {
      content: '';
      position: absolute;
      top: 1px;
      left: 0;
      width: 16px;
      height: 16px;
      background-image: url('${heartIconSrc}');
      background-repeat: no-repeat;
    }
  }

  a {
    border-bottom: 1px solid #ffffff;

    &:hover {
      border-bottom: none;
    }
  }
`;
const CloseButton = styled.button `
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 2;
  width: 16px;
  height: 16px;
  margin: 0;
  padding: 0;
  background-image: url('${crossIconSrc}');
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;

  @media (hover: hover) {
    &:hover {
      opacity: 0.6;
    }
  }
`;
export default SmallBanner;
