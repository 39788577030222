import { SET_PARAMS } from 'constants/ActionTypes';
const paramsReducer = (state = null, action) => {
    switch (action.type) {
        case SET_PARAMS: {
            return action.data;
        }
        default:
            return state;
    }
};
export default paramsReducer;
