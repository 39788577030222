import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import icon1 from '../assets/nav1.png';
import icon2 from '../assets/nav2.png';
import icon3 from '../assets/nav3.png';
import NavigationLink from './NavigationLink';
import Stack from './Stack';
const messages = defineMessages({
    text1: 'Be more active',
    text2: 'Convert steps into rewards',
    text3: 'Spend, auction & donate',
});
const NavigationButtons = ({ onNavLinkClick }) => {
    return (React.createElement(StyledStack, null,
        React.createElement(NavigationLink, { text: React.createElement(FormattedMessage, { ...messages.text1 }), icon: icon1, onNavLinkClick: onNavLinkClick, index: 0 }),
        React.createElement(NavigationLink, { text: React.createElement(FormattedMessage, { ...messages.text2 }), icon: icon2, onNavLinkClick: onNavLinkClick, index: 1 }),
        React.createElement(NavigationLink, { text: React.createElement(FormattedMessage, { ...messages.text3 }), icon: icon3, onNavLinkClick: onNavLinkClick, index: 2 })));
};
const StyledStack = styled(Stack) `
  gap: 10px;
  margin: 0 30px;
`;
export default NavigationButtons;
