import _ from 'lodash';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import sharingUrl from 'assets/sharing.jpg';
import { generateFirebaseQuery } from './firebase';
import platformDetector from './platform';
const useFirebaseQuery = (path, params, options = {}) => {
    const config = useSelector((state) => state.config);
    const { campaign, feature, channel = 'other' } = params;
    const { origin, firebaseDynamicLinkDomain } = config;
    const { encodeLink = true, firebaseQuery = {} } = options;
    const defaultSharingUrl = require('url').resolve(origin, sharingUrl);
    const encodedValues = mapValues(params, (value, key) => {
        return key !== 'deeplink_path' ? encodeURIComponent(value) : value;
    });
    const encodedParams = mapKeys(encodedValues, (value, key) => encodeURIComponent(key));
    const firebasePathParams = queryString.stringify(encodedParams, { encode: false });
    const firebasePath = `${path}?${encodeLink ? encodeURIComponent(firebasePathParams) : firebasePathParams}`;
    const generatedFirebaseQuery = generateFirebaseQuery(`${origin}${firebasePath}`, {
        ct: _.compact([campaign, feature, channel]).join('_'),
        si: defaultSharingUrl,
        // eslint-disable-next-line
        st: "Convert your steps into currency to spend on things you'll love",
        ...firebaseQuery,
    });
    return [generatedFirebaseQuery, getCopyLink, getRedirectLink];
    function getPlatform() {
        return platformDetector(window.navigator.userAgent || window.navigator.vendor || window.opera);
    }
    function getCopyLink() {
        const params = ['link', 'apn', 'isi', 'ibi', 'ius', 'ct', 'pt', '_icp'];
        const copyQuery = pick(mapValues({ ...generatedFirebaseQuery, _icp: 1 }, (value, key) => {
            return ['link'].indexOf(key) !== -1 ? value : encodeURIComponent(value);
        }), params);
        return `${firebaseDynamicLinkDomain}?${queryString.stringify(copyQuery, {
            encode: false,
            sort: (m, n) => params.indexOf(m) >= params.indexOf(n),
        })}`;
    }
    function getRedirectLink() {
        const visitorPlatform = getPlatform();
        if (visitorPlatform === 'iOS') {
            const redirectQuery = mapValues({ ...generatedFirebaseQuery, _fpb: '=' }, (value, key) => {
                return ['link'].indexOf(key) !== -1 ? value : encodeURIComponent(value);
            });
            return `${firebaseDynamicLinkDomain}?${queryString.stringify(redirectQuery, { encode: false })}`;
        }
        else if (visitorPlatform === 'Android') {
            const redirectQuery = mapValues(generatedFirebaseQuery, (value, key) => {
                return ['link'].indexOf(key) !== -1 ? value : encodeURIComponent(value);
            });
            return `${firebaseDynamicLinkDomain}?${queryString.stringify(redirectQuery, { encode: false })}`;
        }
        else {
            return generatedFirebaseQuery.link;
        }
    }
};
export default useFirebaseQuery;
