import React from 'react';
import styled from 'styled-components';
import { useUser } from '../../hooks';
import { logEvent } from '../../utils';
import flowerSrc from './assets/flower.png';
const FirstAchievement = ({ id, type = 10 }) => {
    const user = useUser(id);
    const { steps, percent } = paramsForType(type);
    return (React.createElement(Layout, null,
        React.createElement(Title, null, `You unlocked the ${steps}K achievement!`),
        React.createElement(Label, null, `Congratulations! This week you walked more than ${steps}K steps! These are rough times, congratulations on the hard work!`),
        React.createElement(Box, null,
            React.createElement(Flower, null,
                React.createElement(FlowerIcon, null),
                React.createElement(FlowerTitle, null, `${steps}K`),
                React.createElement(FlowerSubtitle, null, "a week")),
            React.createElement(Subtitle, null, `You are in the top ${percent}% of Sweatcoin worldwide`)),
        React.createElement(ShareButton, { disabled: user == null, onClick: handleShare }, "Share with friends")));
    async function handleShare() {
        try {
            await navigator.share({
                title: "I've unlocked a new Sweatcoin achievement!", //eslint-disable-line
                text: 'Check it out and find motivation to stay healthy!',
                url: `https://sweatco.in/i/${user.username}?channel=achievement&campaign=first`,
            });
            logEvent('event@lofi_firstachievement.sharesuccess');
            alert('Thank you for sharing!');
        }
        catch (error) { }
    }
};
const PERCENTAGES = [55, 30, 24, 16, 9, 4, 2];
function paramsForType(type) {
    return {
        steps: type,
        percent: PERCENTAGES[Number(type) / 10 - 1] || 1,
    };
}
export default FirstAchievement;
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  padding: 34px 16px 0px 16px;
`;
const Title = styled.h1 `
  font-family: PFDINMonoPro !important;
  font-size: 28px;
  letter-spacing: -0.5px;
  line-height: 33.6px;
`;
const Subtitle = styled.h2 `
  font-family: PFDINMonoPro !important;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  margin-left: 22px;
`;
const Label = styled.span `
  font-family: sans-serif;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.4px;
  margin: 16px 0px;
`;
export const Box = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 19px;
  padding: 22px 24px;
  background-color: rgba(0, 20, 48, 0.3);
`;
const FlowerIcon = styled.img.attrs(() => ({
    src: flowerSrc,
})) `
  width: 104px;
  height: 104px;
  position: absolute;
`;
const Flower = styled.div `
  display: flex;
  flex-direction: column;
  min-width: 104px;
  min-height: 104px;
  align-items: center;
  justify-content: center;
  color: black;
`;
const FlowerTitle = styled.p `
  font-family: PFDINMonoPro;
  font-size: 26px;
  letter-spacing: -0.5px;
  color: rgb(248, 167, 70);
  z-index: 1;
  margin-bottom: -6px;
`;
const FlowerSubtitle = styled.p `
  font-family: sans-serif;
  font-size: 13px;
  letter-spacing: -0.1px;
  color: rgb(248, 167, 70);
  z-index: 1;
`;
export const ShareButton = styled.button `
  height: 44px;
  border-radius: 13px;
  border: none;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #1b3556;
  background-color: #ffffff;
  margin: 36px 0px;
`;
