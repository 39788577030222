const createRoutes = () => [
    {
        path: '/launch/co/:referral_code',
        component: require('./pages/ColombiaReferrals').default,
    },
    {
        path: '/launch/co',
        component: require('./pages/Colombia').default,
    },
];
export default createRoutes;
