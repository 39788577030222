import identity from 'lodash/identity';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import sharingUrl from 'assets/sharing.jpg';
import sharingGiftUrl from 'assets/sharing_gift.jpg';
export function generateFirebaseMarketplaceRoute(config, options) {
    const absoluteSharingUrl = require('url').resolve(config.origin, sharingUrl);
    const params = {
        deeplink_path: '/offers',
    };
    const query = options.encodeLink ? encodeURIComponent(queryString.stringify(params)) : queryString.stringify(params);
    const marketplaceLink = `${config.origin}/marketplace?${query}`;
    return generateFirebaseRoute(config, generateFirebaseQuery(marketplaceLink, {
        si: absoluteSharingUrl,
        st: "Convert your steps into currency to spend on things you'll love",
    }));
}
export function generateFirebaseProfileRoute(user, config, options) {
    const absoluteSharingUrl = require('url').resolve(config.origin, sharingUrl);
    const { id, username } = user;
    const params = {
        deeplink_path: `/profile/${id}`,
    };
    const query = options.encodeLink ? encodeURIComponent(queryString.stringify(params)) : queryString.stringify(params);
    const profileLink = `${config.origin}/p/${username}?${query}`;
    return generateFirebaseRoute(config, generateFirebaseQuery(profileLink, {
        deeplink_path: `/profile/${user.id}`,
        si: absoluteSharingUrl,
        st: "Convert your steps into currency to spend on things you'll love",
    }));
}
export function generateFirebaseInviteRoute(user, config, options) {
    return generateFirebaseRoute(config, generateFirebaseInviteQuery(user, config, options));
}
export function generateFirebaseInviteQuery(user, config, options) {
    const absoluteSharingUrl = require('url').resolve(config.origin, sharingUrl);
    const absoluteSharingGiftUrl = require('url').resolve(config.origin, sharingGiftUrl);
    const params = pickBy({
        inviter_id: user.id,
        inviter_name: user.fullname,
        channel: options.channel || 'other',
        feature: options.feature || 'invite',
        campaign: options.campaign,
        ...(options.challenge_id
            ? {
                challenge_id: options.challenge_id,
                deeplink_path: `/walk-with-friends/${options.challenge_id}`,
                feature: 'challenge_invite',
            }
            : {}),
        ...(options.group_id
            ? {
                group_id: options.group_id,
                deeplink_path: `/group/${options.group_id}`,
                feature: 'group_invite',
            }
            : {}),
        ...(options.welcome
            ? {
                deeplink_path: '/onboarding-popup',
            }
            : {}),
    }, identity);
    const invitePath = generateInvitePath(user, params, pick(options, ['encodeLink']));
    return generateFirebaseQuery(`${config.origin}${invitePath}`, {
        ct: params.campaign
            ? `${params.campaign}_${params.feature}_${params.channel}`
            : `${params.feature}_${params.channel}`,
        ...(options.isGift
            ? { si: absoluteSharingGiftUrl, st: 'Claim Your 5 Sweatcoins' }
            : { si: absoluteSharingUrl, st: "Convert your steps into currency to spend on things you'll love" }),
    });
}
export function generateFirebaseCompanyQuery(code, config, options) {
    const absoluteSharingUrl = require('url').resolve(config.origin, sharingUrl);
    const params = {
        channel: options.channel || 'other',
        feature: options.feature || 'company_invite',
        deeplink_path: `/company/${code}`,
        company_invite_code: code,
    };
    const invitePath = generateCompanyPath(params, pick(options, ['encodeLink']));
    return generateFirebaseQuery(`${config.origin}${invitePath}`, {
        ct: `${params.feature}_${params.channel}`,
        si: absoluteSharingUrl,
        st: "Convert your steps into currency to spend on things you'll love",
    });
}
export function generateFirebaseRoute(config, query) {
    return {
        pathname: config.firebaseDynamicLinkDomain,
        query,
    };
}
export function generateInvitePath(user, params, options) {
    return `/loves/${user.username}?${options.encodeLink ? encodeURIComponent(queryString.stringify(params)) : queryString.stringify(params)}`;
}
export function generateCompanyPath(params, options) {
    return `/company/${params.company_invite_code}?${options.encodeLink ? encodeURIComponent(queryString.stringify(params)) : queryString.stringify(params)}`;
}
export function generateFirebaseQuery(link, options) {
    return {
        link: link,
        apn: 'in.sweatco.app',
        isi: '971023427',
        ibi: 'swc',
        ius: 'swc',
        pt: '117705952',
        ...options,
    };
}
