import React from 'react';
import styled, { css } from 'styled-components';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
const renderContent = (props) => {
    const isOverlayOnHover = !!(props.title && props.text);
    return (React.createElement(React.Fragment, null,
        props.index && React.createElement(Index, { isOverlayOnHover: isOverlayOnHover }, props.index),
        props.title && (React.createElement(Title, { noIndex: !props.index, isOverlayOnHover: isOverlayOnHover }, props.title)),
        props.text && React.createElement(Text, { isOverlayOnHover: isOverlayOnHover }, props.text)));
};
const Index__offset_top = 1;
const Index__font_size = 1;
const ContentBox__offset_top = Index__offset_top + Index__font_size;
const ContentBox__offset_round = '14.645%';
const ContentBox__transition_duration = 0.23;
const ContentBox__transition_delay = (ContentBox__transition_duration / 3).toFixed(2);
const Round = (props) => React.createElement("div", { ...props }, props.children || renderContent(props));
const StyledRound = styled(Round) `
  width: 16rem;
  height: 16rem;
  background: ${(props) => props.background || null};
  color: ${(props) => props.color || null};
  background-size: cover;
  border-radius: 50%;
  position: relative;

  ${media.desktop `
    width: 19rem;
    height: 19rem;
  `};
`;
const Index = styled.div `
  position: absolute;
  top: 17%;
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  text-align: center;
  font-family: ${FONTS.SimplonMono.name};
  font-size: ${Index__font_size}rem;
  line-height: 1;
  letter-spacing: -0.06rem;
  transition: opacity ${ContentBox__transition_duration}s;
  transition-delay: ${ContentBox__transition_delay}s;

  ${StyledRound}:hover && {
    transition-delay: 0;
    opacity: ${(props) => (props.isOverlayOnHover ? 0 : 1)};
  }

  ${media.desktop_large `
    font-size: 1.5rem;
  `};
`;
const ContentBox = css `
  position: absolute;
  margin: ${(props) => (props.noIndex ? ContentBox__offset_round : `${ContentBox__offset_top}rem`)} 0
    ${ContentBox__offset_round};
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  white-space: normal;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;
const Title = styled.div `
  font-size: 1.2rem;
  line-height: 1.2;
  transition: opacity ${ContentBox__transition_duration}s;
  opacity: 1;
  font-family: ${FONTS.SimplonMono.name};
  ${ContentBox};
  margin-top: ${ContentBox__offset_round};
  transition-delay: ${ContentBox__transition_delay}s;

  ${StyledRound}:hover && {
    transition-delay: 0;
    opacity: ${(props) => (props.isOverlayOnHover ? 0 : 1)};
  }

  ${media.tablet `
    font-size: 1.1rem;
  `};
  ${media.desktop `
    font-size: 1.7rem;
  `};
  ${media.desktop_large `
    font-size: 1.75rem;
  `};
`;
const Text = styled.div `
  font-size: 0.6rem;
  line-height: 1.2;
  transition: opacity ${ContentBox__transition_duration}s;
  opacity: ${(props) => (props.isOverlayOnHover ? 0 : 1)};
  ${ContentBox};
  margin-top: ${ContentBox__offset_round};

  ${media.tablet `
    font-size: 0.5rem;
  `};
  ${media.desktop `
    align-items: center;
    font-size: 0.65rem;
  `};
  ${media.desktop_medium `
    font-size: 0.7rem;
  `};
  ${media.desktop_large `
    font-size: 0.85rem;
  `};

  ${StyledRound}:hover && {
    opacity: 1;
    transition-delay: ${ContentBox__transition_delay}s;
  }
`;
export const PrincipleRound = styled(StyledRound) `
  // for mobile
  ${(props) => props.text && !props.title
    ? css `
          ${Text} {
            opacity: 1;
          }
        `
    : null}

  ${Index} {
    font-size: 1.7rem;

    ${media.tablet `font-size: 0.7rem;`};
    ${media.desktop `font-size: 1.1rem;`};
    ${media.desktop_medium `font-size: 1.2rem;`};
  }

  ${Title} {
    font-size: 1.7rem;

    ${media.tablet `font-size: 0.7rem;`};
    ${media.desktop `font-size: 1.1rem;`};
    ${media.desktop_medium `font-size: 1.2rem;`};
  }

  ${Text} {
    font-size: 1rem;

    ${media.tablet `font-size: 0.45rem;`};
    ${media.desktop `font-size: 0.65rem;`};
    ${media.desktop_medium `font-size: 0.75rem;`};
  }

  // for mobile
  ${(props) => props.title && !props.text
    ? css `
          ${Index} {
            opacity: 1;
          }

          ${Title} {
            opacity: 1;
          }
        `
    : null}
`;
export default StyledRound;
