import url from 'url';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import downloadIcon from 'assets/Challenges/download_icon.svg';
import sharingSrc from 'assets/Challenges/personal-challenge.jpg';
import clockIconSrc from 'assets/GroupOffers/clock.svg';
import phone from 'assets/GroupOffers/group_offers.png';
import joinerIconSrc from 'assets/GroupOffers/joiner.png';
import swcIconSrc from 'assets/GroupOffers/swc.svg';
import logo_src from 'assets/Home/header__logo.svg';
import Button from 'components/Button';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { makeOfferSelector, makeProfileSelector } from 'selectors/invites';
import About from '../components/About';
import DesktopView from '../components/DesktopView';
import HowItWorks from '../components/HowItWorks';
import Join from '../components/Join';
import Popup from '../components/Popup';
import { useFirebaseEssentials } from '../utils/firebase';
const GROUP_SIZE = 5;
const messages = defineMessages({
    // Mobile
    mobileTitle: 'Limited time only! Download Sweatcoin and claim {offer}',
    mobileNote: 'Unlock this offer when 5 people join.',
    mobileButtonTitle: 'Install Sweatcoin App',
    joinButtonTitle: 'Accept Challenge',
    // Desktop
    desktopTitle: 'Grab your phone!',
    desktopDescription: 'You need to open the invitation link on your mobile device. Please copy the link or scan the QR code with your phone.',
    desktopButtonTitle: 'Copy the Link',
    copyButtonTitle: 'Link copied',
    // How it works
    howItWorksTitle: 'How it works',
    step_1: 'Download the sweatcoin app.',
    step_2: 'Join the group offer and invite friends to fill the spots!',
    step_3: "When 5 people have signed up, you'll all be able to claim the offer!",
    // Popup
    popupTitle: 'Want to learn more?',
    popupDescription: 'Install the Sweatcoin app to see more details and join this challenge. ',
});
const GroupOffers = (props) => {
    const { match, location } = props;
    const { params } = match;
    const [isPopupVisible, setPopupVisible] = useState(false);
    // Profile & meta
    const profileSelector = useMemo(makeProfileSelector, []);
    const profile = useSelector((state) => profileSelector(state, params));
    const offerSelector = useMemo(makeOfferSelector, []);
    const offer = useSelector((state) => offerSelector(state, params));
    const { firebaseData: { firebaseQuery, getRedirectLink }, tracking: { channel, campaign, feature, platform }, buttonsRefs: [topButton, bottomButton], config: { origin }, } = useFirebaseEssentials({
        owner: profile,
        deeplink_path: `/go/${profile.username}/${params.offerId}/${params.groupId}`,
        feature: 'group_offers_invite',
    });
    const pageLink = `${origin}${location.pathname}${location.search}`;
    const absoluteSharingUrl = url.resolve(origin, sharingSrc);
    const priceToFixed = offer.price.toFixed(2);
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'group_offers-page' },
                { name: 'event-params', content: { page_type: 'invite_page', inviter_id: profile.id } },
                { property: 'og:image', content: absoluteSharingUrl },
            ], link: [
                { rel: 'image_src', href: absoluteSharingUrl },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap',
                },
            ] }),
        isPopupVisible ? React.createElement(Popup, { onClickHandler: setPopupVisible, messages: messages }) : null,
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(DesktopView, { qrCodeLink: getCopyLink(), pageLink: pageLink, phone: phone, messages: messages }),
            React.createElement(MobileView, null,
                React.createElement(FormattedMessage, { ...messages.mobileTitle, values: {
                        offer: offer.title,
                    } }, (title) => React.createElement(Title, null, title)),
                firebaseQuery ? (React.createElement(FormattedMessage, { ...messages.mobileButtonTitle }, (title) => (React.createElement(StyledButton, { title: title, ref: topButton, link: firebaseQuery.link, onClick: handleClick },
                    React.createElement(DownloadIcon, { src: downloadIcon }))))) : null,
                React.createElement(Preview, { onClick: () => setPopupVisible(true) },
                    React.createElement(Badge, null, "Limited time"),
                    React.createElement(PriceBadge, null, priceToFixed),
                    React.createElement(OfferImage, { src: offer.featured_image, alt: offer.title }),
                    React.createElement(PreviewInfo, null,
                        React.createElement(PreviewTitle, null, offer.title),
                        React.createElement(PreviewText, null,
                            React.createElement(FormattedMessage, { ...messages.mobileNote })),
                        React.createElement(Joiners, null, [...Array(GROUP_SIZE)].map((e, i) => (React.createElement(Joiner, { src: joinerIconSrc, key: i, alt: `Joiner ${i + 1}` })))))),
                React.createElement(HowItWorks, { messages: messages }),
                React.createElement(About, null),
                React.createElement(Join, null, firebaseQuery ? (React.createElement(FormattedMessage, { ...messages.joinButtonTitle }, (title) => (React.createElement(JoinButton, { title: title, ref: bottomButton, link: firebaseQuery.link, onClick: handleClick })))) : null)))));
    function handleClick(event) {
        event.preventDefault();
        analytics.track('GroupOffersPage', 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink();
    }
};
GroupOffers.fetchData = ({ match: { params } }, { dispatch }) => {
    return Promise.all([
        dispatch(ApiActions.searchUserByUsername(params.username)),
        params.offerId ? dispatch(ApiActions.prefetchOffer(params.offerId)) : null,
    ]);
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(122deg, #578cdb 0%, #162f79 100%);
`;
const Container = styled.div `
  position: relative;
  padding: 30px 0;
  margin: 0 auto;

  ${media.tablet `
    padding: 30px 0;
    width: 700px;
    display: block;
  `};

  ${media.desktop `
    width: 1000px;
  `};

  ${media.desktop_medium `
    width: 1200px;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
  padding: 0 16px;
`;
const Logo = styled.img `
  position: relative;
  width: 150px;

  ${media.tablet `
    width: 239px;
  `};
`;
const MobileView = styled.div `
  display: flex;
  flex-direction: column;

  ${media.tablet `
    display: none;
  `};
`;
const Preview = styled.div `
  position: relative;
  margin: 24px 16px 0;
  width: calc(100% - 32px);
  height: auto;
  display: block;
  border-radius: 16px;
  overflow: hidden;
`;
const PreviewInfo = styled.div `
  padding: 16px;
  padding-bottom: 24px;
  font-weight: 700;
  font-size: 26px;
  color: #ffffff;
  background: linear-gradient(105deg, #b81fff 0%, hsl(342, 100%, 58%) 100%);
`;
const PreviewTitle = styled.p `
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 26px;
  color: #ffffff;
`;
const PreviewText = styled.p `
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1.2;
  color: #ffffff;
`;
const OfferImage = styled.img `
  display: block;
  width: 100%;
  height: 268px;
  object-fit: cover;
`;
const Joiners = styled.div `
  display: flex;
  justify-content: space-between;
  max-width: 350px;
`;
const Joiner = styled.img `
  display: block;
  width: calc(20% - 10px);
  max-width: 60px;
  border-radius: 50%;
`;
const Badge = styled.span `
  position: absolute;
  top: 20px;
  left: 15px;
  padding: 5px 12px;
  padding-left: 30px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  background-color: rgba(0, 20, 48, 0.8);
  border-radius: 16px;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 10px;
    width: 15px;
    height: 15px;
    background-image: url(${clockIconSrc});
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    transform: translateY(-50%);
  }
`;
const PriceBadge = styled(Badge) `
  right: 15px;
  left: auto !important;
  padding-left: 26px !important;

  &::before {
    background-image: url(${swcIconSrc}) !important;
  }
`;
const Title = styled.h1 `
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  color: #ffffff;
  font-size: 28px;
  letter-spacing: -0.2px;
  line-height: 1.3;
  font-style: normal;
  margin-top: 20px;
  margin-bottom: 24px;
  padding: 0 16px;
  word-wrap: break-word;
`;
const StyledButton = styled(Button) `
  width: calc(100% - 32px) !important;
  margin: 0 16px;
`;
const DownloadIcon = styled.img `
  width: 18px;
  display: inline-block;
  margin-right: 8px;
`;
const JoinButton = styled(StyledButton) `
  background-color: rgba(255, 255, 255, 0.3);
  color: #ffffff !important;
`;
export default GroupOffers;
