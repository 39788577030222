import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import QrCode from 'components/QrCode';
import { media } from 'components/responsive';
import logo_src from '../../../assets/Home/header__logo.svg';
import phone from '../../../assets/UpdateApp/phone_fade.png';
import mountains from '../../../assets/mountains-phone.png';
import AppLinks from '../../Home/components/AppLinks';
const configSelector = (state) => state.config;
const UpdateApp = ({ location }) => {
    // Config & firebase
    const config = useSelector(configSelector);
    const { origin } = config;
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'update-app-page' }] }),
        React.createElement(Mountains, null),
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(Phone, { src: phone }),
            React.createElement(Title, null, "Update to\u00A0the\u00A0latest app version to explore"),
            React.createElement(StyledQrCode, { link: `${origin}${location.pathname}` }),
            React.createElement(StyledAppLinks, { isMobile: true, container: Container }))));
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: radial-gradient(165.2% 80% at 33.6% 6%, #ffb175 0%, hsl(321, 100%, 50%) 50%, rgba(37, 19, 95, 1) 100%);
`;
const Container = styled.div `
  position: relative;
  padding: 30px;

  ${media.tablet `
    width: 700px;
    padding: 30px 0;
    margin: 0 auto;
  `};

  ${media.desktop `
    width: 1000px;
    padding: 30px 0;
    margin: 0 auto;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
`;
const Logo = styled.img `
  position: relative;
  width: 197px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
`;
const Phone = styled.img `
  display: none;

  ${media.tablet `
    display: block;
    width: 330px;
    position: absolute;
    top: 35px;
    right: 0;
  `};

  ${media.desktop `
    display: block;
    width: 413px;
    position: absolute;
    top: 35px;
    right: 0;
  `};
`;
const Title = styled.h1 `
  margin-top: 40px;
  font-family: ${FONTS.SimplonNormBold.name}, ${FONTS.fallback};
  font-size: 48px;
  line-height: 64px;
  font-weight: 1000;
  color: #ffffff;

  ${media.tablet `
    width: 330px;
    font-size: 56px;
    line-height: 72px;
  `};

  ${media.desktop `
    margin-top: 100px;
    width: 560px;
    font-size: 64px;
    line-height: 84px;
  `};
`;
const StyledQrCode = styled(QrCode) `
  display: none;

  ${media.tablet `
    margin: 40px 0 0;
    display: block;
  `};
`;
const StyledAppLinks = styled(AppLinks) `
  position: static !important;
  margin: 60px 0 0 0 !important;

  ${media.tablet `
    width: 330px;
  `};
`;
const Mountains = styled.div `
  background: url(${mountains}) no-repeat center bottom;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.3;

  ${media.tablet `
    display: none;
  `};
`;
export default UpdateApp;
