import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import Layout from 'components/Layout';
import '../styles/contacts.scss';
const messages = defineMessages({
    sell: 'If you want to sell your products for Sweatcoins and get our users to see them featured on our Marketplace:',
    team: 'To become a part of Team Sweatcoin:',
    info: 'For any other stuff:',
});
export default class Contacts extends React.Component {
    render() {
        return (React.createElement(Layout, { className: "contacts" },
            React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'contacts' }] }),
            React.createElement(Layout.Container, null,
                React.createElement("section", { className: "contacts__content" },
                    React.createElement("div", { className: "contacts__entry" },
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { ...messages.sell })),
                        React.createElement("h2", null,
                            React.createElement("a", { href: "mailto:partners@sweatco.in" }, "partners@sweatco.in"))),
                    React.createElement("div", { className: "contacts__entry" },
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { ...messages.team })),
                        React.createElement("h2", null,
                            React.createElement("a", { href: "mailto:hire@sweatco.in" }, "hire@sweatco.in"))),
                    React.createElement("div", { className: "contacts__entry" },
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { ...messages.info })),
                        React.createElement("h2", null,
                            React.createElement("a", { href: "mailto:info@sweatco.in" }, "info@sweatco.in")))))));
    }
}
