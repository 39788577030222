import React from 'react';
import styled from 'styled-components';
import bg from '../assets/navBg.png';
const NavigationLink = (props) => {
    const { index, text, icon, onNavLinkClick } = props;
    return (React.createElement(Layout, { href: "#cards", onClick: (e) => onNavLinkClick(e, index) },
        React.createElement(Icon, { src: icon, alt: "" }),
        React.createElement(Text, null, text)));
};
const Layout = styled.a `
  width: 369px;
  height: 86px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background: url(${bg}) no-repeat center center;
  background-size: cover;
  border-radius: 55px;
  padding-left: 134px;
  padding-right: 36px;
`;
const Text = styled.div `
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.002em;
  color: rgba(255, 255, 255, 0.8);
`;
const Icon = styled.img `
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: auto;
`;
export default NavigationLink;
