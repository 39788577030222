import _ from 'lodash';
import React from 'react';
class OnVisible extends React.Component {
    connect = () => {
        const target = this.getRef();
        if (target) {
            this.observer.observe(target);
        }
        else {
            console.warn('Child component needs ref prop', this.props.children);
        }
    };
    getRef = () => {
        // TODO get children element without ref required
        const ref = _.get(this, 'props.children.ref', null);
        let target;
        if (ref !== null) {
            target = typeof ref === 'function' ? ref()[0] : _.get(ref, 'current', null);
        }
        return target;
    };
    disconect = () => {
        this.observer.disconnect();
    };
    onVisible = () => {
        this.props.onVisible && this.props.onVisible();
    };
    onHide = () => {
        this.props.onHide && this.props.onHide();
    };
    componentDidUpdate(prevProps) {
        if (!prevProps.disabled && this.props.disabled) {
            this.disconect();
        }
    }
    componentDidMount() {
        const options = {
            threshold: this.props.threshold || 0.8,
            trackVisibility: true,
            delay: 100,
        };
        this.observer = new IntersectionObserver((entries) => {
            const target = entries ? entries[0] : null;
            if (target.isIntersecting) {
                this.onVisible();
            }
            else {
                this.onHide();
            }
        }, options);
        this.connect();
    }
    componentWillUnmount() {
        this.disconect();
        const target = this.getRef();
        this.observer.unobserve(target);
    }
    render() {
        return React.createElement(React.Fragment, null, this.props.children);
    }
}
export default OnVisible;
