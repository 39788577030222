export const breakpoints = {
    desktop_extra_large: 2400,
    desktop_large: 1919,
    desktop_medium: 1440,
    desktop: 1024,
    tablet: 768,
    phone_medium: 390,
};
export const mediaQueries = (key) => {
    return (style) => `@media (min-width: ${breakpoints[key]}px) { ${style} }`;
};
export const size = (key) => {
    return () => `(min-width: ${breakpoints[key]}px)`;
};
export const desktop_medium = () => `(min-width: ${breakpoints.desktop_medium}px)`;
export const desktop = () => `(min-width: ${breakpoints.desktop}px)`;
export const tablet = () => `(min-width: ${breakpoints.tablet}px)`;
