import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { findDOMNode } from 'react-dom';
import Footer from 'routes/Home/components/Footer';
import NewHeader from 'routes/Home/components/Header';
import Header from './Header';
import 'styles/layout';
export default class Layout extends React.Component {
    static propTypes = {
        header: PropTypes.bool,
    };
    static defaultProps = {
        header: true,
        headerOptions: {},
    };
    componentDidMount() {
        this._mount();
    }
    componentWillUnmount() {
        this._unmount();
    }
    _mount() {
        if (this.refs.header) {
            this._sticky = require('stickyfill')();
            this._sticky.add(findDOMNode(this.refs.header));
        }
        else {
            this._unmount();
        }
    }
    _unmount() {
        if (this._sticky) {
            this._sticky.kill();
            delete this._sticky;
        }
    }
    render() {
        return (React.createElement("section", { className: classnames(this.props.className, 'layout') },
            React.createElement("div", { className: "layout__container" },
                this.props.header ? this._renderHeader() : null,
                React.createElement("section", { className: "layout__content" }, this.props.children)),
            React.createElement("div", { className: "layout__footer" },
                React.createElement(Footer, null))));
    }
    _renderHeader = () => (this.props.newHeader ? this._newHeader() : this._oldHeader());
    _oldHeader = () => (React.createElement("div", { className: "layout__header", ref: "header", style: { flexShrink: 0 } },
        React.createElement(Header, { ...this.props.headerOptions })));
    _newHeader = () => React.createElement(NewHeader, { embed: true, logoAsset: this.props.logoAsset });
}
function component(type) {
    class LayoutComponent extends React.Component {
        render() {
            return (React.createElement("div", { ...this.props, className: classnames(this.props.className, `layout-${type}`) }, this.props.children));
        }
    }
    return LayoutComponent;
}
Layout.Container = component('container');
Layout.Content = component('content');
