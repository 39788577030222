import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import selectArrowSrc from 'assets/SweatPaws/select-arrow.svg';
import { media } from 'components/responsive';
const AnswersItem = (props) => {
    const { points, questionId, handleAnswer, randomNum, isActive, answer, index, setActiveId, type } = props;
    const [inputValue, setInputValue] = useState('');
    const [selectedOption, setSelectedOption] = useState(null);
    const [isAnswerSelected, setAnswerSelected] = useState(false);
    const activeStyles = {
        boxShadow: 'inset 2px 4px 16px rgba(159, 255, 167, 0.25), inset -2px -2px 16px rgba(26, 255, 217, 0.3)',
        filter: 'drop-shadow(-2px -4px 16px rgba(159, 255, 167, 0.4)) drop-shadow(2px 4px 16px rgba(26, 255, 217, 0.4))',
        backdropFilter: 'blur(20px)',
        borderRadius: '4px',
        opacity: 1,
    };
    const selectStyles = {
        indicatorSeparator: () => ({ display: 'none' }),
        container: () => ({
            positiom: 'relative',
            width: '100%',
        }),
        control: (base, state) => ({
            ...base,
            position: 'relative',
            height: '57px',
            padding: '0 12px',
            background: 'transparent',
            paddingRight: '40px',
            opacity: state.isFocused || isAnswerSelected ? null : 0.6,
            background: state.isFocused && !isAnswerSelected
                ? 'linear-gradient(112.4deg, rgba(229, 255, 156, 0.14) 29.63%, rgba(225, 255, 140, 0.14) 29.63%, rgba(26, 255, 217, 0.14) 76.7%)'
                : 'transparent',
            boxShadow: isAnswerSelected
                ? 'inset 2px 4px 16px rgba(159, 255, 167, 0.25), inset -2px -2px 16px rgba(26, 255, 217, 0.3)'
                : null,
            filter: isAnswerSelected
                ? 'drop-shadow(-2px -4px 16px rgba(159, 255, 167, 0.4)) drop-shadow(2px 4px 16px rgba(26, 255, 217, 0.4))'
                : null,
            transition: 'border-color 0.2s ease',
            backdropFilter: 'blur(20px)',
            borderRadius: '4px',
            border: 'none',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '16px',
                right: '20px',
                width: '24px',
                height: '24px',
                backgroundImage: `url(${selectArrowSrc})`,
                backgroundRepeat: 'no-repeat',
                transform: state.menuIsOpen ? 'rotate(0)' : 'rotate(-180deg)',
                transition: 'All .3s',
                zIndex: '1',
            },
            '&::after': {
                content: '""',
                position: 'absolute',
                inset: '0',
                border: isAnswerSelected ? '2px solid transparent' : '1px solid transparent',
                background: state.isFocused || isAnswerSelected
                    ? 'linear-gradient(112.4deg, #E5FF9C 29.63%, #E1FF8C 29.63%, #1AFFD9 76.7%) border-box'
                    : 'linear-gradient(112.4deg, rgba(229, 255, 156, 0.4) 29.63%, rgba(225, 255, 140, 0.4) 29.63%, rgba(26, 255, 217, 0.4) 76.7%) border-box',
                borderRadius: '4px',
            },
        }),
        menu: (base) => ({
            ...base,
            top: 8,
            position: 'relative',
            height: '245px',
            overflow: 'hidden',
            margin: 0,
            background: 'transparent',
            padding: '0 8px',
            border: '1px solid #E5FF9C',
            borderRadius: '4px',
            zIndex: 10,
            '@media only screen and (min-width: 1074px)': {
                border: 'none',
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    inset: 0,
                    border: '1px solid transparent',
                    background: 'linear-gradient(112.4deg, #E5FF9C 29.63%, #E1FF8C 29.63%, #1AFFD9 76.7%) border-box',
                    borderRadius: '4px',
                },
            },
        }),
        menuList: (base) => ({
            ...base,
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            }
        }),
        placeholder: (base) => ({
            ...base,
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '25px',
            letterSpacing: '0.02em',
            textTransform: 'uppercase',
            color: '#FFFFFF',
        }),
        singleValue: (base) => ({
            ...base,
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '25px',
            letterSpacing: '0.02em',
            textTransform: 'uppercase',
            color: '#FFFFFF',
        }),
        input: (base) => ({
            ...base,
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '25px',
            letterSpacing: '0.02em',
            textTransform: 'uppercase',
            color: '#FFFFFF',
        }),
        option: (styles) => ({
            ...styles,
            position: 'relative',
            zIndex: 8,
            overflow: 'hidden',
            background: 'none',
            fontWeight: '600',
            padding: '13px 12px',
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '0.02em',
            textTransform: 'uppercase',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: '#FFFFFF',
            '&:hover': {
                '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    zIndex: '-1',
                    transform: 'translateY(-50%)',
                    width: '100%',
                    height: 'calc(100% - 10px)',
                    background: 'linear-gradient(112.4deg, rgba(50, 0, 48, 0.3) 29.63%, rgba(0, 39, 33, 0.3) 76.7%)',
                    borderRadius: '4px',
                },
            },
            '&:active': {
                background: 'none',
            },
            '&:first-of-type': {
                marginTop: '10px',
            },
            '&:last-child': {
                marginBottom: '10px',
            },
        }),
    };
    useEffect(() => {
        if (points[questionId] !== '' && points[questionId].label.length) {
            setAnswerSelected(true);
            setInputValue(points[questionId].label);
        }
    }, []);
    const handleChange = (selectedOption) => {
        setAnswerSelected(true);
        setSelectedOption;
        handleAnswer(questionId, {
            label: selectedOption.label,
            hours: selectedOption.hours,
            text: selectedOption.text,
        });
    };
    return (React.createElement(React.Fragment, null, (() => {
        switch (type) {
            case 'select':
                return (React.createElement(Select, { defaultValue: points[questionId].label
                        ? { label: points[questionId].label, value: points[questionId].label }
                        : selectedOption, options: answer, styles: selectStyles, placeholder: 'START TYPING...', menuShouldScrollIntoView: false, components: {
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                    }, onChange: handleChange, classNamePrefix: "react-select" }));
            case 'input':
                return (React.createElement(InputContainer, { inputValue: inputValue },
                    React.createElement("input", { type: "text", placeholder: 'START TYPING...', value: inputValue, onInput: (e) => {
                            setInputValue(e.target.value);
                            handleAnswer(questionId, { label: e.target.value, randomNum: randomNum });
                        } })));
            case 'multiple':
                return (React.createElement(Answer, { style: isActive ? activeStyles : {}, className: isActive ? 'active' : '', onClick: () => {
                        setActiveId(index);
                        handleAnswer(questionId, { label: answer.label, value: answer.value });
                    } },
                    isActive ? React.createElement(BlurredElement, null) : null,
                    React.createElement(Text, null, answer.label)));
            case 'multiple-images':
                return (React.createElement(AnswerImage, { onClick: () => {
                        setActiveId(index);
                        handleAnswer(questionId, { label: answer.label, image: answer.image, shareImage: answer.shareImage });
                    } },
                    React.createElement(ImageContainer, { isActive: isActive },
                        React.createElement(Image, { src: answer.image })),
                    React.createElement(Caption, { isActive: isActive }, answer.label)));
            default:
                return null;
        }
    })()));
};
const AnswerImage = styled.button `
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 170px;
  margin-bottom: 20px;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;

  @media (max-width: 389px) {
    width: 140px;
  }

  &:last-child {
    @media (max-width: 599px) {
      margin: 0 auto;
    }
  }
`;
const ImageContainer = styled.div `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 170px;
  margin-bottom: 12px;
  overflow: hidden;
  background: ${(props) => (props.isActive ? 'rgba(26, 255, 217, 0.2)' : 'rgba(255, 255, 255, 0.15)')};
  background-blend-mode: overlay;
  backdrop-filter: blur(20px);
  border-radius: 50%;
  opacity: ${(props) => (props.isActive ? 1 : 0.8)};
  box-shadow: ${(props) => props.isActive
    ? '-2px -4px 20px 2px rgba(159, 255, 167, 0.4), 2px 4px 20px 4px rgba(26, 255, 217, 0.4), inset 2px 4px 16px 8px rgba(159, 255, 167, 0.25), inset -2px -2px 30px 6px rgba(26, 255, 217, 0.3)'
    : null};

  @media (max-width: 389px) {
    width: 140px;
    height: 140px;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    inset: 0;
    border: 2px solid transparent;
    background: ${(props) => props.isActive
    ? 'linear-gradient(112.4deg, #e5ff9c 29.63%, #e1ff8c 29.63%, #1affd9 76.7%) border-box'
    : 'linear-gradient(203.24deg,#ff71c7 12.66%,rgba(158, 149, 196, 0) 46.01%,rgba(106, 127, 202, 0) 58.8%,#928eff 90%) border-box'};
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 50%;
  }
`;
const Image = styled.img `
  display: block;
  width: 100%;
  height: 100%;
`;
const Caption = styled.p `
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  opacity: ${(props) => (props.isActive ? 1 : 0.4)};
`;
const Answer = styled.button `
  width: 100%;
  padding: 0;
  margin-bottom: 12px;
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  height: 57px;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 16px 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  border-radius: 4px;

  ${media.desktop `
    margin-bottom: 16px;
  `};

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 1px solid transparent;
    background: linear-gradient(
        112.4deg,
        rgba(229, 255, 156, 0.4) 29.63%,
        rgba(225, 255, 140, 0.4) 29.63%,
        rgba(26, 255, 217, 0.4) 76.7%
      )
      border-box;
    mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: 4px;
  }

  &::after {
    content: none;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: inset 2px 4px 16px rgba(159, 255, 167, 0.25), inset -2px -2px 16px rgba(26, 255, 217, 0.3);
    filter: drop-shadow(-2px -4px 16px rgba(159, 255, 167, 0.4)) drop-shadow(2px 4px 16px rgba(26, 255, 217, 0.4));
    border-radius: 4px;
  }

  &.active {
    &::before {
      border: 2px solid transparent;
      background: linear-gradient(112.4deg, #e5ff9c 29.63%, #e1ff8c 29.63%, #1affd9 76.7%) border-box;
    }

    &::after {
      content: '';
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;
const BlurredElement = styled.span `
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(35px);
  border-radius: 4px;
`;
const InputContainer = styled.div `
  position: relative;
  width: 100%;

  input {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 57px;
    padding: 0 12px;
    padding-right: 30px;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
    background: transparent;
    box-shadow: ${(props) => props.inputValue
    ? 'inset 2px 4px 16px rgba(159, 255, 167, 0.25), inset -2px -2px 16px rgba(26, 255, 217, 0.3)'
    : null};
    filter: ${(props) => props.inputValue
    ? 'drop-shadow(-2px -4px 16px rgba(159, 255, 167, 0.4)) drop-shadow(2px 4px 16px rgba(26, 255, 217, 0.4))'
    : null};
    transition: border-color 0.2s ease;
    border-radius: 4px;
    border: none;
    outline: none;

    ${media.desktop `
      height: 62px;
      padding: 0 16px;
    `};
  }

  &::before {
    content: ${(props) => (props.inputValue ? '""' : 'none')};
    position: absolute;
    width: 100%;
    height: 100%;
    filter: blur(35px);
    background: linear-gradient(104.74deg, rgba(182, 255, 89, 0.3) 25.73%, rgba(14, 255, 255, 0.3) 66.52%);
  }

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border: ${(props) => (props.inputValue ? '2px solid transparent' : '1px solid transparent')};
    background: ${(props) => props.inputValue
    ? 'linear-gradient(112.4deg, #E5FF9C 29.63%, #E1FF8C 29.63%, #1AFFD9 76.7%) border-box'
    : 'linear-gradient(112.4deg,rgba(229, 255, 156, 0.4) 29.63%,rgba(225, 255, 140, 0.4) 29.63%,rgba(26, 255, 217, 0.4) 76.7%) border-box'};
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    border-radius: 4px;
    opacity: ${(props) => (props.inputValue ? 1 : 0.6)};
  }
`;
const Text = styled.p `
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;

  ${media.desktop `
    font-size: 24px;
    line-height: 30px;
    padding: 28px 18px 30px;
    min-height: 100%;
  `};
`;
export default AnswersItem;
