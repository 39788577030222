import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { media } from 'components/responsive';
import Footer from '../../Home/components/Footer';
import CaseStudy from '../components/Health/CaseStudy';
import Header from '../components/Health/Header';
import Helps from '../components/Health/Helps';
import Partner from '../components/Health/Partner';
import Statistics from '../components/Health/Statistics';
import Testimonials from '../components/Health/Testimonials';
import Awards from '../components/Health/Awards';
const messages = defineMessages({
    title: 'How Sweatcoin works with health partners to tackle the global pandemic of inactivity',
    subtitle: 'Making incentivised movement a key tenet of a preventative healthcare strategy. ',
});
const HealthPage = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, null,
            React.createElement(Helmet, { link: [
                    {
                        rel: 'stylesheet',
                        href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Prompt&family=Inconsolata:wght@800&display=swap',
                    },
                ] }),
            React.createElement(Header, null),
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { ...messages.title })),
            React.createElement(Subtitle, null,
                React.createElement(FormattedMessage, { ...messages.subtitle })),
            React.createElement(Statistics, null),
            React.createElement(Helps, null),
            React.createElement(Partner, null),
            React.createElement(CaseStudy, null),
            React.createElement(Testimonials, null),
            React.createElement(Awards, null)),
        React.createElement(Footer, null)));
};
const Layout = styled.div `
  margin: 0 auto;
  padding: 0 10px;

  ${media.desktop `
    max-width: 1500px;
    padding: 0 60px;
    min-width: 1024px;
    overflow: hidden;
  `};
`;
const Title = styled.h1 `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 1.4;
  margin: 0 auto 45px;
  color: #191841;
  max-width: 1020px;
  text-align: center;

  ${media.desktop `
    font-size: 48px;
    line-height: 60px;
    margin: 0 auto 50px;
  `};
`;
const Subtitle = styled.p `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  margin: 0 auto;
  color: rgba(25, 24, 65, 0.8);
  max-width: 760px;
  padding: 0 20px;

  ${media.desktop `
    font-size: 34px;
    line-height: 46px;
  `};
`;
export default HealthPage;
