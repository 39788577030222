import get from 'lodash/get';
import { GET_USER_BY_USERNAME_FULFILLED, GET_USER_BY_USERNAME_REJECTED, GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_FULFILLED, } from 'constants/ActionTypes';
const profilesReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_BY_USERNAME_FULFILLED: {
            return { ...state, [get(action, 'payload.data.username')]: get(action, 'payload.data') };
        }
        case GET_USER_BY_USERNAME_REJECTED: {
            return state;
        }
        case GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_FULFILLED: {
            return {
                ...state,
                [get(action, 'payload.data.username')]: get(action, 'payload.data'),
            };
        }
        default:
            return state;
    }
};
export default profilesReducer;
