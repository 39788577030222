import debug from 'debug';
import isMobile from 'ismobilejs';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { sizes } from 'components/responsive';
import firebaseProvider from './analytics.firebase';
import gaProvider from './analytics.ga';
import { isBrowser } from './platform';
const LOG = debug('analytics');
function ensureDeviceId(request) {
    const COOKIE_KEY = '_swc_did';
    let cookies, deviceId;
    if (process.browser) {
        cookies = require('cookies-js');
    }
    else if (request) {
        cookies = request.cookies;
        if (request._deviceId) {
            return request._deviceId;
        }
    }
    if (!cookies) {
        return;
    }
    deviceId = cookies.get(COOKIE_KEY);
    LOG('Get deviceId:', deviceId);
    if (!deviceId) {
        deviceId = uuidv4();
        let expires = new Date();
        expires.setTime(expires.valueOf() + 2 * 365 * 24 * 60 * 60 * 1000);
        cookies.set(COOKIE_KEY, deviceId, { expires, path: '/', httpOnly: false });
        if (request) {
            request._deviceId = deviceId;
        }
    }
    return deviceId;
}
const PROVIDERS = [gaProvider(ensureDeviceId), firebaseProvider(ensureDeviceId)];
const init = (request) => {
    LOG('Init');
    PROVIDERS.map((provider) => provider.init(request));
};
const track = async (...args) => {
    if (args[2]) {
        args[2] = addDeviceInfo(args[2]);
    }
    try {
        await Promise.all(PROVIDERS.map((provider) => provider.track(...args)));
    }
    catch (error) {
        LOG('Failed to track:', error);
    }
    LOG('Event:', ...args);
};
const pageview = async (...args) => {
    if (args[1]) {
        args[1] = addDeviceInfo(args[1]);
    }
    try {
        await Promise.all(PROVIDERS.map((provider) => provider.pageview(...args)));
    }
    catch (error) {
        LOG('Failed to pageview:', error);
    }
    LOG('Pageview:', ...args);
};
function addDeviceInfo(args) {
    // TODO Fix analytics for server side (pass useragent to isMobile)
    if (!isBrowser) {
        return args;
    }
    const isHome = args.page === '/';
    let windowSize = {};
    if (isHome) {
        windowSize = {
            isMultiscreen: window.innerWidth < sizes.desktop,
            windowWidth: window.innerWidth,
            windowHeight: window.innerHeight,
        };
    }
    let platform;
    const device = isMobile();
    if (device.apple.device) {
        platform = 'Apple';
    }
    else if (device.amazon.device) {
        platform = 'Amazon';
    }
    else if (device.android.device) {
        platform = 'Android';
    }
    else if (device.windows.device) {
        platform = 'Windows';
    }
    else if (device.any) {
        platform = 'Other';
    }
    else {
        platform = 'PC';
    }
    return {
        ...args,
        platform,
        isMobile: _.get(device, 'any', undefined),
        userAgent: _.get(navigator, 'userAgent', undefined),
        ...windowSize,
    };
}
export default {
    init,
    track,
    pageview,
};
