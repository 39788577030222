import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logo_src from 'assets/Home/header__logo.svg';
const Logo = () => (React.createElement(StyledNavLink, { to: "/", exact: true },
    React.createElement(LogoImage, { id: "box-logo", src: logo_src })));
const StyledNavLink = styled(NavLink) `
  display: block;
  text-align: center;
`;
const LogoImage = styled.img `
  position: relative;
  width: 180px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
  display: block;
`;
export default Logo;
