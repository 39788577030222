import React from 'react';
import styled from 'styled-components';
import { useUser } from '../../hooks';
import { logEvent } from '../../utils';
import flowerSrc from './assets/flower.png';
/* eslint-disable */
const AVAILABLE_TYPES = {
    2: {
        label: "You've walked more than 3K steps two days this week! Try to get 3 days next week! These are rough times, congratulations on the hard work!",
        percent: 60,
    },
    3: {
        label: "You've walked more than 3K steps three days this week! These are rough times, congratulations on the hard work!",
        percent: 30,
    },
    4: {
        label: "You've walked more than 3K steps four days this week! These are rough times, congratulations on the hard work!",
        percent: 25,
    },
    5: {
        label: "You've walked more than 3K steps five days this week! These are rough times, congratulations on the hard work!",
        percent: 8,
    },
    6: {
        label: "You've walked more than 3K steps six days this week! These are rough times, congratulations on the hard work!",
        percent: 4,
    },
    7: {
        label: "You've walked more than 3K steps  every single day this week! These are rough times, congratulations on the hard work!",
        percent: 1,
    },
};
/* eslint-enable */
const StreakAchievement = ({ id, type = '2' }) => {
    const user = useUser(id);
    const { label, percent } = AVAILABLE_TYPES[type];
    return (React.createElement(Layout, null,
        React.createElement(Title, null, "Streak unlocked!"),
        React.createElement(Label, null, label),
        React.createElement(Box, null,
            React.createElement(Flower, null,
                React.createElement(FlowerIcon, null),
                React.createElement(FlowerTitleContainer, null,
                    React.createElement(FlowerTitle, null, type),
                    React.createElement(FlowerTitleSmall, null, "days")),
                React.createElement(FlowerSubtitle, null, "streak")),
            React.createElement(Subtitle, null, `You are in the top ${percent}% of Sweatcoin worldwide`)),
        React.createElement(ShareButton, { disabled: user == null, onClick: handleShare }, "Share with friends")));
    async function handleShare() {
        try {
            await navigator.share({
                title: "I've unlocked a new Sweatcoin achievement!", //eslint-disable-line
                text: 'Check it out and find motivation to stay healthy!',
                url: `https://sweatco.in/i/${user.username}?channel=achievement&campaign=streak`,
            });
            logEvent('event@lofi_streakachievement.sharesuccess');
            alert('Thank you for sharing!');
        }
        catch (error) { }
    }
};
export default StreakAchievement;
const Layout = styled.div `
  display: flex;
  flex-direction: column;
  padding: 34px 16px 0px 16px;
`;
const Title = styled.h1 `
  font-family: PFDINMonoPro !important;
  font-size: 28px;
  letter-spacing: -0.5px;
  line-height: 33.6px;
`;
const Subtitle = styled.h2 `
  font-family: PFDINMonoPro !important;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.5px;
  margin-left: 22px;
`;
const Label = styled.span `
  font-family: sans-serif;
  font-size: 17px;
  line-height: 26px;
  letter-spacing: -0.4px;
  margin: 16px 0px;
`;
export const Box = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 19px;
  padding: 22px 24px;
  background-color: rgba(0, 20, 48, 0.3);
`;
const FlowerIcon = styled.img.attrs(() => ({
    src: flowerSrc,
})) `
  width: 104px;
  height: 104px;
  position: absolute;
`;
const Flower = styled.div `
  display: flex;
  flex-direction: column;
  min-width: 104px;
  min-height: 104px;
  align-items: center;
  justify-content: center;
  color: black;
`;
const FlowerTitleContainer = styled.div `
  display: flex;
  align-items: bottom;
  justify-content: center;
  margin-bottom: -6px;
  z-index: 1;
`;
const FlowerTitle = styled.p `
  font-family: PFDINMonoPro;
  font-size: 26px;
  letter-spacing: -0.5px;
  color: rgb(248, 167, 70);
`;
const FlowerTitleSmall = styled.p `
  font-family: PFDINMonoPro;
  font-size: 22px;
  line-height: 33.6px;
  letter-spacing: -1px;
  color: rgb(248, 167, 70);
`;
const FlowerSubtitle = styled.p `
  font-family: sans-serif;
  font-size: 13px;
  letter-spacing: -0.1px;
  color: rgb(248, 167, 70);
  z-index: 1;
`;
export const ShareButton = styled.button `
  height: 44px;
  border-radius: 13px;
  border: none;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.3px;
  text-align: center;
  color: #1b3556;
  background-color: #ffffff;
  margin: 36px 0px;
`;
