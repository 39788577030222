import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import * as ApiActions from 'actions/api';
import Availability from 'components/Availability';
import Layout from 'components/Layout';
import Press, { quotes } from 'constants/Press';
import { getFirstLetter, getFirstName } from 'utils/user';
import { serviceInviteStructuredSelector } from 'selectors/invites';
import buildLink from '../utils/buildLink';
import '../styles/invite-page.scss';
const messages = defineMessages({
    invite: '<strong>{name}</strong> invites you to join <strong>Sweatcoin</strong>{nbsp}— the{nbsp}free app that PAYS you to get fit!',
    titleGift: 'Claim Your 5 Sweatcoins',
    inviteGift: '<strong>{name}</strong> gifts you 5 SWC and invites you to join <strong>Sweatcoin</strong>{nbsp}— the{nbsp}free app that PAYS you to get fit!',
});
class Invite extends Component {
    static fetchData({ match }, store) {
        return Promise.all([store.dispatch(ApiActions.searchUserByUsername(match.params.username))]);
    }
    static contextTypes = {
        router: PropTypes.object,
    };
    render() {
        const { absoluteSharingGiftUrl, config, profile, firebaseLink, match } = this.props;
        const storeButtonLink = buildLink(firebaseLink);
        const avatar = get(profile, 'avatar.preview');
        const rewardInvitee = get(profile, 'reward_invitee', false);
        const isGift = config.origin.indexOf('gift.sweatcoinapp.uk') !== -1;
        const name = getFirstName(profile);
        const firstLetter = getFirstLetter(profile);
        return (React.createElement(Layout, { header: false, className: "invite-page" },
            React.createElement(FormattedMessage, { ...messages.titleGift }, (title) => (React.createElement(Helmet, { ...(isGift ? { title, link: [{ rel: 'image_src', href: absoluteSharingGiftUrl }] } : {}), meta: [
                    { name: 'event-id', content: 'invite-page' },
                    {
                        name: 'event-params',
                        content: {
                            username: match.params.username,
                            inviter_id: profile.id,
                            channel: this.props.channel,
                            platform: this.props.platform,
                        },
                    },
                    ...(isGift
                        ? [
                            { property: 'og:title', content: title },
                            { property: 'og:image', content: absoluteSharingGiftUrl },
                            { name: 'twitter:title', content: title },
                            { name: 'twitter:image', content: absoluteSharingGiftUrl },
                        ]
                        : []),
                ] }))),
            React.createElement(Layout.Container, null,
                React.createElement("section", { className: "invite-page__content" },
                    React.createElement("div", { className: "invite-page__user" },
                        React.createElement("div", { className: "invite-page__user__avatar" },
                            React.createElement("div", { className: "invite-page__user__avatar__pic", style: avatar ? { backgroundImage: `url(${avatar})` } : null }),
                            firstLetter),
                        React.createElement("div", { className: "invite-page__user__content" },
                            React.createElement("p", null,
                                React.createElement(FormattedMessage, { ...(isGift ? messages.inviteGift : messages.invite), values: { name } })))),
                    React.createElement(Availability, { className: "invite-page__availability is-column", rewardInvitee: rewardInvitee, storeButtonLink: storeButtonLink, location: this.props.location, from: "InvitePage" })),
                React.createElement("div", { className: "invite-page__press" }, Press.map(this._renderPressEntry)))));
    }
    _renderPressEntry = (model, index) => (React.createElement(FormattedMessage, { key: index, ...quotes[model.quoteKey] }, (quote) => (React.createElement("a", { href: model.link, target: "_blank", rel: "noreferrer noopener", className: "invite-page__press__entry", key: `press-entry-${index}`, title: quote }, model.sourceBlack))));
}
export default connect(serviceInviteStructuredSelector)(Invite);
