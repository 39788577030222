import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Slider from 'react-slick';
import styled from 'styled-components';
const messages = defineMessages({
    testimonial1: 'Sweatcoin make me become aware of how little exercise I did and motivates me to walk more.',
    testimonial2: 'This app encourages me to walk every day, which is great for my health. In return, it offers me coins and many coupons that I like.',
    testimonial3: 'I love this app! Im walking more than ever and I even get rewards! Amazing!',
    name1: 'Viktoria',
    name2: 'Ivana',
    name3: 'Alfonso Peyrac',
});
const Testimonials = () => {
    const settings = {
        arrows: false,
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 1,
        dots: true,
    };
    return (React.createElement(Cards, { ...settings },
        React.createElement(Card, null,
            React.createElement(CardText, null,
                React.createElement(FormattedMessage, { ...messages.testimonial1 })),
            React.createElement(CardName, null,
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { ...messages.name1 })))),
        React.createElement(Card, null,
            React.createElement(CardText, null,
                React.createElement(FormattedMessage, { ...messages.testimonial2 })),
            React.createElement(CardName, null,
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { ...messages.name2 })))),
        React.createElement(Card, null,
            React.createElement(CardText, null,
                React.createElement(FormattedMessage, { ...messages.testimonial3 })),
            React.createElement(CardName, null,
                React.createElement("strong", null,
                    React.createElement(FormattedMessage, { ...messages.name3 }))))));
};
const Cards = styled(Slider) `
  margin: 0 30px;

  .slick-list {
    overflow: visible;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    padding: 0 !important;
    margin: 30px 0 0 !important;

    li {
      list-style: none !important;
      margin: 0 4px;
      line-height: 0;

      button {
        width: 8px;
        height: 8px;
        background-color: #ffffff;
        opacity: 0.3;
        border-radius: 6px;
        border: none;
        font-size: 0;
        padding: 0;
        cursor: pointer;
        transition: all 0.3s linear;
      }

      &.slick-active {
        button {
          width: 40px;
          opacity: 1;
        }
      }
    }
  }
`;
const Card = styled.div `
  display: inline-flex !important;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 27px) !important;
  height: 240px !important;
  box-sizing: border-box;
  padding: 20px 40px 28px;
  background: linear-gradient(340.72deg, rgba(255, 255, 255, 0) 37.37%, rgba(255, 255, 255, 0.3) 108.22%);
  border-radius: 25px;
  box-shadow: 0 0 1px 0 rgba(255, 255, 255, 0.2) inset;
  position: relative;
`;
const CardText = styled.p `
  font-family: 'Inter';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #ffffff;
`;
const CardName = styled.p `
  font-family: 'Inter';
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.002em;
  color: rgba(255, 255, 255, 0.8);
  margin: auto 0 0;
`;
export default Testimonials;
