import React from 'react';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { setFontFace } from 'assets/AndroidInstructions';
import FONTS from 'assets/AndroidInstructions';
import slickCss from 'assets/AndroidInstructions/vendor/slick.min.css';
import Container from '../components/AndroidInstructions/Container';
const mapStateToProps = (state) => ({
    applicationAgent: (state.onEnter && state.onEnter.applicationAgent) || '',
    featureAccelerometerTracker: (state.onEnter && state.onEnter.featureAccelerometerTracker) || false,
    isInApp: (state.onEnter && state.onEnter.isInApp) || false,
});
class AndroidInstructions extends React.Component {
    state = {
        applicationAgent: null,
        model: null,
        osVersion: null,
    };
    static getDerivedStateFromProps(props) {
        const parsedAgent = props.applicationAgent.split('/');
        const applicationAgent = parsedAgent[0];
        const model = parsedAgent.length > 1 ? parsedAgent[1].split('|')[0] : '';
        const osVersion = parsedAgent.length > 2 ? parseInt(parsedAgent[2].split(' ')[0]) : null;
        const featureAccelerometerTracker = props.featureAccelerometerTracker;
        return {
            applicationAgent: applicationAgent.toLowerCase(),
            featureAccelerometerTracker: featureAccelerometerTracker,
            model: model.replace(/_/g, ' '),
            osVersion,
        };
    }
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(GlobalStyle, null),
            React.createElement(PFDinPreload, null, "123"),
            React.createElement(Container, { featureAccelerometerTracker: this.state.featureAccelerometerTracker, applicationAgent: this.state.applicationAgent, osVersion: this.state.osVersion, model: this.state.model, isInApp: this.props.isInApp })));
    }
}
const GlobalStyle = createGlobalStyle `
  ${setFontFace(FONTS.RobotoRegular)};
  ${setFontFace(FONTS.RobotoMedium, 700)};
  ${setFontFace(FONTS.PFDIN)};

  html,
  body {
    background: transparent !important;
    color: #fff !important;
    font-family: '${FONTS.RobotoRegular.name}', '${FONTS.PFDIN.name}' !important;
  }
  
  ${slickCss};
`;
// TODO use another way to preload font
const PFDinPreload = styled.span `
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  font-family: '${FONTS.PFDIN.name}';
  color: transparent;
`;
export default connect(mapStateToProps)(AndroidInstructions);
