import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logo_src from 'assets/Home/header__logo.svg';
import phone from 'assets/UpdateApp/phone_fade.png';
import FONTS from 'assets/fonts';
import mountains from 'assets/mountains-phone.png';
import Button from 'components/Button';
import QrCode from 'components/QrCode';
import { media } from 'components/responsive';
import AppLinks from 'routes/Home/components/AppLinks';
import analytics from 'utils/analytics';
import { useFirebaseEssentials } from 'routes/InvitePages/utils/firebase';
const messages = defineMessages({
    title: 'Open the app to explore',
    buttonTitle: 'Open your app',
});
const AppLink = (props) => {
    const { match, location } = props;
    const { params } = match;
    const allowSearch = location.pathname.startsWith('/app/invite') ||
        location.pathname.startsWith('/app/sign_in/confirm_email') ||
        location.pathname.startsWith('/app/today');
    const { firebaseData: { firebaseQuery, getRedirectLink }, tracking: { channel, platform, feature, campaign }, buttonsRefs: [buttonRef], config: { origin }, } = useFirebaseEssentials({
        deeplink_path: `/${params.path}${location.search}`,
        feature: 'app_link',
    });
    const qrCodeLink = `${origin}${location.pathname}${allowSearch ? location.search : ''}`;
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'app-link-page' },
                {
                    name: 'apple-itunes-app',
                    content: 'app-id=971023427',
                },
            ] }),
        React.createElement(Mountains, null),
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(Phone, { src: phone }),
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { ...messages.title })),
            React.createElement(StyledQrCode, { link: qrCodeLink }),
            React.createElement(StyledAppLinks, { isMobile: true, container: Container }),
            firebaseQuery ? (React.createElement(FormattedMessage, { ...messages.buttonTitle }, (title) => React.createElement(StyledButton, { ref: buttonRef, title: title, link: firebaseQuery.link, onClick: handleClick }))) : null)));
    function handleClick(event) {
        event.preventDefault();
        analytics.track('AppLinkPage', 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink();
    }
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: radial-gradient(165.2% 80% at 33.6% 6%, #ffb175 0%, hsl(321, 100%, 50%) 50%, rgba(37, 19, 95, 1) 100%);
`;
const Container = styled.div `
  position: relative;
  padding: 30px;

  ${media.tablet `
    width: 700px;
    padding: 30px 0;
    margin: 0 auto;
  `};

  ${media.desktop `
    width: 1000px;
    padding: 30px 0;
    margin: 0 auto;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
`;
const Logo = styled.img `
  position: relative;
  width: 197px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
`;
const Phone = styled.img `
  display: none;

  ${media.tablet `
    display: block;
    width: 330px;
    position: absolute;
    top: 35px;
    right: 0;
  `};

  ${media.desktop `
    display: block;
    width: 413px;
    position: absolute;
    top: 35px;
    right: 0;
  `};
`;
const Title = styled.h1 `
  margin-top: 40px;
  font-family: ${FONTS.SimplonNormBold.name}, ${FONTS.fallback};
  font-size: 48px;
  line-height: 64px;
  font-weight: 1000;
  color: #ffffff;

  ${media.tablet `
    width: 330px;
    font-size: 56px;
    line-height: 72px;
  `};

  ${media.desktop `
    margin-top: 100px;
    width: 560px;
    font-size: 64px;
    line-height: 84px;
  `};
`;
const StyledQrCode = styled(QrCode) `
  display: none;

  ${media.desktop `
    margin: 40px 0 0;
    display: block;
  `};
`;
const StyledAppLinks = styled(AppLinks) `
  display: none;

  ${media.desktop `
    display: flex;
    position: static !important;
    margin: 60px 0 0 0 !important;
    width: 330px;
  `};
`;
const Mountains = styled.div `
  background: url(${mountains}) no-repeat center bottom;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0.3;

  ${media.tablet `
    display: none;
  `};
`;
const StyledButton = styled(Button) `
  margin: 40px 0 0;

  ${media.desktop `
    display: none;
  `};
`;
export default AppLink;
