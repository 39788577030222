import React, { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import card1 from '../assets/card1.png';
import card2 from '../assets/card2.png';
import card3 from '../assets/card3.png';
const UsageCards = ({ activeSlide = 0, onChange }) => {
    const settings = {
        arrows: false,
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 1,
        dots: true,
        afterChange: (index) => {
            handleOnChange(index);
        },
    };
    const handleOnChange = (index) => {
        if (onChange) {
            onChange(index);
        }
    };
    const sliderRef = useRef(null);
    useEffect(() => {
        sliderRef.current.slickGoTo(activeSlide);
    }, [activeSlide]);
    return (React.createElement(Wrapper, { id: "cards" },
        React.createElement(Cards, { ...settings, ref: sliderRef },
            React.createElement(Card, null,
                React.createElement("img", { src: card1, alt: "" })),
            React.createElement(Card, null,
                React.createElement("img", { src: card2, alt: "" })),
            React.createElement(Card, null,
                React.createElement("img", { src: card3, alt: "" })))));
};
const Wrapper = styled.div `
  padding-top: 1px;
`;
const Cards = styled(Slider) `
  margin: 80px 30px 55px;

  .slick-list {
    overflow: visible;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;
    padding: 0 !important;
    margin: 0 0 0 !important;

    li {
      list-style: none !important;
      margin: 0 4px;
      line-height: 0;

      button {
        width: 8px;
        height: 8px;
        background-color: #ffffff;
        opacity: 0.3;
        border-radius: 6px;
        border: none;
        font-size: 0;
        padding: 0;
        cursor: pointer;
        transition: all 0.3s linear;
      }

      &.slick-active {
        button {
          width: 40px;
          opacity: 1;
        }
      }
    }
  }
`;
const Card = styled.div `
  width: calc(100% - 10px) !important;
  height: 500px !important;
  box-sizing: border-box;
  position: relative;
  outline: none;

  img {
    width: 100%;
    height: auto;
  }
`;
export default UsageCards;
