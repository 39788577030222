import get from 'lodash/get';
import { GET_USERS_ME_FULFILLED, POST_OFFER_PAY_FULFILLED } from 'constants/ActionTypes';
const userReducer = (state = null, action) => {
    switch (action.type) {
        case GET_USERS_ME_FULFILLED: {
            return action.payload.data;
        }
        case POST_OFFER_PAY_FULFILLED: {
            const user = get(action, 'payload.meta.user');
            if (user) {
                return { ...state, ...user };
            }
            return state;
        }
        default:
            return state;
    }
};
export default userReducer;
