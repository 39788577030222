import { GET_CHALLENGES_FULFILLED } from 'constants/ActionTypes';
const challengesReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CHALLENGES_FULFILLED: {
            return { ...state, ...action.payload.data };
        }
        default:
            return state;
    }
};
export default challengesReducer;
