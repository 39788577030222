export default [
    { image: require('assets/team/oleg.jpg'), name: 'Oleg', position: 'Founder', location: 'London' },
    {
        image: require('assets/team/anton.png'),
        name: 'Anton',
        position: 'Founder',
        location: 'London',
        pets: [{ type: '🐶', image: require('assets/team/pets/anton-dog.jpg') }],
    },
    { image: require('assets/team/egor.jpg'), name: 'Egor', position: 'Founder', location: 'Moscow' },
    {
        image: require('assets/team/andrey_a.jpg'),
        name: 'Andrey',
        position: 'Mobile',
        location: 'Moscow',
        pets: [{ type: '🐶', image: require('assets/team/pets/chivi.jpg') }],
    },
    {
        image: require('assets/team/shaun.png'),
        name: 'Shaun',
        position: 'Finance',
        location: 'London',
        pets: [{ type: '🐶', image: require('assets/team/pets/poppy.jpg') }],
    },
    { image: require('assets/team/luke.png'), name: 'Luke', position: 'Partnerships' },
    {
        image: require('assets/team/sergey_b.jpg'),
        name: 'Sergey',
        position: 'Frontend',
        location: 'Moscow',
        pets: [
            { type: '🐱', image: require('assets/team/pets/kesha.jpg') },
            { type: '🐱', image: require('assets/team/pets/pakhlava.jpg') },
        ],
    },
    {
        image: require('assets/team/alexander.jpg'),
        name: 'Alexander',
        position: 'Backend',
        location: 'Moscow',
        pets: [{ type: '🐶', image: require('assets/team/pets/chloe.jpg') }],
    },
    {
        image: require('assets/team/lorenzo.png'),
        name: 'Lorenzo',
        position: 'Partnerships',
        location: 'London',
    },
    {
        image: require('assets/team/jana.jpg'),
        name: 'Jana',
        position: 'Partnerships',
        location: 'London',
    },
    {
        image: require('assets/team/ilya.png'),
        name: 'Ilya',
        position: 'Mobile',
        location: 'St.Petersburg',
    },
    {
        image: require('assets/team/mikhail.jpg'),
        name: 'Mikhail',
        position: 'Mobile',
        location: 'Moscow',
        pets: [{ type: '🐶', image: require('assets/team/pets/mikhail-dog.jpg') }],
    },
    {
        image: require('assets/team/jared.jpg'),
        name: 'Jared',
        position: 'Product',
        location: 'London',
    },
    {
        image: require('assets/team/spear.jpg'),
        name: 'Ashton',
        position: 'Customer success',
        location: 'London',
        pets: [{ type: '🐱', image: require('assets/team/pets/max.jpg') }],
    },
    { image: require('assets/team/maria.jpg'), name: 'Maria', position: 'QA', location: 'Moscow' },
    {
        image: require('assets/team/anatoliy.jpg'),
        name: 'Anatoliy',
        position: 'QA',
        location: 'Moscow',
        pets: [
            { type: '🐶', image: require('assets/team/pets/anatolii-dog.jpg') },
            { type: '🐱', image: require('assets/team/pets/anatolii-cat-1.jpg') },
            { type: '🐱', image: require('assets/team/pets/anatolii-cat-2.jpg') },
            { type: '🐱', image: require('assets/team/pets/anatolii-cat-3.jpg') },
        ],
    },
    {
        image: require('assets/team/iurii.jpg'),
        name: 'Iurii',
        position: 'Backend',
        location: 'Moscow',
    },
    {
        image: require('assets/team/tomiris.jpg'),
        name: 'Tomiris',
        position: 'Data Science',
        location: 'Moscow',
    },
    { image: require('assets/team/max.jpg'), name: 'Max', position: 'Social Media' },
    {
        image: require('assets/team/oleg_p.jpg'),
        name: 'Oleg',
        position: 'Data Science',
        location: 'Moscow',
    },
    {
        image: require('assets/team/diana.jpg'),
        name: 'Diana',
        position: 'Design',
        location: 'Moscow',
        pets: [{ type: '🐶', image: require('assets/team/pets/doge.jpg') }],
    },
    {
        image: require('assets/team/emma.png'),
        name: 'Emma',
        position: 'Customer success',
        location: 'London',
    },
    {
        image: require('assets/team/igor.png'),
        name: 'Igor',
        position: 'Mobile',
        location: 'Volgograd',
    },
    { image: require('assets/team/vlad.jpg'), name: 'Vlad', position: 'DevOps', location: 'Moscow' },
    {
        image: require('assets/team/nick.jpg'),
        name: 'Nick',
        position: 'Mobile',
        location: 'Moscow',
        pets: [
            { type: '🐶', image: require('assets/team/pets/tutti.jpg') },
            { type: '🐱', image: require('assets/team/pets/tuks.jpg') },
        ],
    },
    { image: require('assets/team/irina.jpg'), name: 'Irina', position: 'Growth' },
    { image: require('assets/team/veranika.jpg'), name: 'Veranika', position: 'UX Research' },
    {
        image: require('assets/team/kirill.jpg'),
        name: 'Kirill',
        position: 'Mobile',
        pets: [{ type: '🐶', image: require('assets/team/pets/kirill-dog.jpg') }],
    },
    {
        image: require('assets/team/sergey_s.jpg'),
        name: 'Sergey',
        position: 'QA',
        pets: [
            { type: '🐱', image: require('assets/team/pets/sergey-cat-1.jpg') },
            { type: '🐱', image: require('assets/team/pets/sergey-cat-2.jpg') },
        ],
    },
    { image: require('assets/team/al_g.jpg'), name: 'Alexander', position: 'Mobile' },
    { image: require('assets/team/tavi.jpg'), name: 'Tavi', position: 'Design' },
    { image: require('assets/team/kieron.jpg'), name: 'Kieron', position: 'Partnerships' },
    { image: require('assets/team/sara.jpg'), name: 'Sara', position: 'Partnerships' },
    { image: require('assets/team/alex_g.jpg'), name: 'Alex', position: 'Product' },
    { image: require('assets/team/pawel.jpg'), name: 'Pawel', position: 'Design' },
    { image: require('assets/team/alex_k.jpg'), name: 'Sasha', position: 'Backend' },
    { image: require('assets/team/kristina.jpg'), name: 'Kristina', position: 'Design' },
    { image: require('assets/team/horia.jpg'), name: 'Horia', position: 'Design' },
    { image: require('assets/team/row.jpg'), name: 'Row', position: 'Product' },
    { image: require('assets/team/kevin.jpg'), name: 'Kevin', position: 'UX Research' },
    { image: require('assets/team/will.jpg'), name: 'Will', position: 'Partnerships' },
    { image: require('assets/team/kate.jpg'), name: 'Kate', position: 'Partnerships' },
    { image: require('assets/team/lara.jpg'), name: 'Lara', position: 'Partnerships' },
    {
        image: require('assets/team/natalia.jpg'),
        name: 'Natalia',
        position: 'QA',
        pets: [{ type: '🐱', image: require('assets/team/pets/natalia-cat.jpg') }],
    },
    {
        image: require('assets/team/claudia.jpg'),
        name: 'Claudia',
        position: 'Partnerships',
        pets: [{ type: '🐶', image: require('assets/team/pets/claudia-dog.jpg') }],
    },
    { image: require('assets/team/tobi.jpg'), name: 'Tobi', position: 'Partnerships' },
    { image: require('assets/team/sasha_e.jpg'), name: 'Alex', position: 'Mobile' },
    { image: require('assets/team/anna.jpg'), name: 'Anna', position: 'QA' },
    { image: require('assets/team/yulia.jpg'), name: 'Yulia', position: 'Data Science' },
    {
        image: require('assets/team/laura.jpg'),
        name: 'Laura',
        position: 'Sales Ops',
        pets: [
            { type: '🐱', image: require('assets/team/pets/laura-cat.jpg') },
            { type: '🐶', image: require('assets/team/pets/laura-dog.jpg') },
            { type: '🦎', image: require('assets/team/pets/laura-lizard.jpg') },
        ],
    },
    { image: require('assets/team/vasya.jpg'), name: 'Vasya', position: 'Mobile' },
    { image: require('assets/team/georgie.jpg'), name: 'Georgie', position: 'Design' },
    {
        image: require('assets/team/arthur.jpg'),
        name: 'Arthur',
        position: 'Partnerships',
        pets: [{ type: '🐶', image: require('assets/team/pets/arthur-dog.jpg') }],
    },
    { image: require('assets/team/mimi.jpg'), name: 'Mimi', position: 'Partnerships' },
    { image: require('assets/team/sergey_k.jpg'), name: 'Sergey', position: 'Data Science' },
    { image: require('assets/team/maks_k.jpg'), name: 'Maks', position: 'Product' },
    { image: require('assets/team/pavel_z.jpg'), name: 'Pavel', position: 'DevOps' },
    { image: require('assets/team/tanya.jpg'), name: 'Tanya', position: 'Content' },
    { image: require('assets/team/silviu.jpg'), name: 'Silviu', position: 'Design' },
    {
        image: require('assets/team/sarim.jpg'),
        name: 'Sarim',
        position: 'Crypto',
        pets: [{ type: '🐶', image: require('assets/team/pets/baker.jpg') }],
    },
    { image: require('assets/team/evgeniy.jpg'), name: 'Evgeniy', position: 'Mobile' },
    { image: require('assets/team/lochy.jpg'), name: 'Lochy', position: 'Partnerships' },
    { image: require('assets/team/seb.jpg'), name: 'Sebastian', position: 'Recruiting' },
    { image: require('assets/team/ella.jpg'), name: 'Ella', position: 'Talent Management' },
    { image: require('assets/team/jess.jpg'), name: 'Jessica', position: 'Marketing' },
    { image: require('assets/team/alberto.jpg'), name: 'Alberto', position: 'Product' },
    { image: require('assets/team/maria_k.jpg'), name: 'Maria', position: 'Talent Management' },
    { image: require('assets/team/marina_m.jpg'), name: 'Marina', position: 'Design' },
    { image: require('assets/team/noor.jpg'), name: 'Noor', position: 'Growth' },
    { image: require('assets/team/bogdana.jpg'), name: 'Bogdana', position: 'Growth' },
    { image: require('assets/team/charlie_c.jpg'), name: 'Charlie', position: 'Crypto' },
    { image: require('assets/team/henry_c.jpg'), name: 'Henry', position: 'Crypto' },
    { image: require('assets/team/ruslan.jpg'), name: 'Ruslan', position: 'Product' },
    { image: require('assets/team/lukas.jpg'), name: 'Lukas', position: 'Design' },
    { image: require('assets/team/victoria.jpg'), name: 'Victoria', position: 'Design' },
    { image: require('assets/team/ilya_m.jpg'), name: 'Ilya', position: 'Data Science' },
    { image: require('assets/team/harry_m.jpg'), name: 'Harry', position: 'Partnerships' },
    { image: require('assets/team/aleksei_sch.jpg'), name: 'Aleksei', position: 'Mobile' },
    { image: require('assets/team/demid.jpg'), name: 'Demid', position: 'Backend' },
    { image: require('assets/team/danila.jpg'), name: 'Danila', position: 'Backend' },
    { image: require('assets/team/seva.jpg'), name: 'Seva', position: 'Mobile' },
    { image: require('assets/team/olivia.jpg'), name: 'Olivia', position: 'Talent Management' },
    { image: require('assets/team/jack_w.jpg'), name: 'Jack', position: 'Partnerships' },
    { image: require('assets/team/gavin.jpg'), name: 'Gavin', position: 'Partnerships' },
    { image: require('assets/team/natalia_p.jpg'), name: 'Natalya', position: 'QA' },
    { image: require('assets/team/ramil.jpg'), name: 'Ramil', position: 'Mobile' },
    { image: require('assets/team/tate.jpg'), name: 'Tate', position: 'Growth' },
    { image: require('assets/team/vasilii.jpg'), name: 'Vasilii', position: 'Mobile' },
    { image: require('assets/team/jess_k.jpg'), name: 'Jess', position: 'Partnerships' },
    { image: require('assets/team/job_m.jpg'), name: 'Job', position: 'Partnerships' },
    { image: require('assets/team/yulia_m.jpg'), name: 'Yuliya', position: 'QA' },
    { image: require('assets/team/genia.jpg'), name: 'Genia', position: 'Frontend' },
    { image: require('assets/team/alex_m.jpg'), name: 'Alex', position: 'Frontend' },
    { image: require('assets/team/kirill_z.jpg'), name: 'Kirill', position: 'Backend' },
    { image: require('assets/team/svyatoslav.jpg'), name: 'Slava', position: 'DevOps' },
    { image: require('assets/team/tabriz.jpg'), name: 'Tabriz', position: 'Mobile' },
    { image: require('assets/team/ofir.jpg'), name: 'Ofir', position: 'Crypto' },
    { image: require('assets/team/maria_d.jpg'), name: 'Maria', position: 'Content' },
    { image: require('assets/team/nesir.jpg'), name: 'Nesir', position: 'Design' },
    { image: require('assets/team/olga_m.jpg'), name: 'Olga', position: 'Finance' },
    { image: require('assets/team/sergey_g.jpg'), name: 'Sergei', position: 'Mobile' },
    { image: require('assets/team/shriya.jpg'), name: 'Shriya', position: 'Sales Ops' },
    { image: require('assets/team/abhau.jpg'), name: 'Abhay', position: 'Partnerships' },
    { image: require('assets/team/carolina.jpg'), name: 'Carolina', position: 'Customer success' },
    { image: require('assets/team/dom.jpg'), name: 'Dom', position: 'Crypto' },
    { image: require('assets/team/viktoria.jpg'), name: 'Viktoria', position: 'QA' },
    { image: require('assets/team/stefan.jpg'), name: 'Stefan', position: 'Design' },
    { image: require('assets/team/leo.jpg'), name: 'Leo', position: 'Design' },
    { image: require('assets/team/maxim.jpg'), name: 'Maxim', position: 'Data Science' },
    { image: require('assets/team/suki.jpg'), name: 'Suki', position: 'Crypto' },
    { image: require('assets/team/hattie.jpg'), name: 'Hattie', position: 'Sales Ops' },
    { image: require('assets/team/mustafa.jpg'), name: 'Mustafa', position: 'Sales Ops' },
    { image: require('assets/team/misha_s.jpg'), name: 'Misha', position: 'Mobile' },
    { image: require('assets/team/misha_g.jpg'), name: 'Mikhail', position: 'Mobile' },
    { image: require('assets/team/alex_n.jpg'), name: 'Alex', position: 'Backend' },
    { image: require('assets/team/ildar_k.jpg'), name: 'Ildar', position: 'Mobile' },
    { image: require('assets/team/dimitrije.jpg'), name: 'Dimitrije', position: 'Data Science' },
    { image: require('assets/team/ilya_sh.jpg'), name: 'Ilya', position: 'Data Science' },
    { image: require('assets/team/kirill_d.jpg'), name: 'Kirill', position: 'Design' },
    { image: require('assets/team/kamil.jpg'), name: 'Kamil', position: 'Backend' },
    { image: require('assets/team/artem_v.jpg'), name: 'Artem', position: 'Mobile' },
    { image: require('assets/team/arseniy.jpg'), name: 'Ars', position: 'Mobile' },
    { image: require('assets/team/monika.jpg'), name: 'Monika', position: 'Growth' },
    { image: require('assets/team/dmitry_k.jpg'), name: 'Dmitry', position: 'Backend' },
    { image: require('assets/team/anna_e.jpg'), name: 'Anna', position: 'Data Science' },
    { image: require('assets/team/julia_k.jpg'), name: 'Julia', position: 'Talent Management' },
    { image: require('assets/team/daria_b.jpg'), name: 'Daria', position: 'Design' },
    { image: require('assets/team/egor_k.jpg'), name: 'Egor', position: 'QA' },
    { image: require('assets/team/roman_k.jpg'), name: 'Roman', position: 'QA' },
    { image: require('assets/team/maria_s.jpg'), name: 'Maria', position: 'Product' },
    { image: require('assets/team/ivan_k.jpg'), name: 'Ivan', position: 'Mobile' },
    { image: require('assets/team/dima_k.jpg'), name: 'Dima', position: 'Mobile' },
    { image: require('assets/team/mike_ch.jpg'), name: 'Mike', position: 'Mobile' },
    { image: require('assets/team/daniel_t.jpg'), name: 'Daniel', position: 'Design' },
];
