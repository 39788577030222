import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abort-controller/polyfill';
import 'classlist-polyfill';
import 'intersection-observer';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/es';
import { createBrowserHistory as createHistory } from 'history';
import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { CookiesProvider } from 'react-cookie-banner';
import { hydrate, render, unmountComponentAtNode } from 'react-dom';
import Helmet from 'react-helmet';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { withRouter } from 'react-router';
import { matchRoutes, renderRoutes } from 'react-router-config';
import { Router } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import smoothscroll from 'smoothscroll-polyfill';
import * as MiscActions from './actions/misc';
import * as RouterActions from './actions/router';
import routes from './routes';
import { Helpers } from './routes/Home/components/Background'; // TODO: refactor to remove this dependency
import ScrollBehavior from './utils/ScrollBehavior';
import analytics from './utils/analytics';
import { DEFAULT_COUNTRY } from './utils/countryManager';
import formats from './utils/formats';
import performRouteHandlerStaticMethod from './utils/performRouteHandlerStaticMethod';
import { isDev } from './utils/platform';
import richTextElements from './utils/richTextElements';
import { scrollToElement } from './utils/scrollTo';
import createStore from './utils/store';
smoothscroll.polyfill();
analytics.init();
const history = createHistory();
const store = createStore(window.__REDUX_STATE__, isDev && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const renderer = module.hot && module.hot.data && module.hot.data.hydrated ? render : hydrate;
const { locale, messages, time } = store.getState();
const intlProviderProps = {
    locale,
    formats,
    messages,
    defaultFormats: formats,
    defaultRichTextElements: richTextElements,
    defaultLocale: DEFAULT_COUNTRY.locale,
    initialNow: time,
    textComponent: 'span',
};
const intercept = (historyObject, interceptor) => {
    return {
        ...historyObject,
        listen: (listener) => historyObject.listen(async (...args) => {
            if (interceptor) {
                await interceptor(...args);
            }
            listener(...args);
        }),
    };
};
const routerActions = bindActionCreators(RouterActions, store.dispatch);
const miscActions = bindActionCreators(MiscActions, store.dispatch);
const interceptor = async (location) => {
    const branch = matchRoutes(routes(store), location.pathname);
    routerActions.resetError();
    miscActions.setParams(branch[branch.length - 1].match.params);
    performRouteHandlerStaticMethod('fetchData', branch, store, {
        location: { ...location, query: queryString.parse(location.search) },
    });
};
const interceptedHistory = intercept(history, interceptor);
const scrollBehaviour = new ScrollBehavior(history);
const setScrollPosition = (location) => {
    const { pathname, hash } = location;
    if (pathname === '/' && hash) {
        scrollHome(hash.substr(1));
    }
    else {
        scrollBehaviour.updateScroll({ location });
    }
};
const scrollHome = (hash) => {
    const scroll_to = {
        vision: 1,
        facts: 4,
        'how-it-works': 7,
        partners: 8,
    };
    const elem = hash && scroll_to[hash] ? Helpers.select(`.section_${scroll_to[hash]}`)[0] : null;
    if (elem) {
        scrollToElement(elem, 'auto');
    }
};
const RouteWatcher = withRouter(({ location: { pathname }, location }) => {
    useEffect(() => {
        const { title, metaTags } = Helmet.peek();
        const { config, storeButton } = store.getState();
        const eventTag = _.find(metaTags, ['name', 'event-id']);
        const eventParamsTag = _.find(metaTags, ['name', 'event-params']);
        if (eventTag) {
            const event = eventTag.content;
            const page = location.pathname + location.search;
            const properties = {
                ..._.get(eventParamsTag, 'content', {}),
                origin: config.origin,
                title,
                page,
            };
            analytics.pageview(event, properties).then(() => {
                if (storeButton) {
                    analytics.track('StoreButton', 'show', {
                        ..._.pick(location, ['pathname', 'search']),
                        page,
                        source: event,
                    });
                }
            });
        }
        setScrollPosition(location);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);
    return null;
});
renderer(React.createElement(Router, { history: interceptedHistory },
    React.createElement(RouteWatcher, null),
    React.createElement(CookiesProvider, null,
        React.createElement(IntlProvider, { ...intlProviderProps },
            React.createElement(Provider, { store: store }, renderRoutes(routes(store)))))), document.getElementById('root'));
if (module.hot) {
    module.hot.accept();
    module.hot.dispose((data) => {
        unmountComponentAtNode(document.getElementById('root'));
        data.hydrated = true;
    });
}
