import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import Footer from '../../Home/components/Footer';
import blobs from '../assets/blob1.png';
import EmailForm from '../components/EmailForm';
import Header from '../components/Header';
import UsageCards from '../components/UsageCards';
const channelSelector = (state) => get(state, 'config.location.query.channel');
const featureSelector = (state) => get(state, 'config.location.query.feature');
const campaignSelector = (state) => get(state, 'config.location.query.campaign');
const platformSelector = (state) => get(state, 'config.location.query.platform');
const onEnterSelector = (state) => get(state, 'onEnter', false);
const messages = defineMessages({
    title: 'It pays to walk',
    description: 'Join 150M+ users who believe their steps have value',
});
const Signup = ({ route }) => {
    // Tracking
    const channel = useSelector(channelSelector) || get(route, 'params.channel', 'other');
    const platform = useSelector(platformSelector) || get(route, 'params.platform', 'unknown');
    const feature = useSelector(featureSelector) || get(route, 'params.feature');
    let campaign = useSelector(campaignSelector) || get(route, 'params.campaign');
    const onEnter = useSelector(onEnterSelector);
    return onEnter.redirectUrl ? (React.createElement(Redirect, { to: onEnter.redirectUrl })) : (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'web-to-app-signup-page' },
                {
                    name: 'event-params',
                    content: pickBy({
                        channel,
                        platform,
                        feature,
                        campaign,
                    }, identity),
                },
            ], link: [
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap',
                },
            ] }),
        React.createElement(Blobs, null),
        React.createElement(Container, null,
            React.createElement(StyledHeader, { title: React.createElement(FormattedMessage, { ...messages.title }), description: React.createElement(FormattedMessage, { ...messages.description }) }),
            React.createElement(UsageCards, null),
            React.createElement(EmailForm, null)),
        React.createElement(Footer, { bgColor: "transparent" })));
};
const StyledHeader = styled(Header) `
  margin-bottom: 0 !important;
`;
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: radial-gradient(130.98% 24.71% at 50.13% 16.33%, #9e98ff 0%, #6058d7 100%);
  background-size: cover;
`;
const Container = styled.div `
  position: relative;
  padding: 60px 0 0;
  max-width: 430px;
  margin: 0 auto;
`;
const Blobs = styled.div `
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background: url(${blobs}) no-repeat center 0;
  background-size: 1200px;
  z-index: -1;
`;
export default Signup;
