import _ from 'lodash';
import { ADD_APPLICANT_TO_VACANCY_FULLFILLED, ADD_APPLICANT_TO_VACANCY_REJECTED, APPLICATION_FORM_UPDATED, GET_JOB_OPENINGS_FULLFILLED, GET_LOCATIONS_FULLFILLED, GET_TEAMS_FULLFILLED, GET_VACANCY_FULLFILLED, SAVE_APPLICANT_FULLFILLED, SAVE_APPLICANT_PENDING, SAVE_APPLICANT_REJECTED, SAVE_FILE_FULLFILLED, SAVE_FILE_REJECTED, } from 'constants/ActionTypes';
function getDictionary(payload) {
    const { fields } = payload;
    let dictionary = {};
    if (fields) {
        fields.map((field) => {
            dictionary[field.id] = field.name;
        });
    }
    return dictionary;
}
const huntflowReducer = function (state = null, action) {
    switch (action.type) {
        case GET_JOB_OPENINGS_FULLFILLED: {
            return { ...state, jobOpenings: action.payload.items };
        }
        case GET_TEAMS_FULLFILLED: {
            const teams = (state && state.teams) || {};
            return { ...state, teams: { ...teams, ...getDictionary(action.payload) } };
        }
        case GET_LOCATIONS_FULLFILLED: {
            const locations = (state && state.locations) || {};
            return { ...state, locations: { ...locations, ...getDictionary(action.payload) } };
        }
        case GET_VACANCY_FULLFILLED: {
            const vacancy = (state && state.vacancy) || {};
            vacancy[action.payload.id] = action.payload;
            return { ...state, vacancy: { ...vacancy } };
        }
        case SAVE_FILE_FULLFILLED: {
            const { id } = action.payload;
            const files = (state && state.files) || [];
            if (!_.find(files, { id })) {
                files.push({ id });
                return { ...state, files: [...files] };
            }
            else {
                return state;
            }
        }
        case SAVE_APPLICANT_PENDING: {
            return { ...state, submitError: null, isFormSubmitting: true, isFormSuccess: false };
        }
        case SAVE_APPLICANT_FULLFILLED: {
            const { id } = action.payload;
            return { ...state, applicantId: id };
        }
        case ADD_APPLICANT_TO_VACANCY_FULLFILLED: {
            // Reset fields after submitting
            return { ...state, applicantId: null, files: [], isFormSubmitting: false, isFormSuccess: true };
        }
        case SAVE_FILE_REJECTED: {
            const { statusText } = action.payload;
            return {
                ...state,
                submitError: `Error on file upload: ${statusText}`,
                isFormSubmitting: false,
            };
        }
        case SAVE_APPLICANT_REJECTED: {
            let fields = _.get(action, 'payload.response.fields', null);
            let errorMessage;
            if (fields) {
                errorMessage = _.entries(fields).map((error) => error.length > 1 ? `${error[0]} - ${error[1][0]}` : JSON.stringify(error));
            }
            return {
                ...state,
                submitError: `Error on saving: ${errorMessage.join('\n')}`,
                isFormSubmitting: false,
            };
        }
        case ADD_APPLICANT_TO_VACANCY_REJECTED: {
            let fields = _.get(action, 'payload.response.fields', null);
            let errorMessage;
            if (fields) {
                errorMessage = _.entries(fields).map((error) => error.length > 1 ? `${error[0]} - ${error[1][0]}` : JSON.stringify(error));
            }
            return {
                ...state,
                submitError: `Error on saving: ${errorMessage.join('\n')}`,
                isFormSubmitting: false,
            };
        }
        case APPLICATION_FORM_UPDATED: {
            return {
                ...state,
                isFormSuccess: false,
            };
        }
        default:
            return state;
    }
};
export default huntflowReducer;
