import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import appstoreBg from 'assets/Challenges/appstore.png';
import googlePlayBg from 'assets/Challenges/googleplay.png';
import close from 'assets/S4G/close.svg';
import AppLinks from 'routes/Home/components/AppLinks';
const Popup = ({ onClickHandler, messages }) => {
    const onClickOverlayHandler = (event) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClickHandler(false);
        }
    };
    return (React.createElement(Overlay, { onClick: onClickOverlayHandler },
        React.createElement(Close, { onClick: () => onClickHandler(false) },
            React.createElement("img", { src: close, alt: "Close icon" })),
        React.createElement(Container, null,
            React.createElement(FormattedMessage, { ...messages.popupTitle }, (title) => React.createElement(Title, null, title)),
            React.createElement(FormattedMessage, { ...messages.popupDescription }, (description) => React.createElement(Description, null, description)),
            React.createElement(StyledAppLinks, { isMobile: true, container: Container }))));
};
const Overlay = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 20, 48, 0.6);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Container = styled.div `
  box-sizing: border-box;
  width: 310px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
  background-color: #001430;
  border-radius: 16px;
  text-align: center;
`;
const Title = styled.h3 `
  color: #ffffff;
  font-size: 20px;
  line-height: 140%;
  letter-spacing: -0.2px;
  font-weight: bold;
  margin-bottom: 4px;
`;
const Description = styled.p `
  color: #ffffff;
  font-size: 12px;
  line-height: 140%;
  opacity: 0.6;
  margin-bottom: 24px;
`;
const StyledAppLinks = styled(AppLinks) `
  position: static !important;
  margin: 0 0 0 0 !important;
  padding: 0 !important;

  a span {
    width: 180px;
    background-size: 180px !important;

    &[data-id='appstore'] {
      background: url(${appstoreBg});
      height: 60px;
    }

    &[data-id='googleplay'] {
      background: url(${googlePlayBg});
      height: 53px;
    }
  }
`;
const Close = styled.button `
  background-color: transparent;
  width: 30px;
  height: 30px;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0;
  outline: none;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
  }
`;
export default Popup;
