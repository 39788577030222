import classnames from 'classnames';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { storeButtonShow } from 'actions/misc';
import OutboundLink from './OutboundLink';
import 'styles/download-button';
import 'styles/icons';
class StoreButton extends Component {
    static defaultProps = {
        linkAppStore: 'https://itunes.apple.com/app/sweatcoin-app-that-pays-you/id971023427',
        linkGooglePlay: 'https://play.google.com/store/apps/details',
        campaign: 'store_button',
        type: 'AppStore',
        from: 'Web',
    };
    componentDidMount() {
        this.props.storeButtonShow();
    }
    render() {
        const link = this.props.link ||
            (this.props.type === 'AppStore'
                ? `${this.props.linkAppStore}?${queryString.stringify({
                    pt: '117705952',
                    ct: this.props.campaign,
                    mt: 8,
                })}`
                : `${this.props.linkGooglePlay}?${queryString.stringify({
                    id: 'in.sweatco.app',
                    referrer: queryString.stringify({
                        utm_source: 'website',
                        utm_campaign: this.props.campaign,
                    }),
                })}`);
        return (React.createElement(OutboundLink, { category: "Store", action: "redirect", label: this.props.type, params: { from: this.props.from, campaign: this.props.campaign }, className: classnames('download-button', this.props.className), target: "_blank", href: link },
            React.createElement("i", { className: `icon-${this.props.type.toLocaleLowerCase()}` })));
    }
}
export default connect(null, { storeButtonShow })(StoreButton);
