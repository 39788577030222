import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as ApiActions from 'actions/api';
import HTMLText from 'components/HTMLText';
import Layout from 'components/Layout';
import '../../Static/styles/static';
const mapStateToProps = createStructuredSelector({
    text: (state) => state.legal['consent-to-privacy-notice'],
});
class ConsentPrivacyNotice extends React.Component {
    static fetchData(_, store) {
        return store.dispatch(ApiActions.prefetchApplicationLegal());
    }
    render() {
        return (React.createElement(Layout, null,
            React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'privacy' }] }),
            React.createElement(Layout.Container, null,
                React.createElement("div", { className: "static" },
                    React.createElement("h2", null, "Consent to Privacy Notice"),
                    React.createElement(HTMLText, { component: "div", html: this.props.text && this.props.text.content })))));
    }
}
export default connect(mapStateToProps)(ConsentPrivacyNotice);
