import classnames from 'classnames';
import ClipboardJS from 'clipboard';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import analytics from 'utils/analytics';
import platformDetector from 'utils/platform';
import { companyJoinPageStructuredSelector } from 'selectors/invites';
import '../styles/company-join-page.scss';
const messages = defineMessages({
    invite: 'Join your team{br}on Sweatcoin',
    offer: 'You walk? Get paid! {br} In sweatcoins.',
    button: 'Accept Invite',
});
const mapStateToProps = companyJoinPageStructuredSelector;
class CompanyJoinPage extends Component {
    static contextTypes = {
        router: PropTypes.object,
    };
    state = {
        copy: true,
    };
    render() {
        const itunesLink = 'https://itunes.apple.com/app/sweatcoin-app-that-pays-you/id971023427?mt=8';
        const offerMessage = React.createElement(FormattedMessage, { ...messages.offer });
        return (React.createElement("section", { className: classnames('company-join-page') },
            React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'company-join-page' }] }),
            React.createElement("div", { className: "company-join-page__mood" },
                React.createElement("div", { className: "company-join-page__mood__gradient" })),
            React.createElement("section", { className: "company-join-page__container" },
                React.createElement("div", { className: "company-join-page__top" },
                    React.createElement("div", { className: "company-join-page__social" },
                        React.createElement("div", null,
                            React.createElement("i", { className: "company-join-page__social__logo icon-logo" })),
                        React.createElement("p", null,
                            React.createElement(FormattedMessage, { ...messages.invite })))),
                React.createElement("div", { className: "company-join-page__cta" },
                    React.createElement("h1", { className: "company-join-page__heading" }, offerMessage),
                    React.createElement("div", { className: "company-join-page__action" },
                        React.createElement("a", { className: "company-join-page__action__link", href: itunesLink, onClick: this._handleClick },
                            React.createElement(FormattedMessage, { ...messages.button })))))));
    }
    _handleClick = (event) => {
        event.preventDefault();
        if (this.state.copy) {
            new ClipboardJS('.company-join-page__action__link', { text: this._getCopyLink });
        }
        analytics.track('CompanyJoinPage', 'redirect');
        window.location = this._getRedirectLink();
    };
    _getCopyLink = () => {
        const { firebaseQuery, config } = this.props;
        const params = ['link', 'apn', 'isi', 'ibi', 'ius', 'ct', 'pt', '_icp'];
        const copyQuery = pick(mapValues({ ...firebaseQuery, _icp: 1 }, (value, key) => {
            return ['link'].indexOf(key) !== -1 ? value : encodeURIComponent(value);
        }), params);
        return `${config.firebaseDynamicLinkDomain}?${queryString.stringify(copyQuery, {
            encode: false,
            sort: (m, n) => params.indexOf(m) >= params.indexOf(n),
        })}`;
    };
    _getUserAgent = () => {
        let userAgent = '';
        if (process.browser) {
            userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
        }
        else {
            const request = get(this.props, 'location.state.request');
            if (request) {
                userAgent = request.headers['user-agent'];
            }
        }
        return userAgent;
    };
    _getPlatform = () => {
        return platformDetector(this._getUserAgent());
    };
    _getRedirectLink = () => {
        const { firebaseQuery, config } = this.props;
        const platform = this._getPlatform();
        if (platform === 'iOS') {
            const redirectQuery = mapValues({ ...firebaseQuery, _fpb: '=' }, (value, key) => {
                return ['link'].indexOf(key) !== -1 ? value : encodeURIComponent(value);
            });
            return `${config.firebaseDynamicLinkDomain}?${queryString.stringify(redirectQuery, { encode: false })}`;
        }
        else if (platform === 'Android') {
            const redirectQuery = mapValues(firebaseQuery, (value, key) => {
                return ['link'].indexOf(key) !== -1 ? value : encodeURIComponent(value);
            });
            return `${config.firebaseDynamicLinkDomain}?${queryString.stringify(redirectQuery, { encode: false })}`;
        }
        else {
            return firebaseQuery.link;
        }
    };
}
export default connect(mapStateToProps)(CompanyJoinPage);
