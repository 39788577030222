const routes = (store) => [
    {
        component: require('./components/Root').default,
        routes: [
            ...require('./routes/Home').default(store),
            ...require('./routes/Showroom').default(store),
            ...require('./routes/Team').default(store),
            ...require('./routes/Service').default(store),
            ...require('./routes/InvitePages').default(store),
            ...require('./routes/AppLinks').default(store),
            ...require('./routes/Offers').default(store),
            ...require('./routes/Quiz').default(store),
            ...require('./routes/SweatPaws').default(store),
            ...require('./routes/ShlomoRaveParty').default(store),
            ...require('./routes/Launches').default(store),
            ...require('./routes/Interpride').default(store),
            ...require('./routes/WebToApp').default(store),
            ...require('./routes/Static').default(store),
        ],
    },
];
export default routes;
