import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import analytics from 'utils/analytics';
import leaves_1 from '../assets/leaves_1.png';
import leaves_2 from '../assets/leaves_2.png';
import logo_src from '../assets/logo.svg';
import snow from '../assets/snow.svg';
import tree from '../assets/tree.png';
const urlWhitelist = [
    'origembrand.com',
    'digdetox.com',
    'detoxedofficial.com',
    'faycecosmetics.com',
    'parkscandles.com',
    'czbykennethjaylane.com',
    'jardinjewelry.com',
    'reppsports.com',
    'reveille.io',
    'adidasheadphones.com',
    'nutsola.com',
    'mylittlemascaraclub.com',
    'wayofwill.com',
    'livenobs.com',
    'kashkalondon.co.uk',
    'allmyeco.com',
    'tranya.com',
    'tinggly.com',
    'thyhalo.com',
    'youandoil.eu',
    'perfectimage.com',
    'golden-curl.com',
    'beyousc.com',
    'thegreencompany.online',
    'simplysow.store',
    'survivorlife.com',
    'joanafulana.com',
    'theearthcase.com',
    'lecol.cc',
    'enphasis.com',
];
function useQuery() {
    return queryString.parse(useLocation().search);
}
const Discounts = () => {
    const [isCopied, setCopied] = useState(false);
    const [buttonTitle, setButtonTitle] = useState('Copy');
    const query = useQuery();
    const history = useHistory();
    const [pageTitle] = useState(query.title);
    const [partnersLink] = useState(query.link);
    const [promocode] = useState(query.code);
    const [discountValue] = useState(query.val);
    const [discountType] = useState(query.type);
    useEffect(() => {
        const a = document.createElement('a');
        a.href = partnersLink;
        const partnersHostName = a.hostname.replace(/^www.?/, '');
        urlWhitelist.includes(partnersHostName) ? null : history.replace('/');
    }, [partnersLink]);
    useEffect(() => {
        let timer;
        if (isCopied) {
            timer = setTimeout(() => {
                setCopied(false);
                setButtonTitle('Copy');
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);
    return (React.createElement(Layout, null,
        React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'discounts-page' }] },
            React.createElement("link", { href: "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap", rel: "stylesheet" })),
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(LeavesLeft, null),
            React.createElement(LeavesRight, null),
            React.createElement(Subtitle, null, "Advent calendar offer"),
            React.createElement(Title, null, pageTitle),
            React.createElement(Label, null,
                React.createElement("b", null,
                    discountValue,
                    "% off"),
                React.createElement("span", null, discountType)),
            React.createElement(Step, null, "1. Copy your discount code"),
            React.createElement(CodeInput, null,
                React.createElement(Code, null, promocode),
                React.createElement(CopyButton, { onClick: () => handleCopyClick(promocode) },
                    React.createElement("span", null, buttonTitle))),
            React.createElement(Step, null, "2. Go to the website and apply the code at checkout"),
            React.createElement(Link, { href: partnersLink, onClick: handleLinkClick },
                React.createElement("span", null, "Go to the website")),
            React.createElement(Step, null, "3. Enjoy the discount!"),
            React.createElement(Footnote, null, "Head to the App Store to leave us a review if you LOVED the offer!")),
        React.createElement(Footer, null,
            React.createElement(Tree, null))));
    function handleLinkClick() {
        analytics.track('discounts_link_button', 'click', {
            origin: location.origin,
            page: location.pathname,
        });
    }
    function handleCopyClick(code) {
        const input = document.createElement('input');
        input.type = 'text';
        input.value = code;
        input.style.opacity = '0.000001';
        input.style.position = 'fixed';
        input.style.bottom = '-100%';
        input.readOnly = true;
        document.body.appendChild(input);
        input.focus();
        input.setSelectionRange(0, input.value.length);
        document.execCommand('copy', true, null);
        analytics.track('discounts_copy_button', 'click', {
            origin: location.origin,
            page: location.pathname,
        });
        setCopied(true);
        setButtonTitle('Copied!');
    }
};
const Layout = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(155deg, #f1ede4 0%, #e0faf1 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;
const Container = styled.div `
  position: relative;
  padding: 0 16px;
  width: 100%;
`;
const StyledNavLink = styled(NavLink) `
  display: block;
  text-align: center;
  width: 165px;
  margin: 60px auto 25px;
`;
const Logo = styled.img `
  position: relative;
  width: 165px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;
`;
const LeavesLeft = styled.div `
  width: 189px;
  height: 96px;
  transform: rotate(100deg);
  position: absolute;
  top: 20px;
  left: -60px;
  background: url(${leaves_1}) 50% 50% no-repeat;
  background-size: contain;
  z-index: -1;
`;
const LeavesRight = styled.div `
  width: 112px;
  height: 134px;
  transform: rotate(-61deg);
  position: absolute;
  top: 280px;
  right: -22px;
  background: url(${leaves_2}) 50% 50% no-repeat;
  background-size: contain;
  z-index: -2;
`;
const Subtitle = styled.h3 `
  font-family: 'Inter';
  font-weight: bold;
  color: #e4004b;
  opacity: 0.7;
  font-size: 20px;
  letter-spacing: 0.4px;
  line-height: 1.4;
  font-style: normal;
  margin-bottom: 7px;
  text-align: center;
`;
const Title = styled.h1 `
  font-family: 'Inter';
  font-weight: bold;
  color: #e4004b;
  font-size: 48px;
  letter-spacing: -0.2px;
  line-height: 1.16;
  font-style: normal;
  text-align: center;
  max-width: 340px;
  margin: 0 auto 16px;
`;
const Label = styled.div `
  font-family: 'Inter';
  font-weight: bold;
  box-sizing: border-box;
  width: 111px;
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 10px 12px;
  overflow: visible;
  background-color: #fec601;
  box-shadow: 0px 0px 4px 0px rgba(254, 198, 1, 0.15), 0px 4px 8px 0px rgba(254, 198, 1, 0.15);
  border-radius: 16px;
  color: #fff;
  margin: 0 auto 40px;
  font-size: 20px;

  span {
    font-weight: normal;
    font-size: 16px;
  }
`;
const Step = styled.p `
  font-family: 'Inter';
  font-weight: bold;
  color: #e4004b;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 1.5;
  font-style: normal;
  text-align: center;
  max-width: 340px;
  margin: 0 auto 20px;
`;
const CodeInput = styled.div `
  box-sizing: border-box;
  width: auto;
  max-width: 340px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0 16px;
  overflow: hidden;
  background-color: rgba(3, 37, 85, 0.1);
  border-radius: 16px;
  position: relative;
  z-index: 1;
  margin: 0 auto 50px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid rgba(3, 37, 85, 0.15);
    box-sizing: border-box;
    border-radius: 16px;
    z-index: -1;
  }
`;
const Code = styled.span `
  font-family: 'Inter';
  color: #032555;
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 1;
  font-style: normal;
  padding-bottom: 2px;
`;
const CopyButton = styled.div `
  font-family: 'Inter';
  font-weight: bold;
  box-sizing: border-box;
  height: 100%;
  width: 113px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 20px 16px 20px;
  background-color: rgba(3, 37, 85, 0.9);
  position: relative;
  top: 0;
  right: 0;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.4px;
  line-height: 1.5;
  font-style: normal;
  cursor: pointer;

  span {
    margin-top: -2px;
  }
`;
const Link = styled.a `
  box-sizing: border-box;
  width: 229px;
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px 20px 16px 20px;
  overflow: visible;
  background-color: rgba(3, 37, 85, 0.9);
  border-radius: 16px;
  margin: 0 auto 50px;

  span {
    font-family: 'Inter';
    font-weight: bold;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 1.5;
    font-style: normal;
    margin-top: -2px;
  }
`;
const Footnote = styled.div `
  font-family: 'Inter';
  width: 200px;
  margin: -12px auto 0;
  color: #e4004b;
  font-size: 12px;
  letter-spacing: 0.4;
  line-height: 1.3;
  font-style: normal;
  text-align: center;
`;
const Footer = styled.div `
  margin-top: 80px;
  min-height: 150px;
  width: 100%;
  background: url(${snow}) top center repeat-x;
  position: relative;
  flex: 1;

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin-top: 72px;
    background: #ffffff;
    z-index: -1;
  }
`;
const Tree = styled.div `
  width: 156px;
  height: 170px;
  background: url(${tree}) top center no-repeat;
  background-size: contain;
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: -95px;
  margin-left: 50px;
`;
export default Discounts;
