import React, { useEffect } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import placeholderSrc from 'assets/AndroidInstructions/screenshot_placeholder.png';
import scr6 from 'assets/AndroidInstructions/xiaomi/xiaomi_autostart_1.jpg';
import scr1 from 'assets/AndroidInstructions/xiaomi/xiaomi_battery_1.jpg';
import scr2 from 'assets/AndroidInstructions/xiaomi/xiaomi_battery_2.jpg';
import scr3 from 'assets/AndroidInstructions/xiaomi/xiaomi_battery_3.jpg';
import scr4 from 'assets/AndroidInstructions/xiaomi/xiaomi_battery_4.jpg';
import scr5 from 'assets/AndroidInstructions/xiaomi/xiaomi_battery_5.jpg';
import scr7 from 'assets/AndroidInstructions/xiaomi/xiaomi_recent_1.jpg';
import scr8 from 'assets/AndroidInstructions/xiaomi/xiaomi_recent_2.jpg';
import scr9 from 'assets/AndroidInstructions/xiaomi/xiaomi_recent_3.jpg';
import SliderBlock from '../SliderBlock';
import { AdviceNotSuitable, Common, Divider, Footer, List, ListItem, SamsungDivider, SettingsButton, Text, Title, } from './common';
const messages = defineMessages({
    card1: 'Go to Settings > Apps',
    card2: 'Manage apps',
    card3: 'Search Sweatcoin',
    card4: 'Go to Battery saver',
    card5: 'Select No restrictions',
    card6: 'Go back and allow autostart',
    card7: 'Open Recent Apps',
    card8: 'Long tap on Sweatcoin and press Lock',
    card9: 'Lock icon should appear',
    manage: 'Manage battery settings',
    settings: 'Go to settings',
    autostart: 'Allow autostart',
    lock: 'Lock Sweatcoin as recent app',
    alternative: 'Alternatively try this:',
    remove: 'Remove restrictions',
    security: 'Security',
    battery: 'Battery',
    appBattery: 'App Battery Saver',
    sweatcoin: 'Sweatcoin',
    noRestriction: 'No restriction',
    settings2: 'Settings',
    performance: 'Battery & Performance',
    usage: 'Manage Apps Battery Usage',
    manageApps: 'Manage Apps Battery Usage',
    apps: 'Apps',
    bgSettings: 'Choose “No Restrictions” under Background Settings',
    andAllow: 'and "Allow" under Background Location Settings',
    adjust: 'Adjust power plan',
    advanced: 'Advanced Settings',
    batManager: 'Battery Manager',
    setPower: 'Set Power plan to Performance, set Sweatcoin as Protected app',
    protected: 'Protected apps – Sweatcoin needs to be Protected',
    enable: 'Please enable',
    screenOff: 'Power-intensive prompt and Keep running after screen off',
    additional: 'Additional Settings',
    powerSaving: 'Manage apps battery usage and here: <br /> 1. Switch Power Saving Modes to Off <br /> 2. Choose the next options: Saving Power in The Background > Apps > Sweatcoin > Background Settings > No restrictions',
    autostart2: 'Autostart',
    permissions: 'Permissions',
    turnOn: 'Turn ON for “Sweatcoin”',
    bgActivity: 'Enable background activity',
    launch: 'Launch Sweatcoin',
    home: 'Press Home button',
    applist: 'Open your App list (recent apps, square or three-line button)',
    pull: 'Pull the Sweatcoin App preview slightly down to reveal two buttons above it',
    lock2: 'Press “Lock”',
});
const CardsBattery = [
    {
        screenshot: scr1,
        description: React.createElement(FormattedMessage, { ...messages.card1 }),
    },
    {
        screenshot: scr2,
        description: React.createElement(FormattedMessage, { ...messages.card2 }),
    },
    {
        screenshot: scr3,
        description: React.createElement(FormattedMessage, { ...messages.card3 }),
    },
    {
        screenshot: scr4,
        description: React.createElement(FormattedMessage, { ...messages.card4 }),
    },
    {
        screenshot: scr5,
        description: React.createElement(FormattedMessage, { ...messages.card5 }),
    },
];
const CardsAutostart = [
    {
        screenshot: scr6,
        description: React.createElement(FormattedMessage, { ...messages.card6 }),
    },
];
const CardsRecent = [
    {
        screenshot: scr7,
        description: React.createElement(FormattedMessage, { ...messages.card7 }),
        top: '-35%',
    },
    {
        screenshot: scr8,
        description: React.createElement(FormattedMessage, { ...messages.card8 }),
        top: '-35%',
    },
    {
        screenshot: scr9,
        description: React.createElement(FormattedMessage, { ...messages.card9 }),
        top: '-35%',
    },
];
let trackedEvents = [];
const Xiaomi = (props) => {
    const { openSettings, trackEvent } = props;
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 280,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    useEffect(() => {
        // cleanup
        return () => {
            trackedEvents = [];
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.manage })),
        React.createElement(SliderBlock, { trackedEvents: trackedEvents, trackEvent: trackEvent, index: 0, settings: sliderSettings, cardArray: buildArray(CardsBattery), cards: CardsBattery }),
        React.createElement(SettingsButton, { openSettings: openSettings, style: { marginTop: '16px', marginBottom: '16px' } },
            React.createElement(FormattedMessage, { ...messages.settings })),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.autostart })),
        React.createElement(SliderBlock, { trackedEvents: trackedEvents, trackEvent: trackEvent, index: 0, settings: sliderSettings, cardArray: buildArray(CardsAutostart), cards: CardsAutostart }),
        React.createElement(SettingsButton, { openSettings: openSettings, style: { marginTop: '16px', marginBottom: '16px' } },
            React.createElement(FormattedMessage, { ...messages.settings })),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.lock })),
        React.createElement(SliderBlock, { trackedEvents: trackedEvents, trackEvent: trackEvent, index: 0, settings: sliderSettings, cardArray: buildArray(CardsRecent), cards: CardsRecent }),
        React.createElement(SettingsButton, { openSettings: openSettings, style: { marginTop: '16px', marginBottom: '16px' } },
            React.createElement(FormattedMessage, { ...messages.settings })),
        React.createElement(Divider, null),
        React.createElement(Title, { style: { marginTop: '32px', marginBottom: '16px' } },
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(Common, { featureAccelerometerTracker: props.featureAccelerometerTracker, trackEvent: props.trackEvent, openSettings: props.openSettings, isFooterDividerDefault: true }),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.remove })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.security })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.battery })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.appBattery })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.noRestriction }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings2 })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.performance })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.manageApps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.bgSettings }),
                ' ',
                props.featureAccelerometerTracker ? '' : React.createElement(FormattedMessage, { ...messages.andAllow }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.adjust })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings2 })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.advanced })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batManager })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.setPower }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings2 })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.advanced })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batManager })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.protected }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.enable })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings2 })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.battery })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.screenOff }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings2 })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.additional })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.performance })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.powerSaving }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.autostart2 })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings2 })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.permissions })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.autostart2 })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.turnOn }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(Divider, null),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.bgActivity })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.launch })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.home })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.applist })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.pull })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.lock2 }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(SamsungDivider, null),
        React.createElement(Footer, { featureAccelerometerTracker: props.featureAccelerometerTracker, trackEvent: props.trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: props.trackEvent })));
};
const Card = styled.div `
  padding: 16px 0;
  width: calc(100% - 32px) !important;
  margin: 0 16px 0 0;
  border-radius: 9px;
  background: rgba(255, 255, 255, 0.1);
  outline: none;
  position: relative;
`;
const CardImage = styled.div `
  width: calc(100% - 64px);
  max-width: 264px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`;
const Screenshot = styled.img `
  position: absolute;
  top: ${(props) => props.top};
  left: 0;
  width: 100%;
`;
const ScreenshotWrapper = styled.div `
  position: absolute;
  top: 9%;
  left: 16.2%;
  width: 68%;
  bottom: 1px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
`;
const Placeholder = styled.img `
  width: 100%;
  position: relative;
`;
const renderCard = (index, { src, top = 0 }) => (React.createElement(Card, { key: index },
    React.createElement(CardImage, null,
        React.createElement(ScreenshotWrapper, null,
            React.createElement(Screenshot, { src: src, top: top })),
        React.createElement(Placeholder, { src: placeholderSrc }))));
const buildArray = (cards) => cards.map((card, index) => renderCard(index, { src: card.screenshot, top: card.top || 0 }));
export default Xiaomi;
