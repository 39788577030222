import React, { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import styled from 'styled-components';
import dhl from 'assets/Home/dhl_logo.png';
import hlp from 'assets/Home/hlp_logo.png';
import arrow from 'assets/Home/link-arrow.png';
import nhs from 'assets/Home/nhs_logo.png';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    title: '<span>How do Sweatcoins</span>{br}get their value?',
    slide_1_title: 'Partners',
    slide_1_description: 'The value of a sweatcoin is derived from our wide-reaching partnerships. From brands that want to connect with health conscious audiences, insurers wishing to encourage healthier lifestyle choices and governments looking to reduce healthcare costs.',
    slide_1_numbers: '600+',
    slide_1_caption: 'partners',
    slide_2_title: 'NHS',
    slide_2_description: 'Sweatcoin work with the NHS to deliver Healthy Incentive programmes across the country, using personalised data-driven approaches to deliver sustained behaviour change.',
    slide_3_title: 'Government',
    slide_3_description: 'Sweatcoin helps users become +20% more active each day, even after 6 months — this has the potential to transform public health, by using Sweatcoin as a prevention tool for sustained behaviour change.',
    partners: 'Principal partner',
});
const SweatcoinValue = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const settings = {
        slidesToShow: 1,
        swipeToSlide: true,
        beforeChange: (oldIndex, newIndex) => setActiveSlide(newIndex),
        arrows: false,
        focusOnSelect: true,
        infinite: false,
        variableWidth: true,
    };
    return (React.createElement(Wrapper, { id: "partners" },
        React.createElement(FormattedMessage, { ...messages.title }, (title) => React.createElement(Title, null, title)),
        React.createElement(StyledSlider, { ...settings },
            React.createElement(SliderNavItem, { active: activeSlide === 0 },
                React.createElement(FormattedMessage, { ...messages.slide_1_title })),
            React.createElement(SliderNavItem, { active: activeSlide === 1 },
                React.createElement(FormattedMessage, { ...messages.slide_2_title })),
            React.createElement(SliderNavItem, { active: activeSlide === 2 },
                React.createElement(FormattedMessage, { ...messages.slide_3_title }))),
        React.createElement(Slides, null,
            React.createElement(Slide, { active: activeSlide === 0 },
                React.createElement(Description, null,
                    React.createElement(FormattedMessage, { ...messages.slide_1_description })),
                React.createElement(Circle, null,
                    React.createElement(CircleNumbers, null,
                        React.createElement(FormattedMessage, { ...messages.slide_1_numbers })),
                    React.createElement(CircleCaption, null,
                        React.createElement(FormattedMessage, { ...messages.slide_1_caption })))),
            React.createElement(Slide, { active: activeSlide === 1 },
                React.createElement(Description, null,
                    React.createElement(FormattedMessage, { ...messages.slide_2_description }),
                    React.createElement(Link, { src: "/nhs" })),
                React.createElement(Circle, null,
                    React.createElement(NHS, { src: nhs }),
                    React.createElement(CircleCaption, null,
                        React.createElement(FormattedMessage, { ...messages.partners })))),
            React.createElement(Slide, { active: activeSlide === 2 },
                React.createElement(Description, null,
                    React.createElement(FormattedMessage, { ...messages.slide_3_description })),
                React.createElement(Circle, null,
                    React.createElement(HLP, { src: hlp }),
                    React.createElement(DHL, { src: dhl }),
                    React.createElement(CircleCaption, null,
                        React.createElement(FormattedMessage, { ...messages.partners })))))));
};
const Link = ({ src }) => {
    return (React.createElement(TextLink, null,
        React.createElement(NavLink, { to: src }, "Find out more")));
};
const Wrapper = styled.div `
  margin: 50px 16px;

  @media ${desktop()} {
    width: 980px;
    margin: 0 auto;
    padding-top: 120px;
  }
`;
const Title = styled.h2 `
  font-weight: 800;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 32px;
  letter-spacing: -0.3px;
  line-height: 1.2;
  margin-bottom: 30px;

  @media ${desktop()} {
    font-size: 48px;
    letter-spacing: -0.3px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 60px;
  }

  span {
    font-weight: 400;
    font-size: 18px;
    display: inline-block;

    @media ${desktop()} {
      font-size: 24px;
      margin-bottom: 16px;
    }
  }
`;
const StyledSlider = styled(Slider) `
  .slick-list {
    overflow: visible;
  }

  .slick-slide {
    opacity: 0.3;
  }

  .slick-active {
    opacity: 1;
  }
`;
const SliderNavItem = styled.div `
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 24px;
  line-height: 1.4;
  margin-right: 20px;
  cursor: pointer;

  @media ${desktop()} {
    font-size: 40px;
    line-height: 1.4;
    margin-right: 35px;
  }
`;
const Slides = styled.div `
  margin: 20px auto;

  @media ${desktop()} {
    margin: 60px auto 120px;
  }
`;
const Slide = styled.div `
  ${({ active }) => `display:${active ? 'flex' : 'none'};`}
  position: relative;
  flex-direction: column;

  @media ${desktop()} {
    align-items: center;
    justify-content: start;
    height: 353px;
    flex-direction: row;
  }
`;
const Description = styled.div `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  line-height: 1.4;

  @media ${desktop()} {
    font-size: 24px;
    line-height: 1.6;
    width: 500px;
  }
`;
const TextLink = styled.p `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.6;
  margin-top: 24px;

  &::after {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    transform: rotate(45deg) translateY(5px) translateX(5px);
    background: url(${arrow}) 50% 50% no-repeat;
    background-size: contain;
    margin-left: 5px;
  }

  a {
    color: #fff;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease-in-out;

    &:before {
      content: '';
      background: #fff;
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 0;
      height: 1px;
      transition: all 0.3s ease-in-out;
    }

    &:hover::before {
      width: 100%;
    }
  }
`;
const Circle = styled.div `
  box-sizing: border-box;
  width: 290px;
  height: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: linear-gradient(170deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.05) 100%);
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.05);
  margin: 32px auto;

  @media ${desktop()} {
    width: 382px;
    height: 382px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
`;
const CircleNumbers = styled.span `
  font-weight: 900;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 60px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;

  @media ${desktop()} {
    margin-bottom: 8px;
    font-size: 86px;
  }
`;
const CircleCaption = styled.span `
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.2;
  text-align: center;

  @media ${desktop()} {
    font-size: 24px;
  }
`;
const NHS = styled.img `
  width: 177px;
  display: block;

  @media ${desktop()} {
    margin-bottom: 20px;
  }
`;
const HLP = styled.img `
  width: 175px;
  display: block;
  margin-bottom: 34px;
`;
const DHL = styled.img `
  width: 172px;
  display: block;
  margin-bottom: 35px;
`;
export default SweatcoinValue;
