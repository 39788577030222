import ClipboardJS from 'clipboard';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Button from 'components/Button';
import QrCode from 'components/QrCode';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
const DesktopView = ({ qrCodeLink, pageLink, messages, phone, inviteType }) => {
    const button = useRef();
    const [isCopied, setCopied] = useState(false);
    useEffect(() => {
        let timer;
        if (isCopied) {
            timer = setTimeout(() => {
                setCopied(false);
            }, 1000);
        }
        return () => clearTimeout(timer);
    }, [isCopied]);
    useEffect(() => {
        new ClipboardJS(button.current, { text: () => pageLink });
    }, [pageLink]);
    return (React.createElement(Container, null,
        phone ? React.createElement(Phone, { src: phone }) : null,
        messages.desktopTitle ? (React.createElement(FormattedMessage, { ...messages.desktopTitle }, (title) => React.createElement(Title, null, title))) : null,
        messages.desktopDescription ? (React.createElement(FormattedMessage, { ...messages.desktopDescription }, (description) => React.createElement(Description, null, description))) : null,
        React.createElement(Wrapper, null,
            React.createElement(StyledQrCode, { link: qrCodeLink, size: 144, padding: 12 }),
            React.createElement(StyledButton, { className: inviteType, ref: button, title: isCopied ? (React.createElement(FormattedMessage, { ...messages.copyButtonTitle })) : (React.createElement(FormattedMessage, { ...messages.desktopButtonTitle })), link: qrCodeLink, onClick: handleCopyClick }))));
    function handleCopyClick(event) {
        event.preventDefault();
        setCopied(true);
        analytics.track('challenge_copylink_button', 'click', {
            origin: location.origin,
            page: location.pathname,
        });
    }
};
const Container = styled.div `
  display: none;

  ${media.tablet `
    display: block;
  `};
`;
const Phone = styled.img `
  display: none;

  ${media.tablet `
    display: block;
    width: 300px;
    position: absolute;
    top: 70px;
    right: 0;
  `};

  ${media.desktop `
    width: 400px;
  `};

  ${media.desktop_medium `
    width: 480px;
  `};
`;
const Title = styled.h1 `
  margin-top: 40px;
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  letter-spacing: -0.4px;
  color: #ffffff;

  ${media.tablet `
    width: 330px;
    font-size: 36px;
    line-height: -0.4px;
    line-height: 130%;
  `};

  ${media.desktop `
    margin-top: 200px;
    width: 530px;
    font-size: 36px;
    line-height: -0.4px;
    line-height: 130%;
  `};
`;
const Description = styled.p `
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 18px;
  line-height: 140%;
  letter-spacing: -0.2px;
  margin-top: 12px;
  width: 330px;

  ${media.desktop `
    width: 500px;
  `};
`;
const Wrapper = styled.div `
  width: 600px;
  display: flex;
  align-items: center;
  margin: 52px 0 0;
`;
const StyledQrCode = styled(QrCode) `
  margin: 0;
`;
const StyledButton = styled(Button) `
  width: 160px;
  margin: 0 32px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff !important;
`;
export default DesktopView;
