import _ from 'lodash';
import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import ReactMarkdown from 'react-markdown';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import * as HuntflowActions from 'actions/huntflow';
import { raiseError } from 'actions/router';
import FONTS from 'assets/fonts';
import responsiveContainer, { media } from 'components/responsive';
import locationPinSrc from '../../../assets/team/location_pin.svg';
import Footer from '../../Home/components/Footer';
import Header, { containerHeight } from '../../Home/components/Header';
import Form from '../components/ApplicationForm';
import { FONT_SIZES, SmallTitle } from '../components/common';
const mapStateToProps = (state) => ({
    LOCATIONS: _.get(state, 'huntflow.locations', {}),
    vacancy: _.get(state, `huntflow.vacancy[${state.params.vacancy_id}]`, {}),
});
const ENGINEERING_ID = 2752;
const messages = defineMessages({
    text_engineering: "Please send us your recent work, GitHub projects that you're proud of, or open source contributions.",
    text: "Please send us your recent work that you're proud of.",
});
class Vacancy extends React.Component {
    static async fetchData(state, store) {
        const [vacancy, locations] = await Promise.all([
            store.dispatch(HuntflowActions.fetchVacancy(state.match.params.vacancy_id)),
            store.dispatch(HuntflowActions.fetchLocations()),
        ]);
        if (vacancy && !vacancy.error && vacancy.payload) {
            if (vacancy.payload.publish_career !== 'Yes' && vacancy.payload.publish_career !== 'Да') {
                store.dispatch(raiseError(404));
            }
        }
        return [vacancy, locations];
    }
    formatDescription = (body) => {
        return body ? body.replace(/<p>/gm, '\n' + '<p>').replace(/<[^>]*>?/gm, '') : '';
    };
    render() {
        const { LOCATIONS } = this.props;
        const { position, body, id, city, teams } = this.props.vacancy;
        return (React.createElement(React.Fragment, null,
            React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'vacancy' }] }),
            React.createElement(GlobalStyle, null),
            React.createElement(Header, { embed: true }),
            React.createElement(Container, null,
                React.createElement(Location, null,
                    React.createElement("img", { src: locationPinSrc }),
                    " ",
                    LOCATIONS[city]),
                React.createElement(Title, null, position),
                React.createElement(Content, null,
                    React.createElement(Description, null,
                        React.createElement(ReactMarkdown, { source: this.formatDescription(body), renderers: RENDERERS })),
                    React.createElement(StyledForm, null,
                        React.createElement(DescriptionTitle, { style: { color: '#85963D' } }, "Join us"),
                        React.createElement(Text, { style: { color: '#85963D' } },
                            React.createElement(FormattedMessage, { ...(teams === ENGINEERING_ID ? messages.text_engineering : messages.text) })),
                        React.createElement(Form, { vacancyId: id })))),
            React.createElement(Footer, null)));
    }
}
const GlobalStyle = createGlobalStyle `
html, body {
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback} !important;
  ${FONT_SIZES};
}
`;
const Container = styled.div `
  padding-top: ${containerHeight.phone};
  ${media.tablet `padding-top: ${containerHeight.tablet};`};
  ${media.desktop `padding-top: ${containerHeight.desktop};`};
`;
const Title = styled(SmallTitle) `
  margin-top: 0.225rem;
  ${responsiveContainer};

  ${media.desktop `
    margin-top: 0.3525rem;
  `};
`;
const Location = styled(Title) `
  margin-top: 1.5rem;
  font-size: 0.6375rem;
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  text-transform: uppercase;

  img {
    height: 1rem;
  }

  ${media.desktop `
    margin-top: 3rem;
    font-size: 1rem;
    
    img {
     height: 1.5rem;
    }
  `};
`;
const Content = styled.div `
  ${responsiveContainer};
  display: flex;
  margin-bottom: 6.1rem;
  flex-direction: column;
  line-height: 1.4;

  ${media.desktop `flex-direction: row;`};
`;
const Description = styled.div `
  flex: 1;
  padding-right: 2.3rem;
  margin-top: 1.9rem;

  & > div:first-child {
    margin-top: 0;
  }

  ${media.desktop `
    flex: 2;
    padding-right: 3rem;
  `};
`;
const StyledForm = styled.div `
  flex: 1;
`;
const DescriptionTitle = styled.div `
  margin-top: 1.9rem;
  font-weight: bold;
`;
const Text = styled.div `
  margin-top: 0.75rem;
`;
const List = styled.ul ``;
const Item = styled.li `
  margin-top: 0.75rem;
`;
const RENDERERS = {
    paragraph: Text,
    list: List,
    listItem: Item,
    heading: DescriptionTitle,
};
export default connect(mapStateToProps)(Vacancy);
