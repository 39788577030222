import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';
import arrowDownSrc from 'assets/SweatPaws/arrow-down.png';
import homeDecorSrc from 'assets/SweatPaws/home-decor.png';
import homeImageDesktopSrc from 'assets/SweatPaws/home-image.png';
import homeImageMobileSrc from 'assets/SweatPaws/home-image_mobile.png';
import sweatcoinLogoSrc from 'assets/SweatPaws/sweatcoin-logo.svg';
import sweatpawsLogoSrc from 'assets/SweatPaws/sweatpaws-logo.svg';
import { media } from 'components/responsive';
import AppLink from '../components/AppLink';
import SmallBanner from '../components/SmallBanner';
import { getRandomId } from '../utils/functions';
import Navigation from './Navigation';
import Question from './Question';
import Result from './Result';
const Quiz = (props) => {
    const [id, setId] = useState(null);
    const questionId = props.questions[props.answerNumber - 1].id;
    const isHavingTitle = props.points[questionId].label;
    const refWrapper = useRef(null);
    const { ref, inView, entry } = useInView({ threshold: 0.1 });
    const handleClick = () => {
        refWrapper.current?.scrollIntoView({ behavior: 'smooth' });
    };
    useEffect(() => {
        const randomId = getRandomId();
        const formattedRandomId = randomId.slice(0, 10);
        setId(formattedRandomId);
    }, []);
    return (React.createElement(React.Fragment, null,
        props.isStarted && props.isSmallBannerOpened ? (React.createElement(SmallBanner, { handleCloseBannerBtnClick: props.handleCloseBannerBtnClick, isStarted: props.isStarted })) : null,
        props.isFinished ? (React.createElement(Result, { points: props.points, results: props.results, meta: props.meta, id: id, handleShareBtnClick: props.handleShareBtnClick, handleAnswer: props.handleAnswer, handleClosePanelBtnClick: props.handleClosePanelBtnClick, isSharePanelOpened: props.isSharePanelOpened, isSmallBannerOpened: props.isSmallBannerOpened })) : props.isStarted ? (React.createElement(React.Fragment, null,
            React.createElement(Question, { questions: props.questions, answers: props.answers, title: props.question.title, handleAnswer: props.handleAnswer, quizLength: props.quizLength, answerNumber: props.answerNumber, type: props.question.type, questionId: props.question.id, setActiveId: props.setActiveId, activeId: props.activeId, points: props.points, showError: props.showError, isSmallBannerOpened: props.isSmallBannerOpened }),
            React.createElement(QuestionCredits, null,
                React.createElement(CreditsTitle, null, "SweatPaws is created by Sweatcoin"),
                React.createElement(CreditsText, null, "Download Sweatcoin App, start walking, turn your steps into sweatcoins and spend them on awesome products or donate them to charity.")),
            React.createElement(Navigation, { handleBackBtnClick: props.handleBackBtnClick, handleNextBtnClick: props.handleNextBtnClick, isDisabled: props.answerNumber <= 1, isHavingTitle: isHavingTitle }))) : (React.createElement(React.Fragment, null,
            React.createElement(HomeBanner, null,
                React.createElement(SweatpawsLogoSmall, { src: sweatpawsLogoSrc, width: "146", height: "19" }),
                React.createElement(SweatcoinLogoSmall, { src: sweatcoinLogoSrc, width: "112", height: "21" }),
                React.createElement(AppLink, null)),
            props.isSmallBannerOpened ? (React.createElement(SmallBanner, { handleCloseBannerBtnClick: props.handleCloseBannerBtnClick })) : null,
            React.createElement(Wrapper, { isSmallBannerOpened: props.isSmallBannerOpened },
                React.createElement(Header, null,
                    React.createElement(Title, null, "Is your dog active enough?"),
                    React.createElement(StartButton, { onClick: props.handleStartBtnClick },
                        React.createElement("span", null, "Find Your Result"))),
                React.createElement(ImageMobile, { src: homeImageMobileSrc }),
                React.createElement(ImageDesktop, { src: homeImageDesktopSrc })),
            !inView ? React.createElement(ScrollTo, { onClick: handleClick }) : null,
            React.createElement(ContentWrapper, null,
                React.createElement("span", { ref: refWrapper }),
                React.createElement(Content, { ref: ref },
                    React.createElement(ContentInner, null,
                        React.createElement(Subtitle, null, "Happier doggie, happier you"),
                        React.createElement(Description, null,
                            React.createElement("p", null, "Dogs need regular exercise, just like people. If your dog isn\u2019t getting enough physical activity, they may put on too much weight, become bored or frustrated, or release their pent-up energy in undesirable ways."),
                            React.createElement("p", null, "So it\u2019s really important to give your friend enough activity on daily basis."))),
                    React.createElement(ContentInner, null,
                        React.createElement(SweatcoinLogoBig, { src: sweatcoinLogoSrc, width: "212", height: "40" }),
                        React.createElement(WhySubtitle, null, "Who are Sweatcoin"),
                        React.createElement(Description, null,
                            React.createElement("p", null,
                                "Did you know that dog owners are",
                                ' ',
                                React.createElement("a", { href: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6473089/", target: "_blank", rel: "noopener norefferer noreferrer" }, "considerably more active"),
                                ' ',
                                "than people without a dog?"),
                            React.createElement("p", null,
                                "At",
                                ' ',
                                React.createElement("a", { href: "https://sweatco.in/", target: "_blank", rel: "noopener norefferer noreferrer" }, "Sweatcoin"),
                                ", our mission is to motivate people to move more by rewarding them for their steps."),
                            React.createElement("p", null,
                                "Why? Because when you look after your health, you benefit society. You are more productive. You help save $billions in healthcare. Your movement has value: ",
                                React.createElement("strong", null, "you deserve a share in it"),
                                "."))))),
            React.createElement(Credits, null,
                React.createElement(CreditsTitle, null, "SweatPaws is created by Sweatcoin"),
                React.createElement(CreditsText, null, "Download Sweatcoin App, start walking, turn your steps into sweatcoins and spend them on awesome products or donate them to charity."))))));
};
const ScrollTo = styled.button `
  position: fixed;
  bottom: 20px;
  left: 50%;
  display: none;
  width: 80px;
  height: 80px;
  margin: 0;
  padding: 0;
  background: none;
  background-image: url('${arrowDownSrc}');
  background-repeat: no-repeat;
  background-size: 100%;
  border: none;
  transform: translateX(-50%);

  ${media.desktop `
    display: block;
  `}

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`;
const Wrapper = styled.div `
  ${media.desktop `
    display: flex;
    align-items: center;
    margin: 0 auto;
    margin-top: ${(props) => (props.isSmallBannerOpened ? '-101px' : null)};
    margin-bottom: 200px;
    height: calc(100vh - 43px);
  `}

  ${media.desktop_medium `
    margin-bottom: 60px;
  `}
`;
const Header = styled.div `
  position: relative;
  z-index: 1;

  ${media.desktop `
    width: 715px;
    margin-right: 50px;
  `}

  ${media.desktop_medium `
    margin-right: 80px;
  `};
`;
const Credits = styled.div `
  ${media.desktop `
    width: 700px;
    margin: 0 auto;
    text-align: center;
  `}
`;
const QuestionCredits = styled(Credits) `
  margin-top: 20px;

  @media (min-width: 400px) {
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
`;
const CreditsTitle = styled.h3 `
  margin-bottom: 8px;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  background: linear-gradient(112.4deg, #ffeff9 29.63%, #eefffc 76.7%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  ${media.desktop `
    margin-bottom: 12px;
    font-size: 24px;
  `}
`;
const CreditsText = styled.p `
  margin-bottom: 24px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #ffffff;
  opacity: 0.8;

  ${media.desktop `
    font-size: 20px;
  `}
`;
const HomeBanner = styled.div `
  position: relative;
  display: flex;
  justify-content: center;
  width: calc(100% + 32px);
  margin-left: -16px;
  z-index: 1;
  padding: 11px 0;
  background: rgba(255, 255, 255, 0.01);
  backdrop-filter: blur(20px);

  ${media.desktop `
    width: calc(100% + 120px);
    margin-left: -60px;
  `};

  a::before,
  a::after,
  span {
    display: none;
  }

  a {
    backdrop-filter: none;
    border-radius: 0;
  }

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
const SweatpawsLogoSmall = styled.img `
  width: 146px;
  margin-right: 8px;
`;
const SweatcoinLogoSmall = styled.img `
  width: 112px;
`;
const SweatcoinLogoBig = styled.img `
  width: 212px;
  margin-bottom: 18px;
`;
const ImageMobile = styled.img `
  position: relative;
  left: 50%;
  top: -71px;
  width: 566px;
  transform: translateX(-55%);

  ${media.desktop `
    display: none;
  `};
`;
const ImageDesktop = styled.img `
  display: none;

  ${media.desktop `
    display: block;
    width: 52.8%;
    max-width: 700px;
    margin-right: -60px;
  `};

  @media (min-width: 1600px) {
    margin-right: -100px;
  }
`;
const Description = styled.div `
  margin-bottom: 90px;

  ${media.desktop `
    margin-bottom: 0;
  `}

  p {
    margin-bottom: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;

    color: rgba(255, 255, 255, 0.8);

    &:last-child {
      margin-bottom: 0;
    }

    ${media.desktop `
      font-size: 18px;
    `}

    ${media.desktop_medium `
      font-size: 20px;
    `}
  }

  a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);

    &:hover {
      border-bottom: none;
    }
  }
`;
const Title = styled.h1 `
  margin-bottom: 40px;
  padding-top: 37px;
  font-weight: 700;
  font-size: 37px;
  line-height: 46px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #ffffff;

  ${media.desktop `
    font-size: 42px;
    line-height: 60px;
    text-align: left;
  `};

  ${media.desktop_medium `
    font-size: 60px;
    line-height: 75px;
  `};
`;
const ContentWrapper = styled.div `
  position: relative;
  width: 100%;

  ${media.desktop_medium `
    &::before {
      content: "";
      position: absolute;
      top: 40px;
      left: -60px;
      width: 167px;
      height: 278px;
      background-image: url('${homeDecorSrc}');
      background-repeat: no-repeat;
      background-size: 100%;
    }
  `}
`;
const Content = styled.div `
  ${media.desktop `
    display: flex;
    width: 100%;
    max-width: 980px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 200px;
  `};
`;
const ContentInner = styled.div `
  ${media.desktop `
    display: flex;
    flex-direction: column;

    &:first-child {
      margin-right: 80px;
    }
  `};
`;
const Subtitle = styled.h2 `
  margin-top: -56px;
  margin-bottom: 18px;
  font-weight: 800;
  font-size: 25px;
  line-height: 120%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  background: linear-gradient(275.55deg, #ffb3ef 3.2%, #c2c5ff 53%, #b3fff2 97.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  ${media.desktop `
    margin-top: 0;
    font-size: 32px;
  `};
`;
const WhySubtitle = styled(Subtitle) `
  margin-top: 0;
`;
const StartButton = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 51px;
  margin: 0 auto;
  margin-bottom: 16px;
  padding: 0;
  background: radial-gradient(107.81% 587.36% at -7.81% 28.23%, #ffd342 0%, #ff3ab2 57.29%, #ff3bb2 57.3%, #ff55bc 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border-radius: 50px;
  border: none;

  @media (max-width: 389px) {
    width: 100%;
  }

  ${media.desktop `
    margin-left: 0;
    margin-right: auto;
    height: 68px;
  `};

  span {
    font-weight: 800;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    text-shadow: 0px 0px 10px rgba(19, 0, 33, 0.1);

    ${media.desktop `
      font-size: 20px;
      line-height: 25px;
    `};
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.7;
    }
  }
`;
export default Quiz;
