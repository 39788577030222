import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, FormattedNumber, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    title: 'Sweatcoins created',
    description: 'shown in real time',
});
const statsSelector = (state) => state.stats;
const StepsCounter = ({}) => {
    const stats = useSelector(statsSelector);
    const defaultSweatcoinsAmount = 24131642586;
    const defaultSweatcoinsPerSecond = 660.8125462221348;
    const [sweatcoins, setSweatcoins] = useState(0);
    const frame = useRef(0);
    useEffect(() => {
        if (stats) {
            let initialSweatcoins = _.get(stats, 'sweatcoins', defaultSweatcoinsAmount);
            const sweatcoinsPerSecond = _.get(stats, 'sweatcoins_per_second', defaultSweatcoinsPerSecond);
            const animate = () => {
                setSweatcoins((initialSweatcoins += sweatcoinsPerSecond / 60));
                frame.current = requestAnimationFrame(animate);
            };
            frame.current = requestAnimationFrame(animate);
            return () => cancelAnimationFrame(frame.current);
        }
    }, [stats]);
    return (React.createElement(Counter, null,
        React.createElement(CounterTitle, null,
            React.createElement(FormattedMessage, { ...messages.title })),
        React.createElement(Count, null, sweatcoins ? (React.createElement(FormattedNumber, { value: sweatcoins, maximumFractionDigits: 0 })) : (React.createElement(FormattedNumber, { value: 0, maximumFractionDigits: 0 }))),
        React.createElement(Description, null,
            React.createElement(FormattedMessage, { ...messages.description }))));
};
const Counter = styled.div `
  margin: 0 16px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 0px 0px 0px;
  background: linear-gradient(258deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.01) 100%);
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.06);

  @media ${desktop()} {
    margin: 0 auto;
    width: 980px;
    padding: 32px 0px 0px;
  }
`;
const CounterTitle = styled.p `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 10px;

  @media ${desktop()} {
    font-size: 18px;
    margin-bottom: 30px;
  }
`;
const Count = styled.div `
  font-weight: 800;
  font-family: 'Inconsolata', monospace;
  color: #ffffff;
  font-size: 25px;
  letter-spacing: -1px;
  line-height: 1;
  margin-bottom: 24px;

  @media ${desktop()} {
    font-size: 72px;
    margin-bottom: 37px;
  }
`;
const Description = styled.div `
  width: 100%;
  text-align: center;
  padding: 12px 0;
  background: linear-gradient(85deg, rgba(255, 255, 255, 0.05) 0%, rgba(171, 171, 171, 0.03) 100%);
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase;
`;
export default StepsCounter;
