import React from 'react';
import styled from 'styled-components';
import { media } from 'components/responsive';
const FlatButton = (props) => (React.createElement("button", { ...props },
    React.createElement("div", null, props.children || props.caption),
    React.createElement(Arrow, { color: props.color, alt: "arrow" })));
const StyledFlatButton = styled(FlatButton) `
  margin-top: 5px;
  background: ${(props) => (props.background ? props.background : '#ffffff')};
  font-size: 14px;
  color: ${(props) => (props.color ? props.color : '#1d0255')};
  text-align: center;
  line-height: 1.2;
  text-transform: uppercase;
  height: 3.8em;
  border: 0;
  width: 100%;
  cursor: pointer;

  ${media.phone_medium `font-size: 17px;`};
  ${media.tablet `font-size: 27px;`};
  ${media.desktop `
    font-size: 11px;
    width: 175px;
    margin-left: ${(props) => (props.second ? '20px' : null)};
  `};
  ${media.desktop_medium `
    font-size: 14px;
    width: 235px;
  `};
  ${media.desktop_large `
    font-size: 17px;
    width: 293px;
  `};
`;
const ArrowImg = (props) => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", className: props.className, height: "10", width: "31", fill: "none", stroke: props.color || '#1d0255', strokeWidth: ".8" },
    React.createElement("path", { d: "M30.8 5.2l-6.4 4V.4zM0 4.4h24" })));
const Arrow = styled(ArrowImg) `
  position: relative;
  top: 3px;
`;
export default StyledFlatButton;
