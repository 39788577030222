import classnames from 'classnames';
import get from 'lodash/get';
import React, { Component } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as ApiActions from 'actions/api';
import progress from 'utils/progress';
import 'styles/invite-form';
const messages = defineMessages({
    placeholder: 'E-mail',
    action: 'Send',
    resend: 'Send again',
});
function isValidEmail(email) {
    return email && /.+@.+\..{2,}/i.test(email);
}
const mapStateToProps = createStructuredSelector({
    location: (state) => state.location,
});
class InviteForm extends Component {
    state = {
        email: '',
    };
    render() {
        return (React.createElement("form", { className: classnames('invite-form', this.props.className), onSubmit: this._handleSubmit },
            React.createElement("p", null, this.state.sent ? this.props.success : this.props.title),
            this.state.sent ? (React.createElement("p", null,
                React.createElement("span", { className: "invite-form__again", onClick: this._handleSendAgain },
                    React.createElement(FormattedMessage, { ...messages.resend })))) : null,
            !this.state.sent ? (React.createElement("div", { className: "invite-form__content" },
                React.createElement(FormattedMessage, { ...messages.placeholder }, (placeholder) => (React.createElement("input", { type: "email", placeholder: placeholder, value: this.state.email, onChange: this._handleChange }))),
                React.createElement("button", { disabled: !this._validate(), className: "button button--primary" },
                    React.createElement(FormattedMessage, { ...messages.action })))) : null));
    }
    _validate() {
        return isValidEmail(this.state.email);
    }
    _handleSendAgain = () => {
        this.setState({
            sent: false,
            email: '',
        });
    };
    _handleSubmit = (event) => {
        event.preventDefault();
        let params = {
            email: this.state.email,
        };
        if (this.props.type) {
            params.type = this.props.type;
        }
        const referer = get(this.props.location, 'query.referer');
        if (referer) {
            params.referer = referer;
        }
        progress.wrap(this.props.createInviteRequest(params).then(this._handleComplete));
    };
    _handleChange = (event) => {
        this.setState({
            email: event.target.value,
        });
    };
    _handleComplete = () => {
        this.setState({
            sent: true,
        });
    };
}
export default connect(mapStateToProps, ApiActions)(InviteForm);
