import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
const messages = defineMessages({
    title: 'Remove restrictions',
    settings: 'Settings',
    search: 'Search for and select Battery Optimisation',
    apps: 'From the drop down select “All apps”',
    sweatcoin: 'Scroll down to Sweatcoin and select it',
    dontOptimise: 'Select “Don’t optimise”',
});
import { AdviceNotSuitable, Common, Footer, InstructionCard, List, ListItem } from './common';
const GooglePixel = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Common, { featureAccelerometerTracker: props.featureAccelerometerTracker, trackEvent: props.trackEvent, openSettings: props.openSettings, isFooterDividerDefault: true }),
        React.createElement(InstructionCard, { trackEvent: props.trackEvent, openSettings: props.openSettings, title: React.createElement(FormattedMessage, { ...messages.title }) },
            React.createElement(List, null,
                React.createElement(ListItem, null,
                    React.createElement(FormattedMessage, { ...messages.settings })),
                React.createElement(ListItem, null,
                    React.createElement(FormattedMessage, { ...messages.search })),
                React.createElement(ListItem, null,
                    React.createElement(FormattedMessage, { ...messages.apps })),
                React.createElement(ListItem, null,
                    React.createElement(FormattedMessage, { ...messages.sweatcoin })),
                React.createElement(ListItem, null,
                    React.createElement(FormattedMessage, { ...messages.dontOptimise })))),
        React.createElement(Footer, { trackEvent: props.trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: props.trackEvent })));
};
export default GooglePixel;
