import React from 'react';
import styled from 'styled-components';
import { media } from 'components/responsive';
import { SectionTitle, responsiveContainer, sectionTopOffset } from './common';
const videoLink = 'https://player.vimeo.com/video/363872392?title=0&byline=0&portrait=0';
const Video = (props) => (React.createElement("div", { ...props },
    React.createElement(Container, null,
        React.createElement(SectionTitle, null, "Work at Sweatcoin"),
        React.createElement(Player, null,
            React.createElement(StyledIframe, { src: videoLink, frameBorder: "0", allow: "autoplay; fullscreen", allowFullScreen: true })))));
const StyledVideo = styled(Video) `
  ${responsiveContainer};
  padding-left: 0;
  padding-right: 0;
`;
const Container = styled.div `
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  ${sectionTopOffset};

  ${SectionTitle} {
    padding-left: 20px;
    padding-right: 20px;
    width: 70%;

    ${media.tablet `
      padding: 0;
      width: 100%;
    `};
  }
`;
const Player = styled.div `
  width: 100%;
  height: 0;
  padding-top: 62.25%;
  margin-top: 20px;
  position: relative;
`;
const StyledIframe = styled.iframe `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
export default StyledVideo;
