import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import queryString from 'query-string';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
const mapStateToProps = createStructuredSelector({
    location: (state) => state.location,
});
class OutboundLink extends Component {
    state = {
        href: null,
    };
    componentDidMount() {
        this._mounted = true;
    }
    componentWillUnmount() {
        this._mounted = false;
    }
    render() {
        // eslint-disable-next-line
        const { children, location, dispatch, href, category, action, label, value, params, ...props } = this.props;
        return (React.createElement("a", { ...props, href: this.state.href || href, onMouseDown: this._handleMouseDown, onMouseUp: this._handleMouseUp }, children));
    }
    _handleMouseDown = () => {
        const query = pickBy({
            url: this.props.href,
            dp: `${location.pathname}${location.search}`,
            ec: this.props.category,
            ea: this.props.action,
            el: this.props.label,
            ev: this.props.value,
            params: JSON.stringify(this.props.params),
        }, identity);
        this.setState({
            href: `/track?${queryString.stringify(query)}`,
        });
    };
    _handleMouseUp = () => {
        setTimeout(() => {
            if (!this._mounted)
                return;
            this.setState({
                href: null,
            });
        }, 1);
    };
}
export default connect(mapStateToProps)(OutboundLink);
