import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import useOutbrainAnalytics from 'utils/useOutbrainAnalytics';
import analytics from '../../../utils/analytics';
import bg from '../assets/mountains.png';
import tick from '../assets/tick.svg';
const messages = defineMessages({
    title: 'Get the scoop on exclusive offers or causes you care about',
    email: 'Email address',
    name: 'Name',
    agree: 'I agree to receiving emails from Sweatcoin',
    subscribe: 'Subscribe',
    successTitle: 'Thank you for subscribing for updates',
    successSubtitle: 'We’ll keep you up to date with all the news',
    incorrectEmail: 'Incorrect email format',
    required: 'Required',
});
const EmailForm = () => {
    const [isFormSent, setFormSent] = useState(false);
    const trackClick = useOutbrainAnalytics();
    return (React.createElement(Layout, null,
        React.createElement(Background, { src: bg }),
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { ...messages.title })),
        !isFormSent ? (React.createElement(Formik, { initialValues: { email: '', name: '', agree: false }, onSubmit: formSubmit }, ({ errors, touched, isValid, isSubmitting, dirty }) => (React.createElement(Form, null,
            React.createElement(Wrapper, null,
                React.createElement(InputWrapper, null,
                    React.createElement(FormattedMessage, { ...messages.email }, (placeholder) => (React.createElement(StyledField, { style: errors.email && touched.email && { borderColor: '#3cd6ee' }, id: "email", name: "email", type: "email", validate: validateEmail, placeholder: placeholder }))),
                    errors.email && touched.email && React.createElement(ErrorCaption, null, errors.email)),
                React.createElement(InputWrapper, null,
                    React.createElement(FormattedMessage, { ...messages.name }, (placeholder) => (React.createElement(StyledField, { style: errors.name && touched.name && { borderColor: '#3cd6ee' }, id: "name", name: "name", placeholder: placeholder, validate: validateName }))),
                    errors.name && touched.name && React.createElement(ErrorCaption, null, errors.name)),
                React.createElement(Field, { name: "agree", validate: validateAgreement }, ({ field }) => (React.createElement(React.Fragment, null,
                    React.createElement(Checkbox, { type: "checkbox", id: "agree", checked: field.value, ...field }),
                    React.createElement(Label, { htmlFor: "agree" },
                        React.createElement(FormattedMessage, { ...messages.agree }))))),
                React.createElement(Button, { disabled: !isValid || !dirty || isSubmitting },
                    React.createElement(FormattedMessage, { ...messages.subscribe }))))))) : (React.createElement(Success, null,
            React.createElement(SuccessSubtitle, null,
                React.createElement(FormattedMessage, { ...messages.successTitle })),
            React.createElement(SuccessSubtitle, null,
                React.createElement(FormattedMessage, { ...messages.successSubtitle }))))));
    async function formSubmit(values, { setSubmitting }) {
        trackClick('SignUp');
        await analytics.track('web-to-app-email-submit', 'click', {
            origin: location.origin,
            page: location.pathname,
        });
        const result = await fetch('/web-to-app/emails', {
            method: 'POST',
            body: JSON.stringify(values, null, 2),
            headers: new Headers(),
        });
        if (result && result.status === 200) {
            setSubmitting(false);
            setFormSent(true);
        }
        else {
            setFormFailed(true);
        }
    }
    function validateEmail(value) {
        let error;
        if (!value) {
            error = 'Required';
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
            error = React.createElement(FormattedMessage, { ...messages.incorrectEmail });
        }
        return error;
    }
    function validateName(value) {
        let error;
        if (!value) {
            error = React.createElement(FormattedMessage, { ...messages.required });
        }
        return error;
    }
    function validateAgreement(value) {
        let error;
        if (!value) {
            error = React.createElement(FormattedMessage, { ...messages.required });
        }
        return error;
    }
};
const Layout = styled.div `
  position: relative;
  z-index: 1;
  min-height: 430px;
`;
const Background = styled.img `
  mix-blend-mode: overlay;
  width: 100%;
  position: absolute;
  z-index: -1;
`;
const Title = styled.h2 `
  padding: 6px 40px 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.004em;
  color: rgba(255, 255, 255, 0.8);
`;
const Wrapper = styled.div `
  padding: 0 30px;
  text-align: center;

  label:before {
    content: '';
    -webkit-appearance: none;
    background: #3cd6ee;
    border-radius: 6px;
    padding: 12px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 20px;
  }

  input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: 16px;
    height: 16px;
    background: url(${tick}) no-repeat center center;
  }
`;
const InputWrapper = styled.div `
  position: relative;
`;
const StyledField = styled(Field) `
  width: 100%;
  height: 54px;
  padding: 16px 26px;
  margin-bottom: 25px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  box-sizing: border-box;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.004em;
  color: #ffffff;
  border: 1px solid transparent;
  outline: none;

  &::placeholder {
    color: rgba(255, 255, 255, 0.6);
  }
`;
const Label = styled.label `
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.002em;
  color: rgba(255, 255, 255, 0.8);
  position: relative;
  cursor: pointer;
  text-align: left;
`;
const Checkbox = styled.input `
  display: none;
`;
const Button = styled.button `
  padding: 10px 50px;
  background: #fff;
  border-radius: 220px;
  border: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 13.44px;
  line-height: 18px;
  letter-spacing: -0.002em;
  color: #191841;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;
const ErrorCaption = styled.div `
  font-family: 'Inter', sans-serif;
  color: #3cd6ee;
  font-size: 11px;
  line-height: 1.4;
  margin-top: 9px;
  position: absolute;
  top: -25px;
`;
const Success = styled.div `
  text-align: center;
`;
const SuccessSubtitle = styled.p `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  line-height: 1.4;
`;
export default EmailForm;
