import React from 'react';
import styled from 'styled-components';
import { media } from 'components/responsive';
import { link } from 'routes/Home/components/AppLinks';
import analytics from 'utils/analytics';
import appStoreImg from '../assets/appstore.png';
import googlePlayImg from '../assets/googleplay.png';
const AppLinks = ({ className }) => {
    return (React.createElement(Apps, { className: className },
        React.createElement(App, null,
            React.createElement(Link, { target: "_blank", href: link('AppStore'), onClick: () => onClickHandler('appstore') },
                React.createElement(AppButton, { platform: 'iOS' }))),
        React.createElement(App, null,
            React.createElement(Link, { target: "_blank", href: link(), onClick: () => onClickHandler('googleplay') },
                React.createElement(AppButton, { platform: "Android" })))));
    function onClickHandler(storeName) {
        analytics.track(`quiz_app_button_${storeName}`, 'click', {
            origin: location.origin,
            page: location.pathname,
        });
    }
};
const Apps = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;
const App = styled.div `
  &:last-child {
    margin-left: 8px;
  }
`;
const Link = styled.a `
  display: block;
`;
const AppButton = styled.span `
  background: url(${(props) => (props.platform === 'Android' ? googlePlayImg : appStoreImg)}) no-repeat;
  background-size: cover;
  display: block;
  width: 140px;
  height: 42px;

  ${media.tablet `
    width: 105px;
    height: 32px;
  `};
`;
export default AppLinks;
