import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
const SliderBlock = (props) => {
    const [active, setActive] = useState(0);
    const prevActive = usePrevious(active);
    const { index, trackedEvents, trackEvent } = props;
    useEffect(() => {
        // fix for analytics
        if (active === 0 && index && trackedEvents.indexOf('slide_viewed_1_0') === -1)
            return;
        let direction = null;
        if (prevActive !== undefined) {
            direction = active > prevActive ? 'forward' : 'backward';
        }
        trackEvent(`slide_viewed_${index}_${active}`, {
            direction,
        });
    }, [active]);
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledSlider, { ...props.settings, appendDots: (dots) => React.createElement(Bullets, null, dots), beforeChange: (oldIndex, newIndex) => setActive(newIndex) }, props.cardArray),
        React.createElement(Description, null,
            React.createElement("div", null,
                active !== null ? `${active + 1}. ` : '',
                active !== null ? props.cards[active].description : ''),
            React.createElement("div", null))));
};
const Bullets = (props) => {
    return React.createElement(BulletList, null, props.children);
};
const StyledSlider = styled(Slider) `
  margin: 0 -16px;
  position: relative;

  .slick-track {
    margin-left: 16px;
  }
`;
const Description = styled.div `
  margin: 16px 0 24px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 48px;
`;
const BulletSize = 7;
const BulletList = styled.div `
  display: flex;
  justify-content: center;
  margin-top: 12px;

  li {
    display: block;
    width: ${BulletSize}px;
    height: ${BulletSize}px;
    opacity: 0.3;
    margin: 0 5px;
    background: #ffffff;
    border-radius: 50%;

    button {
      display: none;
    }

    &.slick-active {
      opacity: 1;
    }
  }
`;
function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}
export default SliderBlock;
