import React from 'react';
import styled from 'styled-components';
import { media } from 'components/responsive';
import AnswerItem from './AnswerItem';
const AnswersList = (props) => {
    return (React.createElement(Container, null, props.answers.map((answer, index) => {
        return React.createElement(AnswerItem, { key: index, answer: answer, onAnswerClick: props.onAnswerClick });
    })));
};
const Container = styled.div `
  ${media.tablet `
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items:flex-start;
  align-content:flex-start;
  `};
`;
export default AnswersList;
