import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import * as ApiActions from 'actions/api';
import Layout from 'components/Layout';
const messages = defineMessages({
    title: 'Your email is now confirmed',
});
export default class ConfirmEmail extends Component {
    static fetchData(_, store, { location }) {
        return store.dispatch(ApiActions.checkEmailCode(location.query));
    }
    render() {
        return (React.createElement(Layout, null,
            React.createElement(Helmet, { meta: [{ name: 'event-id', content: 'confirm_email' }] }),
            React.createElement(Layout.Container, null,
                React.createElement("h2", null,
                    React.createElement(FormattedMessage, { ...messages.title })))));
    }
}
