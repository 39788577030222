import React from 'react';
import { defineMessages } from 'react-intl';
import dailyMailBlack from 'assets/press/daily-mail-black.png';
import dailyMail from 'assets/press/daily-mail.png';
import forbesBlack from 'assets/press/forbes-black.png';
import forbes from 'assets/press/forbes.png';
import nytBlack from 'assets/press/nyt-black.png';
import nyt from 'assets/press/nyt.png';
import reutersBlack from 'assets/press/reuters-black.png';
import reuters from 'assets/press/reuters.png';
import tcBlack from 'assets/press/tc-black.png';
import tc from 'assets/press/tc.png';
import theTelegraphBlack from 'assets/press/the-telegraph-black.png';
import theTelegraph from 'assets/press/the-telegraph.png';
export const quotes = defineMessages({
    tc: '“Sweatcoin has risen to the top of the App Store for helping folks get something more than just a glow for taking those daily steps”',
    nyt: '‘Sweatcoin’s “first premise is that physical movement has economic value”’',
    forbes: '“New UK health app Sweatcoin launched in the Apple app store this week and has been an instant hit”',
    reuters: '“New Apple app launches in Britain that pays people to get fit”',
    daily_mail: '“The app that PAYS you to get fit: Sweatcoin rewards people for the number of steps they make every day”',
    the_telegraph: "“If ever you've needed an incentive to exercise, a new app could give you a much-needed boost to get active.”",
});
export default [
    {
        source: React.createElement("img", { src: tc }),
        sourceBlack: React.createElement("img", { src: tcBlack }),
        quoteKey: 'tc',
        link: 'https://techcrunch.com/2018/01/17/sweatcoin-lets-you-earn-crypto-for-working-out/',
    },
    {
        source: React.createElement("img", { src: nyt }),
        sourceBlack: React.createElement("img", { src: nytBlack }),
        quoteKey: 'nyt',
        link: 'https://www.nytimes.com/2018/01/07/technology/sweatcoin-fitness-app.html',
    },
    {
        source: React.createElement("img", { src: forbes }),
        sourceBlack: React.createElement("img", { src: forbesBlack }),
        quoteKey: 'forbes',
        link: 'http://www.forbes.com/sites/montymunford/2016/05/10/free-app-sweatcoin-pays-people-to-get-fit-and-stay-active/',
    },
    {
        source: React.createElement("img", { src: reuters }),
        sourceBlack: React.createElement("img", { src: reutersBlack }),
        quoteKey: 'reuters',
        link: 'http://www.reuters.com/article/us-currency-fitness-idUSKCN0XW193',
    },
    {
        source: React.createElement("img", { src: dailyMail }),
        sourceBlack: React.createElement("img", { src: dailyMailBlack }),
        quoteKey: 'daily_mail',
        link: 'http://www.dailymail.co.uk/sciencetech/article-3575117/New-Apple-app-launches-Britain-pays-people-fit.html',
    },
    {
        source: React.createElement("img", { src: theTelegraph }),
        sourceBlack: React.createElement("img", { src: theTelegraphBlack }),
        quoteKey: 'the_telegraph',
        link: 'http://www.telegraph.co.uk/technology/2016/05/09/need-motivation-this-app-will-pay-you-to-exercise/',
    },
];
