import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled, { css } from 'styled-components';
import FONTS from 'assets/AndroidInstructions';
import OnVisible from 'routes/Home/components/OnVisible';
const messages = defineMessages({
    gpsTitle: 'Check GPS settings',
    settings: 'Settings',
    location: 'Location',
    gpsSwitch: 'The Switch should be on and “Mode” should be set to “High Accuracy”',
    otherSettings: 'OR these settings may be found in:',
    advancedSettings: 'Advanced Settings',
    locationServices: 'Location Services',
    sweatcoin: 'Sweatcoin',
    highAccuracy: 'High Accuracy/Always On/Higher Battery Use (this can vary device to device)',
    gpsPermissions: 'Check GPS permissions',
    apps: 'Apps',
    permissions: 'Permissions',
    locationSwitch: 'Location, and ensure that this is switched ON',
    footerAccelerometer: "These should sort out 95% of all issues. If they don't, and your conversion remains at ZERO then it is likely that there is a hardware issue and your  accelerometer is playing up.",
    footerGPS: "These should sort out 95% of all issues. If they don't, and your conversion remains at ZERO then it is likely that there is a hardware issue and your  GPS is playing up.",
    settingsButton: 'Go to settings',
    notSuitableButton: 'Advice not suitable for my device',
});
export const Common = (props) => props.featureAccelerometerTracker ? null : (React.createElement(React.Fragment, null,
    React.createElement(Title, { trackEvent: props.trackEvent },
        React.createElement(FormattedMessage, { ...messages.gpsTitle })),
    React.createElement(List, null,
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.settings })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.location })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.gpsSwitch }))),
    React.createElement(Text, null,
        React.createElement(FormattedMessage, { ...messages.otherSettings })),
    React.createElement(List, null,
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.settings })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.advancedSettings })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.locationServices })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.sweatcoin })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.highAccuracy }))),
    React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
    React.createElement(Divider, null),
    React.createElement(Title, { trackEvent: props.trackEvent },
        React.createElement(FormattedMessage, { ...messages.gpsPermissions })),
    React.createElement(List, null,
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.settings })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.apps })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.sweatcoin })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.permissions })),
        React.createElement(ListItem, null,
            React.createElement(FormattedMessage, { ...messages.locationSwitch }))),
    React.createElement(SettingsButton, { openSettings: props.openSettings, style: props.isFooterDividerDefault ? { marginTop: '16px', marginBottom: '16px' } : {} }),
    props.isFooterDividerDefault ? React.createElement(Divider, null) : React.createElement(SamsungDivider, null)));
export const Title = (props) => {
    const ref = React.createRef();
    let tracked = false;
    const onVisible = () => {
        // TODO move trackEvent to Context
        if (!tracked && props.trackEvent) {
            props.trackEvent('title_viewed', { title: props.children });
            tracked = true;
        }
    };
    return (React.createElement(OnVisible, { onVisible: onVisible },
        React.createElement(StyledTitle, { style: props.style, ref: ref }, props.children)));
};
const StyledTitle = styled.h1 `
  font-size: 24px;
  line-height: 30px;
  font-family: ${FONTS.PFDIN.name};
  margin: 0 0 16px;
`;
export const List = styled.ul `
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const ListItem = styled.li `
  padding-left: 20px;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 15px;
  letter-spacing: 0.5px;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    vertical-align: middle;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 12px;
    transform: translateY(-50%);
  }

  &::after {
    content: '';
    position: absolute;
    height: calc(100% - 8px);
    width: 2px;
    background: rgba(255, 255, 255, 0.3);
    left: 4px;
    top: 16px;
    transform: translateX(-50%);
  }

  &:last-child::after {
    content: none;
  }
`;
const TextCss = css `
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0;
  font-family: ${FONTS.RobotoRegular.name};
`;
export const Text = styled.div `
  ${TextCss};
  margin-top: 0;
  padding-bottom: 16px;
`;
export const Divider = styled.hr `
  background: #fff;
  opacity: 0.1;
  margin: ${(props) => (props.marginTop ? '16px' : 0)} -16px 16px;
`;
export const SamsungDivider = styled(Divider) `
  margin-top: 24px;
  margin-bottom: 24px;
`;
export const Footer = (props) => {
    const ref = React.createRef();
    let tracked = false;
    const onVisible = () => {
        // TODO move trackEvent to Context
        if (!tracked && props.trackEvent) {
            props.trackEvent('footer_viewed');
            tracked = true;
        }
    };
    return (React.createElement(OnVisible, { onVisible: onVisible },
        React.createElement(StyledFooter, { ref: ref })));
};
export const StyledFooter = styled.div.attrs(({ featureAccelerometerTracker }) => ({
    children: featureAccelerometerTracker ? (React.createElement(FormattedMessage, { ...messages.footerAccelerometer })) : (React.createElement(FormattedMessage, { ...messages.footerGPS })),
})) `
  ${TextCss};
`;
export const SettingsButton = styled.div.attrs((props) => ({
    children: React.createElement(FormattedMessage, { ...messages.settingsButton }),
    onClick: () => props.openSettings(props.title),
})) `
  border: 1px solid #ffffff;
  border-radius: 9px;
  font-size: 16px;
  text-align: center;
  line-height: 21px;
  letter-spacing: 0.5px;
  padding: 14px 12px 15px;
  margin-top: ${({ marginTop }) => (marginTop ? '24px' : null)};
`;
const NotSuitableButton = styled(SettingsButton).attrs(() => ({
    children: React.createElement(FormattedMessage, { ...messages.notSuitableButton }),
})) `
  background: rgba(255, 255, 255, 0.1);
  border: none;
`;
export const AdviceNotSuitable = (props) => {
    const [clicked, setClicked] = useState(false);
    const onClick = () => {
        props.trackEvent('not_suitable_advice');
        setClicked(true);
    };
    // scroll to bottom after redraw
    useEffect(() => {
        if (clicked) {
            window.scrollTo(0, document.body.scrollHeight);
        }
    }, [clicked]);
    return (React.createElement(NotSuitableContainer, null, clicked ? (React.createElement(React.Fragment, null,
        React.createElement(Title, { style: { textAlign: 'center' } }, "Thank you!"),
        React.createElement(Text, { style: { textAlign: 'center' } }, "We are investigating"))) : (React.createElement(NotSuitableButton, { onClick: onClick }))));
};
const NotSuitableContainer = styled.div `
  margin-top: 16px;
`;
export const InstructionCard = (props) => (React.createElement(React.Fragment, null,
    React.createElement(Title, { trackEvent: props.trackEvent }, props.title),
    props.children,
    React.createElement(SettingsButton, { openSettings: props.openSettings, title: props.title, style: { marginTop: '16px', marginBottom: '16px' } }),
    React.createElement(SamsungDivider, null)));
