import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Slider from 'react-slick';
import styled from 'styled-components';
import FlatButton from 'components/FlatButton';
import { media } from 'components/responsive';
import disableOverscroll from 'utils/dIsableOverscroll';
import Join1Src from '../../../assets/team/join_1.jpg';
import Join1OverlaySrc from '../../../assets/team/join_1_over.png';
import Join2Src from '../../../assets/team/join_2.jpg';
import Join2OverlaySrc from '../../../assets/team/join_2_over.png';
import Join3Src from '../../../assets/team/join_3.jpg';
import Join3OverlaySrc from '../../../assets/team/join_3_over.png';
import Join4Src from '../../../assets/team/join_4.jpg';
import Join4OverlaySrc from '../../../assets/team/join_4_over.png';
import Join5Src from '../../../assets/team/join_5.jpg';
import Join5OverlaySrc from '../../../assets/team/join_5_over.png';
import { Text, Title, responsiveContainer, sectionTopOffset } from './common';
const ROUNDS = [
    {
        bg: Join1Src,
        overlay: Join1OverlaySrc,
    },
    {
        bg: Join2Src,
        overlay: Join2OverlaySrc,
    },
    {
        bg: Join3Src,
        overlay: Join3OverlaySrc,
    },
    {
        bg: Join4Src,
        overlay: Join4OverlaySrc,
    },
    {
        bg: Join5Src,
        overlay: Join5OverlaySrc,
    },
];
const messages = defineMessages({
    text: 'We are on a mission of making the world more active and we need the right people to make it happen. Come join us on this journey!',
});
const transitionDuration = 750;
const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: transitionDuration,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    variableWidth: true,
    swipeToSlide: true,
};
const Join = () => {
    const [activeSlide, setActiveSlide] = useState(0);
    const [rounds, setRounds] = useState();
    // Change slides on vertical scroll
    const sliderEl = useRef(null);
    const imageSideEl = useRef(null);
    const onScroll = _.throttle((e) => {
        const { deltaY, deltaX } = e;
        e.preventDefault();
        if (deltaX !== 0 && Math.abs(deltaX) > Math.abs(deltaY)) {
            // stop autoplay
            sliderEl.current.slickPause();
            // change slide
            deltaX > 0 ? sliderEl.current.slickNext() : sliderEl.current.slickPrev();
            // restart  autoplay
            setTimeout(() => sliderEl.current.slickPlay(), sliderSettings.autoplaySpeed);
        }
    }, 1500, // fix momentum scrolling events
    {
        trailing: false,
    });
    // set event listener
    useEffect(() => {
        setRounds(ROUNDS);
        if (imageSideEl) {
            const elCurrent = imageSideEl.current;
            elCurrent.addEventListener('wheel', onScroll);
            const overscroll = disableOverscroll(imageSideEl.current);
            // remove event listener
            return () => {
                elCurrent.removeEventListener('wheel', onScroll);
                overscroll();
            };
        }
    }, []);
    return (React.createElement(Container, null,
        React.createElement(TextSide, null,
            React.createElement(Title, null, "Join the team"),
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { ...messages.text })),
            React.createElement(StyledLink, { href: "https://sweatcoin.teamtailor.com", target: "_blank", rel: "noreferrer" },
                React.createElement(FlatButton, { background: "#422F6C", color: "#FFFFFF" }, "View openings"))),
        React.createElement(ImageSide, { ref: imageSideEl },
            React.createElement(StyledSlider, { ...sliderSettings, ref: sliderEl, beforeChange: (currentIndex, nextIndex) => {
                    setActiveSlide(nextIndex);
                } }, rounds ? (rounds.map((round, key) => (React.createElement(SliderItem, { key: key },
                React.createElement(Round, { className: key === activeSlide ? 'active' : null, background: `url(${round.bg})`, overlay: `url(${round.overlay})` }))))) : (React.createElement(Round, { style: { opacity: 0, pointerEvent: 'none' } }))))));
};
const Container = styled.div `
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow-x: auto;
  ${sectionTopOffset};
  padding-top: 1rem;
`;
const StyledLink = styled.a ``;
const TextSide = styled.div `
  flex: 1 1 100%;
  ${responsiveContainer};

  ${Title} {
    font-size: 38px;

    ${media.tablet `
      font-size: 48px;
    `};
    ${media.desktop `
      font-size: 3.5rem;
    `};
  }

  ${Text} {
    margin-top: 0.9rem;
    font-size: 0.9rem;
    ${media.tablet `
      font-size: 20px;
      width: 80%;
    `};
    ${media.desktop `
      font-size: 0.9rem;
      width: 95%;
    `};
  }

  ${FlatButton} {
    margin-top: 1.5rem;
    width: 188px;
    font-size: 11px;

    ${media.desktop `
      margin-top: 1rem;
    `};
    ${media.desktop_medium `
      width: 235px;
      font-size: 14px;
    `};
    ${media.desktop_large `
      width: 293px;
      font-size: 17px;
    `};
  }

  ${media.desktop `
    flex-basis: 50%;
    padding-right: 0;
  `};
`;
const Round = styled.div `
  background: ${(props) => props.background || null};
  background-size: cover;
  border-radius: 50%;
  position: relative;
  width: 12rem;
  height: 12rem;
  user-select: none;
  outline: none;

  &:focus {
    outline: none;
  }

  &::after {
    content: '';
    display: ${(props) => (props.overlay ? 'block' : 'none')};
    background: ${(props) => props.overlay || ''} no-repeat;
    background-size: cover;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
    transition: opacity ${transitionDuration}ms ease-in-out;
  }

  &.active:after {
    opacity: 0;
  }

  ${media.tablet `
    width: 11rem;
    height: 11rem;
  `};
  ${media.desktop `
    width: 17rem;
    height: 17rem;
  `};
  ${media.desktop_medium `
    width: 18rem;
    height: 18rem;
  `};
`;
const ImageSideBorder__width = '0.8rem';
const ImageSide = styled.div `
  flex: 1 1 100%;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 2rem;
  position: relative;

  ${Round} {
    margin-left: 10px;
    display: inline-block;

    ${media.desktop `
      margin-left: ${ImageSideBorder__width};
    `};
    ${media.desktop_medium `
      width: 18rem;
      height: 18rem;
    `};
    ${media.desktop_large `
      width: 19rem;
      height: 19rem;
    `};
  }

  ${media.desktop `
    flex-basis: 50%;
    margin-top: 1rem;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -1px;
      bottom: 0;
      width: ${ImageSideBorder__width};
      background: linear-gradient(90deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
    }
  `};
`;
const StyledSlider = styled(Slider) `
  ${media.desktop `margin-left: -1px;`};
`;
const SliderItem = styled.div `
  outline: none;
`;
export default Join;
