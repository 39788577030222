import get from 'lodash/get';
import url from 'url';
import { createSelector, createStructuredSelector } from 'reselect';
import { generateFirebaseCompanyQuery, generateFirebaseInviteRoute } from 'utils/firebase';
import sharingGiftUrl from 'assets/sharing_gift.jpg';
import { configSelector, originSelector } from './root';
const profilesSelector = (state) => state.profiles;
const usernameSelector = (_, params) => params.username;
export const makeProfileSelector = () => createSelector(profilesSelector, usernameSelector, (profiles, username) => profiles[username]);
const offersSelector = (state) => state.offers;
const offerIdSelector = (_, params) => params.offer_id;
export const makeOfferSelector = () => createSelector(offersSelector, offerIdSelector, (offers, offerId) => get(offers, `models.${offerId}`));
// services invite page
const servicesUsernameSelector = (_, { match }) => match.params.username;
const channelSelector = (state) => get(state, 'config.location.query.channel', 'other');
export const platformSelector = (state) => get(state, 'config.location.query.platform', 'unknown');
const optionsSelector = createSelector(channelSelector, (channel) => ({ channel }));
const profileSelector = createSelector(profilesSelector, servicesUsernameSelector, (profiles, username) => profiles[username]);
export const absoluteSharingGiftUrlSelector = createSelector(originSelector, (origin) => url.resolve(origin, sharingGiftUrl));
export const firebaseLinkSelector = createSelector(profileSelector, configSelector, optionsSelector, generateFirebaseInviteRoute);
export const serviceInviteStructuredSelector = createStructuredSelector({
    profile: profileSelector,
    config: configSelector,
    channel: channelSelector,
    platform: platformSelector,
    firebaseLink: firebaseLinkSelector,
    absoluteSharingGiftUrl: absoluteSharingGiftUrlSelector,
});
// company join page
const codeSelector = (_, props) => props.match.params.code;
const joinPageOptionsSelector = createSelector([], () => ({ encodeLink: true }));
const firebaseQuerySelector = createSelector(codeSelector, configSelector, joinPageOptionsSelector, generateFirebaseCompanyQuery);
export const companyJoinPageStructuredSelector = createStructuredSelector({
    config: configSelector,
    firebaseQuery: firebaseQuerySelector,
});
