import * as url from 'url';
import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import logo_src from 'assets/Home/header__logo.svg';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import cowboyBootsSharingUrl from '../assets/shoes/shares/cowboy.jpg';
import crocsSharingUrl from '../assets/shoes/shares/crocks.jpg';
import highHeelsSharingUrl from '../assets/shoes/shares/high_heels.jpg';
import rollerSkatesSharingUrl from '../assets/shoes/shares/roller_skates.jpg';
import sharingUrl from '../assets/shoes/shares/shoes_share.jpg';
import slippersSharingUrl from '../assets/shoes/shares/slippers.jpg';
import sneakersSharingUrl from '../assets/shoes/shares/sneakers.jpg';
import AppLinks from '../components/AppLinks';
import Quiz from '../components/Quiz';
import quizData from '../constants/shoesQuizData';
function useQuery() {
    return queryString.parse(useLocation().search);
}
function getQuestions() {
    // we need to limit the number of possible results
    const questions = [...quizData.questions];
    const ids = ['Sneakers', 'High_heels', 'Slippers', 'Cowboy_boots', 'Roller_skates', 'Crocs'];
    const gameSet = _.shuffle(ids).splice(2);
    const necessaryQuestions = questions.map((question) => {
        return { ...question, answers: question.answers.filter((answer) => gameSet.includes(answer.id)) };
    });
    return necessaryQuestions;
}
const App = () => {
    const { origin } = useSelector((state) => state.config);
    const { result } = useSelector((state) => state.params);
    const history = useHistory();
    const query = useQuery();
    const [points, setPoints] = useState({
        Sneakers: 0,
        High_heels: 0,
        Slippers: 0,
        Cowboy_boots: 0,
        Roller_skates: 0,
        Crocs: 0,
    });
    const [questions, setQuestion] = useState(getQuestions());
    const [meta] = useState({ ...quizData.meta });
    const [activeQuestion, setActiveQuestion] = useState(0);
    const [isStarted, setIsStarted] = useState(false);
    const [isFinished, setIsFinished] = useState(false);
    const [winner, setWinner] = useState(null);
    const currentQuestion = questions[activeQuestion];
    const shuffledAnswers = _.shuffle(currentQuestion.answers);
    const [isUserFromApp] = useState(query.src === 'prizedraw');
    let absoluteSharingUrl;
    useEffect(() => {
        if ((process.browser && result) || isUserFromApp) {
            history.replace('/quiz/shoes');
        }
    }, []);
    switch (result) {
        case 'sneakers':
            absoluteSharingUrl = url.resolve(origin, sneakersSharingUrl);
            break;
        case 'high-heels':
            absoluteSharingUrl = url.resolve(origin, highHeelsSharingUrl);
            break;
        case 'slippers':
            absoluteSharingUrl = url.resolve(origin, slippersSharingUrl);
            break;
        case 'cowboy-boots':
            absoluteSharingUrl = url.resolve(origin, cowboyBootsSharingUrl);
            break;
        case 'roller-skates':
            absoluteSharingUrl = url.resolve(origin, rollerSkatesSharingUrl);
            break;
        case 'crocs':
            absoluteSharingUrl = url.resolve(origin, crocsSharingUrl);
            break;
        default:
            absoluteSharingUrl = url.resolve(origin, sharingUrl);
            break;
    }
    return (React.createElement(Background, null,
        getHelmet(),
        React.createElement(Layout, null,
            React.createElement(Header, null,
                React.createElement(StyledNavLink, { to: "/", exact: true },
                    React.createElement(Logo, { id: "box-logo", src: logo_src })),
                React.createElement(HeaderAppLinks, null)),
            React.createElement(Quiz, { meta: meta, question: currentQuestion, answers: shuffledAnswers, quizLength: questions.length, answerNumber: activeQuestion + 1, winner: winner, isStarted: isStarted, isFinished: isFinished, onStartClickHandler: onStartClickHandler, onAnswerClickHandler: onAnswerClickHandler, onRetryHandler: onRetryHandler, isUserFromApp: isUserFromApp }),
            !isFinished ? React.createElement(FooterAppLinks, null) : null)));
    function getHelmet() {
        return (React.createElement(Helmet, { title: meta.title, link: [{ rel: 'image_src', href: absoluteSharingUrl }], meta: [
                { name: 'event-id', content: 'quiz' },
                { property: 'og:title', content: meta.title },
                { name: 'twitter:title', content: meta.title },
                { name: 'description', content: meta.description },
                { property: 'og:description', content: meta.description },
                { name: 'twitter:description', content: meta.description },
                { property: 'og:image', content: absoluteSharingUrl },
                { name: 'twitter:image', content: absoluteSharingUrl },
                { name: 'twitter:card', content: 'summary_large_image' },
            ] }));
    }
    function onStartClickHandler() {
        analytics.track('quiz_start_button', 'click', {
            origin: location.origin,
            page: location.pathname,
            quizId: meta.quizId,
        });
        setIsStarted(true);
    }
    function onAnswerClickHandler(answerId) {
        const questionNumber = activeQuestion + 1;
        analytics.track('quiz_answer_button', 'click', {
            origin: location.origin,
            page: location.pathname,
            questionNumber: questionNumber,
            quizId: meta.quizId,
        });
        setPoints({ ...points, [answerId]: points[answerId] + 1 });
        if (isQuizFinished()) {
            const winnerName = getWinner();
            setIsFinished(true);
            setWinner({ ...quizData.results[winnerName] });
        }
        else {
            setActiveQuestion(activeQuestion + 1);
        }
    }
    function isQuizFinished() {
        return activeQuestion + 1 === questions.length;
    }
    function getWinner() {
        const pointsSorted = Object.keys(points).sort(function (a, b) {
            return points[b] - points[a];
        });
        return pointsSorted[0];
    }
    function onRetryHandler() {
        setPoints({
            Sneakers: 0,
            High_heels: 0,
            Slippers: 0,
            Cowboy_boots: 0,
            Roller_skates: 0,
            Crocs: 0,
        });
        setActiveQuestion(0);
        setIsStarted(true);
        setIsFinished(false);
        setWinner(null);
        setQuestion(getQuestions());
        analytics.track('quiz_restart_button', 'click', {
            origin: location.origin,
            page: location.pathname,
            quizId: meta.quizId,
        });
    }
};
const Background = styled.div `
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: linear-gradient(164deg, #032555 0%, #2984c1 50%, #00c9ff 100%);
`;
const Layout = styled.div `
  margin: 20px 16px 100px;
  flex: 1;

  ${media.tablet `
    width: 667px;
    flex: none;
  `};
`;
const Header = styled.div `
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  z-index: 10;
`;
const StyledNavLink = styled(NavLink) `
  display: block;
`;
const Logo = styled.img `
  position: relative;
  width: 156px;

  ${media.tablet `
    width: 177px;
  `};
`;
const HeaderAppLinks = styled(AppLinks) `
  display: none;

  ${media.tablet `
    display: flex;
    width: 220px;
  `};
`;
const FooterAppLinks = styled(AppLinks) `
  margin-top: 70px;

  ${media.tablet `
    display: none;
  `};
`;
export default App;
