import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
const HowItWorks = ({ messages }) => {
    return (React.createElement(Container, null,
        React.createElement(FormattedMessage, { ...messages.howItWorksTitle }, (title) => React.createElement(Title, null, title)),
        React.createElement(List, null,
            React.createElement(Item, null,
                React.createElement(Number, null, "1"),
                React.createElement(FormattedMessage, { ...messages.step_1 }, (description) => React.createElement(Description, null, description))),
            React.createElement(Item, null,
                React.createElement(Number, null, "2"),
                React.createElement(FormattedMessage, { ...messages.step_2 }, (description) => React.createElement(Description, null, description))),
            React.createElement(Item, null,
                React.createElement(Number, null, "3"),
                React.createElement(FormattedMessage, { ...messages.step_3 }, (description) => React.createElement(Description, null, description))))));
};
const Container = styled.div `
  margin: 24px 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  padding: 19px 0 20px 0;
`;
const Title = styled.h2 `
  color: #ffffff;
  font-size: 24px;
  line-height: 130%;
  letter-spacing: -0.2px;
  font-weight: bold;
  margin-bottom: 16px;
`;
const List = styled.div ``;
const Number = styled.div `
  flex: none;
  width: 30px;
  height: 30px;
  border: 2px solid rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  margin-right: 20px;
  line-height: 1;
  position: relative;
  z-index: 1;
  box-sizing: border-box;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 2px;
    height: 26px;
    background-color: rgba(255, 255, 255, 0.1);
    left: 12px;
    bottom: -28px;
    z-index: -1;
  }
`;
const Item = styled.div `
  display: flex;
  min-height: 56px;

  &:last-child ${Number}::after {
    display: none;
  }
`;
const Description = styled.p `
  color: #ffffff;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.2px;
  padding-top: 2px;
`;
export default HowItWorks;
