import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import hands from 'assets/Health/hands.png';
import bg from 'assets/Health/partner.jpg';
import { media } from 'components/responsive';
const messages = defineMessages({
    title: 'How we partner ',
    item1: 'We work with health industry partners on programs to improve global health',
    item2: 'Partners include the NHS, research charities and sports brands',
    item3: 'We design custom, time-bound programmes for those at risk of health conditions including diabetes, cardiovascular disease and weight management',
    item4: 'Free to the user, the Sweatcoin app is the most accessible movement-tracking technology and does not require the use of expensive wearables',
    item5: 'We work with partners to develop custom programmes of activity incorporating personalized health content, participant leader boards and custom rewards',
    mail: 'To set up a trial in your region contact',
});
const Partner = () => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { ...messages.title })),
            React.createElement(Items, null,
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item1 })),
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item2 })),
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item3 })),
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item4 })),
                React.createElement(Item, null,
                    React.createElement(FormattedMessage, { ...messages.item5 }))),
            React.createElement(Mail, null,
                React.createElement(Hands, null),
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, { ...messages.mail }),
                    ' ',
                    React.createElement(MailLink, { href: "mailto:health@sweatco.in" }, "health@sweatco.in"))))));
};
const Layout = styled.div `
  background: url(${bg}) no-repeat;
  background-size: cover;
  border-radius: 50px;
  padding: 40px 16px;
  margin-bottom: 100px;

  ${media.desktop `
    padding: 50px 90px 35px;
  `};
`;
const Title = styled.h2 `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  color: #191841;
  margin-bottom: 30px;

  ${media.desktop `
    margin-bottom: 70px;
  `};
`;
const Items = styled.div `
  display: flex;
  justify-content: center;
  gap: 20px;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
  overflow: hidden;
  flex-direction: column;

  ${media.desktop `
    flex-direction: row;
  `};
`;
const Item = styled.div `
  flex: 1;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #191841;
  padding-left: 70px;
  position: relative;

  ${media.desktop `
    padding: 0;
  `};

  &::before {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: linear-gradient(78.36deg, #ffffff -20.31%, #ffac65 16.95%, #854bff 77.8%);
    margin: 0 auto 20px;
    position: absolute;
    left: 0;
    top: 0;

    ${media.desktop `
      width: 60px;
      height: 60px;
      position: relative;
    `};
  }

  &::after {
    top: 0;
    left: 18px;
    content: '';
    display: block;
    position: absolute;
    width: 4px;
    height: calc(100% + 20px);
    z-index: -1;
    border-left: 1px solid #553caa;
    border-right: 1px solid #553caa;

    ${media.desktop `
      top: 28px;
      left: 0;
      height: 4px;
      width: calc(100% + 20px);
      border: none;
      border-top: 1px solid #553caa;
      border-bottom: 1px solid #553caa;
    `};
  }

  &:first-child::before {
    margin: 0 0 20px;
  }

  &:last-child::after {
    display: none;

    ${media.desktop `
      display: block;
      width: 50%;
    `};
  }
`;
const Mail = styled.div `
  display: flex;
  margin-top: -50px;
  flex-direction: column;

  ${media.desktop `
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 46px;
    margin-top: 0;
  `};
`;
const Hands = styled.div `
  width: 179px;
  height: 153px;
  background: url(${hands}) no-repeat;
  background-size: contain;
`;
const Text = styled.p `
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #191841;
  padding: 0 14px;

  ${media.desktop `
    font-size: 32px;
    line-height: 50px;
    padding: 0;
  `};
`;
const MailLink = styled.a `
  text-decoration: underline;
`;
export default Partner;
