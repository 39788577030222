import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_FULFILLED, MISC_SET_META, ROUTER_BEFORE_NAVIGATE, } from 'constants/ActionTypes';
const metaReducer = (state = {}, action) => {
    switch (action.type) {
        case MISC_SET_META: {
            return {
                ...state,
                ...pickBy(action.data, identity),
            };
        }
        case GET_USER_BY_USERNAME_WITH_PRIZEDRAW_DEADLINE_FULFILLED: {
            return {
                ...state,
                prizedraw_deadline: get(action, 'payload.meta.prizedraw_deadline'),
            };
        }
        case ROUTER_BEFORE_NAVIGATE: {
            return {
                title: 'Sweatcoin',
            };
        }
        default:
            return state;
    }
};
export default metaReducer;
