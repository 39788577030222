/**
 * Accepts an array of matched routes as returned from react-router's
 * `Router.run()` and calls the given static method on each. The methods may
 * return a promise.
 *
 * Returns a promise that resolves after any promises returned by the routes
 * resolve. The practical uptake is that you can wait for your data to be
 * fetched before continuing. Based off react-router's async-data example
 * https://github.com/rackt/react-router/blob/master/examples/async-data/app.js#L121
 * @param {array} routes - Matched routes
 * @param {string} methodName - Name of static method to call
 * @param {...any} ...args - Arguments to pass to the static method
 */
const performRouteHandlerStaticMethod = (methodName, branch, store, ...args) => {
    const { error } = store.getState();
    if (error && error.nextCode) {
        return Promise.resolve();
    }
    return Promise.all(branch
        .map((node) => node.route.component)
        .map((component) => {
        const routeComponent = component && component.WrappedComponent ? component.WrappedComponent : component;
        return routeComponent ? routeComponent[methodName] : null;
    })
        .filter((method) => typeof method === 'function')
        .map((method) => method(branch[branch.length - 1], store, ...args)));
};
export default performRouteHandlerStaticMethod;
