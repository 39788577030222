import React from 'react';
import styled from 'styled-components';
import addButtonIcon from '../assets/add-button-icon.svg';
const EmailList = ({ friends, setEmail, setFriends }) => {
    return (React.createElement(Layout, null,
        React.createElement(Label, null, "Your email"),
        React.createElement(EmailInput, { placeholder: "name@example.com", onChange: setEmail }),
        React.createElement(Label, null, "Friend emails"),
        friends.map((email, i) => (React.createElement(EmailInputStatic, { key: i, value: email }))),
        React.createElement(EmailInput, { showAddButton: true, placeholder: "friend@example.com", onSubmit: (email) => setFriends((arr) => [...arr, email]) })));
};
export default EmailList;
export function isValidEmail(email) {
    return /.+@.+\..{2,}/i.test(email);
}
const EmailInput = ({ value, placeholder, showAddButton, onSubmit, onChange }) => {
    const errorTimeout = React.useRef();
    const [email, setEmail] = React.useState(value);
    const [error, setError] = React.useState(false);
    React.useEffect(() => {
        return () => {
            clearTimeout(errorTimeout.current);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(InputContainer, null,
            React.createElement(Input, { value: email, placeholder: placeholder, onChange: handleOnChange, onKeyDown: handleKeyDown }),
            showAddButton ? React.createElement(AddButton, { onClick: handleAddButtonClick }) : null),
        React.createElement(Line, { error: error })));
    function handleOnChange(event) {
        const email = event.target.value;
        setEmail(email);
        if (onChange) {
            onChange(email);
        }
    }
    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            submit();
        }
    }
    function handleAddButtonClick() {
        submit();
    }
    function submit() {
        if (email && isValidEmail(email)) {
            onSubmit(email);
            setEmail('');
        }
        else {
            setError(true);
            errorTimeout.current = setTimeout(() => {
                setError(false);
            }, 2000);
        }
    }
};
const EmailInputStatic = ({ value }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(InputContainer, null,
            React.createElement(InputStatic, null, value)),
        React.createElement(Line, null)));
};
const Layout = styled.div ``;
const Label = styled.p `
  font-family: sans-serif;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.2px;
  margin-bottom: 7px;
`;
const Input = styled.input.attrs(() => ({ type: 'email' })) `
  flex-grow: 1;
  border: none;
  outline: none;
  font-family: sans-serif;
  font-size: 17px;
  line-height: 26px;
  padding: 0;
  color: white;
  background-color: transparent;
  ::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;
const InputStatic = styled.p `
  flex-grow: 1;
  font-family: sans-serif;
  font-size: 17px;
  line-height: 26px;
`;
const InputContainer = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Line = styled.hr `
  border-color: ${(props) => (props.error ? 'rgba(255, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.3)')};
`;
const AddButton = ({ onClick }) => {
    return (React.createElement(Button, { onClick: onClick },
        React.createElement(AddIcon, null)));
};
const Button = styled.button `
  border: none;
  background-color: transparent;
`;
const AddIcon = styled.img.attrs(() => ({ src: addButtonIcon })) `
  width: 20px;
  height: 20px;
`;
