import styled, { css } from 'styled-components';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
import { default as responsiveContainerBase } from 'components/responsive';
export const responsiveContainer = css `
  ${responsiveContainerBase};

  ${media.tablet `
    padding-left: 75px;
    padding-right: 75px;
  `};
  ${media.desktop `
    padding-left: 100px;
    padding-right: 100px;
  `};
  ${media.desktop_medium `
    padding-left: 120px;
    padding-right: 120px;
  `};
`;
export const Title = styled.div `
  font-size: 2.3rem;
  line-height: 0.95;
  text-transform: uppercase;

  ${media.desktop `font-size: 3.65rem;`};
`;
export const SmallTitle = styled.div `
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.025rem;
  font-family: ${FONTS.SimplonMono.name}, ${FONTS.fallback};

  ${media.desktop `font-size: 2.35rem;`};
`;
export const SectionTitle = styled.div `
  font-size: 30px;
  font-family: ${FONTS.SimplonMono.name}, ${FONTS.fallback};

  ${media.desktop `font-size: 2.35rem;`};
`;
export const Text = styled.div `
  font-size: 1rem;
  line-height: 1.3;
`;
export const HideScrollbar = css `
  overscroll-behavior-x: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const FONT_SIZES = css `
  font-size: 18px !important;

  ${media.phone_medium `
    font-size: 22px !important;
  `};
  ${media.tablet `
    font-size: 30px !important;
  `};
  ${media.desktop `
    font-size: 23px !important;
  `};
  ${media.desktop_medium `
    font-size: 28px !important;
  `};
  ${media.desktop_large `
    font-size: 34px !important;
  `};
  ${media.desktop_extra_large `
    font-size: 42px !important;
  `};
`;
export const sectionTopOffset = css `
  margin-top: 5rem;

  ${media.tablet `
    margin-top: 4rem;
  `};
  ${media.desktop `
    margin-top: 8rem;
  `};
  ${media.desktop_large `
    margin-top: 7rem;
  `};
`;
export const PRINCIPLES = [
    {
        title: 'Own the mission, build for the user',
        text: '',
        background: '#F8A746',
    },
    {
        title: 'Think and act like a founder',
        text: '',
        background: '#F07363',
    },
    {
        title: 'Learn, share, develop, repeat',
        text: '',
        background: '#85963D',
    },
    {
        title: 'Be open: speak up and take time to listen',
        text: '',
        background: '#032555',
    },
    {
        title: 'You are not alone',
        text: '',
        background: '#CF5142',
    },
];
