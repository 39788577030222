import url from 'url';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import bg from 'assets/AppLinks/s4g_bg.jpg';
import hero_image from 'assets/AppLinks/s4g_card.png';
import sharingSrc from 'assets/AppLinks/s4g_invite.jpg';
import logo_src from 'assets/Home/header__logo.svg';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { getFirstLetter, getFirstName } from 'utils/user';
import { makeOfferSelector, makeProfileSelector } from 'selectors/invites';
import { useFirebaseEssentials } from '../utils/firebase';
const messages = defineMessages({
    title: ' invites you{br} to join Sweatcoin app',
    subtitle: 'Join {name} and convert your steps into help for this crowdfunding project.',
    learnMore: 'Learn more about campaign',
    buttonTitle: 'Accept Invite',
    appstoreName: 'Apple App Store',
    appstoreRating: '(200k ratings)',
    googleplayName: 'Google play',
    googleplayRating: '(1.8M ratings)',
});
const InvitePage = (props) => {
    const { match, route } = props;
    const { params } = match;
    // Profile & meta
    const profileSelector = useMemo(makeProfileSelector, []);
    const profile = useSelector((state) => profileSelector(state, params));
    const avatar = get(profile, 'avatar.preview');
    const name = getFirstName(profile);
    const username = params.username;
    const firstLetter = getFirstLetter(profile);
    // Offer & meta & extended layout
    const extendedPageParams = pick(params, ['offer_id']);
    const offerSelector = useMemo(makeOfferSelector, []);
    const offer = useSelector((state) => offerSelector(state, params));
    const background = get(offer, 'images[0].cover'); // Crowdfunding sharing image
    const { firebaseData: { firebaseQuery, getRedirectLink }, tracking: { channel, campaign, feature, platform }, buttonsRefs: [buttonRef, bottomButtonRef], config: { origin }, } = useFirebaseEssentials({ owner: profile, route });
    const absoluteSharingUrl = url.resolve(origin, sharingSrc);
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'invite-page' },
                {
                    name: 'event-params',
                    content: pickBy({
                        page_type: 'invite_page',
                        username: username,
                        inviter_id: profile.id,
                        channel,
                        platform,
                        feature,
                        campaign,
                        ...extendedPageParams,
                    }, identity),
                },
                { property: 'og:image', content: absoluteSharingUrl },
            ], link: [
                { rel: 'image_src', href: absoluteSharingUrl },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap',
                },
            ] }),
        React.createElement(StyledNavLink, { to: "/", exact: true },
            React.createElement(Logo, { id: "box-logo", src: logo_src }),
            React.createElement(Tagline, null, "change the world with your steps")),
        React.createElement(Container, null,
            React.createElement(HeaderWrapper, null,
                React.createElement(Header, null,
                    React.createElement(Avatar, { src: avatar }, !avatar && React.createElement("span", null, firstLetter)),
                    React.createElement(FormattedMessage, { ...messages.title }, (title) => (React.createElement(Title, null,
                        React.createElement("span", null, name),
                        title)))),
                React.createElement(CompaignTitle, null, offer.title),
                React.createElement(Subtitle, null,
                    React.createElement(FormattedMessage, { ...messages.subtitle, values: { name } })),
                firebaseQuery ? (React.createElement(Button, { onClick: handleClick, href: firebaseQuery.link, ref: buttonRef },
                    React.createElement(FormattedMessage, { ...messages.buttonTitle }))) : null),
            React.createElement(HeroImage, { src: hero_image }),
            React.createElement(CompaignContent, null,
                React.createElement(CompaignContentTitle, null,
                    React.createElement(FormattedMessage, { ...messages.learnMore })),
                React.createElement(CompaignContentDescription, null, offer.description),
                React.createElement(OnlyMobile, null, firebaseQuery ? (React.createElement(Button, { onClick: handleClick, href: firebaseQuery.link, ref: bottomButtonRef },
                    React.createElement(FormattedMessage, { ...messages.buttonTitle }))) : null),
                background ? React.createElement(CompaignCover, { src: background }) : null))));
    function handleClick(event) {
        event.preventDefault();
        analytics.track(`${feature}_link_click`, 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink();
    }
};
InvitePage.fetchData = async ({ match: { params } }, { dispatch }) => {
    return Promise.all([
        dispatch(ApiActions.searchUserByUsername(params.username)),
        params.offer_id ? dispatch(ApiActions.prefetchOffer(params.offer_id)) : null,
    ]);
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: url(${bg}) no-repeat top left;
  background-attachment: fixed;
  background-size: cover;
  padding-bottom: 100px;
`;
const Container = styled.div `
  position: relative;
  padding: 20px 0;

  ${media.desktop `
    width: 980px;
    margin: 0 auto;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
  text-align: center;
  margin: 20px 0;

  ${media.desktop `
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 32px 108px 0 108px;
  `};
`;
const Logo = styled.img `
  position: relative;
  width: 170px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;

  ${media.desktop `
    width: 197px;
  `};
`;
const Tagline = styled.div `
  display: none;

  ${media.desktop `
    font-weight: 600;
    font-family: 'Inter-SemiBold', 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 1.2;
    text-transform: uppercase;
    margin-left: 20px;
    padding-top: 2px;
  `};
`;
const HeroImage = styled.img `
  position: static;
  width: 100%;

  ${media.desktop `
    display: block;
    position: absolute;
    width: 600px;
    top: 100px;
    left: -130px;
    z-index: -1;
  `};
`;
const Header = styled.div `
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  padding: 0 16px;

  ${media.desktop `
    padding: 0;
    align-items: center;
    margin-top: 120px;
    margin-bottom: 24px;
    
  `}
`;
const HeaderWrapper = styled.div `
  margin: 0;

  ${media.desktop `
    margin-left: 520px;
    margin-bottom: 120px;
  `}

  ${media.desktop_medium `
    width: 580px;
  `}
`;
const Avatar = styled.div `
  width: 80px;
  height: 80px;
  box-shadow: 12px 12px 40px 0px rgba(61, 0, 16, 0.3);
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  flex: none;
  margin-right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.desktop `
    width: 100px;
    height: 100px;
    margin-right: 28px;
  `}

  span {
    font-weight: 100;
    font-size: 70px;
    color: #fff;
  }
`;
const Title = styled.h1 `
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.9);
  font-size: 24px;
  line-height: 1.6;

  ${media.desktop `
    font-weight: 700;
    font-size: 24px;
    line-height: 1.6;
  `};

  span {
    position: relative;
    z-index: 1;
    display: inline-block;
    margin-right: 8px;

    &::before {
      content: '';
      position: absolute;
      width: calc(100% + 12px);
      height: 100%;
      background-color: rgba(0, 20, 48, 0.3);
      border-radius: 4px;
      z-index: -1;
      top: 2px;
      left: -7px;
    }
  }
`;
const CompaignTitle = styled.h2 `
  font-weight: 700;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  color: #ffffff;
  font-size: 36px;
  letter-spacing: -0.2px;
  line-height: 1.3;
  text-align: left;
  margin: 0 16px 24px;

  ${media.desktop `
    font-size: 48px;
    line-height: 1.4;
    letter-spacing: 0;
    margin: 0 0 24px;
  `};
`;
const Subtitle = styled.p `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 24px;
  font-size: 18px;
  line-height: 1.6;
  margin: 0 16px 24px;

  ${media.desktop `
    font-size: 24px;
    margin: 0 0 32px;
  `};
`;
const Button = styled.a `
  display: block;
  text-align: center;
  width: calc(100% - 32px);
  box-sizing: border-box;
  padding: 18px 40px;
  box-shadow: 0px 8px 16px 0px rgba(152, 107, 82, 0.16);
  background: linear-gradient(102deg, rgba(255, 255, 255, 0.95) 0%, rgba(255, 255, 255, 0.7) 100%);
  border-radius: 12px;
  border: none;
  font-weight: 700;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  color: #001430;
  font-size: 19px;
  letter-spacing: -0.6px;
  line-height: 1.2;
  margin: 0 16px 24px;
  cursor: pointer;

  ${media.desktop `
    width: auto;
    display: inline-block;
    margin: 0;
  `};
`;
const CompaignContent = styled.div `
  margin: 20px 16px 0;

  ${media.desktop `
    margin: 0;
  `}
`;
const CompaignContentTitle = styled.h2 `
  font-weight: 700;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  color: #ffffff;
  font-size: 28px;
  letter-spacing: -0.2px;
  line-height: 1.3;
  text-align: left;
  margin-bottom: 24px;

  ${media.desktop `
    font-size: 40px;
    line-height: 1.4;
    letter-spacing: 0;
  `};
`;
const CompaignContentDescription = styled.p `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  line-height: 1.6;
  text-align: left;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;
const CompaignCover = styled.img `
  border-radius: 32px;
  width: 100%;
  height: auto;
  display: block;
  margin: 24px 0;
`;
const OnlyMobile = styled.div `
  ${media.desktop `
    display: none;
  `}

  a {
    margin: 24px 0 0;
    width: 100%;
  }
`;
export default InvitePage;
