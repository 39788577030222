import fetch from 'cross-fetch';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { createMiddleware } from 'redux-api-middleware';
import thunkMiddleware from 'redux-thunk';
import sessionMiddleware from 'middlewares/sessionMiddleware';
import * as reducers from 'reducers';
const reducer = combineReducers(reducers);
const createStoreWithMiddleware = applyMiddleware(thunkMiddleware, createMiddleware({ fetch }), sessionMiddleware)(createStore);
const initStore = (initialState, debug) => {
    return createStoreWithMiddleware(reducer, initialState, debug);
};
export default initStore;
