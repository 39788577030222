import _ from 'lodash';
import queryString from 'query-string';
import React from 'react';
import styled from 'styled-components';
import Instruction from './Instruction';
import SelectDevice from './SelectDevice';
import GooglePixel from './instructions/GooglePixel';
import HTC from './instructions/HTC';
import Huawei from './instructions/Huawei';
import LG from './instructions/LG';
import Motorolla from './instructions/Motorolla';
import Nokia from './instructions/Nokia';
import OPPO from './instructions/OPPO';
import OnePlus from './instructions/OnePlus';
import Other from './instructions/Other';
import Samsung7 from './instructions/Samsung7';
import Samsung9 from './instructions/Samsung9';
import Sony from './instructions/Sony';
import Xiaomi from './instructions/Xiaomi';
const INSTRUCTIONS = {
    xiaomi: { component: Xiaomi },
    huawei: { component: Huawei },
    samsung_9: { component: Samsung9, label: 'Samsung (Android 9)' },
    samsung_7: { component: Samsung7, label: 'Samsung (Android 8 & 7)' },
    htc: { component: HTC, label: 'HTC' },
    lg: { component: LG, label: 'LG' },
    motorolla: { component: Motorolla },
    nokia: { component: Nokia },
    oneplus: { component: OnePlus, label: 'OnePlus' },
    oppo: { component: OPPO, label: 'OPPO' },
    sony: { component: Sony },
    google_pixel: { component: GooglePixel, label: 'Google Pixel' },
    other: { component: Other },
};
class Container extends React.Component {
    state = {
        device: null,
        applicationAgent: null,
    };
    static getDerivedStateFromProps(props) {
        return {
            applicationAgent: props.applicationAgent,
        };
    }
    openSettings = (title) => {
        const settingsUrl = 'android.settings.SETTINGS';
        const Android = window.Android;
        if (typeof Android !== 'undefined') {
            this.trackEvent('opened_settings', {
                instruction: title ? title.replace(/ /g, '_').toLowerCase() : '',
            });
            Android.navigate(settingsUrl);
        }
        else {
            console.log('Android object not found');
        }
    };
    trackEvent = (event, params) => {
        if (typeof window === 'undefined')
            return;
        const Android = window.Android;
        if (typeof Android !== 'undefined') {
            if (typeof params !== 'undefined') {
                Android.logEvent(event, JSON.stringify(params));
            }
            else {
                Android.logEvent(event);
            }
        }
        else {
            console.log('Android object not found', event, params);
        }
    };
    selectDevice = (device) => {
        this.setState({ device });
        !this.props.isInApp && this.updateApplicationAgent(device);
    };
    updateApplicationAgent = (device) => {
        const query = queryString.parse(location.search.slice(1));
        query['application-agent'] = device;
        const newUrl = location.href.replace(location.search, '') + `?${queryString.stringify(query)}`;
        window.history.pushState('android-instructions', 'android-instructions', newUrl);
    };
    componentDidMount() {
        this.trackEvent('instructions_opened', {
            device: this.props.applicationAgent,
            model: this.props.model,
        });
        if (this.getDevice() === 'other') {
            this.trackEvent('not_available_vendor', {
                device: this.props.applicationAgent,
                model: this.props.model,
            });
        }
    }
    getDevice = () => {
        const { device, applicationAgent } = this.state;
        const { osVersion } = this.props;
        const other = 'other';
        let currentDevice = String(device || applicationAgent).toLowerCase();
        if (currentDevice === '')
            return other;
        // device not found
        if (!INSTRUCTIONS[currentDevice]) {
            let withVersion = `${currentDevice}_${osVersion}`;
            // for samsung android 8
            if (osVersion === 8 && currentDevice === 'samsung') {
                withVersion = `${currentDevice}_7`;
            }
            if (osVersion && INSTRUCTIONS[withVersion]) {
                return withVersion;
            }
            const foundDevice = _.find(Object.keys(INSTRUCTIONS), (device) => device.indexOf(currentDevice) !== -1);
            return foundDevice ? foundDevice : other;
        }
        return currentDevice;
    };
    render() {
        const { className, featureAccelerometerTracker } = this.props;
        const device = this.getDevice();
        const instuction = INSTRUCTIONS[device] ? INSTRUCTIONS[device].component : null;
        return (React.createElement("div", { className: className },
            React.createElement(InnerContainer, null,
                React.createElement(SelectDevice, { selectDevice: this.selectDevice, instructions: INSTRUCTIONS, currentDevice: device }),
                React.createElement(Instruction, { trackEvent: this.trackEvent, device: device, instruction: instuction, openSettings: this.openSettings, featureAccelerometerTracker: featureAccelerometerTracker }))));
    }
}
const StyledContainer = styled(Container) `
  padding: 16px 16px 32px;
  user-select: none;
  background: ${(props) => (props.isInApp ? null : 'linear-gradient(to bottom, #032555, #fb7465)')};
  min-height: 100vh;
`;
const InnerContainer = styled.div `
  margin: 0 auto;
  max-width: 640px;
`;
export default StyledContainer;
