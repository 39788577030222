import url from 'url';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import sharingSrc from 'assets/Clubs/invite_share.jpg';
import phonesPromoImage from 'assets/Clubs/phones_promo_image.png';
import phonesPromoImageWebp from 'assets/Clubs/phones_promo_image.webp';
import phonesPromoImagePreview from 'assets/Clubs/phones_promo_image_preview.png';
import phonesPromoMobileImage from 'assets/Clubs/promo_mobile_sfw.png';
import phonesPromoMobileImageWebp from 'assets/Clubs/promo_mobile_sfw.webp';
import phonesPromoMobileImagePreview from 'assets/Clubs/promo_mobile_sfw_preview.png';
import logo_src from 'assets/Home/header__logo.svg';
import googlePlaySrc from 'assets/Home/home_googleplay.png';
import appStoreSrc from 'assets/Leaderboard/appstore_icon.svg';
import OutboundLink from 'components/OutboundLink';
import { media } from 'components/responsive';
import analytics from 'utils/analytics';
import { link } from '../../Home/components/AppLinks';
import DesktopView from '../components/DesktopView';
import { useFirebaseEssentials } from '../utils/firebase';
const messages = defineMessages({
    genericClubName: 'Sweatcoin for Work',
    mobileLeadingTitle: 'Join {clubName}',
    mobileTitle: 'Unlock Fitness Rewards with your Team',
    note: '*By accepting an invite to a Sweatcoin for Work Team, your step count and username will be publicly visible to other users on sweatcoin. View our terms and conditions {tcLink}.',
    title: 'Open the link on your phone and join the {clubName} Team for extra fun & rewards',
    desktopSubtitle: 'You need to open the invitation link on your mobile device. Please copy the link or scan the QR code with your phone.',
    generalSubtitle: 'Download Sweatcoin and join {clubName} Team. Enjoy fun activities with your colleagues and get awesome rewards powered by your company',
    joinBtnTitle: 'Join the Team',
    downloadButtonTitle: 'Download Sweatcoin App',
    desktopButtonTitle: 'Copy the Link',
    copyButtonTitle: 'Link copied',
    appStoreName: 'Apple App Store',
    appStoreRating: '(273k ratings)',
    googlePlayName: 'Google play',
    googlePlayRating: '(1.8M ratings)',
    expiredLinkTitle: 'Oops...{br}This link has expired.',
    expiredLinkSubtitle: 'Please reach out to the Admin of your Sweatcoin for Work Team so they can share a fresh link with you.',
});
const sfwClubSelector = (state) => state.sfwClub?.club;
const InviteClubPage = (props) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const [isPromoImageLoaded, setPromoImageLoaded] = useState(false);
    const [isMobilePromoImageLoaded, setMobilePromoImageLoaded] = useState(false);
    const { match: { params }, location, } = props;
    const [isError, setIsError] = useState(false); // link expired or broken
    const [isFetched, setIsFetched] = useState(false);
    useEffect(() => {
        async function fetch() {
            const club = await dispatch(ApiActions.getSfwClub(params.invite_id));
            if (club.error)
                setIsError(true);
            setIsFetched(true);
        }
        fetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const club = useSelector(sfwClubSelector);
    const clubName = get(club, 'title') || formatMessage(messages.genericClubName);
    const invite_id = params.invite_id;
    const { firebaseData: { firebaseQuery, getRedirectLink }, tracking: { channel, platform, feature, campaign }, buttonsRefs: [buttonRef, bottomButtonRef], config: { origin }, } = useFirebaseEssentials({
        deeplink_path: isFetched ? `/sfw/${invite_id}` : '/sfw',
    });
    const pageLink = isFetched ? `${origin}${location.pathname}${location.search}` : `${origin}/app/sfw`;
    const absoluteSharingUrl = url.resolve(origin, sharingSrc);
    if (!isFetched) {
        // prevent reflow
        return null;
    }
    return (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'leaderboard-invite-page' },
                {
                    name: 'event-params',
                    content: pickBy({
                        page_type: 'invite_page',
                        channel,
                        platform,
                        feature,
                        campaign,
                    }, identity),
                },
                { property: 'og:image', content: absoluteSharingUrl },
            ], link: [
                { rel: 'image_src', href: absoluteSharingUrl },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap',
                },
            ] }),
        React.createElement(Container, null,
            React.createElement(StyledNavLink, { to: "/", exact: true },
                React.createElement(Logo, { id: "box-logo", src: logo_src })),
            React.createElement(Header, null,
                React.createElement(OnlyDesktop, null,
                    React.createElement(Title, null, isError
                        ? formatMessage(messages.expiredLinkTitle, { br: React.createElement("br", null) })
                        : formatMessage(messages.title, { clubName: React.createElement(GradientText, null, clubName) }))),
                !isError && (React.createElement(OnlyMobile, null,
                    React.createElement(LeadingTitleContainer, null,
                        React.createElement(LeadingTitle, null, formatMessage(messages.mobileLeadingTitle, { clubName: React.createElement(GradientText, null, clubName) }))))),
                React.createElement(OnlyMobile, null,
                    React.createElement(Title, null, isError ? (React.createElement(LeadingTitleContainer, null, formatMessage(messages.expiredLinkTitle, { br: React.createElement("br", null) }))) : (formatMessage(messages.mobileTitle))))),
            React.createElement(Subtitle, null,
                React.createElement(OnlyDesktop, null, isError ? formatMessage(messages.expiredLinkSubtitle) : formatMessage(messages.desktopSubtitle)),
                React.createElement(OnlyMobile, null, isError
                    ? formatMessage(messages.expiredLinkSubtitle)
                    : formatMessage(messages.generalSubtitle, { clubName }))),
            !isError && (React.createElement(React.Fragment, null,
                React.createElement(Wrap, null,
                    React.createElement(DesktopView, { qrCodeLink: getCopyLink(), pageLink: pageLink, messages: messages, inviteType: "clubs" }),
                    React.createElement(OnlyMobile, null,
                        React.createElement(ButtonLink, { href: firebaseQuery.link, onClick: handleClick, ref: buttonRef }, formatMessage(messages.joinBtnTitle)))),
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, { ...messages.note, values: {
                            tcLink: (React.createElement("a", { href: "https://sweatco.in/tnc", target: "_blank", rel: "noreferrer" }, "here")),
                        } })),
                React.createElement(Stores, null,
                    React.createElement(StyledOutboundLink, { category: "Store", action: "redirect", label: "AppStore", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('AppStore') },
                        React.createElement(AppStoreIcon, null),
                        React.createElement(StoreDescription, null,
                            React.createElement(StoreName, null,
                                React.createElement(FormattedMessage, { ...messages.appStoreName })),
                            React.createElement(StoreRating, null,
                                React.createElement("strong", null, "4.6"),
                                "\u00A0",
                                React.createElement(FormattedMessage, { ...messages.appStoreRating })))),
                    React.createElement(StyledOutboundLink, { category: "Store", action: "redirect", label: "Google Play", match: { params: null }, params: { from: 'Web', campaign: 'store_button' }, target: "_blank", href: link('Google Play') },
                        React.createElement(GooglePlayIcon, null),
                        React.createElement(StoreDescription, null,
                            React.createElement(StoreName, null,
                                React.createElement(FormattedMessage, { ...messages.googlePlayName })),
                            React.createElement(StoreRating, null,
                                React.createElement("strong", null, "4.4"),
                                "\u00A0",
                                React.createElement(FormattedMessage, { ...messages.googlePlayRating }))))))),
            !isMobilePromoImageLoaded && (React.createElement(PromoImageMobile, { isReady: isMobilePromoImageLoaded, src: phonesPromoMobileImagePreview })),
            React.createElement("picture", null,
                React.createElement("source", { srcSet: phonesPromoMobileImageWebp, type: "image/webp" }),
                React.createElement("source", { srcSet: phonesPromoMobileImage, type: "image/png" }),
                React.createElement(PromoImageMobile, { onLoad: () => setMobilePromoImageLoaded(true), isReady: isMobilePromoImageLoaded, src: phonesPromoMobileImage })),
            React.createElement(OnlyMobile, null, firebaseQuery && !isError && (React.createElement(DownloadButton, { ref: bottomButtonRef, onClick: handleClick, href: firebaseQuery.link },
                React.createElement(FormattedMessage, { ...messages.downloadButtonTitle }))))),
        !isPromoImageLoaded && React.createElement(PromoImage, { isReady: isPromoImageLoaded, src: phonesPromoImagePreview }),
        React.createElement("picture", null,
            React.createElement("source", { srcSet: phonesPromoImageWebp, type: "image/webp" }),
            React.createElement("source", { srcSet: phonesPromoImage, type: "image/png" }),
            React.createElement(PromoImage, { isReady: isPromoImageLoaded, onLoad: () => setPromoImageLoaded(true), src: phonesPromoImage }))));
    function handleClick(event) {
        event.preventDefault();
        analytics.track(`${feature}_link_click`, 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink();
    }
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: linear-gradient(270deg, #a46efb, 35%, #3b1fa5);

  ${media.tablet `
    background: linear-gradient(270deg, #A46EFB, #3B1FA5);
  `};
`;
const Wrap = styled.div `
  display: flex;
  margin-bottom: 12px;

  ${media.tablet `
    margin-top: -28px;
  `}

  a.clubs {
    display: inline-block;
    width: auto;
    text-align: center;
    height: 60px;
    box-sizing: border-box;
    padding: 18px 40px;
    background: #09afcf;
    box-shadow: -2px 4px 40px rgba(45, 0, 146, 0.2);
    border-radius: 100px;
    border: none;
    font-weight: 700;
    font-family: 'Inter-Bold', 'Inter', sans-serif;
    color: #ffffff;
    font-size: 20px;
    letter-spacing: 0.01em;
    line-height: 1.2;
    margin: 0 16px;
    cursor: pointer;
    transition: opacity 0.2s ease;

    @media (hover: hover) {
      &:hover {
        opacity: 0.4;
        transition: opacity 0.2s ease;
      }
    }

    ${media.tablet `
      display: inline-block;
      margin: 0 0 12px 24px;
      font-size: 22px;

      span {
        color: #ffffff;
      }
    `};

    span {
      color: #ffffff;
    }
  }
`;
const Container = styled.div `
  position: relative;
  padding: 20px 0 60px;

  ${media.tablet `
    width: 600px;
    margin: 0 auto;
  `};

  ${media.desktop `
    padding: 32px 0;
    margin-left: 40px;
  `};

  ${media.desktop_medium `
    width: 680px;
  `};

  @media (min-width: 1280px) {
    margin-left: 108px;
    margin-bottom: 70px;
  }

  ${media.desktop_extra_large `
    width: 1000px;
    margin: 0 0 0 187px;
  `};
`;
const StyledNavLink = styled(NavLink) `
  display: block;
  text-align: center;
  height: 36px;
`;
const Logo = styled.img `
  position: relative;
  width: 170px;
  border-top: 0 solid transparent;
  border-bottom: 0 solid transparent;
  border-right: 0 solid transparent;

  ${media.tablet `
    width: 197px;
  `};
`;
const PromoImageMobile = styled.img `
  position: absolute;
  top: 100px;
  display: block;
  width: 100%;
  margin-left: auto;
  z-index: -1;
  filter: ${({ isReady }) => (isReady ? 'blur(0)' : 'blur(40px)')};
  transition: filter 0.5s ease;

  ${media.tablet `
    display: none;
  `}
`;
const PromoImage = styled.img `
  display: none;
  transition: filter 0.5s ease;

  ${media.desktop `
    display: block;
    position: absolute;
    top: 0;
    right: -10%;
    width: auto;
    height: 100%;
    z-index: -1;
    filter: ${({ isReady }) => (isReady ? 'blur(0)' : 'blur(40px)')};
  `};

  @media all and (max-width: 1270px) {
    right: -20%;
  }

  @media all and (min-width: 1400px) {
    right: 0;
  }

  ${media.desktop_medium `
    right: 0;
  `};
`;
const Header = styled.div `
  display: flex;
  margin-top: 32px;
  margin-bottom: 18px;
  padding: 0 16px;

  ${media.phone_medium `
    flex-direction: column;
    align-items: center;
  `}

  ${media.tablet `
    padding: 0;
    margin-top: 80px;
    margin-bottom: 24px;
    flex-direction: column;
    align-items: flex-start;
  `}

  ${media.desktop `
    align-items: flex-start;
  `}
`;
const LeadingTitle = styled.h2 `
  font-size: 28px;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: -0.8%;
  color: #ffffff;
  text-align: center;
`;
const LeadingTitleContainer = styled.div `
  margin-top: 50vw;
`;
const GradientText = styled.b `
  background: linear-gradient(270deg, #c7d1fe, #3f95fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;
const Title = styled.h1 `
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  font-weight: 700;
  font-size: 37px;
  line-height: 55px;
  letter-spacing: -0.002em;
  color: #ffffff;

  ${media.phone_medium `
    line-height: 1.6;
    margin-top: 20px;
  `};

  ${media.tablet `
    font-weight: 700;
    font-size: 48px;
    line-height: 1.4;
    margin-top: 0;
  `};
`;
const Subtitle = styled.div `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 32px;
  font-size: 20px;
  line-height: 1.6;
  letter-spacing: -0.002em;
  padding: 0 16px;
  color: #fff;

  ${media.phone_medium `
    text-align: center;
  `};

  ${media.tablet `
    width: auto;
    max-width: 600px;
    margin: 0;
    padding: 0;
    font-size: 20px;
    line-height: 32px;
    text-align: left;
  `};
`;
const ButtonLink = styled.a `
  display: block;
  text-align: center;
  width: calc(100% - 32px);
  height: 60px;
  box-sizing: border-box;
  padding: 18px 40px;
  background: #09afcf;
  box-shadow: -2px 4px 40px rgba(45, 0, 146, 0.2);
  border-radius: 100px;
  border: none;
  font-weight: 700;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.01em;
  line-height: 1.2;
  margin: 0 16px;
  cursor: pointer;
  transition: opacity 0.2s ease;

  @media (hover: hover) {
    &:hover {
      opacity: 0.4;
      transition: opacity 0.2s ease;
    }
  }

  ${media.tablet `
  width: 100%;
  max-width: 168px;
  display: inline-block;
  margin: 0 0 12px;
  font-size: 22px;

  span {
    color: #ffffff;
  }
`};

  span {
    color: #ffffff;
  }
`;
const DownloadButton = styled.button `
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: calc(100% - 32px);
  max-width: 382px;
  height: 58px;
  margin: 0 auto;
  padding: 12px 30px;
  font-weight: 700;
  font-family: 'Inter-Bold', 'Inter', sans-serif;
  font-size: 18px;
  line-height: 1.2;
  letter-spacing: -0.006em;
  color: #001430;
  background: linear-gradient(91.32deg, rgba(255, 255, 255, 0.95) 0.01%, rgba(255, 255, 255, 0.7) 100.01%);
  border-radius: 16px;
  cursor: pointer;
  transition: opacity 0.2s ease;
  border: none;

  ${media.phone_medium `
    font-size: 20px;
  `};
`;
const Text = styled.p `
  width: calc(100% - 32px);
  margin: 0 auto;
  margin-bottom: 12px;
  padding-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.8);

  ${media.phone_medium `
    text-align: center;
  `}

  ${media.tablet `
    width: auto;
    max-width: 600px;
    margin: 0;
    margin-bottom: 40px;
    text-align: left;
  `};

  @media (min-width: 1520px) {
    margin-bottom: 100px;
  }

  a {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.8);
      transition: background-color 0.2s ease;
    }

    @media (hover: hover) {
      &:hover::before {
        background-color: transparent;
        transition: background-color 0.2s ease;
      }
    }
  }
`;
const Stores = styled.div `
  margin: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.phone_medium `
    flex-direction: row;
    justify-content: center;
  `}

  ${media.tablet `
    width: 600px;
    justify-content: flex-start;
    margin: 0;
  `};
`;
const StyledOutboundLink = styled(OutboundLink) `
  display: flex;
  align-items: center;
  padding: 16px 22px;
  background: linear-gradient(83.85deg, rgba(255, 255, 255, 0.01) -0.02%, rgba(255, 255, 255, 0.1) 99.96%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  transition: border-color 0.2s ease;

  ${media.phone_medium `
    padding: 16px;
  `}

  @media (min-width: 414px) {
    padding: 16px 22px;
  }

  ${media.tablet `
    align-items: initial;
    padding: 24px 32px;
    padding-left: 47px;
    background: linear-gradient(94.63deg, rgba(255, 255, 255, 0.01) 0%, rgba(255, 255, 255, 0.1) 100%);
  `}

  @media (hover: hover) {
    &:hover {
      border-color: rgba(255, 255, 255, 0.8);
      transition: border-color 0.2s ease;
    }
  }

  &:first-child {
    margin-bottom: 10px;

    ${media.phone_medium `
      margin-right: 10px;
      margin-bottom: 0;
    `}
  }
`;
const StoreIcon = styled.div `
  width: 23px;
  height: 23px;
  margin-right: 12px;

  ${media.tablet `
    width: 58px;
    height: 58px;
    margin-right: 24px;
  `}
`;
const AppStoreIcon = styled(StoreIcon) `
  background: url(${appStoreSrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const GooglePlayIcon = styled(StoreIcon) `
  background: url(${googlePlaySrc}) 50% 50% no-repeat;
  background-size: contain;
`;
const StoreDescription = styled.div `
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;
const StoreName = styled.div `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 10px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  ${media.tablet `
    font-size: 12px;
    line-height: 1.2;
  `}
`;
const StoreRating = styled.div `
  line-height: 1;

  ${media.tablet `
    display: flex;
    align-items: flex-end;
    margin-top: 2px;
  `}

  strong {
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    color: #ffffff;
    font-size: 16px;
    letter-spacing: -0.002em;
    line-height: 1;
    text-transform: capitalize;

    ${media.phone_medium `
    `}

    ${media.tablet `
      font-size: 24px;
      line-height: 1.2;
    `}
  }

  span {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.6);
    font-size: 10px;
    letter-spacing: 0.05em;
    line-height: 1.2;
    font-weight: 400;
    position: relative;
    top: -1px;

    ${media.tablet `
      top: -8px;
      font-size: 10px;
      text-transform: uppercase;
    `}
  }
`;
const OnlyMobile = styled.div `
  width: 100%;

  ${Title} {
    text-align: center;
  }

  ${media.tablet `
    display: none;
  `}
`;
const OnlyDesktop = styled.div `
  display: none;

  ${media.tablet `
    display: block;
  `}
`;
export default InviteClubPage;
