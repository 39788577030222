import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { media } from 'components/responsive';
import AnswerItem from './AnswerItem';
import ErrorMessage from './ErrorMessage';
const AnswersList = (props) => {
    const { questions, answerNumber, showError, activeId, points, questionId } = props;
    const errorText = questions[answerNumber - 1].errorText;
    return (React.createElement(Container, null,
        React.createElement(React.Fragment, null,
            (() => {
                switch (props.type) {
                    case 'select':
                        return (React.createElement(AnswerItem, { answer: props.answers, handleAnswer: props.handleAnswer, type: props.type, questionId: questionId, points: points }));
                    case 'input':
                        return (React.createElement(AnswerItem, { answer: props.answers, handleAnswer: props.handleAnswer, type: props.type, questionId: questionId, points: points, randomNum: props.randomNum }));
                    case 'multiple':
                        return props.answers.map((answer, index) => {
                            return (React.createElement(AnswerItem, { key: index, index: index, answer: answer, handleAnswer: props.handleAnswer, type: props.type, questionId: questionId, setActiveId: props.setActiveId, isActive: index === activeId || answer.label === points[questionId].label, points: points }));
                        });
                    case 'multiple-images':
                        return (React.createElement(Wrapper, null, props.answers.map((answer, index) => {
                            return (React.createElement(AnswerItem, { key: index, index: index, answer: answer, handleAnswer: props.handleAnswer, type: props.type, questionId: questionId, setActiveId: props.setActiveId, isActive: index === activeId || answer.label === points[questionId].label, points: points }));
                        })));
                    default:
                        return null;
                }
            })(),
            questionId === 'name' ? (React.createElement(Notation, null, "*Don't worry, this is just for the quiz. This data is not being shared, and your information is protected.")) : null,
            React.createElement(CSSTransition, { in: showError, timeout: 300, classNames: "error", unmountOnExit: true },
                React.createElement(ErrorMessage, { text: errorText })))));
};
const Container = styled.div `
  margin-bottom: 0;

  ${media.desktop `
    margin-bottom: 0;
  `};

  input {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
    outline: none !important;
  }

  // error animation
  .error-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .error-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .error-exit {
    opacity: 1;
  }
  .error-exit-active {
    opacity: 0;
    transform: scale(0.9);
  }

  .react-select__control {
      &::after {
        mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
  }

  .react-select__menu {
    @media only screen and (min-width: 1074px) {
      &::after {
        mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
      }
    }
  }
`;
const Wrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 358px;
  margin: 0 auto;
  margin-bottom: 84px;

  @media (max-width: 389px) {
    justify-content: space-around;
  }

  @media (min-width: 600px) {
    max-width: none;
  }

  ${media.desktop `
    margin-bottom: 0;
    padding: 0 26px;
  `};
`;
const Notation = styled.p `
  margin-top: 16px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 120%;
  opacity: 0.8;
  color: #ffffff;
`;
export default AnswersList;
