import { ROUTER_NAVIGATE, STORE_BUTTON_SHOW } from 'constants/ActionTypes';
const storeButtonReducer = (state = false, action) => {
    switch (action.type) {
        case ROUTER_NAVIGATE: {
            return false;
        }
        case STORE_BUTTON_SHOW: {
            return true;
        }
        default:
            return state;
    }
};
export default storeButtonReducer;
