import fetch from 'cross-fetch';
import React from 'react';
export function useUser(id) {
    const [user, setUser] = React.useState();
    React.useEffect(() => {
        async function run() {
            try {
                const response = await fetch(`/api/v2/users/${id}.json`);
                const json = await response.json();
                setUser(json.data);
            }
            catch (error) {
                console.error('Failed to load user');
            }
        }
        run();
    }, []);
    return user;
}
