const STATE_KEY_PREFIX = '@@scroll|';
export default class {
    read(location) {
        const stateKey = this.getStateKey(location);
        const value = sessionStorage.getItem(stateKey);
        return JSON.parse(value);
    }
    save(location, key, value) {
        const stateKey = this.getStateKey(location);
        const storedValue = JSON.stringify(value);
        sessionStorage.setItem(stateKey, storedValue);
    }
    getStateKey(location) {
        const locationKey = location.key || 'init';
        return `${STATE_KEY_PREFIX}${locationKey}`;
    }
}
