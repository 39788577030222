import React from 'react';
import styled from 'styled-components';
import arrowLeftSrc from 'assets/SweatPaws/arrow-left.svg';
import arrowRightSrc from 'assets/SweatPaws/arrow-right.svg';
import blurredFragmentSrc from 'assets/SweatPaws/blurred-fragment.png';
import { media } from 'components/responsive';
const Navigation = (props) => {
    return (React.createElement(Container, null,
        React.createElement(Panel, null,
            React.createElement(BackButton, { onClick: props.handleBackBtnClick, disabled: props.isDisabled },
                React.createElement("span", null, "Back")),
            React.createElement(NextButton, { onClick: (e) => {
                    props.handleNextBtnClick(e);
                }, className: !props.isHavingTitle ? 'disabled' : '' },
                React.createElement("span", null, "Next")))));
};
const Container = styled.div `
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 11;
  order: 1;
  width: 100%;
  padding: 20px 16px;
  padding-bottom: 40px;
  background: linear-gradient(
    235.91deg,
    rgba(47, 18, 118, 0.8) 23.55%,
    rgba(11, 10, 40, 0.8) 46.91%,
    rgba(0, 8, 20, 0.8) 70.18%
  );
  box-shadow: 0px -5px 10px rgba(7, 0, 32, 0.1), 0px -10px 40px rgba(7, 0, 32, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 30px 30px 0px 0px;

  ${media.desktop `
    padding: 24px 16px;
    border-radius: 0;
  `}
`;
const Panel = styled.div `
  display: flex;
  width: 100%;
  background: url('${blurredFragmentSrc}');
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50px;

  ${media.desktop `
    width: 700px;
    margin: 0 auto;
  `}
`;
const BackButton = styled.button `
  position: relative;
  display: flex;
  align-items: center;
  width: 120px;
  height: 48px;
  margin-right: 12px;
  padding: 0 20px;
  padding-left: 50px;
  background: none;
  border-radius: 50px;
  border: none;

  ${media.desktop `
    margin-right: 24px;
  `};

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    background: linear-gradient(112.4deg, rgba(254, 83, 187, 0.6) 29.63%, rgba(83, 78, 255, 0.6) 76.7%) border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 50px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(104.74deg, rgba(255, 105, 227, 0.2) 25.73%, rgba(99, 95, 255, 0.2) 66.52%);
    filter: blur(40px);
  }

  span {
    position: relative;
    font-weight: 800;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.05em;
    opacity: 0.8;
    background-image: linear-gradient(98.62deg, #ffabde 22.15%, #938fff 76.46%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -30px;
      width: 24px;
      height: 24px;
      background-image: url('${arrowLeftSrc}');
      transform: translateY(-50%);
    }
  }

  &:disabled {
    opacity: 0.4;
  }

  &:hover:not(:disabled)::after,
  &:active:not(:disabled)::after {
    background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.5) 25.73%, rgba(48, 255, 226, 0.5) 66.52%);
  }

  &:active:not(:disabled)::before {
    background: linear-gradient(112.4deg, rgba(254, 83, 187, 0.6) 29.63%, rgba(83, 78, 255, 0.6) 76.7%) border-box;
  }
`;
const NextButton = styled.button `
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 48px;
  padding: 0 20px;
  padding-right: 50px;
  background: none;
  border-radius: 50px;
  border: none;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border: 2px solid transparent;
    background: linear-gradient(112.4deg, #fe53bb 29.63%, #09fbd3 76.7%) border-box;
    -webkit-mask: /*4*/ linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
    border-radius: 50px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.3) 25.73%, rgba(48, 255, 226, 0.3) 66.52%);
    filter: blur(30px);
  }

  span {
    position: relative;
    font-weight: 800;
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 0.05em;
    opacity: 0.8;
    background-image: linear-gradient(112.4deg, #ffa6dc 29.63%, #6affe6 76.7%);
    background-size: 100%;
    background-repeat: repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: -30px;
      width: 24px;
      height: 24px;
      background-image: url('${arrowRightSrc}');
      transform: translateY(-50%);
    }
  }

  &.disabled {
    opacity: 0.4;
  }

  &:hover:not(.disabled)::after,
  &:active:not(.disabled)::after {
    background: linear-gradient(104.74deg, rgba(255, 68, 220, 0.5) 25.73%, rgba(48, 255, 226, 0.5) 66.52%);
  }

  &:active:not(.disabled)::before {
    background: linear-gradient(112.4deg, rgba(254, 83, 187, 0.6) 29.63%, rgba(83, 78, 255, 0.6) 76.7%) border-box;
  }
`;
export default Navigation;
