import reduce from 'lodash/reduce';
import React from 'react';
import Linkify from 'react-linkify';
class SimpleText extends React.Component {
    render() {
        return React.createElement(Linkify, { properties: { target: '_blank' } }, this._breakLine(this.props.content));
    }
    _breakLine(text = '') {
        let regex = /(?:\r)?\n/g;
        let breakedLine = reduce(text.split(regex), (result, line) => {
            return result.concat(line, React.createElement("br", null));
        }, []);
        breakedLine.pop();
        return breakedLine.map((value, index) => React.createElement(React.Fragment, { key: `break-line-${index}` }, value));
    }
}
export default SimpleText;
