import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AdviceNotSuitable, Common, Divider, Footer, List, ListItem, SamsungDivider, SettingsButton, Text, Title, } from './common';
const messages = defineMessages({
    removeTitle: 'Remove restrictions',
    apps: 'Apps',
    settings: 'Settings',
    battery: 'Battery',
    usage: 'Battery Usage',
    powerSaving: 'Power saving exclusions / Ignore optimizations',
    sweatcoin: 'Sweatcoin',
    optimization: 'Disable Battery Optimization',
    alternative: 'Alternatively try this:',
    batteryAndPower: 'Battery & power saving',
    ignore: 'Ignore optimizations',
    turnOn: 'Turn ON for Sweatcoin',
    gps: 'Fix GPS Accuracy',
    location: 'Location option',
    access: 'Turn on the Location access',
    tap: 'Tap on the Mode',
    accuracy: 'Set to High accuracy',
});
const LG = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Common, { featureAccelerometerTracker: props.featureAccelerometerTracker, trackEvent: props.trackEvent, openSettings: props.openSettings, isFooterDividerDefault: true }),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.removeTitle })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.battery })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.usage })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.powerSaving })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.optimization }))),
        React.createElement(Text, null,
            React.createElement(FormattedMessage, { ...messages.alternative })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batteryAndPower })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.usage })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.ignore })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.turnOn }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        !props.featureAccelerometerTracker
            ? [
                React.createElement(Divider, null),
                React.createElement(Title, { trackEvent: props.trackEvent },
                    React.createElement(FormattedMessage, { ...messages.gps })),
                React.createElement(List, null,
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.settings })),
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.location })),
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.access })),
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.tap })),
                    React.createElement(ListItem, null,
                        React.createElement(FormattedMessage, { ...messages.accuracy }))),
                React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
            ]
            : null,
        React.createElement(SamsungDivider, null),
        React.createElement(Footer, { trackEvent: props.trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: props.trackEvent })));
};
export default LG;
