import React, { useEffect } from 'react';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
import AppLinks from './AppLinks';
import Shares from './Shares';
const Result = ({ winner, onRetry, meta, isUserFromApp }) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (React.createElement(Container, null,
        React.createElement(Header, null,
            React.createElement(DesktopShares, { text: winner.shareTitle, url: winner.shareUrl, hashtags: winner.hashtags ? winner.hashtags : null, quizId: meta.quizId }),
            React.createElement(MobileHeader, null,
                React.createElement(MobileLabel, null, "You are"),
                React.createElement(Title, null, winner.title)),
            React.createElement(Cover, { url: winner.img })),
        React.createElement(ResultInfo, null,
            React.createElement(DesktopHeader, null,
                React.createElement(Label, null, meta.title),
                React.createElement(Title, null, winner.title)),
            React.createElement(MobileShares, { text: winner.shareTitle, url: winner.shareUrl, hashtags: winner.hashtags ? winner.hashtags : null, quizId: meta.quizId }),
            React.createElement(Description, null, winner.description),
            winner.ps ? React.createElement(PS, null, winner.ps) : null,
            React.createElement(ShareReminder, null, isUserFromApp ? meta.shareReminder : null),
            React.createElement(CallToAction, null, winner.callToAction),
            React.createElement(StyledAppLinks, null),
            React.createElement(RestartButton, { onClick: onRetry }, meta.restartButtonTitle))));
};
const Container = styled.div ``;
const Header = styled.div `
  position: relative;
`;
const ResultInfo = styled.div `
  ${media.tablet `
    width: 500px;
    margin-top: 45px;
  `};
`;
const MobileHeader = styled.div `
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 2;

  ${media.tablet `
    display: none;
  `};
`;
const DesktopHeader = styled.div `
  display: none;

  ${media.tablet `
    display: block;
  `};
`;
const Cover = styled.div `
  background: url(${(props) => props.url}) 50% 50% no-repeat;
  background-size: cover;
  width: calc(100% + 32px);
  height: 250px;
  margin-top: -54px;
  margin-left: -16px;
  margin-bottom: 16px;
  z-index: -2;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -16px;
    right: -16px;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;

    ${media.tablet `
      display: none;
    `};
  }

  ${media.tablet `
    width: 767px;
    height: 381px;
    margin-top: 33px;
    margin-left: -50px
    z-index: 1;
  `};
`;
const Label = styled.div `
  font-family: ${FONTS.SimplonMono.name}, ${FONTS.fallback};
  font-size: 18px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 3px;
  margin-top: 36px;
  margin-bottom: 13px;
  text-transform: uppercase;

  ${media.tablet `
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
    margin-top: 56px;
  `};
`;
const MobileLabel = styled(Label) `
  margin: 0;
`;
const Title = styled.div `
  font-family: ${FONTS.SimplonMono.name}, ${FONTS.fallback};
  font-size: 40px;
  line-height: 52px;
  color: #fff;
  margin-bottom: 12px;

  ${media.tablet `
    font-size: 44px;
    line-height: 64px;
    margin-top: 8px;
    margin-bottom: 16px;
  `};
`;
const Description = styled.div `
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 23px;

  ${media.tablet `
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 40px;
  `};
`;
const PS = styled(Description) `
  margin-top: -23px;

  ${media.tablet `
    margin-top: -40px;
  `};
`;
const ShareReminder = styled(Description) `
  color: #fbd769;
`;
const CallToAction = styled.div `
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-size: 16px;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 24px;

  ${media.tablet `
    font-size: 20px;
    width: 320px;
  `};
`;
const StyledAppLinks = styled(AppLinks) `
  margin-bottom: 30px;
`;
const RestartButton = styled.button `
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  font-family: ${FONTS.SimplonNormBold.name}, ${FONTS.fallback};
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  padding: 16px 0;
  margin-bottom: 50px;
  letter-spacing: 2px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  margin-top: 50px;

  ${media.tablet `
    width: 300px;
  `};
`;
const DesktopShares = styled(Shares) `
  display: none;

  ${media.tablet `
    display: block;
    position: absolute;
    bottom: 50px;
    left: 0;
  `};
`;
const MobileShares = styled(Shares) `
  display: block;

  ${media.tablet `
    display: none;
  `};
`;
export default Result;
