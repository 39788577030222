import React from 'react';
import styled from 'styled-components';
import FONTS from 'assets/fonts';
import { media } from 'components/responsive';
const Credits = ({ credits, className }) => {
    return (React.createElement(Container, { className: className },
        credits.caption,
        React.createElement("a", { href: credits.link, target: "_blank", rel: "noreferrer noopener" }, credits.author)));
};
const Container = styled.p `
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  font-style: italic;
  font-size: 12px;
  line-height: 133%;
  color: #fff;
  opacity: 0.3;
  margin-top: 10px;

  ${media.tablet `
    position: absolute;
    right: 0;
  `};

  a {
    color: inherit;
    text-decoration: underline;
  }
`;
export default Credits;
