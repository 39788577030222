import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logo from 'assets/Health/logo.svg';
import { media } from 'components/responsive';
const Header = () => {
    return (React.createElement(Container, null,
        React.createElement(LogoNavLink, { to: "/" },
            React.createElement(Logo, { src: logo })),
        React.createElement(Menu, null,
            React.createElement(MenuItem, null,
                React.createElement(NavLink, { to: "/#vision" }, "Vision")),
            React.createElement(MenuItem, null,
                React.createElement(NavLink, { to: "/#facts" }, "Facts")),
            React.createElement(MenuItem, null,
                React.createElement(NavLink, { to: "/#how-it-works" }, "How it works")),
            React.createElement(MenuItem, null,
                React.createElement(NavLink, { to: "/#partners" }, "Partners")))));
};
const Container = styled.div `
  display: flex;
  padding: 20px 0 50px;

  ${media.desktop `
    padding: 50px 0;
  `};
`;
const LogoNavLink = styled(NavLink) `
  display: flex;
  align-items: center;
  flex: 1;

  ${media.desktop `
    flex: 0;
  `};
`;
const Logo = styled.img `
  display: block;
  align-items: center;
  width: 214px;
  height: auto;
  margin: 0 auto;

  ${media.desktop `
    margin: 0;
  `};
`;
const Menu = styled.div `
  display: none;
  flex: 1;
  justify-content: flex-end;
  gap: 150px;

  ${media.desktop `
    display: flex;
  `};
`;
const MenuItem = styled.div `
  a {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 14px;
    color: #191841;
  }
`;
export default Header;
