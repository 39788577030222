import ScrollBehavior from 'scroll-behavior';
import ScrollStorage from './ScrollStorage';
export default class {
    constructor(history) {
        this._context = null;
        this._storage = new ScrollStorage();
        this._history = history;
        this._behavior = new ScrollBehavior({
            addNavigationListener: (cb) => this._history.block((...args) => {
                args[0] = this._history.location;
                window.scrollTo(0, 0);
                cb(...args);
            }),
            stateStorage: this._storage,
            getCurrentLocation: this._currentLocation.bind(this),
            shouldUpdateScroll: this._shouldUpdateScroll.bind(this),
        });
    }
    updateScroll = (nextContext) => {
        const prevContext = this._context;
        this._context = nextContext;
        this._behavior.updateScroll(prevContext, this._context);
    };
    _currentLocation = () => {
        return this._history.location;
    };
    _shouldUpdateScroll = (prevContext, { location }) => {
        return prevContext && location.pathname !== prevContext.location.pathname;
    };
}
