import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AdviceNotSuitable, Common, Footer, List, ListItem, SamsungDivider, SettingsButton, Title } from './common';
const messages = defineMessages({
    removeTitle: 'Remove restrictions',
    settings: 'Settings',
    battery: 'Battery',
    batteryOptimization: 'Battery Optimization',
    allApps: 'All apps',
    sweatcoin: 'Sweatcoin',
    optimize: 'Enable “Don’t optimize”',
});
const OnePlus = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Common, { featureAccelerometerTracker: props.featureAccelerometerTracker, trackEvent: props.trackEvent, openSettings: props.openSettings, isFooterDividerDefault: true }),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.removeTitle })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.battery })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.batteryOptimization })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.allApps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.sweatcoin })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.optimize }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(SamsungDivider, null),
        React.createElement(Footer, { trackEvent: props.trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: props.trackEvent })));
};
export default OnePlus;
