import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ANDROID_ADJUST_LINK, IOS_ADJUST_LINK } from 'constants/analytics';
const ADGROUP_PARAM = 'utm_source';
const CREATIVE_PARAM = 'utm_medium';
const CAMPAIGN_PARAM = 'utm_campaign';
const isBrowser = () => typeof window !== 'undefined' && typeof localStorage !== 'undefined';
const addQueryToAdjustLink = (queryStr) => ({
    IOS: IOS_ADJUST_LINK + '?' + queryStr,
    ANDROID: ANDROID_ADJUST_LINK + '?' + queryStr,
});
const useAdjustLink = () => {
    const location = useLocation();
    const [result, setResult] = useState({ IOS: IOS_ADJUST_LINK, ANDROID: ANDROID_ADJUST_LINK });
    useEffect(() => {
        if (!isBrowser())
            return;
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.size) {
            const adjustParams = {
                adgroup: queryParams.get(ADGROUP_PARAM),
                creative: queryParams.get(CREATIVE_PARAM),
                campaign: queryParams.get(CAMPAIGN_PARAM),
            };
            const nonEmptyAdjustParams = Object.entries(adjustParams).reduce((acc, [key, value]) => {
                if (value) {
                    acc[key] = value;
                }
                return acc;
            }, {});
            setResult(addQueryToAdjustLink(new URLSearchParams(nonEmptyAdjustParams).toString()));
        }
    }, [location]);
    return result;
};
export default useAdjustLink;
