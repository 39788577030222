// question covers
import img1d from '../assets/shoes/1_desktop.jpg';
import img1m from '../assets/shoes/1_mobile.jpg';
import img2d from '../assets/shoes/2_desktop.jpg';
import img2m from '../assets/shoes/2_mobile.jpg';
import img3d from '../assets/shoes/3_desktop.jpg';
import img3m from '../assets/shoes/3_mobile.jpg';
import img4d from '../assets/shoes/4_desktop.jpg';
import img4m from '../assets/shoes/4_mobile.jpg';
import img5d from '../assets/shoes/5_desktop.jpg';
import img5m from '../assets/shoes/5_mobile.jpg';
import img6d from '../assets/shoes/6_desktop.gif';
import img6m from '../assets/shoes/6_mobile.gif';
import img7d from '../assets/shoes/7_desktop.gif';
import img7m from '../assets/shoes/7_mobile.gif';
import startCoverDesktop from '../assets/shoes/landing_desktop.jpg';
import startCoverMobile from '../assets/shoes/landing_mobile.jpg';
import cowboyBoots from '../assets/shoes/results/cowboy_boots_desktop.jpg';
import crocs from '../assets/shoes/results/crocs_desktop.jpg';
import highHeels from '../assets/shoes/results/high_heels_desktop.jpg';
import rollerSkates from '../assets/shoes/results/roller_skates_desktop.jpg';
import slippers from '../assets/shoes/results/slippers_desktop.jpg';
import sneakers from '../assets/shoes/results/sneakers_desktop.jpg';
export default {
    meta: {
        quizId: 'shoes',
        label: 'QUIZ',
        title: 'Which shoes are you? 👟',
        description: 'Answer a few questions to find out!',
        subtitle: 'You can tell a lot about a person by their shoes.\n Take the quiz to find out who you are in the world of shoes!',
        startButtonTitle: 'Start',
        restartButtonTitle: 'Start again',
        startCoverType: 'img',
        startCoverSrcDesktop: startCoverDesktop,
        startCoverSrcMobile: startCoverMobile,
        shareReminder: "Don't forget to share the quiz to stand a chance of winning the prize 🏆",
        credits: {
            caption: 'Illustrated by ',
            author: 'Nailya Mustafi',
            link: 'https://www.instagram.com/nel.ms/',
        },
    },
    questions: [
        {
            title: 'How does your perfect day start?',
            cover: {
                mobile: img1m,
                desktop: img1d,
            },
            answers: [
                { title: 'I go for a run', img: null, id: 'Sneakers' },
                { title: 'I put on some makeup first', img: null, id: 'High_heels' },
                { title: 'I make a cup of coffee', img: null, id: 'Slippers' },
                { title: 'I drink a glass of fresh milk', img: null, id: 'Cowboy_boots' },
                { title: 'I need a complete and balanced breakfast', img: null, id: 'Roller_skates' },
                { title: 'I watch the sunrise on the beach', img: null, id: 'Crocs' },
            ],
        },
        {
            title: 'If you opened a shoe store, what would it be called?',
            cover: {
                mobile: img2m,
                desktop: img2d,
            },
            answers: [
                { title: 'Fill your shoes', img: null, id: 'Sneakers' },
                { title: 'Shoe fly', img: null, id: 'High_heels' },
                { title: 'Out of the closet', img: null, id: 'Slippers' },
                { title: 'Horse runner', img: null, id: 'Cowboy_boots' },
                { title: 'Lead foot', img: null, id: 'Roller_skates' },
                { title: 'Your comfort zone', img: null, id: 'Crocs' },
            ],
        },
        {
            title: "You've lost your pair. Where will you look for?",
            cover: {
                mobile: img3m,
                desktop: img3d,
            },
            answers: [
                { title: 'In the locker', img: null, id: 'Sneakers' },
                { title: "At last night's party", img: null, id: 'High_heels' },
                { title: 'Under the bed', img: null, id: 'Slippers' },
                { title: 'In the garage', img: null, id: 'Cowboy_boots' },
                { title: 'In the attic', img: null, id: 'Roller_skates' },
                { title: 'By the pool', img: null, id: 'Crocs' },
            ],
        },
        {
            title: 'What kind of work would you prefer to do?',
            cover: {
                mobile: img4m,
                desktop: img4d,
            },
            answers: [
                { title: 'IT, programming and stuff', img: null, id: 'Sneakers' },
                { title: 'Business and management', img: null, id: 'High_heels' },
                { title: 'Freelance only', img: null, id: 'Slippers' },
                { title: 'I’d like to run my own farm', img: null, id: 'Cowboy_boots' },
                { title: 'No work, please', img: null, id: 'Roller_skates' },
                { title: "I've always wanted to be a doctor", img: null, id: 'Crocs' },
            ],
        },
        {
            title: 'What socks are you wearing today?',
            cover: {
                mobile: img5m,
                desktop: img5d,
            },
            answers: [
                { title: 'Monochrome ankle socks', img: null, id: 'Sneakers' },
                { title: 'Nylon socks', img: null, id: 'High_heels' },
                { title: 'Warm cozy socks', img: null, id: 'Slippers' },
                { title: 'Who cares? No one sees them', img: null, id: 'Cowboy_boots' },
                { title: 'Super high socks', img: null, id: 'Roller_skates' },
                { title: 'Socks off!', img: null, id: 'Crocs' },
            ],
        },
        {
            title: 'Where would you go on a trip?',
            cover: {
                mobile: img6m,
                desktop: img6d,
            },
            answers: [
                { title: 'To the top of Kilimanjaro', img: null, id: 'Sneakers' },
                { title: 'To Milan for Haute couture Week', img: null, id: 'High_heels' },
                { title: 'A trip? I’m on the lockdown!', img: null, id: 'Slippers' },
                { title: "I'm gonna take my horse to the old town road", img: null, id: 'Cowboy_boots' },
                { title: 'I’ll take part in the Tour de France', img: null, id: 'Roller_skates' },
                { title: 'Definitely to the sea', img: null, id: 'Crocs' },
            ],
        },
        {
            title: 'We’ve bought popcorn. What are we going to watch?',
            cover: {
                mobile: img7m,
                desktop: img7d,
            },
            answers: [
                { title: 'Silicon Valley', img: null, id: 'Sneakers' },
                { title: 'Pretty Woman', img: null, id: 'High_heels' },
                { title: 'Friends', img: null, id: 'Slippers' },
                { title: 'The Good, the Bad, & the Ugly', img: null, id: 'Cowboy_boots' },
                { title: 'Good Will Hunting', img: null, id: 'Roller_skates' },
                { title: 'Toy Story', img: null, id: 'Crocs' },
            ],
        },
    ],
    results: {
        Sneakers: {
            img: sneakers,
            title: 'Sneakers',
            description: 'Oh my! You are sneakers! Casual and practical. The goal of 10k steps is a daily routine for you. Of course, everyone knows you’re cool. We know it too.',
            callToAction: '👞 Grab your shoes and walk with Sweatcoin',
            ps: 'P.S. at least not barefoot',
            shareTitle: 'I am sneakers! Which shoes are you? @Sweatcoin',
            shareUrl: 'https://sweatco.in/quiz/shoes/sneakers',
            hashtags: 'sweatcoin,quiz,shoesquiz',
        },
        High_heels: {
            img: highHeels,
            title: 'High heels',
            description: "Oh my! You are high heels! Pretty and aesthetic. It's not about how many steps you take, it's about how gracefully you take them. We know you’re perfect.",
            callToAction: '👞 Grab your shoes and walk with Sweatcoin',
            ps: 'P.S. at least not barefoot',
            shareTitle: 'I am high heels! Which shoes are you? @Sweatcoin',
            shareUrl: 'https://sweatco.in/quiz/shoes/high-heels',
            hashtags: 'sweatcoin,quiz,shoesquiz',
        },
        Slippers: {
            img: slippers,
            title: 'Slippers',
            description: 'Oh my! You are slippers! Soft and cozy. You prefer to stay at home (and not just in 2020). Keep your house clean as well as your mind. We appreciate you.',
            callToAction: '👞 Grab your shoes and walk with Sweatcoin',
            ps: 'P.S. at least not barefoot',
            shareTitle: 'I am slippers! Which shoes are you? @Sweatcoin',
            shareUrl: 'https://sweatco.in/quiz/shoes/slippers',
            hashtags: 'sweatcoin,quiz,shoesquiz',
        },
        Cowboy_boots: {
            img: cowboyBoots,
            title: 'Cowboy boots',
            description: 'Oh my! You are cowboy boots! Classy and durable. You might not be simple, but you’re definitely attention-catching. Stay confident in yourself. We admire you.',
            callToAction: '👞 Grab your shoes and walk with Sweatcoin',
            ps: 'P.S. at least not barefoot',
            shareTitle: 'I am cowboy boots! Which shoes are you? @Sweatcoin',
            shareUrl: 'https://sweatco.in/quiz/shoes/cowboy-boots',
            hashtags: 'sweatcoin,quiz,shoesquiz',
        },
        Roller_skates: {
            img: rollerSkates,
            title: 'Roller skates',
            description: 'Oh my! You are roller skates! Fast and competitive. You bring fun into people’s lives and take stress away. By the way, thanks for eco-friendly transportation.',
            callToAction: '👞 Grab your shoes and walk with Sweatcoin',
            ps: 'P.S. at least not barefoot',
            shareTitle: 'I am roller skates! Which shoes are you? @Sweatcoin',
            shareUrl: 'https://sweatco.in/quiz/shoes/roller-skates',
            hashtags: 'sweatcoin,quiz,shoesquiz',
        },
        Crocs: {
            img: crocs,
            title: 'Crocs',
            description: 'Oh my! You are crocs! Trendy and lightweight. You get along literally with everyone. Don’t be offended when you’re called flip-flops. We love you.',
            callToAction: '👞 Grab your shoes and walk with Sweatcoin',
            ps: 'P.S. at least not barefoot',
            shareTitle: 'I am crocs! Which shoes are you? @Sweatcoin',
            shareUrl: 'https://sweatco.in/quiz/shoes/crocs',
            hashtags: 'sweatcoin,quiz,shoesquiz',
        },
    },
};
