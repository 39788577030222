import PFDINWoff from './PF_Din_Mono_W01_Regular.woff'
import PFDINWoff2 from './PF_Din_Mono_W01_Regular.woff2'
import RobotoMeidumWoff from './roboto_medium.woff'
import RobotoMeidumWoff2 from './roboto_medium.woff2'
import RobotoRegularWoff from './roboto_regular.woff'
import RobotoRegularWoff2 from './roboto_regular.woff2'

const FONTS = {
  RobotoRegular: {
    name: 'Roboto',
    woff: RobotoRegularWoff,
    woff2: RobotoRegularWoff2,
  },
  RobotoMedium: {
    name: 'Roboto',
    weight: 700,
    woff: RobotoMeidumWoff,
    woff2: RobotoMeidumWoff2,
  },
  PFDIN: {
    name: 'PFDinMono',
    woff: PFDINWoff,
    woff2: PFDINWoff2,
  },
}

export default FONTS

export const setFontFace = (font, weight = 'normal') => `
@font-face {  
  font-family: '${font.name}';  
  font-weight: ${weight};
  font-display: swap; 
  /* Download only latin glyphs */ 
  unicode-range: U+000-5FF; 
  src: local('${font.name}'), url('${font.woff2}') format('woff2'), url('${font.woff}') format('woff');
}
`
