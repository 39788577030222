import classnames from 'classnames';
import partial from 'lodash/partial';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import 'styles/page-control';
export default class PageControl extends Component {
    static propTypes = {
        page: PropTypes.number.isRequired,
        pages: PropTypes.number.isRequired,
        onPageClick: PropTypes.func,
        appearance: PropTypes.oneOf(['black', 'white']),
    };
    static defaultProps = {
        page: 0,
        pages: 0,
        onPageClick: () => { },
    };
    render() {
        const modifiers = {
            [`is-${this.props.appearance}`]: this.props.appearance,
        };
        return (React.createElement("div", { className: classnames(this.props.className, 'page-control', modifiers) }, this.props.pages > 8 ? this._renderCounter() : range(this.props.pages).map(this._renderPage.bind(this))));
    }
    _renderPage(index) {
        if (this.props.pages <= 1) {
            return null;
        }
        const modifiers = {
            'is-selected': index === this.props.page,
        };
        return (React.createElement("div", { key: index, className: classnames('page-control__page', modifiers), onClick: partial(this.props.onPageClick, index) },
            React.createElement("span", null)));
    }
    _renderCounter() {
        return (React.createElement("div", { className: classnames('page-control__counter') },
            React.createElement("strong", null, this.props.page + 1),
            " / ",
            this.props.pages));
    }
}
