import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import React from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import useOutbrainAnalytics from 'utils/useOutbrainAnalytics';
import { useFirebaseEssentials } from 'routes/InvitePages/utils/firebase';
import analytics from '../../../utils/analytics';
import Footer from '../../Home/components/Footer';
import blobs from '../assets/blobs.png';
import DownloadButton from '../components/DownloadButton';
import Header from '../components/Header';
import NavigationButtons from '../components/NavigationButtons';
import Phone from '../components/Phone';
import Stores from '../components/Stores';
import Testimonials from '../components/Testimonials';
import UsageCards from '../components/UsageCards';
const onEnterSelector = (state) => get(state, 'onEnter', false);
const messages = defineMessages({
    title: 'It pays to walk',
    description: '150M+ people use Sweatcoin to:',
});
const WebToApp = (props) => {
    const { match } = props;
    const { params } = match;
    const [activeSlide, setActiveSlide] = React.useState(0);
    const { firebaseData: { firebaseQuery, getRedirectLink }, buttonsRefs: [topButton, bottomButton], tracking: { channel, platform, feature, campaign }, config: { origin }, } = useFirebaseEssentials({
        path: '/app',
        deeplink_path: '/today',
        feature: 'web_to_app',
    });
    const trackClick = useOutbrainAnalytics();
    const onNavLinkClick = (e, index) => {
        e.preventDefault();
        setActiveSlide(index);
        const section = document.getElementById('cards');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const onEnter = useSelector(onEnterSelector);
    return onEnter.redirectUrl ? (React.createElement(Redirect, { to: onEnter.redirectUrl })) : (React.createElement(Section, null,
        React.createElement(Helmet, { meta: [
                { name: 'event-id', content: 'web-to-app-page' },
                {
                    name: 'event-params',
                    content: pickBy({
                        channel,
                        platform,
                        feature,
                        campaign,
                    }, identity),
                },
            ], link: [
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap',
                },
            ] }),
        React.createElement(Blobs, null),
        React.createElement(Container, null,
            React.createElement(Header, { title: React.createElement(FormattedMessage, { ...messages.title }), description: React.createElement(FormattedMessage, { ...messages.description }) }),
            React.createElement(NavigationButtons, { onNavLinkClick: onNavLinkClick }),
            React.createElement(TopDownloadButton, { ref: topButton, link: firebaseQuery.link, onClick: handleClick }),
            React.createElement(Stores, null),
            React.createElement(Testimonials, null),
            React.createElement(UsageCards, { activeSlide: activeSlide, onChange: (index) => setActiveSlide(index) }),
            React.createElement(Phone, null),
            React.createElement(BottomDownloadButton, { ref: bottomButton, link: firebaseQuery.link, onClick: handleClick })),
        React.createElement(Footer, { bgColor: "transparent" })));
    function handleClick(event) {
        event.preventDefault();
        trackClick('Install');
        analytics.track('WebToAppPage', 'redirect', pickBy({
            app_link: params.path,
            origin,
            channel,
            platform,
            feature,
            campaign,
        }, identity));
        window.location = getRedirectLink();
    }
};
const Section = styled.section `
  position: relative;
  z-index: 1;
  overflow: hidden;
  min-height: 100vh;
  background: radial-gradient(130.98% 24.71% at 50.13% 16.33%, #9e98ff 0%, #6058d7 100%);
  background-size: cover;
`;
const Container = styled.div `
  position: relative;
  padding: 60px 0 0;
  max-width: 430px;
  margin: 0 auto;
`;
const Blobs = styled.div `
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  background: url(${blobs}) no-repeat center -450px;
  background-size: 859px;
  z-index: -1;
`;
const TopDownloadButton = styled(DownloadButton) `
  margin: 50px auto;
`;
const BottomDownloadButton = styled(DownloadButton) `
  margin: 0 auto;
`;
export default WebToApp;
