import classnames from 'classnames';
import React from 'react';
import PageControl from '../../../components/PageControl';
import PagingView from '../../../components/PagingView';
import '../styles/gallery';
export default class Gallery extends React.Component {
    state = {
        page: 0,
        pages: 0,
    };
    render() {
        return (React.createElement("div", { className: classnames(this.props.className, 'gallery', { 'has-pages': this.state.pages > 1 }) },
            React.createElement("div", { className: "gallery__container" },
                React.createElement("button", { rel: "prev", className: classnames({ 'is-disabled': this.state.page === 0 }), onClick: this._handlePrevClick.bind(this), onTouchStart: this._handlePrevClick.bind(this) },
                    React.createElement("span", null,
                        React.createElement("i", { className: "icon-arrow-left" }))),
                React.createElement("button", { rel: "next", className: classnames({ 'is-disabled': this.state.page === this.state.pages - 1 }), onClick: this._handleNextClick.bind(this), onTouchStart: this._handleNextClick.bind(this) },
                    React.createElement("span", null,
                        React.createElement("i", { className: "icon-arrow-right" }))),
                React.createElement(PagingView, { className: "gallery__content", page: this.state.page, onPagesChange: this._handlePagesChange.bind(this) }, (this.props.models || []).map(this._renderPhoto.bind(this)))),
            this.state.pages > 1 ? (React.createElement(PageControl, { className: "gallery__page-control", page: this.state.page, pages: this.state.pages, onPageClick: this._handlePagesChange.bind(this) })) : null));
    }
    _renderPhoto(model, index) {
        return React.createElement("div", { key: `photo-${index}`, className: "gallery__photo", style: { backgroundImage: `url(${model.cover})` } });
    }
    _handlePagesChange(page, pages = this.state.pages) {
        this.setState({ page, pages });
    }
    _handlePrevClick(event) {
        event.preventDefault();
        this._updatePage(this.state.page - 1, this.state.pages);
    }
    _handleNextClick(event) {
        event.preventDefault();
        this._updatePage(this.state.page + 1, this.state.pages);
    }
    _updatePage(page, pages) {
        page = Math.max(0, Math.min(page, pages - 1));
        this.setState({ page, pages });
    }
}
