import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import styled from 'styled-components';
import lock from 'assets/Home/home_lock_icon.png';
import pie from 'assets/Home/home_pie_icon.png';
import arrow from 'assets/Home/link-arrow.png';
import { breakpoints, desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    title: 'A new <span>global economy of wealth</span> through health is <span>coming.</span>',
    subtitle: 'Get ready and start growing your wallet today.',
    card1Number: '100%',
    card1Title: 'Data privacy',
    card1Text1: 'We do not sell your data',
    card1Text2: 'The safety of your data is our top priority. It is not for sale to any third party, and never will be.',
    card1Text3: 'Verification algorithm',
    card2Number: '+20%',
    card2Title: 'Activity increase',
    card2Text1: 'British Journal of Sports Medicine',
    card2Text2: 'Sweatcoin has achieved sustainable physical activity behaviour change using a viable business model that can continuously reward its users for being active.',
    findOutMore: 'Find out more',
});
const GlobalEconomy = () => {
    const settings = {
        arrows: false,
        infinite: false,
        swipeToSlide: true,
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: breakpoints.tablet,
                settings: {
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: breakpoints.desktop,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    };
    return (React.createElement(Wrapper, { id: "facts" },
        React.createElement(FormattedMessage, { ...messages.title }, (title) => React.createElement(Title, null, title)),
        React.createElement(Subtitle, null,
            React.createElement(FormattedMessage, { ...messages.subtitle })),
        React.createElement(Cards, { ...settings },
            React.createElement(Card, null,
                React.createElement(CardNumber, null,
                    React.createElement(FormattedMessage, { ...messages.card1Number })),
                React.createElement(CardTitle, null,
                    React.createElement(FormattedMessage, { ...messages.card1Title })),
                React.createElement(CardText, null,
                    React.createElement("strong", null,
                        React.createElement(FormattedMessage, { ...messages.card1Text1 }))),
                React.createElement(CardText, null,
                    React.createElement(FormattedMessage, { ...messages.card1Text2 })),
                React.createElement(TextLink, null,
                    React.createElement(NavLink, { to: "/verification-algorithm" },
                        React.createElement(FormattedMessage, { ...messages.card1Text3 }))),
                React.createElement(TextLink, null,
                    React.createElement(NavLink, { to: "/privacy" },
                        React.createElement(FormattedMessage, { ...messages.findOutMore }))),
                React.createElement(Icon, { src: lock })),
            React.createElement(Card, null,
                React.createElement(CardNumber, null,
                    React.createElement(FormattedMessage, { ...messages.card2Number })),
                React.createElement(CardTitle, null,
                    React.createElement(FormattedMessage, { ...messages.card2Title })),
                React.createElement(CardText, null,
                    React.createElement("strong", null,
                        React.createElement(FormattedMessage, { ...messages.card2Text1 }))),
                React.createElement(CardText, null,
                    React.createElement(FormattedMessage, { ...messages.card2Text2 })),
                React.createElement(TextLink, null,
                    React.createElement("a", { href: "https://bjsm.bmj.com/content/bjsports/early/2019/01/04/bjsports-2018-099739.full.pdf", rel: "noreferrer noopener" },
                        React.createElement(FormattedMessage, { ...messages.findOutMore }))),
                React.createElement(Icon, { src: pie })))));
};
const Wrapper = styled.div `
  margin: 0 16px;

  @media ${desktop()} {
    width: 980px;
    margin: 0 auto;
    padding-top: 160px;
  }
`;
const Title = styled.h2 `
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.6);
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 18px;

  span {
    color: #fff;
  }

  @media ${desktop()} {
    font-size: 52px;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 24px;
  }
`;
const Subtitle = styled.h4 `
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.2;

  @media ${desktop()} {
    font-size: 24px;
    text-align: center;
  }
`;
const Cards = styled(Slider) `
  display: flex;
  margin: 50px auto 0;
  flex-direction: column;

  .slick-list {
    overflow: visible;
  }

  @media ${desktop()} {
    margin: 160px auto 0;
    display: block;
  }
`;
const Card = styled.div `
  width: calc(100% - 16px) !important;
  height: 580px !important;
  box-sizing: border-box;
  padding: 28px 25px 10px 25px;
  background: linear-gradient(170deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.05) 100%);
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.05);
  position: relative;

  @media ${desktop()} {
    padding: 40px 35px 12px 35px;
    width: 473px !important;
    height: 670px;
  }
`;
const CardNumber = styled.h5 `
  font-weight: 900;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 40px;
  letter-spacing: 1px;
  line-height: 1.2;
  text-transform: uppercase;
  margin-bottom: 8px;

  @media ${desktop()} {
    font-size: 60px;
  }
`;
const CardTitle = styled.h6 `
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 24px;
  line-height: 1.2;
  margin-bottom: 24px;

  @media ${desktop()} {
    font-size: 28px;
  }
`;
const CardText = styled.p `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 20px;

  strong {
    font-weight: 700;
  }

  @media ${desktop()} {
    font-size: 18px;
  }
`;
const TextLink = styled.p `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 20px;

  &::after {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    transform: rotate(45deg) translateY(5px) translateX(5px);
    background: url(${arrow}) 50% 50% no-repeat;
    background-size: contain;
    margin-left: 5px;
  }

  a {
    color: #fff;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease-in-out;

    &:before {
      content: '';
      background: #fff;
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 0;
      height: 1px;
      transition: all 0.3s ease-in-out;
    }

    &:hover::before {
      width: 100%;
    }
  }
`;
const Icon = styled.div `
  width: 150px;
  height: 150px;
  ${({ src }) => `background: url(${src}) 50% 50% no-repeat;`}
  background-size: contain;
  margin-left: auto;

  @media ${desktop()} {
    width: 212px;
    height: 212px;
    position: absolute;
    right: 0;
    bottom: 41px;
    margin: 0;
  }
`;
export default GlobalEconomy;
