const createRoutes = () => [
    {
        path: '/sweatpaws/:result',
        cookies: false,
        component: require('./pages/SweatPaws').default,
    },
    {
        path: '/sweatpaws',
        params: { disableDefaultImageSrc: true },
        cookies: false,
        exact: true,
        component: require('./pages/SweatPaws').default,
    },
];
export default createRoutes;
