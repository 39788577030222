import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { AdviceNotSuitable, Common, Footer, List, ListItem, SamsungDivider, SettingsButton, Title } from './common';
const messages = defineMessages({
    removeTitle: 'Remove restrictions',
    settings: 'Settings',
    apps: 'Apps',
    allApps: 'See all apps',
    menu: 'Menu in top-right corner',
    system: 'Show system',
    close: 'Force close “Power Saver” app',
});
const Nokia = (props) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Common, { featureAccelerometerTracker: props.featureAccelerometerTracker, trackEvent: props.trackEvent, openSettings: props.openSettings, isFooterDividerDefault: true }),
        React.createElement(Title, { trackEvent: props.trackEvent },
            React.createElement(FormattedMessage, { ...messages.removeTitle })),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.settings })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.apps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.allApps })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.menu })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.system })),
            React.createElement(ListItem, null,
                React.createElement(FormattedMessage, { ...messages.close }))),
        React.createElement(SettingsButton, { openSettings: props.openSettings, style: { marginTop: '16px', marginBottom: '16px' } }),
        React.createElement(SamsungDivider, null),
        React.createElement(Footer, { trackEvent: props.trackEvent }),
        React.createElement(AdviceNotSuitable, { trackEvent: props.trackEvent })));
};
export default Nokia;
