import { useEffect } from 'react';
const useOutbrainAnalytics = () => {
    useEffect(() => {
        /** DO NOT MODIFY THIS CODE**/
        !(function (_window, _document) {
            var OB_ADV_ID = '007c59ad4ffd50bbc7c749ae72edc9550e';
            if (_window.obApi) {
                var toArray = function (object) {
                    return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
                };
                _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
                return;
            }
            var api = (_window.obApi = function () {
                api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
            });
            api.version = '1.1';
            api.loaded = true;
            api.marketerId = OB_ADV_ID;
            api.queue = [];
            var tag = _document.createElement('script');
            tag.async = true;
            tag.src = 'https://amplify.outbrain.com/cp/obtp.js';
            tag.type = 'text/javascript';
            var script = _document.getElementsByTagName('script')[0];
            script.parentNode.insertBefore(tag, script);
            return () => {
                // clean up the script when the component in unmounted
                script.parentNode.removeChild(tag);
            };
        })(window, document);
        obApi('track', 'PAGE_VIEW');
    }, []);
    function trackEvent(event_id) {
        if (window.obApi) {
            obApi('track', event_id);
        }
    }
    return trackEvent;
};
export default useOutbrainAnalytics;
